import React, { useRef, useState } from 'react';

import LearningStatusContainer from './LearningStatusContainer';
import LearningHabitContainer from './LearningHabitContainer';
import LearningAchievementContainer from './LearningAchievementContainer';
import LearningAnalyticsContainer from './LearningAnalyticsContainer';

import NavigatorComponent from '../reportPage/reportMainComponent/NavigatorComponent';
import ContentsComponent from '../reportPage/reportMainComponent/ContentsComponent';
import { useParams } from 'react-router-dom';
import MainLayout from '../../components/MainLayout';
import AT from '../../assets/texts/AT';
const url = ['achievement', 'status', 'habit', 'analytics', 'achievement/diagnosis', 'achievement/program'];
const reportMessage = {
    [url[0]]: AT(1),
    [url[1]]: AT(2),
    [url[2]]: AT(3),
    [url[3]]: AT(4)
};

export default function ReportMainContainer() {
    const { viewType = url[0], detail, domain: _domain, status: _status, grade: _grade } = useParams();
    const [grade, setGrade] = useState(_grade);
    const [status, setStatus] = useState(_status || '1');
    const [domain, setDomain] = useState(_domain);
    const initialDomain = useRef();

    return (
        <>
            {!detail && (
                <MainLayout back message={reportMessage[viewType]} robotSrc={'AT1.svg'} wrongAnswerView helpRequest>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <NavigatorComponent url={url} viewType={viewType} />
                        {viewType === url[0] && (
                            <ContentsComponent>
                                <LearningAchievementContainer
                                    url={url}
                                    viewType={viewType}
                                    detail={detail}
                                    grade={grade}
                                    setGrade={setGrade}
                                    domain={domain}
                                    setDomain={setDomain}
                                    status={status}
                                    setStatus={setStatus}
                                    initialDomain={initialDomain}
                                />
                            </ContentsComponent>
                        )}
                        {(viewType === url[1] || viewType === url[3]) && (
                            <ContentsComponent padding>
                                {viewType === url[1] && <LearningStatusContainer />}
                                {viewType === url[3] && <LearningAnalyticsContainer />}
                            </ContentsComponent>
                        )}
                        {viewType === url[2] && (
                            <ContentsComponent>
                                <LearningHabitContainer />
                            </ContentsComponent>
                        )}
                    </div>
                </MainLayout>
            )}
            {detail && (
                <ContentsComponent>
                    <LearningAchievementContainer
                        url={url}
                        viewType={viewType}
                        detail={detail}
                        grade={grade}
                        setGrade={setGrade}
                        domain={domain}
                        setDomain={setDomain}
                        status={status}
                        setStatus={setStatus}
                        initialDomain={initialDomain}
                    />
                </ContentsComponent>
            )}
        </>
    );
}
