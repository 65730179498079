import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import GR from '../../../assets/texts/GR';
import { ReactComponent as Robot } from '../../../assets/images/character-anim/GR1_character.svg';
import useSound from '../../../hooks/useSound';
import { soundPath } from '../../../utils/util';
import media from '../../../utils/media';
const Block = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 220;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 10%;
    ${media.small} {
        padding: 40px;
    }
    & > div {
        margin-bottom: 1em;
    }
`;
export default function RewardIntro({ onEnd }) {
    const txtObj = GR(1);
    const timer = useRef();
    const { pause } = useSound({ src: `${soundPath}/${txtObj.src}`, pauseOnUnmount: true });
    const [hide, setHide] = useState(false);
    useEffect(() => {
        timer.current = setTimeout(setHide, 2500, true);
        return () => clearTimeout(timer.current);
    }, []);
    useEffect(() => {
        if (hide) {
            pause();
            if (onEnd) {
                onEnd();
            }
        }
    }, [hide, onEnd, pause]);
    if (hide) return null;
    return (
        <Block className="ft-title" onClick={setHide.bind({}, true)}>
            <div>{txtObj.text}</div>
            <Robot />
        </Block>
    );
}
