const SET_GRADE_CODE = 'learningInfo/SET_GRADE_CODE';
const SET_CURRICULUM_CODE = 'learningInfo/SET_CURRICULUM_CODE';
const SET_CURRICULUM_NAME = 'learningInfo/SET_CURRICULUM_NAME';
const SET_PROGRAM = 'learningInfo/SET_PROGRAM';
const SET_REWARD = 'learningInfo/SET_REWARD';
const SET_HABIT_STATE = 'learningInfo/SET_HABIT_STATE';
const SET_FIRST_LOGIN = 'learningInfo/SET_FIRST_LOGIN';
const SET_PREIVIOUS_PAGE = 'learningInfo/SET_PREIVIOUS_PAGE';
const SET_MAIN_REWARD = 'learningInfo/SET_MAIN_REWARD';
const SET_REWARD_PAGE_TRANSITION = 'learningInfo/SET_REWARD_PAGE_TRANSITION';
const SET_REPORT_LEARNING_BACK = 'learningInfo/SET_REPORT_LEARNING_BACK';
const SET_LEARNING_TARGET = 'learningInfo/SET_LEARNING_TARGET';
const keyInfo = {
    [SET_GRADE_CODE]: 'gradeCode',
    [SET_CURRICULUM_CODE]: 'curriculumCode',
    [SET_CURRICULUM_NAME]: 'curriculumName',
    [SET_PROGRAM]: 'program',
    [SET_REWARD]: 'reward',
    [SET_HABIT_STATE]: 'habitState',
    [SET_FIRST_LOGIN]: 'firstLogin',
    [SET_PREIVIOUS_PAGE]: 'previousPage',
    [SET_MAIN_REWARD]: 'mainReward',
    [SET_REWARD_PAGE_TRANSITION]: 'rewardPageTransition',
    [SET_REPORT_LEARNING_BACK]: 'reportLearningBack',
    [SET_LEARNING_TARGET]: 'learningTarget'
};

const makeNewState = (state, action, key) => ({ ...state, [key]: action[key] });
const actionCreator = type => data => ({ type: type, [keyInfo[type]]: data });

const initialState = {
    [keyInfo[SET_GRADE_CODE]]: null,
    [keyInfo[SET_CURRICULUM_CODE]]: null,
    [keyInfo[SET_CURRICULUM_NAME]]: null,
    [keyInfo[SET_PROGRAM]]: {},
    [keyInfo[SET_REWARD]]: [],
    [keyInfo[SET_HABIT_STATE]]: null,
    [keyInfo[SET_FIRST_LOGIN]]: false,
    [keyInfo[SET_PREIVIOUS_PAGE]]: '',
    [keyInfo[SET_MAIN_REWARD]]: [],
    [keyInfo[SET_REWARD_PAGE_TRANSITION]]: false,
    [keyInfo[SET_REPORT_LEARNING_BACK]]: false,
    [keyInfo[SET_LEARNING_TARGET]]: null
};

export const setGradeCode = actionCreator(SET_GRADE_CODE);
export const setCurriculumCode = actionCreator(SET_CURRICULUM_CODE);
export const setCurriculumName = actionCreator(SET_CURRICULUM_NAME);
export const setProgram = actionCreator(SET_PROGRAM);
export const setReward = actionCreator(SET_REWARD);
export const setHabitState = actionCreator(SET_HABIT_STATE);
export const setFirstLogin = actionCreator(SET_FIRST_LOGIN);
export const setPreviousPage = actionCreator(SET_PREIVIOUS_PAGE);
export const setMainReward = actionCreator(SET_MAIN_REWARD);
export const setRewardPageTransition = actionCreator(SET_REWARD_PAGE_TRANSITION);
export const setReportLearningBack = actionCreator(SET_REPORT_LEARNING_BACK);
export const setLearningTarget = actionCreator(SET_LEARNING_TARGET);

export default function learningInfo(state = initialState, action) {
    if (!keyInfo[action.type]) return state;
    return makeNewState(state, action, keyInfo[action.type]);
}
