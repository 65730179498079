import React, { useRef, useState, useCallback, useEffect, useReducer, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as lms from '../api/lms';
import * as util from '../utils/util';
import { setProgram, setReward, setGradeCode, setMainReward } from '../reducers/learningInfo';
import { initLearningGrade } from '../reducers/learningGrade';
import { initLearningCurriculum } from '../reducers/learningCurriculum';
import CF from '../assets/texts/CF';
import { ReactComponent as ChallengeHeart } from '../assets/images/icon-anim/CF7_icon.svg';
import useHabitState from './useHabitState';
import usePromise from './usePromise';
import useLearnerInfo from './useLearnerInfo';
import InformationBody from '../pages/learningPage/components/InformationBody';
import { grades, planetNames } from '../utils/dataMap';
import ModalBodyText from '../components/ModalBodyText';
import moment from 'moment';
import SimpleRobotModalBody from '../components/SimpleRobotModalBody';
import TimerModalBody from '../pages/learningPage/components/TimerModalBody';
import useProgramPage from './useProgramPage';
function feedbackReducer(state, action) {
    //맞다가 틀림 혹은 틀리다가 맞음
    if (action.result * state.count < 0)
        return { count: 0, type: action.result > 0 ? 1 : 2, isDiagnosis: action.isDiagnosis };
    //연속 다섯번
    if (Math.abs(state.count) >= 4)
        return { count: state.count + action.result, type: action.result > 0 ? 3 : 4, isDiagnosis: action.isDiagnosis };
    return { count: state.count + action.result, type: action.result > 0 ? 1 : 2, isDiagnosis: action.isDiagnosis };
}
export default function useLearningWindow() {
    //redux,history,custom hooks

    const { gradeCode, curriculumCode, program, curriculumName, previousPage } = useSelector(
        state => state.learningInfo
    );
    const { programId, programName } = program;
    const { setFocusIndex, setNeedProgramFocus, focusArea } = useProgramPage();
    const { data: frameLearning, error: frameLearningError } = usePromise(
        [lms.getLearningFrame, lms.getRepeatLearningFrame][focusArea],
        !(gradeCode && curriculumCode && programId),
        {
            gradeCode,
            curriculumCode,
            programId
        }
    );
    const { data: curriculums } = useSelector(state => state.learningCurriculum);
    const dispatch = useDispatch();
    const history = useHistory();
    const { getHabitState, setHabiStateAlready } = useHabitState();
    const { data: learnerInfo } = useLearnerInfo();

    //ref, state
    const timerRef = useRef();
    const iframe = useRef();
    const programInfo = useRef();
    const frameId = useRef('');
    const helpContent = useRef('');
    const isTimerEnd = useRef(false);
    const [modalInfo, setModalInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [timerPause, setTimerPause] = useState(false);
    const [feedback, setFeedback] = useReducer(feedbackReducer, { count: 0 });
    const [programDetailModal, setProgramDetailModal] = useState({ show: false, data: null });
    const [learningTimes, setLearningTimes] = useState([]);
    const [toastData, setToastData] = useState({ show: false, message: '' });
    const [error, setError] = useState();
    //기타 필요 변수들

    const isDiagnosis = useMemo(() => (programId ? programId.indexOf('DG') !== -1 : false), [programId]);
    //프로그램 디테일 관련
    const programDetail = usePromise([lms.getProgramDetail, lms.getRepeatProgramDetail][focusArea], true, {
        curriculumCode,
        gradeCode,
        programId
    });
    //도움요청
    const { data: helpRemainCount, refresh: getHelpRemainCount } = usePromise(lms.getHelpRemainCount, true);

    //별보상을 별자리 보상으로 바꾸어야 하는지 판단
    const setStarReward = useCallback(async (starCount, rewardArr) => {
        try {
            if (starCount) {
                const { result, reward_name, reward_id } = await lms.postRewardConstellation();
                if (result === 'success')
                    rewardArr[0] = {
                        type: reward_id,
                        value: starCount,
                        txtType: 16,
                        params: { starCount, constellationName: reward_name, id: Number(reward_id.replace('CS.', '')) }
                    };
            }
        } catch (e) {
            setError(e);
        }
    }, []);
    //리워드 공통 부분 빼는 함수
    const getPreviousPage = useCallback(
        url => (previousPage.indexOf('report') !== -1 ? previousPage : url),
        [previousPage]
    );
    const setCommonReward = useCallback(
        async ({ rewardArr, to, starCount, habitState: _habitState }) => {
            try {
                const { subject_status_code, learning_end_date } = learnerInfo;
                if (to === '/program') setNeedProgramFocus(true);
                const habitState = _habitState || (await getHabitState());
                const mainReward = [];
                if (habitState.result === 'success') {
                    mainReward.push({ txtType: 7, rewardCount: habitState.rewardCount });
                    setHabiStateAlready();
                }
                if (habitState.result !== 'belowStandards') {
                    //체험회원
                    if (subject_status_code === '0') {
                        if (moment(learning_end_date).format('YYYYMMDD') === moment().format('YYYYMMDD'))
                            mainReward.push(11);
                        else mainReward.push(6);
                    }
                    //서비스회원
                    if (subject_status_code === '1') mainReward.push(10);
                }
                dispatch(setMainReward(mainReward));
                if (rewardArr.length < 1) return history.replace(to);
                rewardArr.push({ type: 'stop', params: { to } });
                const filterRewards = util.filterRewards(rewardArr);
                if (filterRewards.length > 0) await lms.postRewardHistory(util.filterRewards(rewardArr));
                await setStarReward(starCount, rewardArr);

                dispatch(setReward(rewardArr));
                history.replace('/reward-item');
            } catch (e) {
                setError(e);
            }
        },
        [dispatch, history, setStarReward, learnerInfo, getHabitState, setHabiStateAlready, setNeedProgramFocus]
    );
    //methods

    const forcedStop = useCallback(
        async (starCount, habitState) => {
            const rewardArr = starCount
                ? [{ type: 'RW.01', value: starCount, txtType: 15, params: { starCount } }]
                : [];
            setCommonReward({ rewardArr, to: getPreviousPage('/program'), starCount, habitState });
        },
        [setCommonReward, getPreviousPage]
    );
    const postLearningHistory = useCallback(
        ({ data }) => {
            const { learningHistoryStatusCode } = frameLearning;
            const { programType, learningLogicType, trackingId } = programInfo.current;
            const body = {
                ...data,
                learningHistoryStatusCode,
                curriculumCode,
                programId,
                programType,
                learningLogicType,
                trackingId
            };
            return lms.postLearningHistory(body);
        },
        [curriculumCode, frameLearning, programId]
    );
    //lifecycle methods
    const onBeforeLoad = ({ programInfo: _programInfo, frameId: _frameId, repeatGenerate }) => {
        programInfo.current = _programInfo;
        frameId.current = _frameId;
        if (_programInfo.learningLogicType === 'G1' && !repeatGenerate) {
            const txtObj = CF(4, { questionCount: _programInfo[_frameId].repeatCount });
            setModalInfo({
                ...txtObj,
                body: <SimpleRobotModalBody text={txtObj.body}></SimpleRobotModalBody>,
                buttons: [{ text: txtObj.buttonTexts[0], onClick: setModalInfo.bind(null, null) }],
                onClose: setModalInfo.bind(null, null)
            });
        }
    };
    const onRepeatGenerate = useCallback(code => {
        if (code === '98' || code === '97') {
            setTimerPause(true);
            const txtObj = CF(code === '98' ? 11 : 2);

            return new Promise(resolve => {
                const onClick = () => {
                    setModalInfo(null);
                    isTimerEnd.current = false;
                    setTimerPause(false);
                    resolve();
                };
                setModalInfo({
                    ...txtObj,
                    body: <SimpleRobotModalBody text={txtObj.body}></SimpleRobotModalBody>,
                    onClose: onClick,
                    buttons: [
                        {
                            text: txtObj.buttonTexts[0],
                            onClick: onClick
                        }
                    ]
                });
            });
        }
    }, []);
    const onLoad = () => {
        setLoading(false);
    };

    const onBeforeTimer = useCallback(({ time, questionCount, repeatGenerate }) => {
        if (repeatGenerate) {
            return;
        }
        const txtObj = CF(5, { questionCount });

        return new Promise(resolve => {
            const onClick = () => {
                setModalInfo(null);
                resolve();
            };
            setModalInfo({
                ...txtObj,
                body: (
                    <TimerModalBody time={time}>
                        <ModalBodyText text={txtObj.body} />
                    </TimerModalBody>
                ),
                onClose: onClick,
                buttons: [
                    {
                        text: txtObj.buttonTexts[0],
                        onClick
                    }
                ]
            });
        });
    }, []);
    const onQuestionEnd = useCallback(
        async data => {
            const { frameCurrentStartTime, frameCurrentResult, frameCurrentIndex, frameLearningCount } = data;
            const newTimes = learningTimes
                .slice(Math.max(learningTimes.length - 2, 0))
                .concat((new Date().getTime() - frameCurrentStartTime) / 1000);
            const { learningLogicType } = programInfo.current;
            setLearningTimes(newTimes);
            setFeedback({ result: [-1, 1][frameCurrentResult], isDiagnosis });
            const needConcentration =
                programId.indexOf('DG') === -1 && //진단아님
                Math.abs(feedback.count % 3) === 2 && //3번연속
                data.frameCurrentResult === 0 && //오답
                feedback.count < 0 && // 오답연속
                ['A1', 'B1', 'B2'].includes(learningLogicType) && //응용, 기본
                newTimes.every(el => el < 5) && //5초 이내
                frameLearningCount - 1 !== frameCurrentIndex; // 마지막 문제 제외
            timerRef.current = setTimeout(() => {
                if (!needConcentration) {
                    //제너레이트 틀렸을때,
                    if (data.repeatG2 && !isTimerEnd.current)
                        iframe.current.contentWindow.postMessage({ code: '18' }, '*');
                    //그렇지 않을때
                    else setTimerPause(false);
                }
                if (needConcentration) {
                    const txtObj = CF(13);
                    setModalInfo({
                        ...txtObj,
                        body: <SimpleRobotModalBody text={txtObj.body} />,
                        onClick: setModalInfo.bind(null, null),
                        buttons: [
                            {
                                text: txtObj.buttonTexts[0],
                                onClick: setModalInfo.bind(null, null)
                            }
                        ]
                    });
                }
            }, 2000);
            try {
                if (data.paths.length > 0) {
                    await lms.postLearningLog({
                        type: 'scratch-pad',
                        trackingId: data.trackingId,
                        frameId: data.frameId,
                        frameRepeatCount: data.frameRepeatCount,
                        frameLearningOrder: data.frameLearningOrder,
                        frameLearningCount: data.frameLearningCount,
                        frameCurrentIndex: data.frameCurrentIndex,
                        frameCurrentResult: data.frameCurrentResult,
                        paths: data.paths
                    });
                }
            } catch (e) {
                setError(e);
            }
        },
        [learningTimes, feedback, programId, isDiagnosis]
    );
    const onTrackingEnd = useCallback(async data => {
        setTimerPause(true);
        if (!programInfo.current) return;
        const { trackingId, learningLogicType } = programInfo.current;
        const anim = document.getElementById('anim');
        const timerWidth =
            learningLogicType === 'G2' ? Math.round((anim.getBoundingClientRect().width / anim.offsetWidth) * 100) : 0;
        const trackingInfo = { timerWidth, trackingId, ...data };
        delete trackingInfo.code;
        try {
            await lms.postLearningTracking(trackingInfo);
        } catch (e) {
            setError(e);
        }
    }, []);

    const onTimerEnd = useCallback(async () => {
        isTimerEnd.current = true;
        iframe.current.contentWindow.postMessage({ code: '17' }, '*');
    }, []);

    const onFrameEnd = useCallback(
        async data => {
            try {
                programDetail.init();
                //G1,G2일 경우 초기화 학습시간 모아둔거 초기화
                if (['G1', 'G2'].includes(programInfo.current.learningLogicType)) {
                    setLearningTimes([]);
                }
                if (['91', '96', '97', '98', '99'].includes(data.code)) data.remark = data.code;
                let { innerSVG, code, ...learningHistoryData } = data;
                await postLearningHistory({ data: learningHistoryData });
                //선생님 도움 요청
                if (data.code === '96') {
                    await lms.postLearningHelp({
                        curriculumCode,
                        programId,
                        frameId: data.frameId,
                        helpFrameSvg: innerSVG,
                        helpMessage: helpContent.current
                    });
                    setToastData({ show: true, message: '선생님께 전달되었어요!' });
                    setModalInfo(null);
                    await util.sleep(1800);
                    setTimerPause(false);
                    setToastData({ show: false });
                    await getHelpRemainCount();
                }

                //체험학습,서비스학습자 10분 초과시 학습종료

                //학습자 강제종료
                if (data.code === '99') await forcedStop(data.starCount);
            } catch (e) {
                setError(e);
            }
        },
        [postLearningHistory, forcedStop, programDetail, curriculumCode, programId, getHelpRemainCount]
    );
    const onNoAction = useCallback(
        data => {
            const txtObj = CF(3);
            timerRef.current = setTimeout(() => {
                history.replace(getPreviousPage('/program'));
            }, 10000);
            const onClick = () => {
                onFrameEnd(data);
                clearTimeout(timerRef.current);
                setModalInfo(null);
                const src = iframe.current.src;
                iframe.current.src = src;
            };
            setModalInfo({
                ...txtObj,
                body: <SimpleRobotModalBody text={txtObj.body} />,
                onClose: onClick,
                buttons: [
                    {
                        text: txtObj.buttonTexts[0],
                        onClick
                    }
                ]
            });
        },
        [history, onFrameEnd, getPreviousPage]
    );
    const onNextFrame = useCallback(
        async starCount => {
            try {
                if (
                    (learnerInfo.subject_status_code === '0' || learnerInfo.subject_status_code === '1') &&
                    !isDiagnosis
                ) {
                    const habitState = await getHabitState();
                    if (habitState?.result !== 'belowStandards') {
                        //학습시간 10분 초과
                        await forcedStop(starCount, habitState);
                        return false;
                    }
                }
                return true;
            } catch (error) {
                setError(error);
            }
        },
        [getHabitState, isDiagnosis, learnerInfo, forcedStop]
    );
    const onProgramEnd = useCallback(
        async starCount => {
            try {
                const { learningHistoryStatusCode } = frameLearning;
                //복습일때
                if (learningHistoryStatusCode.indexOf('12') !== -1 || learningHistoryStatusCode.indexOf('13') !== -1) {
                    setCommonReward({
                        rewardArr: [{ type: 'RW.01', value: starCount, txtType: 15, params: { starCount } }],
                        to: getPreviousPage('/program'),
                        starCount
                    });
                    return;
                }
                setLoading(true);
                let {
                    code,
                    diagnosisProgramId,
                    learningProgramCount: programCount,
                    wrongObjectiveCount
                } = await lms.getLearningResult({
                    gradeCode,
                    curriculumCode,
                    programId,
                    learningHistoryStatusCode
                });
                // '20': 프로그램 미달성
                // '21': 커리큘럼 미달성
                // '40': 하위 진단프로그램 실행
                // '41': 자동이력 반영 후 하위 진단프로그램 실행
                // '51': 처방 있음 repeat
                // '52': 처방 있음 minus
                // '53': 처방 있음 system5
                // '30': 등급 미달성
                // '31': 등급 달성
                // '32': 수료

                if (['40', '41'].includes(code)) {
                    dispatch(setProgram({ ...program, programId: diagnosisProgramId })); // programId 변경
                    setLoading(false);
                    return;
                }
                if (['20', '21', '51', '52', '53'].includes(code)) {
                    const rewardArr = [];
                    if (starCount)
                        rewardArr.push({ type: 'RW.01', value: starCount, txtType: 15, params: { starCount } });
                    if (code === '20') rewardArr.push({ type: 'program-fail', txtType: 28, params: { programName } });
                    if (code === '21')
                        rewardArr.push({ type: 'RW.02', value: 1, txtType: 27, params: { programName } });
                    if (code === '51')
                        rewardArr.push({ type: 'repeat', txtType: 24, params: { programName, programCount } });
                    if (code === '52')
                        rewardArr.push({ type: 'minus', txtType: 25, params: { programName, programCount } });
                    if (code === '53')
                        rewardArr.push({ type: 'system5', txtType: 26, params: { programName, programCount } });
                    if (code === '20' || code === '21') setFocusIndex(null);

                    setCommonReward({ rewardArr, to: getPreviousPage('/program'), starCount });
                }
                if (['30', '31', '32'].includes(code)) {
                    const rewardArr = [];
                    if (starCount)
                        rewardArr.push({ type: 'RW.01', value: starCount, txtType: 15, params: { starCount } });
                    //프로그램 달성,진단
                    if (isDiagnosis)
                        rewardArr.push({
                            type: 'RW.03',
                            value: 1,
                            txtType: wrongObjectiveCount
                                ? 23
                                : util.getContinuousPassDiagnosis(curriculumCode, curriculums),
                            params: { programName }
                        });
                    else {
                        rewardArr.push({ type: 'RW.02', value: 1, txtType: 27, params: { programName } }); //프로그램 달성
                        rewardArr.push({ type: 'RW.04', value: 1, txtType: 29, params: { curriculumName } }); // 커리큘럼 달성
                    }
                    if (code === '31' || code === '32') {
                        // 등급 달성
                        rewardArr.push({
                            type: `RW.1${grades.indexOf(gradeCode)}`,
                            value: 1,
                            txtType: 30,
                            params: {
                                gradeCode,
                                displayName: learnerInfo.display_name,
                                planetName: planetNames[grades.indexOf(gradeCode)]
                            }
                        });
                        // 수료
                        if (code === '32')
                            rewardArr.push({
                                type: 'complete',
                                txtType: 31,
                                params: { displayName: learnerInfo.display_name }
                            });
                        dispatch(setGradeCode(null)); // gradeCode 초기화
                        dispatch(initLearningGrade()); // gradeInfos 초기화
                    }
                    // TODO curriculumInfos 초기화
                    dispatch(initLearningCurriculum());
                    setFocusIndex(null);
                    setCommonReward({ rewardArr, to: getPreviousPage('/main'), starCount });
                }
            } catch (e) {
                setError(e);
            }
        },
        [
            frameLearning,
            gradeCode,
            curriculumCode,
            programId,
            dispatch,
            setCommonReward,
            curriculums,
            programName,
            learnerInfo,
            curriculumName,
            isDiagnosis,
            getPreviousPage,
            program,
            setFocusIndex
        ]
    );
    const onError = useCallback(status => {
        setError({ response: { status } });
    }, []);
    const onHandwriteRecognitionError = useCallback(() => {
        history.replace('/program');
    }, [history]);
    const onBasicReTry = useCallback(reTryCount => {
        const txtObj = CF(6);
        return new Promise(resolve => {
            const onClick = () => {
                setModalInfo(null);
                resolve();
            };
            setModalInfo({
                ...txtObj,
                body: <SimpleRobotModalBody text={txtObj.body} />,
                onClose: onClick,
                buttons: [
                    {
                        text: txtObj.buttonTexts[0],
                        onClick
                    }
                ]
            });
        });
    }, []);

    const onG2Clear = useCallback(async repeatGenerate => {
        //repeatGenerate: true이면 여러번시도 false 이면 한번에 성공
        try {
            await lms.postRewardHistory([{ rewardId: 'ST.02', rewardCount: repeatGenerate ? 1 : 2 }]);
        } catch (e) {
            setError(e);
        }
        const txtObj = CF(repeatGenerate ? 8 : 7);

        return new Promise(resolve => {
            const onClick = () => {
                setModalInfo(null);
                resolve();
            };
            setModalInfo({
                ...txtObj,
                body: (
                    <SimpleRobotModalBody
                        text={txtObj.body}
                        img={<ChallengeHeart />}
                        imgCount={repeatGenerate ? 1 : 2}
                    ></SimpleRobotModalBody>
                ),
                onClose: onClick,
                buttons: [
                    {
                        text: txtObj.buttonTexts[0],
                        onClick
                    }
                ]
            });
        });
    }, []);

    // 학습창에 반응추가 (ex button..)
    const onInfoTextClick = () => {
        iframe.current.contentWindow.postMessage({ code: '11' }, '*');
    };
    const onHomeButtonClick = () => {
        setTimerPause(true);
        const txtObj = CF(1);
        const onClick = type => {
            setModalInfo(null);
            if (type) {
                iframe.current.contentWindow.postMessage({ code: '19' }, '*');
            } else {
                setTimerPause(false);
            }
        };
        setModalInfo({
            ...txtObj,
            onClose: onClick.bind(null, 0),
            body: <SimpleRobotModalBody text={txtObj.body}></SimpleRobotModalBody>,
            buttons: txtObj.buttonTexts.map((el, i) => ({ text: el, onClick: onClick.bind(null, i) }))
        });
    };
    const onProgramDetailModalClick = () => {
        setTimerPause(false);
        setProgramDetailModal(state => ({ ...state, show: false }));
    };
    const onProgramDetailClick = async () => {
        try {
            setTimerPause(true);
            if (!programDetail.data) await programDetail.refresh();
            setProgramDetailModal(state => ({ ...state, show: true }));
        } catch (e) {
            setError(e);
        }
    };

    const onInformationButtonClick = () => {
        const txtObj = CF(10);
        setTimerPause(true);
        const onClick = () => {
            setModalInfo(null);
            setTimerPause(false);
        };
        setModalInfo({
            ...txtObj,
            title: <div style={{ whiteSpace: 'nowrap', letterSpacing: '-1px' }}>{txtObj.title}</div>,
            body: <InformationBody texts={txtObj.body}></InformationBody>,
            onClose: onClick,
            buttons: txtObj.buttonTexts.map((el, i) => ({ text: el, onClick: onClick }))
        });
    };
    const onTeacherCallButtonClick = useCallback(() => {
        setTimerPause(true);
        //도움요청 가능할 때
        const onClick = (type, content) => {
            if (type) {
                //도움요청할때
                helpContent.current = content;
                iframe.current.contentWindow.postMessage({ code: '16' }, '*');
            } else {
                setTimerPause(false);
                setModalInfo(null);
            }
        };
        if (helpRemainCount.helpRemainCount) {
            setModalInfo({
                type: 1,
                onClick,
                helpRemainCount: helpRemainCount ? helpRemainCount.helpRemainCount : null
            });
        }
        //도움 요청 불가 (남은횟수0)
        else {
            const txtObj = CF(14);
            const onClick = () => {
                setTimerPause(false);
                setModalInfo(null);
            };
            setModalInfo({
                ...txtObj,
                body: <SimpleRobotModalBody text={txtObj.body}></SimpleRobotModalBody>,
                onClose: onClick,
                buttons: [
                    {
                        text: txtObj.buttonTexts[0],
                        onClick
                    }
                ]
            });
        }
    }, [helpRemainCount]);
    //effect
    useEffect(() => {
        //첫진입이 학습창일 때 메인으로 돌아감
        if (!(gradeCode && curriculumCode && programId) && !frameLearning) history.replace(getPreviousPage('/main'));
    }, [gradeCode, curriculumCode, programId, history, getPreviousPage, frameLearning]);
    useEffect(() => () => clearTimeout(timerRef.current), []);
    useEffect(() => {
        setProgramDetailModal(state => ({
            ...state,
            data: { frames: programDetail.data, programName, frameId: frameId.current }
        }));
    }, [programDetail.data, programName]);
    useEffect(() => {
        //최초에 api 호출
        if (!helpRemainCount && gradeCode && curriculumCode && programId) getHelpRemainCount();
    }, [helpRemainCount, gradeCode, curriculumCode, programId, getHelpRemainCount]);
    useEffect(() => {
        setError(frameLearningError);
    }, [frameLearningError]);
    return {
        frameLearning,
        programId,
        iframe,
        modalInfo,
        loading,
        timerPause,
        feedback,
        programDetailModal,
        onProgramDetailModalClick,
        onHomeButtonClick,
        onProgramDetailClick,
        onTeacherCallButtonClick,
        onInformationButtonClick,
        onLoad,
        onFrameEnd,
        onNextFrame,
        onBeforeLoad,
        onRepeatGenerate,
        onTrackingEnd,
        onProgramEnd,
        onTimerEnd,
        onBasicReTry,
        onBeforeTimer,
        onG2Clear,
        onInfoTextClick,
        onQuestionEnd,
        onNoAction,
        isDiagnosis,
        helpRemainCount: helpRemainCount ? helpRemainCount.helpRemainCount : null,
        toastData,
        error,
        onError,
        onHandwriteRecognitionError
    };
}
