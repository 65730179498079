import { useState } from 'react';
import { isMobile as _isMobile } from '../utils/util';
const isMobile = _isMobile();
export default function useClickEvent() {
    const [active, setActive] = useState(false);
    const onMouseDown = setActive.bind({}, true),
        onTouchEnd = setActive.bind({}, false),
        onMouseOut = setActive.bind({}, false),
        onMouseUp = setActive.bind({}, false);
    return { active, onMouseDown, onTouchEnd, onMouseOut, onMouseUp, isMobile };
}
