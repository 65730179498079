import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import media, { breakPoints } from '../../../utils/media';
import RaisedCard from '../../../components/RaisedCard';
import Badge from '../../../components/Badge';
import SoundButton from '../../../components/SoundButton';
const Block = styled.div`
    display: flex;
    padding: 1em 1.6rem 1em 1.6rem;
    max-width: ${breakPoints.large}px;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    &.center {
        justify-content: center;
    }
    .card-container {
        width: 50%;
        padding: 0.5em;
        ${media.small} {
            width: 100%;
        }
        .card {
            cursor: pointer;
            & > div {
                padding: 0.5em;
            }
            & > .top {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                & > div:first-child {
                    padding: 0.5em 0.5em 0 0;
                }
                & > div:nth-child(2) {
                    padding: 0 0 0.5em 0;
                }
            }
            .name {
                /* text-overflow: ellipsis; */
                /* white-space: nowrap; */
                /* overflow: hidden; */
            }
            .bottom {
                width: 100%;
                background: ${theme.palette.primaryLight};
                display: flex;
                justify-content: flex-end;
                .emphasis {
                    color: ${theme.palette.primary};
                    margin-right: 0.25em;
                }
            }
        }
    }
`;
export default function WrongAnswerList({ data, setSelectedData, setViewType }) {
    const handleCardClick = data => {
        setViewType(1);
        setSelectedData(data);
    };
    return (
        <Block className={data.length > 1 ? 'between' : 'center'}>
            {data.map((el, i) => (
                <div className="card-container" key={i}>
                    <SoundButton>
                        <RaisedCard
                            borderRadius={1}
                            backgroundColor="#FAFFFF"
                            borderTopColor="#ECF5F5"
                            borderBottomColor="#D9E8E7"
                        >
                            <div className="card" onClick={handleCardClick.bind({}, el)}>
                                <div className="top">
                                    <Badge show={moment().isSame(el.learning_date, 'day')}>
                                        <span>{moment(el.learning_date).format('MM.DD.')}</span>
                                    </Badge>
                                    <div className="name">{el.program_name}</div>
                                </div>
                                <div className="bottom">
                                    <span className="emphasis">{el.total_wrong_question_count}개</span>문항
                                </div>
                            </div>
                        </RaisedCard>
                    </SoundButton>
                </div>
            ))}
        </Block>
    );
}
