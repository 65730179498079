import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import theme from '../assets/styles/theme';
import { ReactComponent as Check } from '../assets/images/check_circle.svg';
const Block = styled.div`
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.6s;
    padding: 1em;
    & > div {
        background: ${theme.palette.primary};
        color: ${theme.palette.white};
        padding: 1em 1.5em;
        border-radius: ${theme.modalRadius};
        box-shadow: 0px 0px 10px 10px ${theme.palette.gray};
        display: flex;
        align-items: center;
        justify-content: center;
        .message {
            margin-left: 0.5em;
        }
    }

    &.hide {
        transform: translateY(100%);
    }
`;
export default function Toast({ message, style = {}, icon = <Check />, onToastHide = () => {} }) {
    const [className, setClassName] = useState('');
    const timer = useRef();
    const handleAnimEnd = () => onToastHide();
    useEffect(() => {
        timer.current = setTimeout(setClassName, 1500, 'hide');
        return () => clearTimeout(timer.current);
    }, []);
    return (
        <Block className={className} onTransitionEnd={handleAnimEnd}>
            <div style={style}>
                {icon}
                <div className="message">{message}</div>
            </div>
        </Block>
    );
}
