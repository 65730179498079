import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import usePromise from '../../hooks/usePromise';
import * as lms from '../../api/lms';
import { getLearningGrade } from '../../reducers/learningGrade';
import { getLearningCurriculum } from '../../reducers/learningCurriculum';

import Loading from '../../components/common/Loading';
import LearningProgressComponent from '../reportPage/learningAchievementComponent/LearningProgressComponent';
import LearningHistoryComponent from './learningAchievementComponent/LearningHistoryComponent';
import DetailViewComponent from './learningAchievementComponent/DetailViewComponent';
import useLearnerInfo from '../../hooks/useLearnerInfo';

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0px auto;
    padding: 1.6rem 0.4rem;
`;

const objToArray = obj => {
    let result = [];
    for (const key in obj) {
        result.push({ key: key, value: obj[key] });
    }
    return result;
};

export default function LearningAchievementContainer({
    url,
    viewType,
    detail,
    grade,
    setGrade,
    domain,
    setDomain,
    status,
    setStatus,
    initialDomain
}) {
    const dispatch = useDispatch();
    const { data: learnerInfo } = useLearnerInfo();
    const { data: learningGrade, error: error1 } = useSelector(state => state.learningGrade);
    const { data: curriculumInfos, loading: loading5, error: error5 } = useSelector(state => state.learningCurriculum);
    const { data: learningAchievement, loading: loading2, error: error2 } = usePromise(
        lms.getLearningAchievement,
        !grade,
        { grade }
    );
    const { data: diagnosisHistory, error: error3, loading: loading3 } = usePromise(
        lms.getDiagnosisHistory,
        !grade || !status,
        { grade, status }
    );
    const { data: programHistory, error: error4, loading: loading4 } = usePromise(
        lms.getProgramHistory,
        !grade || !domain,
        { grade, domain }
    );

    const error = error1 || error2 || error3 || error4 || error5;
    const loading = loading2;
    const listLoading = loading3 || loading4 || loading5;

    useEffect(() => {
        if (!learningGrade) dispatch(getLearningGrade());
        if (!grade && learningGrade) setGrade(learningGrade[learningGrade.length - 1]);
    }, [learningGrade, grade, setGrade, dispatch]);

    useEffect(() => {
        if (!curriculumInfos && grade) dispatch(getLearningCurriculum({ gradeCode: grade }));
    }, [curriculumInfos, grade, dispatch]);

    useEffect(() => {
        if (learningAchievement && !domain) {
            initialDomain.current = objToArray(learningAchievement.programs).find(el => el.value.count !== 0);
            setDomain(initialDomain.current ? initialDomain.current.key.substring(7, 8) : '1');
        }
    }, [learningAchievement, setDomain, initialDomain, domain]);

    if (error) throw error;
    if (loading) return <Loading />;

    return (
        <>
            {!detail && learningAchievement && domain && status && (
                <Container>
                    <LearningProgressComponent
                        displayName={learnerInfo?.display_name}
                        url={url}
                        learningGrade={learningGrade}
                        setGrade={setGrade}
                        grade={grade}
                        domain={domain}
                        status={status}
                        learningAchievement={learningAchievement}
                    />
                    <LearningHistoryComponent
                        url={url}
                        learningAchievement={learningAchievement}
                        domain={domain}
                        setDomain={setDomain}
                    />
                </Container>
            )}
            {detail && learningAchievement && diagnosisHistory && programHistory && (
                <DetailViewComponent
                    url={url}
                    viewType={viewType}
                    detail={detail}
                    grade={grade}
                    domain={domain}
                    setDomain={setDomain}
                    status={status}
                    setStatus={setStatus}
                    learningAchievement={learningAchievement}
                    diagnosisHistory={diagnosisHistory}
                    programHistory={programHistory}
                    listLoading={listLoading}
                    curriculumInfos={curriculumInfos}
                />
            )}
        </>
    );
}
