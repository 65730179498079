import { Howl } from 'howler';
import { renderToStaticMarkup } from 'react-dom/server';
export const soundPath = '/sounds';
export const getAccessToken = () => sessionStorage.getItem('accessToken');
export const setAccessToken = token => sessionStorage.setItem('accessToken', token);
export const removeAccessToken = () => sessionStorage.removeItem('accessToken');

export const makeNullArray = count => {
    const arr = [];
    for (var i = 0; i < count; i++) arr.push(null);
    return arr;
};

export const getDiagnosisProgress = ({ progress, frameCurrentResult }) => {
    const newProgress = [...progress];
    if (progress[0] === null) newProgress[0] = frameCurrentResult;
    if (progress[0] !== null && progress[1] === null) {
        newProgress[1] = frameCurrentResult;
        if (progress[0] !== frameCurrentResult) newProgress[2] = null;
    }
    if (progress[0] !== null && progress[1] !== null && progress[2] === null) newProgress[2] = frameCurrentResult;
    return newProgress;
};

export const filterRewards = rewards => {
    return rewards
        .filter(el => el.type.indexOf('RW') !== -1 && el.value !== 0)
        .map(el => ({
            rewardId: el.type,
            rewardCount: el.value
        }));
};

export const getHandWriteRecognitionLevel = programId => {
    let level = '4';
    const programIdSplit = programId.split('.');
    const grade = programId.indexOf('DG') !== -1 ? programIdSplit[2] : programIdSplit[1].charAt(0);
    if (['A', 'B', 'C'].includes(grade)) level = '1';
    if (['D', 'E', 'F'].includes(grade)) level = '2';
    if (['G', 'H', 'I'].includes(grade)) level = '3';
    return level;
};

export const getContinuousPassDiagnosis = (curriculumCode, curriculums) => {
    const index = curriculums.findIndex(el => el.code === curriculumCode);
    let txtType = 18;

    for (let i = index - 1; i > -1; i--) {
        if (curriculums[i] && curriculums[i].status === 5) txtType++;
        else break;
    }
    return Math.min(22, txtType);
};

export const getPointAtEvent = (e, mysvg) => {
    var pt = mysvg.createSVGPoint();
    if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend') {
        pt.x = e.changedTouches[0].clientX;
        pt.y = e.changedTouches[0].clientY;
    } else {
        pt.x = e.clientX;
        pt.y = e.clientY;
    }
    return pt.matrixTransform((mysvg.getScreenCTM ? mysvg.getScreenCTM() : mysvg.getCTM()).inverse());
};

export const isMobile = () =>
    navigator.userAgent.match(
        /iPhone|iPod|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson|LG|SAMSUNG|Samsung/i
    ) != null ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const replaceURL = url => {
    window.history.replaceState('', '', url);
};
export const sleep = time => new Promise(resolve => setTimeout(resolve, time));
export const convertsecToMinuteSec = sec => {
    if (sec >= 60) {
        if (sec % 60 === 0) return `${Math.floor(sec / 60)}분`;
        return `${Math.floor(sec / 60)}분 ${sec % 60}초`;
    }
    return `${sec}초`;
};
export const getUrlData = Component =>
    `data:image/svg+xml;utf8,${encodeURIComponent(renderToStaticMarkup(<Component />))}`;
export const preloadAudios = list => {
    list.forEach((src, i) => {
        if (global.JEIAUDIOS[src]) return;
        global.JEIAUDIOS[src] = new Howl({
            src,
            autoplay: false
        });
    });
};

export const startGA = () => {
    global.dataLayer = global.dataLayer || [];
    global.gtag = function () {
        global.dataLayer.push(arguments);
    };
    global.gtag('js', new Date());
    global.gtag('config', process.env.REACT_APP_GA_KEY);
};

export const setGAConfig = option => {
    global.gtag('config', process.env.REACT_APP_GA_KEY, option);
};

export const postGAEvent = (type, option) => {
    global.gtag('event', type, option);
};
