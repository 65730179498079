import React from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import { breakPoints } from '../../../utils/media';

import HelpCard from './HelpCard';

const Block = styled.div`
    hr {
        color: ${theme.palette.divider};
        opacity: 0.2;
        /* max-width: ${breakPoints.large}px; */
        margin: 0 auto;
    }
`;

export default function HelpList({ data, openIndex, setOpenIndex, learningHelpDetail, getHelpDetail, updateMemoPath }) {
    return (
        <Block>
            {data.map((el, i) => (
                <React.Fragment key={el.help_history_date_time}>
                    <HelpCard
                        learningHelpDetail={learningHelpDetail}
                        getHelpDetail={getHelpDetail}
                        updateMemoPath={updateMemoPath}
                        open={openIndex === i}
                        data={el}
                        onClick={setOpenIndex.bind({}, i === openIndex ? null : i)}
                    ></HelpCard>
                    <hr></hr>
                </React.Fragment>
            ))}
        </Block>
    );
}
