export default function AR(type) {
    switch (type) {
        case 2:
            return '하트를 조금 더 빨리 모아 봐요.';
        case 3:
            return '우와! 하트를 조금만 더 모으면 돼요.';
        case 4:
            return '하트를 모두 모았어요.\n신비한 우주의 모습을 봐요.';
        case 5:
            return '잘했어요.\n다시 새롭게 시작해 볼까요?';
        case 11:
            return '하트를 많이 못 모았네요.\n이제 열심히 학습해 봐요!';
        case 12:
            return '앞으로도 하트를 많이 모아 봐요.';
        case 13:
            return '30개의 하트를 모아서\n우주여행을 해 보세요.';
        default:
            return;
    }
}
