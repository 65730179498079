import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLearnerInfo } from '../reducers/learnerInfo';

let requestTime = 0;
export default function useLearnerInfo(refresh) {
    const { data, loading, error } = useSelector(state => state.learnerInfo);
    const dispatch = useDispatch();
    useEffect(() => {
        if ((!data || refresh) && Date.now() - requestTime > 10) {
            dispatch(getLearnerInfo());
            requestTime = Date.now();
        }
    }, [dispatch, data, refresh]);
    return { data, loading, error };
}
