import React, { useMemo } from 'react';
import { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { useRef } from 'react';
import { ReactComponent as EditIcon } from './images/edit.svg';
import { ReactComponent as UndoIcon } from './images/undo.svg';
import { ReactComponent as DeleteIcon } from './images/delete.svg';
import { ReactComponent as CloseIcon } from './images/close_memo.svg';
import WithGlitter from '../WithGlitter';
function getTotalLength(pts) {
    return pts.reduce((pre, cur, i, arr) => {
        if (i === 0) return 0;
        const lengthX = cur[0] - arr[i - 1][0];
        const lengthY = cur[1] - arr[i - 1][1];
        return pre + Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2));
    }, 0);
}
function getPointAtLength(pts, len) {
    if (len === 0) return pts[0];
    let _len = 0;
    for (let i = 0; i < pts.length; i++) {
        if (i === 0) continue;
        const [x1, y1] = pts[i - 1];
        const [x2, y2] = pts[i];
        const lengthX = x2 - x1;
        const lengthY = y2 - y1;
        const r = Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2));
        if (r === 0) continue;
        if (len - r <= _len) {
            return [(len - _len) * (lengthX / r) + x1, (len - _len) * (lengthY / r) + y1];
        }
        _len += r;
    }
    return pts[pts.length - 1];
}

function makeSmoothPath(_p) {
    const p = _p
        .split(/M|L/)
        .slice(1)
        .map(el => el.split(',').map(el => Number(el)));
    var pathLen = getTotalLength(p);
    if (pathLen === 0)
        return p.reduce((pre, cur, i, arr) => {
            if (i === 0) return 'M' + cur;
            return pre + ('L' + cur);
        }, '');
    var len = 10;
    var count = Math.floor(pathLen / len);
    var points = [];
    for (let i = 0; i < count + 1; i++) {
        const pt = getPointAtLength(p, i < count ? i * len : pathLen);
        points[i] = pt;
    }
    return _pointsToSmoothPath(points);

    function _pointsToSmoothPath(points) {
        let smoothPath = '';
        for (let k = 0; k < points.length; k++) {
            if (k === 0) {
                smoothPath = 'M' + points[k];
            } else if (k > 1) {
                smoothPath = smoothPath + _bezierCommand(points[k - 1], k - 1, points);
            }
            if (k !== 0 && k === points.length - 1) {
                smoothPath = smoothPath + _bezierCommand(points[k], k, points);
            }
        }
        return smoothPath;
    }

    function _lineInfo(pointA, pointB) {
        var lengthX = pointB[0] - pointA[0];
        var lengthY = pointB[1] - pointA[1];
        return {
            length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
            angle: Math.atan2(lengthY, lengthX)
        };
    }

    function _controlPoint(current, previous, next, reverse) {
        const p = previous || current;
        const n = next || current;
        const smoothing = 0.2;
        const o = _lineInfo(p, n);
        const angle = o.angle + (reverse ? Math.PI : 0);
        const length = o.length * smoothing;
        const x = current[0] + Math.cos(angle) * length;
        const y = current[1] + Math.sin(angle) * length;
        return [x, y];
    }

    function _bezierCommand(point, i, a) {
        const [cpsX, cpsY] = _controlPoint(a[i - 1], a[i - 2], point);
        const [cpeX, cpeY] = _controlPoint(point, a[i - 1], a[i + 1], true);
        return `C${cpsX},${cpsY},${cpeX},${cpeY},${point[0]},${point[1]}`;
    }
}

const slideUp = keyframes`
    from {
        transform:translateY(0px);
    }
    to {
        transform:translateY(-3em);
    }
`;

const Pad = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &.index {
        z-index: 1000;
    }
    &.d-none {
        display: none;
    }
    svg {
        touch-action: none;
        background: rgb(255, 255, 255, 0.5);
        width: 100%;
        height: 100%;
    }
    path {
        stroke: #003784;
        stroke-width: 5px;
        fill: none;
        pointer-events: none;
        stroke-linejoin: round;
        stroke-linecap: round;
    }
`;
const Buttons = styled.div`
    z-index: 1050;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    & > svg {
        height: 100%;
        width: auto;
        cursor: pointer;
    }

    .edit-group {
        animation: ${slideUp} 0.7s forwards;
        background: #97ddd8;
        border-radius: 2em;
        position: absolute;
        bottom: 0px;

        cursor: pointer;
        height: calc(200% + 2em);
        width: calc(100% + 0.5em);

        & > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0 0.5em;
            & > div {
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
        hr {
            border: 1px solid #0c9da1;
        }
        .glitter {
            left: 0.3em;
        }
    }
`;
function ScratchPad({ scratchKey, onPathsChange }) {
    const [isDrawing, setIsDrawing] = useState(false);
    const [path, setPath] = useState('');
    const [paths, setPaths] = useState([]);
    const [open, setOpen] = useState(false);
    const pad = useRef();
    const smoothPaths = useMemo(() => {
        return paths.map(el => makeSmoothPath(el));
    }, [paths]);
    const startHandler = useCallback(e => {
        let target = e;
        if (e.type === 'touchstart') target = e.changedTouches[0];
        setIsDrawing(true);
        setPath('M' + [target.pageX, target.pageY] + 'L' + [target.pageX, target.pageY]);
    }, []);
    const moveHandler = useCallback(
        e => {
            if (!isDrawing) return;
            let target = e;
            if (e.type === 'touchmove') target = e.changedTouches[0];
            if (path === '') return setPath('M' + [target.pageX, target.pageY] + 'L' + [target.pageX, target.pageY]);
            setPath(path + 'L' + [target.pageX, target.pageY]);
        },
        [path, isDrawing]
    );
    const endHandler = useCallback(() => {
        setIsDrawing(false);
        if (path === '') return;
        setPaths(paths.concat(path));
        setPath('');
    }, [paths, path]);

    const handleBefore = e => {
        setPaths(paths.slice(0, paths.length - 1));
    };
    const handleRemove = e => {
        setPaths([]);
    };
    useEffect(() => {
        const padEl = pad.current;
        padEl.addEventListener('mousedown', startHandler);
        padEl.addEventListener('mousemove', moveHandler);
        padEl.addEventListener('mouseup', endHandler);
        padEl.addEventListener('touchstart', startHandler);
        padEl.addEventListener('touchmove', moveHandler);
        padEl.addEventListener('touchend', endHandler);
        return () => {
            padEl.removeEventListener('mousedown', startHandler);
            padEl.removeEventListener('mousemove', moveHandler);
            padEl.removeEventListener('mouseup', endHandler);
            padEl.removeEventListener('touchstart', startHandler);
            padEl.removeEventListener('touchmove', moveHandler);
            padEl.removeEventListener('touchend', endHandler);
        };
    }, [moveHandler, startHandler, endHandler]);
    useEffect(() => {
        setPaths([]);
        setPath('');
        setIsDrawing(false);
        setOpen(false);
    }, [scratchKey]);
    useEffect(() => {
        onPathsChange(paths);
    }, [paths, onPathsChange]);
    return (
        <>
            <Buttons>
                {open && (
                    <>
                        <div className="edit-group" id="modal-container">
                            <WithGlitter glitterColor="#E3FCF7">
                                <div>
                                    <UndoIcon onClick={handleBefore}></UndoIcon>
                                </div>
                                <hr></hr>
                                <div>
                                    <DeleteIcon onClick={handleRemove}></DeleteIcon>
                                </div>
                            </WithGlitter>
                        </div>
                        <CloseIcon height="100%" onClick={setOpen.bind(null, !open)}></CloseIcon>
                    </>
                )}
                {!open && <EditIcon height="100%" onClick={setOpen.bind(null, !open)}></EditIcon>}
            </Buttons>
            <Pad className={`${open ? '' : 'd-none'} ${isDrawing ? 'index' : ''}`}>
                <svg ref={pad}>
                    {smoothPaths.map((el, i) => (
                        <path key={i} d={el}></path>
                    ))}
                    <path d={path}></path>
                </svg>
            </Pad>
        </>
    );
}

export default React.memo(ScratchPad);
