import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { getLearningTracking } from '../../../api/lms';
import theme from '../../../assets/styles/theme';
import Carousel from '../../../components/Carousel';
import Loading from '../../../components/common/Loading';
import usePromise from '../../../hooks/usePromise';
import media from '../../../utils/media';
import WrongAnswerAllModal from './WrongAnswerAllModal';

import WrongAnswerViewer from './WrongAnswerViewer';
const Block = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    .question-index {
        display: flex;
        justify-content: center;
        background: ${theme.palette.gray};
        padding: 0.5em 1.6rem;
        ${media.xsmall} {
            justify-content: flex-start;
        }
        span {
            position: absolute;
            right: 1.6rem;
            color: ${theme.palette.primary};
            cursor: pointer;
        }
    }
    .contents-container {
        padding: 1em 1.6rem;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .contents-inner {
            flex-grow: 1;
            width: 100%;
            max-width: 800px;

            .carousel-inner-container {
                width: 100%;
            }
        }
    }
`;
export default function WrongAnswerDetail({ data }) {
    const { learning_date: learningDate, program_id: programId } = data;
    const [showModal, setShowModal] = useState(false);
    const [wrongAnswerIndex, setWrongAnswerIndex] = useState(0);
    const [wrongAnswerDetailIndex, setWrongAnswerDetailIndex] = useState(0);
    const getLearningTrackingAPI = useCallback(async () => await getLearningTracking({ programId, learningDate }), [
        learningDate,
        programId
    ]);
    const { data: trackingData, loading, error } = usePromise(getLearningTrackingAPI);
    const handleWrongAnswerDetailClick = () => {
        setShowModal(true);
    };
    useEffect(() => {
        setWrongAnswerDetailIndex(0);
    }, [wrongAnswerIndex]);
    if (error) throw error;
    if (loading) return <Loading></Loading>;
    return (
        <Block>
            {trackingData && (
                <>
                    <div className="question-index h4">
                        {wrongAnswerIndex + 1}/{trackingData.length}
                        {trackingData[wrongAnswerIndex].length > 1 && (
                            <span onClick={handleWrongAnswerDetailClick}>과정 보기</span>
                        )}
                    </div>
                    <div className="contents-container">
                        <div className="contents-inner">
                            <Carousel
                                classes={{ carouselInnerContainer: 'carousel-inner-container' }}
                                arrowDown={true}
                                showIndex={false}
                                onIndexChange={setWrongAnswerIndex}
                            >
                                {trackingData.map(el => {
                                    const contentsData = el.last();
                                    return (
                                        <WrongAnswerViewer
                                            key={contentsData.dateTime}
                                            achievementDegree={contentsData.achievementDegree}
                                            progress={contentsData.progress}
                                            timerWidth={contentsData.timerWidth}
                                            learningContents={contentsData.learningContents}
                                        ></WrongAnswerViewer>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </div>
                    {showModal && (
                        <WrongAnswerAllModal
                            onChangeIndexButtonClick={setWrongAnswerDetailIndex}
                            title={`과정 보기 ${wrongAnswerDetailIndex + 1}/${trackingData[wrongAnswerIndex].length}`}
                            data={trackingData[wrongAnswerIndex]}
                            onClose={setShowModal.bind({}, false)}
                        ></WrongAnswerAllModal>
                    )}
                </>
            )}
        </Block>
    );
}
