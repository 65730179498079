import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import DF from '../../../assets/texts/DF';
import ModalContainer from '../../../components/common/ModalContainer';
import { ReactComponent as FeedbackImage1 } from '../../../assets/images/learning/good_popup_1.svg';
import { ReactComponent as FeedbackImage2 } from '../../../assets/images/learning/good_popup_2.svg';
import { ReactComponent as FeedbackImage3 } from '../../../assets/images/learning/good_popup_3.svg';
import { ReactComponent as FeedbackImage4 } from '../../../assets/images/learning/good_popup_4.svg';
import useSound from '../../../hooks/useSound';
const Images = [<FeedbackImage1 />, <FeedbackImage2 />, <FeedbackImage3 />, <FeedbackImage4 />];
const up = keyframes`
    0%{
        transform:translateY(100%)
    }
    15%,85%{
        transform:translateY(0);
    }
    100%{
        transform:translateY(100%)
    }
`;
const Block = styled.div`
    position: fixed;
    left: 0.5em;
    bottom: 0;
    color: white;
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    border-radius: 0.5em;
    svg {
        height: 3.5em;
        width: auto;
        #feedback-text {
            fill: white;
            font-size: 28px;
            letter-spacing: -1.2px;
        }
    }
    .text {
        position: absolute;
        margin-left: 10%;
    }

    animation: ${up} 2s forwards;
`;
export default function Feedback({ type, isDiagnosis }) {
    const [show, setShow] = useState(true);
    const messageRef = useRef(DF(type, { isDiagnosis }));
    useSound({ src: `/sounds/${messageRef.current.src}` });
    const handleAnimEnd = () => {
        setShow(false);
    };
    useEffect(() => {
        if (document.getElementById('feedback-text'))
            document.getElementById('feedback-text').textContent = messageRef.current.text;
    }, []);
    return (
        <>
            {show && (
                <ModalContainer>
                    <Block id="loader" className="h3 bold" show={show} onAnimationEnd={handleAnimEnd}>
                        {Images[type - 1]}
                    </Block>
                </ModalContainer>
            )}
        </>
    );
}
