import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import SigninContainer from './pages/signinPage/SigninContainer';
import MainContainer from './pages/mainPage/MainContainer';
import ProgramContainer from './pages/programPage/ProgramContainer';
import DiagnosisQuestionContainer from './pages/diagnosisQuestionPage/DiagnosisQuestionContainer';
import LearningContainer from './pages/learningPage/LearningContainer';
import MainInformationContainer from './pages/mainInformationPage/MainInformationContainer';
import ReportMainContainer from './pages/reportPage/ReportMainContainer';
import HelpRequestContainer from './pages/helpRequestPage/HelpRequestContainer';
import WrongAnswerContainer from './pages/wrongAnswerPage/WrongAnswerContainer';
import ErrorPage from './pages/ErrorPage';
import RewardHeartContainer from './pages/rewardHeartPage/RewardHeartContainer';
import RewardItemContainer from './pages/rewardItemPage/RewardItemContainer';
import SystemMaintenancePage from './pages/SystemMaintenancePage';
/**
 * isSystemMaintenance = true; 점검중일 때
 */
const isSystemMaintenance = false;
function Routes() {
    if (isSystemMaintenance)
        return (
            <SystemMaintenancePage
                title={`시스템 점검 중`}
                duration={`점검 시간: 09:00 ~ 24:00`}
                content={`시스템 점검으로 인해 학습 서비스가 중단되고 있으며,\n작업 상황에 따라 중단 시간은 변경될 수 있습니다.`}
            />
        );
    return (
        <ErrorBoundary>
            <Switch>
                <Route path="/sign-in" component={SigninContainer} />
                <Route path="/main" component={MainContainer} />
                <Route path="/main-information" component={MainInformationContainer}></Route>
                <Route path="/program" component={ProgramContainer} />
                <Route path="/diagnosis-question" component={DiagnosisQuestionContainer} />
                <Route path="/learning" component={LearningContainer} />
                <Route path="/help-request" component={HelpRequestContainer}></Route>
                <Route path="/report/:viewType/:detail/:domain/:status/:grade" component={ReportMainContainer}></Route>
                <Route path="/report/:viewType/:detail" component={ReportMainContainer}></Route>
                <Route path="/report/:viewType" component={ReportMainContainer}></Route>
                <Route path="/report" component={ReportMainContainer}></Route>
                <Route path="/wrong-answer" component={WrongAnswerContainer}></Route>
                <Route path="/reward-heart" component={RewardHeartContainer}></Route>
                <Route path="/reward-item" component={RewardItemContainer}></Route>
                <Route path="/" exact>
                    <Redirect to="/sign-in"></Redirect>
                </Route>
                <Route path="/">
                    <ErrorPage code={404} />
                </Route>
            </Switch>
        </ErrorBoundary>
    );
}

export default Routes;
