import { css } from 'styled-components';
const learningContentCSS = css`
    html {
        min-height: 100vh;
        overflow: hidden;
        background-size: 100vw 100vh;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    body {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        border: 0;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
    }

    .div-main {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .touch-action-none {
        touch-action: none;
    }

    svg {
        margin: 0;
        padding: 0;
        /* IE : SVG 외부에서 개체가 보이는 부분을 안보이도록 수정 */
        overflow: hidden;
        /*두 svg 사이 간격 제거*/
        display: block;
    }

    #loader {
        width: 70px;
        text-align: center;
        position: absolute;
        /* display: none; */
        left: calc(50vw - 35px);
        top: 50vh;
    }

    #loader > div {
        width: 18px;
        height: 18px;
        background-color: #fbae17;

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    #loader .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    #loader .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
        }

        40% {
            -webkit-transform: scale(1);
        }
    }

    @keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        40% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    } /* 공통 */

    /*선택 방지*/
    * {
        user-select: none;
        -khtml-user-select: none;
        -o-user-select: none;
        -moz-user-select: -moz-none;
        -webkit-user-select: none;
    }

    ::selection {
        background: transparent;
        color: inherit;
    }

    ::-moz-selection {
        background: transparent;
        color: inherit;
    }

    tspan.ffgt,
    text.ffgt {
        font-family: 'new_jei_font';
    }

    tspan.ffdn53,
    text.ffdn53 {
        font-family: 'new_esls_dna_53';
    }

    tspan.mobileText,
    text.mobileText {
        font-family: 'new_esls_mobile';
    }

    tspan.fs20,
    text.fs20 {
        font-size: 20px;
    }

    tspan.fs25,
    text.fs25 {
        font-size: 25px;
    }

    tspan.fs30,
    text.fs30 {
        font-size: 30px;
    }

    tspan.fs35,
    text.fs35 {
        font-size: 35px;
    }

    tspan.fs40,
    text.fs40 {
        font-size: 40px;
    }

    tspan.fs50,
    text.fs50 {
        font-size: 50px;
    }

    tspan.fs60,
    text.fs60 {
        font-size: 60px;
    }

    tspan.fs70,
    text.fs70 {
        font-size: 70px;
    }

    tspan.fs80,
    text.fs80 {
        font-size: 80px;
    }

    tspan.fs90,
    text.fs90 {
        font-size: 90px;
    }

    tspan.fs100,
    text.fs100 {
        font-size: 100px;
    }

    tspan.ta-s,
    text.ta-s {
        text-anchor: start;
    }

    tspan.ta-m,
    text.ta-m {
        text-anchor: middle;
    }

    tspan.ta-e,
    text.ta-e {
        text-anchor: end;
    }

    .gridBg {
        background-image: url('/commons/images/LI01/LI00000007.svg');
    }

    .cp {
        cursor: url('/commons/images/LI01/LI00000002.cur'), default;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        /* for iOS tap-blinking */
    }

    .cn {
        cursor: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        /* for iOS tap-blinking */
    }

    .q-mark {
        animation-duration: 0.8s;
        animation-name: oscillate;
        animation-iteration-count: infinite;
        -webkit-animation-duration: 0.8s;
        -webkit-animation-name: oscillate;
        -webkit-animation-iteration-count: infinite;
    }

    @-webkit-keyframes oscillate {
        0% {
            -webkit-transform: rotate(-10deg);
        }

        50% {
            -webkit-transform: rotate(10deg);
        }

        100% {
            -webkit-transform: rotate(-10deg);
        }
    }

    @keyframes oscillate {
        0% {
            transform: rotate(-10deg);
        }

        50% {
            transform: rotate(10deg);
        }

        100% {
            transform: rotate(-10deg);
        }
    }

    .blink {
        animation-duration: 5s;
        animation-name: blink;
        animation-iteration-count: infinite;
        -webkit-animation-duration: 5s;
        -webkit-animation-name: blink;
        -webkit-animation-iteration-count: infinite;
    }

    @-webkit-keyframes blink {
        0% {
            -webkit-opacity: 1;
        }

        5% {
            -webkit-opacity: 0;
        }

        10% {
            -webkit-opacity: 1;
        }

        15% {
            -webkit-opacity: 0;
        }

        20% {
            -webkit-opacity: 1;
        }

        50% {
            -webkit-opacity: 1;
        }

        55% {
            -webkit-opacity: 0;
        }

        60% {
            -webkit-opacity: 1;
        }

        65% {
            -webkit-opacity: 0;
        }

        70% {
            -webkit-opacity: 1;
        }

        100% {
            -webkit-opacity: 1;
        }
    }

    @keyframes blink {
        0% {
            -webkit-opacity: 1;
        }

        5% {
            -webkit-opacity: 0;
        }

        10% {
            -webkit-opacity: 1;
        }

        15% {
            -webkit-opacity: 0;
        }

        20% {
            -webkit-opacity: 1;
        }

        50% {
            -webkit-opacity: 1;
        }

        55% {
            -webkit-opacity: 0;
        }

        60% {
            -webkit-opacity: 1;
        }

        65% {
            -webkit-opacity: 0;
        }

        70% {
            -webkit-opacity: 1;
        }

        100% {
            -webkit-opacity: 1;
        }
    }

    .blink-once {
        animation-duration: 5s;
        animation-name: blink-once;
        animation-iteration-count: 1;
        -webkit-animation-duration: 5s;
        -webkit-animation-name: blink-once;
        -webkit-animation-iteration-count: 1;
    }

    @-webkit-keyframes blink-once {
        0% {
            -webkit-opacity: 1;
        }

        5% {
            -webkit-opacity: 0;
        }

        10% {
            -webkit-opacity: 1;
        }

        100% {
            -webkit-opacity: 1;
        }
    }

    @keyframes blink-once {
        0% {
            opacity: 1;
        }

        5% {
            opacity: 0;
        }

        10% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }
    /* 텍스트(일반 텍스트-버튼 내부 텍스트 제외) */
    .t01 {
        /*지시문*/
        font-family: 'new_jei_font';
        fill: #545454;
        font-size: 30px;
    }
    .t02 {
        fill: #545454;
    } /*기본 텍스트*/
    .t05 {
        fill: #fc6573;
    } /*텍스트 강조색*/
    .t06 {
        fill: #ffffff;
    } /*흰색 텍스트*/
    .t07 {
        /*흰색 텍스트*/
        font-family: 'new_jei_font';
        fill: #ffffff;
        font-size: 30px;
    }

    /*배경색*/
    .f89 {
        fill: #ffffff;
    } /*배경색*/
    .f00 {
        fill: #ffffff;
    } /*배경색 *************************************현재 미사용 배경색은 f89로 대체 사용!*/
    .f10 {
        fill: #898989;
    } /*진한배경색 *************************************현재 공통에서 미사용*/
    .f11 {
        fill: #f5f5f5;
    } /*연한배경색 *************************************현재 공통에서 미사용*/

    /*기타 엘리먼트 */
    .fno {
        fill: none;
    } /*없음*/
    .f01 {
        fill: #898989;
    } /*gray(표, line 그릴 때 사용)*/
    .f02 {
        fill: #ed6276;
    } /*coral*/
    .f03 {
        fill: #f7c541;
    } /*yellow*/
    .f04 {
        fill: #85bc4a;
    } /*light green*/
    .f05 {
        fill: #e885b4;
    } /*pink*/
    .f06 {
        fill: #59c3ff;
    } /*sky blue*/
    .f07 {
        fill: #f48134;
    } /*orange*/
    .f09 {
        fill: #9467b9;
    } /*purple*/
    .f12 {
        fill: #508446;
    } /*green*/
    .f13 {
        fill: #20a1e2;
    } /*blue*/
    .f14 {
        fill: #ffffff;
    } /*white*/
    .f27 {
        fill: #006898;
    } /*진한파랑*/
    .f51 {
        fill: #8e612f;
    } /*갈색*/
    .f85 {
        fill: #000000;
    } /*검정*/
    .f91 {
        fill: #545454;
    } /*검정(텍스트 검정과 동일) 20.01.10추가*/

    .f42 {
        fill: #ef6073;
    } /*물음표 면, 답 입력 박스 선 색*/
    .f08 {
        fill: #ffffff;
    } /*답 입력 내부 면 색*/
    .f86 {
        fill: #cccccc;
    } /*암시 숫자(회색)*/

    .f39 {
        fill: #ffffff;
    } /*팝업창(정오답 피드백) 텍스트 *************************************현재 공통에서 미사용*/
    .f45 {
        fill: #545454;
    } /*팝업창(텍스트, 이미지) 텍스트*/
    .f33 {
        fill: #fc6573;
    } /*팝업창(텍스트) 텍스트 강조색*/
    .f47 {
        fill: #fc6573;
    } /*팝업창(이미지) 텍스트 강조색*/

    .f48 {
        fill: #545454;
    } /*분수(수식) 텍스트(일반)*/
    .f49 {
        fill: #ffffff;
    } /*분수(수식) 텍스트(색깔 있는 사각형, 선택형, 드래그 버튼에 사용시)*/
    .f15 {
        fill: #fc6573;
    } /*분수(수식) 텍스트(강조)*/
    .f50 {
        fill: #595757;
    } /*수식(세로셈) 선*/

    .f23 {
        fill: #78d171;
    } /*입체 선택형(텍스트) 버튼 내부*/
    .f24 {
        fill: #5daf54;
    } /*입체 선택형(텍스트) 버튼 그림자*/
    .f17 {
        fill: #409933;
    } /*입체 선택형(텍스트) 버튼 선택시 테두리 선 색*/
    .f25 {
        fill: #939da6;
    } /*입체 선택형(텍스트 yes,no만 사용) 버튼 내부*/
    .f26 {
        fill: #657077;
    } /*입체 선택형(텍스트 yes,no만 사용) 버튼 그림자*/
    .f19 {
        fill: #23c274;
    } /*입체 선택형(텍스트 yes,no만 사용) 테두리 선 색-yes 선택시*/
    .f20 {
        fill: #fc5c73;
    } /*입체 선택형(텍스트 yes,no만 사용) 테두리 선 색-no 선택시*/
    .f46 {
        fill: #ffffff;
    } /*입체 선택형(텍스트), 드래그 버튼 텍스트*/
    .f29 {
        fill: #f5f5f5;
    } /*입체 선택형(이미지, 텍스트) 버튼 내부 *************************************현재 진단 버튼에 사용*/
    .f30 {
        fill: #898989;
    } /*입체 선택형(이미지, 텍스트) 버튼 그림자 ************************************현재 진단 버튼에 사용*/
    .f18 {
        fill: #fc6573;
    } /*입체 선택형(이미지, 텍스트) 버튼 선택시 테두리 선 색*/
    .f92 {
        fill: #ffffff;
    } /*입체 선택형(이미지, 텍스트) 버튼 내부(흰색) *************************************현재 진단(온라인 2.0 영어) 버튼에 사용 20.01.15추가*/

    .f28 {
        fill: #dddddd;
    } /*평면 선택형 버튼 배경*/
    .f31 {
        fill: #fc716a;
    } /*평면 선택형 버튼 선택시 테두리 선*/
    .f32 {
        fill: #368cc1;
    } /*평면 선택형 버튼 텍스트*/

    .f87 {
        fill: #d4f0a6;
    } /*단계 버튼 바탕*/
    .f88 {
        fill: #b2c592;
    } /*단계 버튼 그림자*/

    .f22 {
        fill: #dddddd;
    } /*숫자 반응판 배경 *************************************현재 공통에서 미사용*/
    .f52 {
        fill: #22578c;
    } /*숫자 반응판 텍스트(안눌렀을 때) *************************************현재 공통에서 미사용(f55로 대체해서 사용)*/
    .f53 {
        fill: #ffffff;
    } /*숫자 반응판 배경(눌렀을 때) *************************************현재 공통에서 미사용*/
    .f55 {
        fill: #22578c;
    } /*계산기 텍스트(안눌렀을 때)*/
    .f56 {
        fill: #ffffff;
    } /*계산기 텍스트(눌렀을 때)*/
    .f57 {
        fill: #368cc1;
    } /*handwrite에서 그리는 path*/
    .f90 {
        fill: #85bc4a;
    } /*HandWriteML에서 그리는 path*/
    .f16 {
        fill: #545454;
    } /*통삽화 하단 반응판 내부*/

    .f58 {
        fill: #666666;
    } /*스크래치 패드-색상(검정)*/
    .f59 {
        fill: #fc716a;
    } /*스크래치 패드-색상(분홍)*/
    .f60 {
        fill: #4dabf5;
    } /*스크래치 패드-색상(파랑)*/
    .f61 {
        fill: #fab153;
    } /*스크래치 패드-색상(노랑)*/
    .f62 {
        fill: #b6c3d6;
    } /*스크래치 패드-두께 조작 버튼 *************************************현재 공통에서 미사용*/
    .f63 {
        fill: #d1d9e5;
    } /*스크래치 패드-외곽 틀 선 *************************************현재 공통에서 미사용*/
    .f64 {
        fill: #ededed;
    } /*스크래치 패드-외곽 틀 내부 *************************************현재 공통에서 미사용*/

    .f65 {
        fill: #d93a2f;
    } /*색칠하기 빨강*/
    .f66 {
        fill: #f29a3b;
    } /*색칠하기 주황*/
    .f67 {
        fill: #fcda33;
    } /*색칠하기 노랑*/
    .f68 {
        fill: #4d9a4d;
    } /*색칠하기 초록*/
    .f69 {
        fill: #328dd3;
    } /*색칠하기 파랑*/
    .f70 {
        fill: #9c3584;
    } /*색칠하기 보라*/
    .f71 {
        fill: #e64680;
    } /*색칠하기 자주*/
    .f72 {
        fill: #f0a7c7;
    } /*색칠하기 분홍*/
    .f73 {
        fill: #d69a2b;
    } /*색칠하기 갈색*/

    .f74 {
        fill: #ffe4a1;
    } /*파스텔톤 노랑*/
    .f75 {
        fill: #ffc599;
    } /*파스텔톤 주황*/
    .f76 {
        fill: #f7c6ec;
    } /*파스텔톤 분홍*/
    .f77 {
        fill: #fcbbbb;
    } /*파스텔톤 빨강*/
    .f78 {
        fill: #aeef97;
    } /*파스텔톤 연두*/
    .f79 {
        fill: #94dd9b;
    } /*파스텔톤 초록*/
    .f80 {
        fill: #9bf9ed;
    } /*파스텔톤 하늘*/
    .f81 {
        fill: #99b5d8;
    } /*파스텔톤 진한 파랑*/
    .f82 {
        fill: #be98ba;
    } /*파스텔톤 보라*/
    .f83 {
        fill: #dca994;
    } /*파스텔톤 갈색*/
    .f84 {
        fill: #92dbff;
    } /*파스텔톤 파랑*/

    /*배경색*/
    .s89 {
        stroke: #ffffff;
    } /*배경색*/
    .s00 {
        stroke: #ffffff;
    } /*배경색 *************************************현재 미사용 배경색은 f89로 대체 사용!*/
    .s10 {
        stroke: #898989;
    } /*진한배경색 *************************************현재 공통에서 미사용*/
    .s11 {
        stroke: #f5f5f5;
    } /*연한배경색 *************************************현재 공통에서 미사용*/

    /*기타 엘리먼트 */
    .sno {
        stroke: none;
    } /*없음*/
    .s01 {
        stroke: #898989;
    } /*gray(표, line 그릴 때 사용)*/
    .s02 {
        stroke: #ed6276;
    } /*coral*/
    .s03 {
        stroke: #f7c541;
    } /*yellow*/
    .s04 {
        stroke: #85bc4a;
    } /*light green*/
    .s05 {
        stroke: #e885b4;
    } /*pink*/
    .s06 {
        stroke: #59c3ff;
    } /*sky blue*/
    .s07 {
        stroke: #f48134;
    } /*orange*/
    .s09 {
        stroke: #9467b9;
    } /*purple*/
    .s12 {
        stroke: #508446;
    } /*green*/
    .s13 {
        stroke: #20a1e2;
    } /*blue*/
    .s14 {
        stroke: #ffffff;
    } /*white*/
    .s27 {
        stroke: #006898;
    } /*진한파랑*/
    .s51 {
        stroke: #8e612f;
    } /*갈색*/
    .s85 {
        stroke: #000000;
    } /*검정(먹 100)*/
    .s91 {
        stroke: #545454;
    } /*검정(텍스트 검정과 동일) 20.01.10추가*/

    .s42 {
        stroke: #ef6073;
    } /*물음표 면, 답 입력 박스 선 색*/
    .s08 {
        stroke: #ffffff;
    } /*답 입력 내부 면 색*/
    .s86 {
        stroke: #cccccc;
    } /*암시 숫자(회색)*/

    .s39 {
        stroke: #ffffff;
    } /*팝업창(정오답 피드백) 텍스트 *************************************현재 공통에서 미사용*/
    .s45 {
        stroke: #545454;
    } /*팝업창(텍스트, 이미지) 텍스트*/
    .s33 {
        stroke: #fc6573;
    } /*팝업창(텍스트) 텍스트 강조색*/
    .s47 {
        stroke: #fc6573;
    } /*팝업창(이미지) 텍스트 강조색*/

    .s48 {
        stroke: #545454;
    } /*분수(수식) 텍스트(일반)*/
    .s49 {
        stroke: #ffffff;
    } /*분수(수식) 텍스트(색깔 있는 사각형, 선택형, 드래그 버튼에 사용시)*/
    .s15 {
        stroke: #fc6573;
    } /*분수(수식) 텍스트(강조)*/
    .s50 {
        stroke: #595757;
    } /*수식(세로셈) 선*/

    .s23 {
        stroke: #78d171;
    } /*입체 선택형(텍스트) 버튼 내부*/
    .s24 {
        stroke: #5daf54;
    } /*입체 선택형(텍스트) 버튼 그림자*/
    .s17 {
        stroke: #409933;
    } /*입체 선택형(텍스트) 버튼 선택시 테두리 선 색*/
    .s25 {
        stroke: #939da6;
    } /*입체 선택형(텍스트 yes,no만 사용) 버튼 내부*/
    .s26 {
        stroke: #657077;
    } /*입체 선택형(텍스트 yes,no만 사용) 버튼 그림자*/
    .s19 {
        stroke: #23c274;
    } /*입체 선택형(텍스트 yes,no만 사용) 테두리 선 색-yes 선택시*/
    .s20 {
        stroke: #fc5c73;
    } /*입체 선택형(텍스트 yes,no만 사용) 테두리 선 색-no 선택시*/
    .s46 {
        stroke: #ffffff;
    } /*입체 선택형(텍스트), 드래그 버튼 텍스트*/
    .s29 {
        stroke: #f5f5f5;
    } /*입체 선택형(이미지) 버튼 내부*/
    .s30 {
        stroke: #898989;
    } /*입체 선택형(이미지) 버튼 그림자*/
    .s18 {
        stroke: #fc6573;
    } /*입체 선택형(이미지) 버튼 선택시 테두리 선 색*/
    .s92 {
        stroke: #ffffff;
    } /*입체 선택형(이미지, 텍스트) 버튼 내부(흰색) *************************************현재 진단(온라인 2.0 영어) 버튼에 사용 20.01.15추가*/

    .s28 {
        stroke: #dddddd;
    } /*평면 선택형 버튼 배경*/
    .s31 {
        stroke: #fc716a;
    } /*평면 선택형 버튼 선택시 테두리 선*/
    .s32 {
        stroke: #368cc1;
    } /*평면 선택형 버튼 텍스트*/

    .s87 {
        stroke: #d4f0a6;
    } /*단계 버튼 바탕*/
    .s88 {
        stroke: #b2c592;
    } /*단계 버튼 그림자*/

    .s22 {
        stroke: #dddddd;
    } /*숫자 반응판 배경 *************************************현재 공통에서 미사용*/
    .s52 {
        stroke: #22578c;
    } /*숫자 반응판 텍스트(안눌렀을 때) *************************************현재 공통에서 미사용(f55로 대체해서 사용)*/
    .s53 {
        stroke: #ffffff;
    } /*숫자 반응판 배경(눌렀을 때) *************************************현재 공통에서 미사용*/
    .s55 {
        stroke: #22578c;
    } /*계산기 텍스트(안눌렀을 때)*/
    .s56 {
        stroke: #ffffff;
    } /*계산기 텍스트(눌렀을 때)*/
    .s57 {
        stroke: #368cc1;
    } /*handwrite에서 그리는 path*/
    .s90 {
        stroke: #85bc4a;
    } /*HandWriteML에서 그리는 path*/
    .s16 {
        stroke: #545454;
    } /*통삽화 하단 반응판 내부*/

    .s58 {
        stroke: #666666;
    } /*스크래치 패드-색상(검정)*/
    .s59 {
        stroke: #fc716a;
    } /*스크래치 패드-색상(분홍)*/
    .s60 {
        stroke: #4dabf5;
    } /*스크래치 패드-색상(파랑)*/
    .s61 {
        stroke: #fab153;
    } /*스크래치 패드-색상(노랑)*/
    .s62 {
        stroke: #b6c3d6;
    } /*스크래치 패드-두께 조작 버튼 *************************************현재 공통에서 미사용*/
    .s63 {
        stroke: #d1d9e5;
    } /*스크래치 패드-외곽 틀 선 *************************************현재 공통에서 미사용*/
    .s64 {
        stroke: #ededed;
    } /*스크래치 패드-외곽 틀 내부 *************************************현재 공통에서 미사용*/

    .s65 {
        stroke: #d93a2f;
    } /*색칠하기 빨강*/
    .s66 {
        stroke: #f29a3b;
    } /*색칠하기 주황*/
    .s67 {
        stroke: #fcda33;
    } /*색칠하기 노랑*/
    .s68 {
        stroke: #4d9a4d;
    } /*색칠하기 초록*/
    .s69 {
        stroke: #328dd3;
    } /*색칠하기 파랑*/
    .s70 {
        stroke: #9c3584;
    } /*색칠하기 보라*/
    .s71 {
        stroke: #e64680;
    } /*색칠하기 자주*/
    .s72 {
        stroke: #f0a7c7;
    } /*색칠하기 분홍*/
    .s73 {
        stroke: #d69a2b;
    } /*색칠하기 갈색*/

    .s74 {
        stroke: #ffe4a1;
    } /*파스텔톤 노랑*/
    .s75 {
        stroke: #ffc599;
    } /*파스텔톤 주황*/
    .s76 {
        stroke: #f7c6ec;
    } /*파스텔톤 분홍*/
    .s77 {
        stroke: #fcbbbb;
    } /*파스텔톤 빨강*/
    .s78 {
        stroke: #aeef97;
    } /*파스텔톤 연두*/
    .s79 {
        stroke: #94dd9b;
    } /*파스텔톤 초록*/
    .s80 {
        stroke: #9bf9ed;
    } /*파스텔톤 하늘*/
    .s81 {
        stroke: #99b5d8;
    } /*파스텔톤 진한 파랑*/
    .s82 {
        stroke: #be98ba;
    } /*파스텔톤 보라*/
    .s83 {
        stroke: #dca994;
    } /*파스텔톤 갈색*/
    .s84 {
        stroke: #92dbff;
    } /*파스텔톤 파랑*/
`;
export default learningContentCSS;
