import React from 'react';
import styled from 'styled-components';
const Block = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -0.2em;
    .mx {
        margin: 0 0.25em;
    }
    &.two-line {
        flex-direction: column;
    }
`;
export default function CenterText({ type, texts }) {
    return (
        <Block className={`h5 ${type ? 'two-line' : ''}`}>
            {type === 0 && (
                <>
                    <div className="bold h4">{texts[0]}</div>
                    <div className="mx">/</div>
                    <div> {texts[1]}</div>
                </>
            )}
            {type === 1 && (
                <>
                    {texts.map((el, i) => (
                        <div className={i === 0 && texts.length > 1 ? 'h4' : ''} key={el}>
                            {el}
                        </div>
                    ))}
                </>
            )}
        </Block>
    );
}
