import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import media from './util/media';
import { ReactComponent as ExitIcon } from './images/exit_button.svg';
import { ReactComponent as Star0Icon } from './images/star_0.svg';
import { ReactComponent as Star1Icon } from './images/star_1.svg';
import { ReactComponent as Star2Icon } from './images/star_2.svg';
import { ReactComponent as StarnullIcon } from './images/star_null.svg';
import { ReactComponent as ProgramsEmpty } from './images/programs_empty.svg';
import { ReactComponent as ProgramsFill } from './images/programs_fill.svg';
import { ReactComponent as Information } from './images/information.svg';
import theme from '../../assets/styles/theme';
const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
`;

const Block = styled.div`
    width: 100%;
    height: 60px;
    background: ${theme.palette.primary};
    .container {
        margin: 0 auto;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        padding: 5px 1.6rem 5px 1.6rem;
        justify-content: space-between;
    }
    .left {
        color: #5ceae6;
        cursor: ${({ isTracking, exitEvent }) => (isTracking || !exitEvent ? 'unset' : 'pointer')};
        pointer-events: ${process.env.NODE_ENV !== 'development' ? 'none' : 'initial'};
    }
    .right {
        cursor: ${({ isTracking }) => (isTracking ? 'unset' : 'pointer')};
        display: flex;
        align-items: center;
        height: 100%;
        max-height: 2.25em;
        svg {
            height: 100%;
            width: auto;
            padding: 0.25em 0;
        }
        svg + svg {
            margin-left: 1em;
        }
    }
    .stars {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0 1em;
    }
    .pointer {
        pointer-events: auto;
        cursor: pointer;
    }
    .bounce {
        animation: ${bounce} 1s;
    }
    ${media.medium} {
        height: 50px;
    }
    ${media.xsmall} {
        height: 120px;
        justify-content: center;
        .container {
            padding-top: 5px;
            padding-bottom: 10px;
            flex-wrap: wrap;
            align-content: space-around;
            .stars {
                order: 1;
                padding: 0;
                height: 35px;
                width: 100%;
                flex: initial;
            }
            .right {
                height: 40px;
            }
        }
    }
`;
function Star({ type, x, noBounce }) {
    if (type === null) return null;
    return (
        <g className={noBounce ? '' : 'bounce'}>
            {type === 0 && <Star0Icon width="30" x={x}></Star0Icon>}
            {type === 1 && <Star1Icon width="30" x={x}></Star1Icon>}
        </g>
    );
}
export default function ProgressStar({
    result,
    onHomeButtonClick,
    infoText,
    onInfoTextClick,
    onProgramDetailClick,
    onInformationButtonClick,
    achievementDegree,
    feedback
}) {
    const firstNull = result.indexOf(null);
    const width = Math.max(40 * result.length, 40) + (achievementDegree === null ? -10 : 60);
    const nonNullProgress = useRef(result.indexOf(null));
    const [needPreventBounce, setNeedPreventBounce] = useState(result[0] !== null);
    useEffect(() => {
        if (result[0] === null) setNeedPreventBounce(false);
    }, [result]);
    return (
        <Block exitEvent={!!onInfoTextClick}>
            <div className="container">
                {infoText && (
                    <div className="left" onClick={onInfoTextClick}>
                        {infoText}
                    </div>
                )}
                <div className="stars">
                    <svg
                        viewBox={`0 0 ${width} 45`}
                        width={`${width}px`}
                        height="100%"
                        style={{ maxWidth: '100%', overflow: 'visible' }}
                    >
                        {result.map((el, i) => (
                            <React.Fragment key={i}>
                                <Star
                                    type={el}
                                    x={40 * i}
                                    noBounce={needPreventBounce && i < nonNullProgress.current}
                                ></Star>
                                {el === null && firstNull === i && !feedback && <Star2Icon width="30" x={40 * i} />}
                                {el === null && (firstNull !== i || feedback) && <StarnullIcon width="30" x={40 * i} />}
                            </React.Fragment>
                        ))}
                        {achievementDegree !== null && (
                            <g onClick={onProgramDetailClick} className="pointer">
                                <mask id="mymask">
                                    <rect
                                        x="0"
                                        y="0"
                                        width="100%"
                                        fill="#fff"
                                        height={((100 - Math.min(100, achievementDegree)) / 100) * 45.7}
                                        style={{
                                            transition: 'height 1s'
                                        }}
                                    ></rect>
                                </mask>
                                <rect width="60" height="100%" x={40 * result.length} fill="transparent"></rect>
                                <ProgramsFill width="60" x={40 * result.length}></ProgramsFill>
                                <ProgramsEmpty width="60" x={40 * result.length} mask="url('#mymask')"></ProgramsEmpty>
                            </g>
                        )}
                    </svg>
                </div>
                <div className="right">
                    {onInformationButtonClick && <Information onClick={onInformationButtonClick}></Information>}
                    <ExitIcon onClick={onHomeButtonClick} />
                </div>
            </div>
        </Block>
    );
}
