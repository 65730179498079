import React from 'react';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../assets/styles/theme';
import Carousel from '../../../components/Carousel';
import Button from '../../../components/common/Button';
import media from '../../../utils/media';
import Donut from './Donut';
import moment from 'moment';
import { ReactComponent as ClockB } from '../../../assets/images/program/clock_b.svg';
import { ReactComponent as ClockG } from '../../../assets/images/program/clock_g.svg';
import { ReactComponent as TimeB } from '../../../assets/images/program/time_b.svg';
import { ReactComponent as TimeG } from '../../../assets/images/program/time_g.svg';
import { ReactComponent as ArrowDown } from '../../../assets/images/arrow_down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/images/arrow_up.svg';
import CenterText from './CenterText';
import { isMobile } from '../../../utils/util';
import SoundButton from '../../../components/SoundButton';
import SVGIcon from '../../../components/SVGIcon';
import ProgramLearningStatus from './ProgramLearningStatus';
import useProgramPage from '../../../hooks/useProgramPage';
export const cardGraphStyle = css`
    #each {
        padding: 10px 0 10px 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        svg {
            width: auto;
            height: 6em;
        }
        .title {
            margin: 0.5em 0;
        }
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &.img-container {
                position: relative;
            }
        }
        ${media.xsmall} {
            width: 50%;
            justify-content: flex-start;
        }
    }
`;
export const buttonBlock = css`
    .button-block {
        display: flex;
        padding: 1.5em 0 1.5em 0;
        align-items: center;
        justify-content: center;
        &.no-detail {
            padding: 3em 0 7em 0;
        }
        .btn {
            width: 8em;
        }
    }
`;
const CardBlock = styled.div`
    position: relative;
    &.focus {
        z-index: 5;
    }
    & > div {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1.6rem 0 1.6rem;
    }
    .result-block {
        .result-group-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .result-group {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-around;
                position: relative;
                .arrow-group {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
        ${cardGraphStyle}
        .controller {
            display: flex;
            justify-content: center;
            .circle {
                cursor: pointer;
                border-radius: 50%;
                width: 1em;
                height: 1em;
                border: 1px solid gray;
                margin: 0 0.25em;
                &.selected {
                    background: #33b4df;
                }
            }
        }
    }
    ${buttonBlock}
    .carousel-inner-container {
        width: 100%;
    }
`;

const TitleBlock = styled.div`
    display: flex;
    height: 60px;
    padding: 10px 0 10px 0;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    pointer-events: ${({ pointerEvents }) => pointerEvents};
    .arrow_down1,
    .arrow_up1 {
        fill: ${theme.palette.textGray};
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            &.svg-container {
                margin: 0 10px;
                width: 40px;
                display: flex;
                justify-content: center;
            }
            flex-shrink: 0;
        }
        svg {
            height: auto;
            width: 35px;
            position: relative;
            z-index: 10;
        }
    }
`;
const makeLearningDate = data => (data ? [moment(data).format('MM.DD')] : []);
const parseTime = time => {
    if (time < 60) return [time + '초'];
    return [Math.round(time / 60) + '분'];
};
export const getResultDetails = (result, isDiagnosis) => {
    let titles, values, reLearnings, pers, reLearningPers, types;
    if (isDiagnosis) {
        titles = ['오답 수/문항 수', '결손학습목표', '학습 시간', '마지막 학습일'];
        pers = [
            (result.rightAnswerCount / result.totalQuestionCount) * 100,
            ((result.totalObjectiveCount - result.wrongObjectiveCount) / result.totalObjectiveCount) * 100,
            100,
            100
        ];
        values = [
            [result.totalQuestionCount - result.rightAnswerCount, result.totalQuestionCount], //개
            [result.wrongObjectiveCount, result.totalObjectiveCount],
            parseTime(result.learningTime),
            makeLearningDate(result.lastLearningDate)
        ];
        types = [0, 0, 1, 1];
    } else {
        titles = ['달성률', '진척률', '학습 시간', '마지막 학습일'];
        values = [
            [result.achievementDegree + '%'],
            [result.progressDegree + '%'],
            parseTime(result.learningTime),
            makeLearningDate(result.lastLearningDate)
        ];
        pers = [result.achievementDegree, result.progressDegree, 100, 100];
        reLearnings = [
            [result.reAchievementDegree + '%'],
            [result.reProgressDegree + '%'],
            parseTime(result.reLearningTime),
            result.reLastLearningDate !== '0' ? makeLearningDate(result.reLastLearningDate) : null
        ];
        reLearningPers = [result.reAchievementDegree, result.reProgressDegree, 100, 100];
        types = [1, 1, 1, 1];
    }

    return titles.map((el, i) => ({
        title: el,
        value: values[i],
        per: pers[i],
        reLearning: reLearnings && reLearnings[i],
        reLearningPer: reLearnings && reLearningPers[i],
        type: types[i]
    }));
};
export const makeDetails = (datas, reLearning) =>
    datas.map((el, i) => (
        <div key={i} id="each">
            <div className="img-container h5">
                {i < 2 && <Donut per={el.per} color={reLearning ? '#A0D14A' : '#37BDE6'}></Donut>}
                {i === 2 && !reLearning && <ClockB></ClockB>}
                {i === 2 && !!reLearning && <ClockG></ClockG>}
                {i === 3 && !reLearning && <TimeB></TimeB>}
                {i === 3 && !!reLearning && <TimeG></TimeG>}
                <CenterText type={el.type} texts={el.value}></CenterText>
            </div>
            <div className="title h5">{el.title}</div>
        </div>
    ));
const getDiagnosisStatus = programs => {
    // 처방프로그램 중 하나라도 미진행 또는 진행 중이 있다면 => 진단은 회색
    if (programs.some(({ status }) => status === 0 || status === 1)) return 6;
    // 처방프로그램 모두 달성이라면 => 진단은 파란색
    if (programs.every(({ status }) => status === 2)) return 1;
    // 그 외 => 진단그룹은 빨강
    return 2;
};
const ProgramCard = ({ info, onChangeFocus, onButtonClick, curriculumStatus, programInfos }) => {
    const { programId, programName, status, programResult, isFocused: _isFocused, index } = info;
    const { focusArea } = useProgramPage();
    const isFocused = _isFocused && focusArea === 0;
    const backgroundColor = isFocused ? theme.palette.primaryLight : theme.palette.white;
    const isDiagnosis = programId.indexOf('DG') !== -1;
    const pointerEvents = isDiagnosis || status !== 0 ? 'auto' : 'none';

    const isDetail = isDiagnosis ? status === 2 || status === 3 : status !== 0 && programResult.progressDegree !== 0;
    const details = useMemo(
        () => (isFocused ? getResultDetails(programResult, isDiagnosis) : []),
        [isFocused, programResult, isDiagnosis]
    );
    const buttonText = isFocused && isDiagnosis && (status === 2 || status === 3) ? '문항 보기' : '학습하기';
    const isReLearning = !!info.programResult.reLastLearningDate;
    const diagnosisStatus = useMemo(() => {
        if (status < 3) {
            return [3, 3, 5][status];
        } else if (status === 3) {
            return getDiagnosisStatus(programInfos.slice(1));
        }
    }, [status, programInfos]);
    return (
        <CardBlock
            id={`program-card-${index}`}
            className={isFocused ? 'focus' : ''}
            style={{ backgroundColor }}
            backgroundColor={backgroundColor}
        >
            <div>
                <SoundButton>
                    <TitleBlock
                        className={`h5 ${isFocused ? 'bold' : ''}`}
                        pointerEvents={pointerEvents}
                        onClick={() => onChangeFocus(index)}
                    >
                        <div>
                            {isDiagnosis && (
                                <div className={'svg-container'}>
                                    <SVGIcon src={`/status_${diagnosisStatus}.svg`}></SVGIcon>
                                </div>
                            )}

                            {!isDiagnosis && <ProgramLearningStatus type={status}></ProgramLearningStatus>}
                            <span>{programName}</span>
                        </div>
                        {isFocused && <ArrowUp></ArrowUp>}
                        {!isFocused && <ArrowDown></ArrowDown>}
                    </TitleBlock>
                </SoundButton>

                {isFocused && isDetail && (
                    <div className="result-block bold">
                        <div className="result-group-container">
                            {!isReLearning && <div className="result-group">{makeDetails(details)}</div>}
                            {isReLearning && (
                                <Carousel
                                    hideArrow={isMobile()}
                                    classes={{ carouselInnerContainer: 'carousel-inner-container' }}
                                >
                                    <div className="result-group">{makeDetails(details)}</div>
                                    <div className="result-group">
                                        {makeDetails(
                                            details.map(el => ({
                                                ...el,
                                                title: el.title,
                                                value: el.reLearning,
                                                per: el.reLearningPer
                                            })),
                                            true
                                        )}
                                    </div>
                                </Carousel>
                            )}
                        </div>
                    </div>
                )}

                {isFocused && (
                    <div className={`button-block ${isDetail ? '' : 'no-detail'}`}>
                        <Button
                            className="h5 bold btn"
                            text={buttonText}
                            onClick={() => onButtonClick({ programId, status, programResult, index, programName })}
                        />
                    </div>
                )}
            </div>
        </CardBlock>
    );
};

export default ProgramCard;
