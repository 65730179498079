import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainList from './components/MainList';
import Loading from '../../components/common/Loading';
import { getLearningGrade } from '../../reducers/learningGrade';
import { getLearningCurriculum, initLearningCurriculum } from '../../reducers/learningCurriculum';
import { initLearningProgram } from '../../reducers/learningProgram';
import { setGradeCode, setCurriculumCode, setCurriculumName } from '../../reducers/learningInfo';
import { useHistory } from 'react-router-dom';
import useLearnerInfo from '../../hooks/useLearnerInfo';
import usePromise from '../../hooks/usePromise';
import { getRewardBadge } from '../../api/lms';
import AD from '../../assets/texts/AD';
import MainLayout from '../../components/MainLayout';
import useGetHeartReward from '../../hooks/useGetHeartReward';
import MainRewardModal from '../../components/MainRewardModal';
import LearningTargetModal from '../../components/common/LearningTargetModal';
import ModalContainer from '../../components/common/ModalContainer';
import Toast from '../../components/Toast';
import { ReactComponent as ToastIcon } from '../../assets/images/report/Toast_icon.svg';
import TT from '../../assets/texts/TT';
import useLearningTime from '../../hooks/useLearningTime';
import useProgramPage from '../../hooks/useProgramPage';
function MainContainer() {
    const { data: learnerInfo, loading: loading3, error: error3 } = useLearnerInfo();
    const { data: gradeInfos, loading: loading1, error: error1 } = useSelector(state => state.learningGrade);
    const { data: curriculumInfos, loading: loading2, error: error2 } = useSelector(state => state.learningCurriculum);
    const { setFocusIndex, setFocusArea } = useProgramPage();
    const { data: badge, loading: loading4, error: error4 } = usePromise(getRewardBadge);
    const { data: learningTime, error: error5, refresh: learningTimeRefresh } = useLearningTime();
    const { gradeCode, firstLogin } = useSelector(state => state.learningInfo);
    const { modalInfo } = useGetHeartReward();
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = loading1 || loading2 || loading3 || loading4 || !learnerInfo || !curriculumInfos || !gradeInfos;
    const error = error1 || error2 || error3 || error4 || error5;
    const [toast, setToast] = useState(false);
    const [openLearningTarget, setOpenLearningTarget] = useState(false);
    const onClick = curriculumCode => {
        dispatch(initLearningProgram());
        dispatch(setCurriculumCode(curriculumCode));
        setFocusIndex(null);
        setFocusArea(0);
        dispatch(setCurriculumName(curriculumInfos.find(el => el.code === curriculumCode).name));
        history.push(`/program`);
    };

    const onChangeGrade = code => {
        if (code !== gradeCode) {
            dispatch(initLearningCurriculum());
            dispatch(setGradeCode(code));
        }
    };
    const handleLearningTargetClick = () => {
        if (learningTime.updateable) setOpenLearningTarget(true);
        else setToast(true);
    };
    const message = useMemo(() => {
        if (!learnerInfo || !curriculumInfos) return { text: '반가워요!' };
        const notPass = curriculumInfos.filter(el => el.status === 2).length;
        const isEndGrade = curriculumInfos.every(el => [1, 2, 5].includes(el.status));
        //등급완료
        if (isEndGrade) {
            //미통과 커리큘럼 존재
            if (notPass) return AD(3);
            //모든 커리큘럼 통과
            return AD(4, { gradeCode });
        }
        //등급 미완료
        else {
            //최초 로그인
            if (firstLogin) return AD(1, { displayName: learnerInfo.display_name });
            //최초 로그인 아닌경우
            return AD(2, { curriculumName: curriculumInfos.find(el => el.status === 3).name });
        }
    }, [curriculumInfos, learnerInfo, gradeCode, firstLogin]);
    useEffect(() => {
        if (!gradeInfos && !gradeCode) dispatch(getLearningGrade());
        if (gradeInfos && !gradeCode) dispatch(setGradeCode(gradeInfos[gradeInfos.length - 1]));
        if (gradeInfos && gradeCode) dispatch(getLearningCurriculum({ gradeCode }));
    }, [gradeInfos, gradeCode, dispatch]);
    if (error) throw error;
    if (loading) return <Loading />;
    return (
        <>
            <MainLayout
                robotSrc={message.robotSrc}
                message={message.text}
                report
                reward
                logout
                badge={(badge || 0).badgeCount}
            >
                {curriculumInfos && (
                    <MainList
                        displayName={learnerInfo.display_name}
                        gradeInfos={gradeInfos}
                        curriculumInfos={curriculumInfos}
                        learnerInfo={learnerInfo}
                        onClick={onClick}
                        onChangeGrade={onChangeGrade}
                        onLearningTargetClick={handleLearningTargetClick}
                    />
                )}
                {modalInfo && <MainRewardModal {...{ ...modalInfo }}></MainRewardModal>}
                {openLearningTarget && (
                    <LearningTargetModal
                        onSaveEnd={learningTimeRefresh}
                        initialTargetTime={learningTime.targetTime}
                        onClick={setOpenLearningTarget.bind({}, false)}
                    />
                )}
                {toast && (
                    <ModalContainer>
                        <Toast
                            message={TT(2)}
                            icon={<ToastIcon />}
                            style={{ background: 'lightgray' }}
                            onToastHide={setToast.bind({}, false)}
                        />
                    </ModalContainer>
                )}
            </MainLayout>
        </>
    );
}

export default MainContainer;
