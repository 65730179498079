import React from 'react';
import styled from 'styled-components';
import ModalContainer from '../../../components/common/ModalContainer';
import SVGIcon from '../../../components/SVGIcon';
import usePreventScroll from '../../../hooks/usePreventScroll';
import { grades, planetNames } from '../../../utils/dataMap';
import ResponsiveCarousel from './ResponsiveCarousel';
const Block = styled.div`
    width: 100%;
    .planent-container {
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
    .planet {
        width: 20em;
        height: 20em;
    }
    .planet-name {
        position: absolute;
        bottom: 0;
        padding: 0.25em 2em;
        transform: translateY(100%);
        display: flex;
        justify-content: center;
        align-items: center;
        background: #06457a;
        border-radius: 15px;
    }
`;
export default function Planets({ grades: _grades, onClose }) {
    usePreventScroll();
    return (
        <ModalContainer onClick={onClose} overflow="hidden">
            <Block onClick={e => e.stopPropagation()}>
                <ResponsiveCarousel onClose={onClose}>
                    {_grades.map((el, i) => (
                        <div className="planent-container" key={i}>
                            <SVGIcon src={`/reward-items/planet_${grades.indexOf(el) + 1}.svg`} className="planet" />
                            <div className="planet-name ft-body">{planetNames[grades.indexOf(el)]}</div>
                        </div>
                    ))}
                </ResponsiveCarousel>
            </Block>
        </ModalContainer>
    );
}
