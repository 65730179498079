import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import * as lms from '../../api/lms';
import usePromise from '../../hooks/usePromise';
import useScript from '../../hooks/useScript';

import Loading from '../../components/common/Loading';
import LeanerStateComponent from '../reportPage/learningAnalyticsComponent/LearnerStateComponent';
import LearningMapComponent from '../reportPage/learningAnalyticsComponent/LearningMapComponent';

const Container = styled.div`
    max-width: 1200px;
    margin: 0px auto;

    svg {
        margin: 0px auto;
        min-width: 800px;
    }
`;

export default function LearningAchievementContainer() {
    const [mapLoading, setMapLoading] = useState(true);
    const [learningStateInfo, setLearningStateInfo] = useState();
    const [learningMapInfo, setLearningMapInfo] = useState();
    const { data: learningMapData, loading, error } = usePromise(lms.getLearningMapData);
    useScript({ url: '/snap.svg-min.js', checkObject: 'Snap' });
    useScript({ url: '/learningMap.js', onLoad: () => setMapLoading(false), checkObject: 'learningMap' });
    useMemo(() => {
        if (learningMapData) {
            const data = Object.entries(learningMapData)
                .sort((a, b) => b[0].localeCompare(a[0]))
                .reverse()
                .map(el => ({ ...el[1], id: el[0], title: el[1].title, titles: el[1].title.split('\\n') }));
            const current = data.find(el => el.status === '3');
            const before = current && data.find(el => el.nodes.indexOf(current.id) !== -1);
            // const before = { titles: ['덧셈과 곱셈의 성질', '덧셈과 뺄셈, 곱셈과 나눗셈의', '관계'], status: '2' };
            const after = current && data.find(el => el.id === current.nodes[0]);
            setLearningMapInfo(data);
            setLearningStateInfo({ current, before, after, data });
        }
    }, [learningMapData]);

    if (error) throw error;
    if (mapLoading || loading) return <Loading />;    

    return (
        <Container>
            {!loading && <LeanerStateComponent learningStateInfo={learningStateInfo}/>}
            {!loading && <LearningMapComponent mapLoading={mapLoading} learningMapInfo={learningMapInfo} />}
        </Container>
    );
}
