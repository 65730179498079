import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { getWrongAnswers } from '../../api/lms';
import WrongAnswerDetail from './components/WrongAnswerDetail';
import WrongAnswerList from './components/WrongAnswerList';
import usePromise from '../../hooks/usePromise';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import TitleCalendarHeader from '../../components/TitleCalendarHeader';
import MainLayout from '../../components/MainLayout';
import AT from '../../assets/texts/AT';
export default function WrongAnswerContainer() {
    const history = useHistory();
    const [startDate, setStartDate] = useState(moment().subtract(30, 'day').format('YYYYMMDD'));
    const [endDate, setEndDate] = useState(moment().format('YYYYMMDD'));
    const { data, error } = usePromise(getWrongAnswers, false, { startDate, endDate });
    const [viewType, setViewType] = useState(0);
    const [selectedData, setSelectedData] = useState();
    const message = useMemo(() => {
        if (viewType === 0) {
            if (!data) return '';
            if (data.length < 1) return '오답이 없어요.';
            return AT(5);
        } else return AT(6, { programName: selectedData.program_name });
    }, [selectedData, viewType, data]);
    const onBack = url => {
        if (viewType === 1) setViewType(0);
        if (viewType === 0) history.replace('/report/achievement');
    };
    if (error) throw error;
    return (
        <MainLayout back={onBack} message={message} robotSrc="AT6.svg">
            {viewType === 0 && (
                <TitleCalendarHeader
                    title="풀이 과정 확인"
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                ></TitleCalendarHeader>
            )}
            {viewType === 0 && data && (
                <WrongAnswerList
                    setViewType={setViewType}
                    data={data}
                    setSelectedData={setSelectedData}
                ></WrongAnswerList>
            )}
            {viewType === 1 && selectedData && <WrongAnswerDetail data={selectedData}></WrongAnswerDetail>}
        </MainLayout>
    );
}
