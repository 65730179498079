export default function DF(type) {
    const texts = [
        ['좋아요!', '잘했어요!', '정말 훌륭해요!', '최고예요!', '멋진데요!', '훌륭해요!', '멋져요!'],
        [
            '집중해서 풀어 봐요!',
            '잘 할 수 있어요!',
            '이런, 다시 해 볼까요?',
            '아! 아까워요!',
            '잘 생각해 봐요!',
            '아쉬워요!',
            '차분히 풀어 봐요!'
        ],
        ['잘하고 있어요!', '와! 최고예요!', '정말 대단해요!'],
        ['좀 더 집중해야겠어요! ', '포기하지 마요!', '너무 아쉽네요!']
    ][type - 1];
    const random = Math.floor(Math.random() * texts.length);
    return {
        text: texts[random],
        src: `DF${type}-${random + 1}_no fx.mp3`
    };
}
