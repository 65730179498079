import React from 'react';
import styled from 'styled-components';
import media from '../../../utils/media';

import { ReactComponent as ButtonOn1 } from '../../../assets/images/report/button_on_1.svg';
import { ReactComponent as ButtonOn2 } from '../../../assets/images/report/button_on_2.svg';
import { ReactComponent as ButtonOn3 } from '../../../assets/images/report/button_on_3.svg';
import { ReactComponent as ButtonOff1 } from '../../../assets/images/report/button_off_1.svg';
import { ReactComponent as ButtonOff2 } from '../../../assets/images/report/button_off_2.svg';
import { ReactComponent as ButtonOff3 } from '../../../assets/images/report/button_off_3.svg';

import { ReactComponent as Button2On1 } from '../../../assets/images/report/button2_on_1.svg';
import { ReactComponent as Button2On2 } from '../../../assets/images/report/button2_on_2.svg';
import { ReactComponent as Button2On3 } from '../../../assets/images/report/button2_on_3.svg';
import { ReactComponent as Button2On4 } from '../../../assets/images/report/button2_on_4.svg';
import { ReactComponent as Button2On5 } from '../../../assets/images/report/button2_on_5.svg';
import { ReactComponent as Button2Off1 } from '../../../assets/images/report/button2_off_1.svg';
import { ReactComponent as Button2Off2 } from '../../../assets/images/report/button2_off_2.svg';
import { ReactComponent as Button2Off3 } from '../../../assets/images/report/button2_off_3.svg';
import { ReactComponent as Button2Off4 } from '../../../assets/images/report/button2_off_4.svg';
import { ReactComponent as Button2Off5 } from '../../../assets/images/report/button2_off_5.svg';

const Container = styled.div`
    margin: 0px auto;
    overflow: auto;
    padding-bottom: 1em;
    max-width: 550px;
    width: 100%;

    .diagnosis-svgIcon {
        width: 10em;
        height: auto;
    }
    .program-svgIcon {
        width: 6em;
        height: auto;
    }

    ${media.xsmall} {
        max-width: 400px;
        .diagnosis-svgIcon {
            width: 8.5em;
            height: auto;
        }
    }

    .diagnosis-image {
        margin: 0 0.5em;
    }

    .program-image {
        margin: 0 0.5em;
    }
    .diagnosis-count {
        margin-top: 0.2em;
    }

    .program {
        margin: 0 auto;
        justify-content: space-between;
        & > div {
            width: 20%;
            ${media.xsmall} {
                width: 33%;
            }
        }
        ${media.xsmall} {
            max-width: 300px;
            & > div:nth-of-type(1) {
                width: 34%;
            }
            & > div:nth-of-type(2) {
                width: 34%;
            }
        }

        ${media.xsmall} {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .program-image {
        margin: 0px 0.5em;
        ${media.xsmall} {
            margin-top: 1em;
        }
    }
    .color-status-1 {
        color: #22bfbf;
    }
    .color-status-2 {
        color: #f48787;
    }
    .color-status-0 {
        color: #e8a600;
    }

    .color-domain-1 {
        color: #79ba1c;
    }
    .color-domain-2 {
        color: #ec8733;
    }
    .color-domain-3 {
        color: #f48787;
    }
    .color-domain-4 {
        color: #e8a600;
    }
    .color-domain-5 {
        color: #7359b9;
    }
`;

const DiagnosisHeader = ({ learningAchievement, status, setStatus }) => {
    const { diagnosis } = learningAchievement;
    return (
        <div className="flex text-align-center justify-between">
            <div>
                <div className="cursor-pointer diagnosis-image" onClick={() => setStatus('1')}>
                    {status === '1' ? (
                        <ButtonOn1 className="diagnosis-svgIcon" />
                    ) : (
                        <ButtonOff1 className="diagnosis-svgIcon" />
                    )}
                </div>
                <div className={`${status === '1' ? 'color-status-' + status : ''}`}>잘했어요</div>
                <div className={`diagnosis-count ${status === '1' ? 'color-status-' + status : ''}`}>
                    ({diagnosis.status_1}개)
                </div>
            </div>
            <div>
                <div className="cursor-pointer diagnosis-image" onClick={() => setStatus('2')}>
                    {status === '2' ? (
                        <ButtonOn2 className="diagnosis-svgIcon" />
                    ) : (
                        <ButtonOff2 className="diagnosis-svgIcon" />
                    )}
                </div>
                <div className={`${status === '2' ? 'color-status-' + status : ''}`}>학습해요</div>
                <div className={`diagnosis-count ${status === '2' ? 'color-status-' + status : ''}`}>
                    ({diagnosis.status_2}개)
                </div>
            </div>
            <div>
                <div className="cursor-pointer diagnosis-image" onClick={() => setStatus('0')}>
                    {status === '0' ? (
                        <ButtonOn3 className="diagnosis-svgIcon" />
                    ) : (
                        <ButtonOff3 className="diagnosis-svgIcon" />
                    )}
                </div>
                <div className={`${status === '0' ? 'color-status-' + status : ''}`}>진행할 거예요</div>
                <div className={`diagnosis-count ${status === '0' ? 'color-status-' + status : ''}`}>
                    ({diagnosis.status_0}개)
                </div>
            </div>
        </div>
    );
};

const ProgramsHeader = ({ learningAchievement, domain, setDomain }) => {
    const { programs, learningDomains } = learningAchievement;
    return (
        <div className="flex text-align-center program">
            <div>
                <div className="cursor-pointer program-image" onClick={() => setDomain('1')}>
                    {domain === '1' ? (
                        <Button2On1 className="program-svgIcon" />
                    ) : (
                        <Button2Off1 className="program-svgIcon" />
                    )}
                </div>
                <div className={`${domain === '1' ? 'color-domain-' + domain : ''}`}>{learningDomains['1']}</div>
                <div className={`${domain === '1' ? 'color-domain-' + domain : ''}`}>
                    ({programs.domain_1.status_2}개)
                </div>
            </div>
            <div>
                <div className="cursor-pointer program-image" onClick={() => setDomain('2')}>
                    {domain === '2' ? (
                        <Button2On2 className="program-svgIcon" />
                    ) : (
                        <Button2Off2 className="program-svgIcon" />
                    )}
                </div>
                <div className={`${domain === '2' ? 'color-domain-' + domain : ''}`}>{learningDomains['2']}</div>
                <div className={`${domain === '2' ? 'color-domain-' + domain : ''}`}>
                    ({programs.domain_2.status_2}개)
                </div>
            </div>
            <div>
                <div className="cursor-pointer program-image" onClick={() => setDomain('3')}>
                    {domain === '3' ? (
                        <Button2On3 className="program-svgIcon" />
                    ) : (
                        <Button2Off3 className="program-svgIcon" />
                    )}
                </div>
                <div className={`${domain === '3' ? 'color-domain-' + domain : ''}`}>{learningDomains['3']}</div>
                <div className={`${domain === '3' ? 'color-domain-' + domain : ''}`}>
                    ({programs.domain_3.status_2}개)
                </div>
            </div>
            <div>
                <div className="cursor-pointer program-image" onClick={() => setDomain('4')}>
                    {domain === '4' ? (
                        <Button2On4 className="program-svgIcon" />
                    ) : (
                        <Button2Off4 className="program-svgIcon" />
                    )}
                </div>
                <div className={`${domain === '4' ? 'color-domain-' + domain : ''}`}>{learningDomains['4']}</div>
                <div className={`${domain === '4' ? 'color-domain-' + domain : ''}`}>
                    ({programs.domain_4.status_2}개)
                </div>
            </div>
            <div>
                <div className="cursor-pointer program-image" onClick={() => setDomain('5')}>
                    {domain === '5' ? (
                        <Button2On5 className="program-svgIcon" />
                    ) : (
                        <Button2Off5 className="program-svgIcon" />
                    )}
                </div>
                <div className={`${domain === '5' ? 'color-domain-' + domain : ''}`}>{learningDomains['5']}</div>
                <div className={`${domain === '5' ? 'color-domain-' + domain : ''}`}>
                    ({programs.domain_5.status_2}개)
                </div>
            </div>
        </div>
    );
};

export default function DiagnosisHeaderComponent({
    url,
    viewType,
    detail,
    status,
    setStatus,
    domain,
    setDomain,
    learningAchievement
}) {
    return (
        <Container className="h6">
            {viewType + '/' + detail === url[4] && (
                <DiagnosisHeader learningAchievement={learningAchievement} status={status} setStatus={setStatus} />
            )}
            {viewType + '/' + detail === url[5] && (
                <ProgramsHeader learningAchievement={learningAchievement} domain={domain} setDomain={setDomain} />
            )}
        </Container>
    );
}
