import { useCallback, useEffect, useState } from 'react';

export default function usePromise(promise, wait = false, params = {}) {
    const [state, setState] = useState({
        data: null,
        loading: !wait,
        error: null
    });
    const callPromise = useCallback(
        async _params => {
            setState(state => ({ ...state, loading: true }));
            try {
                const data = await promise(_params || params);
                setState({ data, loading: false, error: null });
            } catch (e) {
                setState({ data: null, loading: false, error: e });
            }
        },
        // eslint-disable-next-line
        Object.values(params).concat(promise)
    );
    const init = () => {
        setState({ loading: false, data: null, error: null });
    };
    useEffect(() => {
        if (wait) return setState(state => ({ ...state, loading: false }));
        callPromise();
    }, [callPromise, wait]);
    return { ...state, refresh: callPromise, init };
}
