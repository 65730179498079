import styled, { keyframes } from 'styled-components';
import ShootingStar from '../../../assets/images/reward-items/Shooting-star_g.svg';
import { ReactComponent as Ufo } from '../../../assets/images/reward-items/ufo.svg';
import { ReactComponent as SpaceShip } from '../../../assets/images/reward-items/spaceship.svg';
import media, { breakPoints } from '../../../utils/media';
import { planetNames } from '../../../utils/dataMap';
import theme from '../../../assets/styles/theme';
import { ReactComponent as ShootingStarAnim } from '../../../assets/images/icon-anim/OA3_icon.svg';
import SVGIcon from '../../../components/SVGIcon';
import { useCallback, useEffect, useState } from 'react';
const rotate = keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(-360deg);
    }
`;
const reverseRotate = keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg);
    }
`;

const floating = keyframes`
    0%,100% {
    transform: translateY(0);
	}
    50%{
        transform:translateY(-15%);
    }
`;
const blink = keyframes`
    0% {opacity:1;
    }
    50% {opacity:0.5;
    }
    100% {opacity:1;
    }
`;
const slide = width => keyframes`
    0%{
        transform:translate(0)
    }
    100%{
        transform:translate(-${Math.min(width, breakPoints.large)}px,${Math.min(width, breakPoints.large) / 3}px)
    }
`;
const Block = styled.div`
    width: 50%;
    padding-bottom: 1.6rem;
    ${media.small} {
        width: 60%;
    }
    ${media.xsmall} {
        width: 100%;
    }
    svg {
        height: auto;
    }

    .shooting-star-container {
        display: flex;
        position: relative;
        height: 5em;
        &.hidden {
            visibility: hidden;
        }
        .shooting-star {
            position: absolute;
            width: 5em;
            animation: ${blink} 2s infinite linear;
            left: 2.5em;
            top: 2.5em;
        }
        ${media.xsmall} {
            justify-content: flex-end;
            padding: 0 1.6rem;
            .shooting-star {
                left: unset;
                top: unset;
                width: 4em;
                transform: translateY(50%);
                /* left: 5em; */
                /* top: 1em; */
            }
        }
    }
    .bottom-container {
        display: flex;
        margin-bottom: 50px;
        margin-top: 10em;
        ${media.small} {
            justify-content: center;
        }
        ${media.xsmall} {
            margin-top: 50px;
        }
        animation: 6s ${floating} infinite ease-in-out;
    }
    .bottom {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        svg {
            height: auto;
        }

        .ufo-container {
            pointer-events: none;
            position: absolute;
            width: 90%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            transform-origin: center;
            &.anim {
                animation: ${reverseRotate} 2.5s ease-in-out;
            }
            .ufo {
                width: 25%;
                position: absolute;
                top: 0;
                left: 0;
                transform: rotate(-45deg);
            }
        }

        .spaceship-container {
            pointer-events: none;
            position: absolute;
            width: 110%;
            height: 110%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            transform-origin: center;
            &.anim {
                animation: ${rotate} 2.5s ease-in-out;
            }
            .spaceship {
                position: absolute;
                top: -5%;
                right: 0;
                width: 16%;
                transform: rotate(-45deg);
            }
        }
        .planet-name {
            position: absolute;
            right: -1em;
            bottom: -2em;
            padding: 0.5em;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            &::before {
                position: absolute;
                content: ' ';
                border-left: 1px solid ${theme.palette.textSpace};
                height: 80%;
                left: 0;
                bottom: 0;
                transform: rotate(-30deg);
                transform-origin: bottom center;
            }
            &::after {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                content: ' ';
                border-bottom: 1px solid ${theme.palette.textSpace};
            }
        }
        .planet {
            width: 10em;
            cursor: pointer;
        }
        .fire-anim {
            position: absolute;
            width: 100%;
            height: 100%;
            transform: scale(3);
        }
    }
    .planet-buttons {
        position: fixed;
        left: 0;
        bottom: 1em;
        display: flex;
        justify-content: center;
        width: 100%;
        ${media.xsmall} {
            position: absolute;
        }
    }
`;
const ShootingStartAnimContainer = styled.div`
    position: fixed;

    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    .anim-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: ${breakPoints.large}px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
    svg {
        width: 250px;
        animation: ${props => slide(props.width)} 3.5s forwards ease-in-out;
    }
`;
export default function RewardItems({
    rewardSum,
    onPlanetClick,
    ufoAnim,
    rocketAnim,
    shootingStarAnim,
    planetAnim,
    planetIndex
}) {
    const [width, setWidth] = useState(window.innerWidth);
    const resize = useCallback(() => {
        setWidth(window.innerWidth);
    }, []);
    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [resize]);
    return (
        <Block className="h5">
            {shootingStarAnim && (
                <ShootingStartAnimContainer className="shootingstar-anim" width={width}>
                    <div className="anim-container">
                        <ShootingStarAnim preserveAspectRatio="xMaxYMin" />
                    </div>
                </ShootingStartAnimContainer>
            )}

            {
                <div className={`shooting-star-container${shootingStarAnim || !rewardSum['RW.02'] ? ' hidden' : ''}`}>
                    <img src={ShootingStar} alt="유성" className="shooting-star" />
                </div>
            }

            {
                <div className="bottom-container">
                    <div className="bottom">
                        {rewardSum['RW.04'] && (
                            <div className={`ufo-container ${ufoAnim ? 'anim' : ''}`}>
                                <Ufo className="ufo" />
                            </div>
                        )}
                        {rewardSum['RW.03'] && (
                            <div className={`spaceship-container ${rocketAnim ? 'anim' : ''}`}>
                                <SpaceShip className="spaceship" />
                            </div>
                        )}
                        <div className="planet-name">{planetNames[planetIndex - 1]}</div>

                        <img
                            alt="행성"
                            className="planet"
                            onClick={onPlanetClick}
                            src={require(`../../../assets/images/reward-items/planet_${planetIndex}.svg`).default}
                        ></img>
                        {planetAnim && (
                            <SVGIcon
                                className="fire-anim"
                                src={`/icon-anim/OA5-${((planetIndex - 1) % 4) + 1}_icon.svg`}
                            ></SVGIcon>
                        )}
                    </div>
                </div>
            }
        </Block>
    );
}
