import { version as packageVersion } from '../../package.json';
export const grades = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
export const planetNames = [
    '레인보우',
    '슬라임몬',
    '프로즌',
    '워터파크',
    '버블껌',
    '베리베리',
    '피자홀릭',
    '마카롱런',
    '다트엔젤',
    '모카커피'
];
export const version = 'v' + packageVersion;
