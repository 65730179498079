import React, { useMemo } from 'react';
import styled from 'styled-components';

import usePromise from '../../hooks/usePromise';
import * as lms from '../../api/lms';

import Loading from '../../components/common/Loading';
import GraphComponent from '../reportPage/learningHibitComponent/GraphComponent';

const Container = styled.div`
    max-width: 1200px;
    margin: 0rem auto;
    position: relative;

    & > .top {
        position: absolute;
        height: 1.5px;
        top: 145px;
        /* top: 175px; */
        width: 100%;
        border: none;
        background: #b5b5b6;
    }
    & > .bottom {
        position: absolute;
        height: 1.5px;
        top: 395px;
        width: 100%;
        border: none;
        background: #b5b5b6;
    }
`;

const getMinuteType = sec => Math.round(sec / 60);

export default function LearningHabitContainer() {
    const { data: learningHabit, loading, error } = usePromise(lms.getLearningHabit);

    const learningHabitData = useMemo(() => {
        let learningTimeHistory;
        let questionCountHistory;
        let todayIndex;
        let chartWidth;
        if (learningHabit) {
            learningTimeHistory = learningHabit.map((el, i) => ({
                learning_date: el.learning_date,
                learning_time: getMinuteType(el.learning_time),
                // learning_time: el.learning_time === 0 ? null : el.learning_time,
                learning_habit_yn: el.learning_habit_yn,
                focusIndex: i
            }));

            questionCountHistory = learningHabit.map((el, i) => ({
                learning_date: el.learning_date,
                total_question_count: el.total_question_count,
                right_question_count: el.total_question_count - el.wrong_question_count,
                wrong_question_count: el.wrong_question_count,
                focusIndex: i
            }));

            // learningTimeHistory = [];
            // questionCountHistory = [];
            // for (let index = 0; index < 50; index++) {
            //     const today = new Date('2020-01-31');
            //     const date = new Date();
            //     date.setDate(today.getDate() + index);
            //     const yy = date.getFullYear();
            //     let mm = date.getMonth() + 1;
            //     mm = mm < 10 ? '0' + mm : mm;
            //     const dd = date.getDate();
            //     learningTimeHistory[index] = {
            //         learning_date: yy + '' + mm + dd,
            //         learning_time: Math.floor(Math.random() * 10),
            //         learning_habit_yn: true,
            //         focusIndex: index
            //     };
            //     questionCountHistory[index] = {
            //         learning_date: yy + '' + mm + dd,
            //         total_question_count:
            //             learningTimeHistory[index].learning_time === 0 ? 0 : Math.floor(Math.random() * 60 + 1),
            //         right_question_count:
            //             learningTimeHistory[index].learning_time === 0 ? 0 : Math.floor(Math.random() * 30),
            //         wrong_question_count:
            //             learningTimeHistory[index].learning_time === 0 ? 0 : Math.floor(Math.random() * 30),
            //         focusIndex: index
            //     };
            // }
            todayIndex = learningTimeHistory.length - 1;
            chartWidth = 95 + learningTimeHistory.length * 42.5;

            return { learningTimeHistory, questionCountHistory, todayIndex, chartWidth };
        }
    }, [learningHabit]);

    if (error) throw error;
    if (loading) return <Loading />;

    return (
        <Container>
            {learningHabit.length !== 0 && <hr className="top" />}
            <hr className="bottom" />
            <GraphComponent
                learningTimeHistory={learningHabitData.learningTimeHistory}
                questionCountHistory={learningHabitData.questionCountHistory}
                todayIndex={learningHabitData.todayIndex}
                chartWidth={learningHabitData.chartWidth}
            />
        </Container>
    );
}
