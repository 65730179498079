import { Howl } from 'howler';
import { useCallback, useEffect } from 'react';

export default function useSound({ src, autoPlay = true, onEnd, pauseOnUnmount = true }) {
    const play = useCallback(() => {
        Object.values(global.JEIAUDIOS).forEach(el => {
            el.pause();
        });
        global.JEIAUDIOS[src].stop();
        global.JEIAUDIOS[src].play();
    }, [src]);
    const pause = useCallback(() => {
        global.JEIAUDIOS[src].pause();
    }, [src]);
    useEffect(() => {
        if (!src) return;
        if (!global.JEIAUDIOS[src]) {
            global.JEIAUDIOS[src] = new Howl({
                src,
                autoplay: autoPlay
            });
        }
        if (onEnd) global.JEIAUDIOS[src].on('end', onEnd);
        if (autoPlay) {
            play();
        }
        return () => {
            global.JEIAUDIOS[src].off('end');
            if (pauseOnUnmount) global.JEIAUDIOS[src].pause();
        };
    }, [src, autoPlay, onEnd, pauseOnUnmount, play]);

    return { play, pause };
}
