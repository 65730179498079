import styled from 'styled-components';

const CardContent = styled.div`
    &.selected {
        color: white;
        svg {
            * {
                fill: white;
            }
        }
    }
    padding: 0.5em;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 90px;
    & > div {
        margin-left: 0.5em;
    }
    svg {
        width: 2em;
        height: auto;
        flex-shrink: 0;
    }
`;
export default CardContent;
