import React from 'react';
import styled from 'styled-components';
import ProgressStar from './ProgressStar';
import Timer from '../../../components/FrameLearning/Timer';
import contentCSS from '../../../assets/styles/learningContentCSS';
const Block = styled.div`
    ${contentCSS}
    width:100%;
    height: 100%;
    margin: 0 auto;
    background: white;
    .header {
        position: relative;
    }
`;
export default function WrongAnswerViewer({ achievementDegree, progress, learningContents, timerWidth }) {
    return (
        <Block>
            <div className="header">
                <ProgressStar achievementDegree={achievementDegree} result={progress} isTracking></ProgressStar>
                <Timer timer={timerWidth} isTracking></Timer>
            </div>
            <svg
                viewBox="0 0 800 500"
                dangerouslySetInnerHTML={{
                    __html: learningContents
                }}
            ></svg>
        </Block>
    );
}
