import { useMemo } from 'react';
import useSound from '../hooks/useSound';
import { soundPath } from '../utils/util';

export default function SoundButton({ children, src = 'OS6_ver2.mp3' }) {
    const { play } = useSound({ src: `${soundPath}/${src}`, autoPlay: false, pauseOnUnmount: false });
    const newChildren = useMemo(() => {
        const _newChildren = { ...children };
        const { onMouseDown, onTouchStart } = children.props;
        const _onMouseDown = (...params) => {
            if (onMouseDown) onMouseDown(...params);
            play();
        };
        const _onTouchStart = (...params) => {
            if (onTouchStart) onTouchStart(...params);
            if (params[0] && params[0].touches && params[0].touches.length > 1) return;
            play();
        };
        _newChildren.props = { ..._newChildren.props, onTouchStart: _onTouchStart, onMouseDown: _onMouseDown };
        return _newChildren;
    }, [children, play]);

    return <>{newChildren}</>;
}
