import { useEffect } from 'react';

export default function useScript({ url, onLoad, checkObject }) {
    useEffect(() => {
        if (window[checkObject]) {
            if (onLoad) onLoad();
            return;
        }
        const script = document.createElement('script');
        script.src = url;
        script.async = false;
        script.onload = onLoad;
        document.body.appendChild(script);
        return () => document.body.removeChild(script);
    }, [url, onLoad, checkObject]);
}
