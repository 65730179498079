import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../assets/styles/theme';
import Calendar from '../components/common/Calendar';
import moment from 'moment';
import { breakPoints } from '../utils/media';
import { ReactComponent as CalendarIcon } from '../assets/images/calendar_icon.svg';
import media from './FrameLearning/util/media';
const Block = styled.div`
    padding: 0.5em 1.6rem;
    background: ${theme.palette.primaryLight};
    .container {
        display: flex;
        align-items: center;
        max-width: ${breakPoints.large}px;
        margin: 0 auto;
        ${media.xsmall} {
            flex-wrap: wrap;
            & > .title {
                padding-left: 0.25em;
            }
        }
        & > .title {
            white-space: nowrap;
            flex: 1;
        }
        .calendar-container {
            display: flex;
            align-items: center;
            position: relative;
            outline: none;
            & > span {
                padding: 0.5em;
            }
            & > .calendar-toggle {
                cursor: pointer;
                display: flex;
                padding: 0.5em;
                border-bottom: 1px solid ${theme.palette.divider};
                & > svg {
                    margin-left: 0.5em;
                }
            }
            & > .picker {
                z-index: 11;
                position: absolute;
                top: 3em;
                right: 0;
            }
        }
    }
    .h7 {
        font-size: 24px;
        ${media.large} {
            font-size: 23px;
        }
        ${media.medium} {
            font-size: 21px;
        }
        ${media.small} {
            font-size: 19px;
        }
        ${media.xsmall} {
            font-size: 17px;
        }
    }
`;
export default function TitleCalendarHeader({ title, startDate, endDate, setStartDate, setEndDate }) {
    const [calendarHidden, setCalendarHidden] = useState(true);
    const [calendarType, setCalendarType] = useState();
    const handleClick = type => {
        setCalendarType(type);
        setCalendarHidden(false);
    };
    const onSelect = date => {
        if (calendarType === 'start') setStartDate(date);
        if (calendarType === 'end') setEndDate(date);
        setCalendarHidden(true);
    };

    return (
        <Block>
            <div className="container">
                <div className="title h7">{title}</div>
                <div className="calendar-container" tabIndex="-1">
                    <div className="calendar-toggle" onClick={handleClick.bind({}, 'start')}>
                        {moment(startDate).format('YYYY.MM.DD')}
                        <CalendarIcon></CalendarIcon>
                    </div>
                    <span>~</span>
                    <div className="calendar-toggle" onClick={handleClick.bind({}, 'end')}>
                        {moment(endDate).format('YYYY.MM.DD')}
                        <CalendarIcon></CalendarIcon>
                    </div>
                    <div className="picker">
                        {!calendarHidden && (
                            <Calendar
                                startDate={calendarType === 'end' ? startDate : null}
                                endDate={calendarType === 'start' ? endDate : null}
                                selectedDate={calendarType === 'start' ? startDate : endDate}
                                onSelect={onSelect}
                                onClose={setCalendarHidden.bind({}, true)}
                            ></Calendar>
                        )}
                    </div>
                </div>
            </div>
        </Block>
    );
}
