import React, { useState } from 'react';
import styled from 'styled-components';
import usePreventScroll from '../../../hooks/usePreventScroll';
import { breakPoints } from '../../../utils/media';
import ConstellationCard from './ConstellationCard';
import ResponsiveCarousel from './ResponsiveCarousel';
const Block = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0, 0.7);
    z-index: 10;
    & > .container {
        max-width: ${breakPoints.large}px;
        margin: 0 auto;
        width: 100%;
        /* height: 100%; */
        max-height: 100%;
        overflow: hidden;
        position: relative;
    }
    & > .lock-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
export default function ConstellationCards({ onCardClose, collectedConstellations, checkLast }) {
    usePreventScroll();
    const [focusIndex, setFocusIndex] = useState(Math.max(collectedConstellations.length - (checkLast ? 1 : 0), 0));
    return (
        <Block onClick={onCardClose}>
            <div className="container" onClick={e => e.stopPropagation()}>
                <ResponsiveCarousel initialIndex={focusIndex} onChange={setFocusIndex} onClose={onCardClose}>
                    {collectedConstellations
                        .map((el, i) => (
                            <ConstellationCard
                                key={el.reward_id}
                                onClose={onCardClose}
                                type={Number(el.reward_id.slice(3))}
                                isFocus={i === focusIndex}
                            ></ConstellationCard>
                        ))
                        .concat(<ConstellationCard key={-1} type={-1} onClose={onCardClose} />)}
                </ResponsiveCarousel>
            </div>
        </Block>
    );
}
