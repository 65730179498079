import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLearningTargetTime } from '../api/lms';
import { setLearningTarget } from '../reducers/learningInfo';
import useLearnerInfo from './useLearnerInfo';
import usePromise from './usePromise';

export default function useLearningTime() {
    const learningTarget = useSelector(({ learningInfo }) => learningInfo.learningTarget);
    const { data: learnerInfo } = useLearnerInfo();
    const isTarget = learnerInfo && learnerInfo.subject_status_code !== '0' && learnerInfo.subject_status_code !== '1';
    const dispatch = useDispatch();
    const { data, error, refresh } = usePromise(
        getLearningTargetTime,
        !!(!isTarget || (learningTarget && !learningTarget.updateable))
    );
    useEffect(() => {
        if (data) dispatch(setLearningTarget(data));
    }, [data, dispatch]);
    return { data: learningTarget, error, refresh };
}
