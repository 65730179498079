import React from 'react';
import styled from 'styled-components';
import RewardConstellationStar from './RewardConstellationStar';
import { ReactComponent as ConstellationBack } from '../../../assets/images/reward-items/Constellation_bg.svg';
import { ReactComponent as Explanation } from '../../../assets/images/reward-items/Explanation.svg';
import media from '../../../utils/media';
import constellationText from '../../../assets/texts/constellation';
const Block = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1.6rem;
    ${media.small} {
        width: 40%;
    }
    ${media.xsmall} {
        width: 100%;
        justify-content: flex-start;
        padding: 0 6em 0 1em;
    }
    .cursor {
        cursor: pointer;
    }
    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        & > svg {
            height: auto;
            max-width: 100%;
        }
        & > .explanation {
            margin-top: 1em;
        }
        & > .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 83%; /* bottom: 6%; */
            transform: translateY(-50%);
            .yellow {
                color: #ffd81a;
            }
            line-height: 1.3;
        }
    }
`;
export default function Constellation({
    onClick,
    starCount,
    csIndex,
    totalStarCount,
    check,
    starAnim,
    constellationAnim
}) {
    return (
        <Block>
            <div className="container cursor" onClick={onClick}>
                <ConstellationBack className="cursor"></ConstellationBack>
                <RewardConstellationStar
                    index={csIndex}
                    starCount={starCount}
                    check={check}
                    constellationAnim={constellationAnim}
                    starAnim={starAnim}
                ></RewardConstellationStar>
                <Explanation className="explanation"></Explanation>
                <div className="text">
                    {check && (
                        <>
                            <div className="h4">
                                <span className="yellow">{starCount || 0}</span>
                                <span>/{totalStarCount}</span>
                            </div>
                            <div className="h5">
                                <span className="yellow">{constellationText[csIndex - 1].ko_KR}</span>
                                <span>를 모으고 있어요.</span>
                            </div>
                        </>
                    )}
                    {!check && (
                        <>
                            <div className="yellow">별자리 완성</div>
                            <div>
                                <span className="yellow">{constellationText[csIndex - 1].ko_KR}</span>를 선택해보세요.
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Block>
    );
}
