import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { getPointAtEvent } from '../../../utils/util';
const Pad = styled.svg`
    path {
        stroke: #003784;
        fill: none;
        stroke-width: 5px;
        stroke-linecap: round;
        stroke-linecap: round;
    }
    rect {
        fill: transparent;
    }
`;
export default function ScratchPad({ fullMode, isLandScape, path, paths, setPath, setPaths }) {
    const [drawing, setDrawing] = useState(false);
    const pad = useRef();
    const startHandler = ({ nativeEvent: e }) => {
        if (fullMode) {
            const { x, y } = getPointAtEvent(e, pad.current);
            setPath(`M${x},${y}`);
            setDrawing(true);
        }
    };
    const moveHandler = ({ nativeEvent: e }) => {
        if (drawing && fullMode) {
            const { x, y } = getPointAtEvent(e, pad.current);
            if (x < 0 || x > 800 || y < 0 || y > 500) return;
            setPath(p => `${p}L${x},${y}`);
        }
    };
    const endHandler = () => {
        if (fullMode) {
            setPaths(p => p.concat(path));
            setDrawing(false);
            setPath('');
        }
    };

    return (
        <>
            <Pad
                preserveAspectRatio="xMidYMin"
                className={isLandScape ? 'landscape' : 'portrait'}
                ref={pad}
                viewBox="0 0 800 500"
            >
                {paths.map((el, i) => (
                    <path d={el} key={i}></path>
                ))}
                <path d={path}></path>
                <rect
                    x="0"
                    y="0"
                    width="800"
                    height="500"
                    onMouseDown={startHandler}
                    onMouseMove={moveHandler}
                    onMouseUp={endHandler}
                    onTouchStart={startHandler}
                    onTouchMove={moveHandler}
                    onTouchEnd={endHandler}
                ></rect>
            </Pad>
        </>
    );
}
