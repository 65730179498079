import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
    position: relative;
    .badge {
        background: red;
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
`;

export default function Badge({ children, classes, show = true }) {
    return (
        <Block className={classes?.container}>
            {children}
            {show && <div className={'badge' + (classes?.badge ? ' ' + classes?.badge : '')}></div>}
        </Block>
    );
}
