import React from 'react';

const element = {
    title: { x: 67.5, y: 20 },
    domainImage: { x: 52.5, y: 55, size: 30 },
    domain: { x: 67.5, y: 110 },
    card: {
        width: 200,
        height: 90,
        rx: 10
    },
    line: {
        width: 20,
        height: 10
    },
    currentImage: {
        offset: { x: -30, y: -15 },
        width: 25,
        height: 33.5
    },
    statusCircle: {
        r: 5,
        offset: { x: -15, y: 10 },
        status: { 1: '#5abef5', 2: '#ff7878' }
    },
    cardMarginTop: 40
};
const setText = ({ cx, cy, texts }) => {
    const length = texts.length;
    const offset = [5, -5, -12][length - 1];
    const textGap = 20;

    return texts.map((el, i) => (
        <text
            key={el}
            x={cx}
            y={cy + offset + textGap * i}
            textAnchor="middle"
            fill="#444444"
            style={{ fontSize: '15px' }}
        >
            {el}
        </text>
    ));
};

export default function LearnerStateComponent({ learningStateInfo }) {
    const { before, current, after } = learningStateInfo;
    if (!current) return null;
    let max = 1;
    if (before || after) max = 2;
    if (before && after) max = 3;
    const totalWidth = element.card.width * max + element.line.width * (max - 1);
    const matrix = { x: (680 - totalWidth) / 2 - (!before ? element.card.width + element.line.width : 0), y: 0 };
    return (
        <svg viewBox="0 0 800 175">
            <g>
                <text x={element.title.x} y={element.title.y} textAnchor="middle" fill="#444444" style={{ fontSize: '15px' }}>
                    나의 학습 위치
                </text>
                <image
                    href={`/images/icon_on_${current.learning_domain_code}.svg`}
                    x={element.domainImage.x}
                    y={element.domainImage.y}
                    width={element.domainImage.size}
                    height={element.domainImage.size}
                ></image>
                <text style={{ fontSize: '15px' }} x={element.domain.x} y={element.domain.y} textAnchor="middle" fill="#444444">
                    {current.learning_domain_name}
                </text>
            </g>
            <g transform={`matrix(1, 0, 0, 1, ${matrix.x}, ${matrix.y})`}>
                {before && (
                    <g>
                        <text
                            style={{ fontSize: '15px' }}
                            x={120 + element.card.width / 2}
                            y={element.title.y}
                            textAnchor="middle"
                            fill="#444444"
                        >
                            이전
                        </text>
                        <rect
                            x="120"
                            y={element.cardMarginTop}
                            width={element.card.width}
                            height={element.card.height}
                            fill="#fff"
                            rx={element.card.rx}
                        ></rect>
                        <rect
                            x={120 + element.card.width}
                            y={element.cardMarginTop + element.card.height / 2 - element.line.height / 2}
                            width={element.line.width}
                            height={element.line.height}
                            fill="#FFE391"
                        ></rect>
                        {before.status !== '0' && (
                            <circle
                                cx={120 + element.card.width + element.statusCircle.offset.x}
                                cy={element.cardMarginTop + element.statusCircle.offset.y}
                                r={5}
                                fill={element.statusCircle.status[before.status]}
                            ></circle>
                        )}
                        {setText({
                            cx: 120 + element.card.width / 2,
                            cy: element.cardMarginTop + element.card.height / 2,
                            texts: before.titles
                        })}
                    </g>
                )}
                <g>
                    <text
                        style={{ fontSize: '15px' }}
                        x={120 + element.line.width + element.card.width * 1.5}
                        y={element.title.y}
                        textAnchor="middle"
                        fill="#444444"
                    >
                        현재
                    </text>
                    <rect
                        x={120 + element.line.width + element.card.width}
                        y={element.cardMarginTop}
                        width={element.card.width}
                        height={element.card.height}
                        fill="#DBF7F7"
                        rx={element.card.rx}
                    ></rect>
                    <image
                        href="/images/location.svg"
                        x={120 + element.line.width + element.card.width * 2 + element.currentImage.offset.x}
                        y={element.cardMarginTop + element.currentImage.offset.y}
                        width={element.currentImage.width}
                        height={element.currentImage.height}
                    ></image>
                    {setText({
                        cx: 120 + element.line.width + element.card.width * 1.5,
                        cy: element.cardMarginTop + element.card.height / 2,
                        texts: current.titles
                    })}
                </g>
                {after && (
                    <g>
                        <text
                            style={{ fontSize: '15px' }}
                            x={120 + element.line.width * 2 + element.card.width * 2.5}
                            y={element.title.y}
                            textAnchor="middle"
                            fill="#444444"
                        >
                            이후
                        </text>
                        <rect
                            x={120 + element.line.width + element.card.width * 2}
                            y={element.cardMarginTop + element.card.height / 2 - element.line.height / 2}
                            width={element.line.width}
                            height={element.line.height}
                            fill="#B5B5B6"
                        ></rect>
                        <rect
                            x={120 + (element.line.width + element.card.width) * 2}
                            y={element.cardMarginTop}
                            width={element.card.width}
                            height={element.card.height}
                            fill="#fff"
                            rx={element.card.rx}
                        ></rect>
                        {setText({
                            cx: 120 + element.line.width * 2 + element.card.width * 2.5,
                            cy: element.cardMarginTop + element.card.height / 2,
                            texts: after.titles
                        })}
                    </g>
                )}
            </g>
        </svg>
    );
}
