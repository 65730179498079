import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import theme from '../../../../assets/styles/theme';
import { ReactComponent as blockG } from '../../../../assets/images/program/block_G.svg';
import { breakPoints } from '../../../../utils/media';
import RepeatProgramCard from './RepeatProgramCard';
import { progressContainer } from '../ProgramList';
import useProgramPage from '../../../../hooks/useProgramPage';
const Block = styled.div`
    position: relative;
    ${progressContainer}
`;
const Title = styled.div`
    /* background-color: ${theme.palette.repeat}; */
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: ${breakPoints.large}px;
    padding: 0 1.6rem;
    margin: 0 auto;
    .container {
        background: ${theme.palette.repeat};
        padding: 6px 6px 6px 10px;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        color: white;
        .svg-container {
            background: white;
            padding: 4px;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px 0 0;
        }
    }
`;
const BlockG = styled(blockG)`
    transform: translateY(-2px);
`;
export default function RepeatProgramList({ programInfos, onButtonClick }) {
    const { setFocusArea, focusArea, needProgramFocus, setNeedProgramFocus, focusIndex, setFocusIndex } =
        useProgramPage();
    const handleClick = i => {
        if (focusIndex === i && focusArea === 1) setFocusIndex(-1);
        else setFocusIndex(i);
        setFocusArea(1);
    };

    const executeInitScroll = useRef(false);
    useEffect(() => {
        if (needProgramFocus && !executeInitScroll.current && Number.isInteger(focusIndex) && focusArea === 1) {
            document.getElementById('repeat-card-' + focusIndex)?.scrollIntoView({ behavior: 'smooth' });
            setNeedProgramFocus(false);
            executeInitScroll.current = true;
        }
    }, [focusIndex, needProgramFocus, setNeedProgramFocus, focusArea]);

    return (
        <Block>
            <div className="progress-container">
                <div>
                    <div className="progress"></div>
                </div>
            </div>
            <Title>
                <div className="container h5">
                    <div className="svg-container">
                        <BlockG></BlockG>
                    </div>
                    <div>실력 다지기를 할 수 있어요.</div>
                </div>
            </Title>
            {programInfos.map((info, i) => (
                <RepeatProgramCard
                    key={info.programId}
                    info={info}
                    isFocus={i === focusIndex && focusArea === 1}
                    onClick={handleClick}
                    index={i}
                    onButtonClick={onButtonClick}
                ></RepeatProgramCard>
            ))}
        </Block>
    );
}
