const theme = {
    palette: {
        primary: '#0eb7bb',
        primaryLight: '#e9f7f7',
        white: '#fff',
        timer: '#197d80',
        attendModalTitle: '#11B780',
        mainCardBg: ['#DEF2FF', '#FFE6E6', '#FFF5D1', '#F0F0F0', '#DEF2FF', '#F0F0F0'],
        mainCardBgActive: ['#60A4F0', '#FF7878', '#FFB300', '#828282', '#60A4F0', '#828282'],
        mainCardTopBorder: ['#D1EDFF', '#FFD6D6', '#FFEDB3', '#E0E0E0', '#D1EDFF', '#E0E0E0'],
        mainCardBotttomBorder: ['#AECAE8', '#F5B0AE', '#F7D285', '#C6C6C7', '#AECAE8', '#C6C6C7'],
        mainCardBorderActive: ['#3A90F0', '#D15454', '#F79000', '#5C5C5C', '#3A90F0', '#5C5C5C'],
        text: '#545454',
        diagnosisIntroBack: '#f3f3f3',
        diagnosisIntroShadow: '#e8e8e8',
        gray: '#E6E6E6',
        divider: '#B5B5B6',
        textGray: '#AAAAAA',
        red: '#F75757',
        teacherHeart: '#ED7676',
        textSpace: '#6ecbfa',
        repeat: '#A0D14A'
    },
    modalRadius: '1rem'
};
export default theme;
