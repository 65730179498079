import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHabitState } from '../reducers/learningInfo';
import * as lms from '../api/lms';
export default function useHabitState(wait = true) {
    const { habitState } = useSelector(state => state.learningInfo);
    const dispatch = useDispatch();
    //habit result
    // 'already': 오늘 이미 보상받음,
    // 'belowStandards':보상 기준 미달,
    // 'success': 습관 보상 처리 완료
    const getHabitState = useCallback(async () => {
        let habitResult;
        if (!habitState || habitState.result === 'belowStandards') {
            habitResult = await lms.postRewardHabit();
            dispatch(setHabitState(habitResult));
            return habitResult;
        }
        return habitState;
    }, [habitState, dispatch]);
    const setHabiStateAlready = useCallback(() => {
        dispatch(setHabitState({ ...habitState, result: 'already' }));
    }, [dispatch, habitState]);
    useEffect(() => {
        if (!wait) getHabitState();
    }, [getHabitState, wait]);
    return { habitState, getHabitState, setHabiStateAlready };
}
