import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Glitter } from '../assets/images/glitter.svg';
const Block = styled.div`
    position: relative;
    & > .glitter {
        width: ${({ glitterWidth }) => glitterWidth};
        height: auto;
        position: absolute;
        top: 0.2em;
        left: 0.2em;
        z-index: 100;
        .glitter0 {
            fill: ${({ glitterColor }) => glitterColor};
        }
    }
`;
export default function WithGlitter({ children, glitterColor = 'white', glitterWidth = '12.69px', className }) {
    return (
        <Block className={className} glitterColor={glitterColor} glitterWidth={glitterWidth}>
            <Glitter className="glitter"></Glitter>
            {children}
        </Block>
    );
}
