import * as lms from '../api/lms';
import { createPromiseThunk, createInitThunk, handleAsyncActions } from './asyncUtils';

const GET_LEARNING_PROGRAM = 'learningProgram/GET_LEARNING_PROGRAM';

export const getLearningProgram = createPromiseThunk(GET_LEARNING_PROGRAM, lms.getLearningProgram);
export const initLearningProgram = createInitThunk(GET_LEARNING_PROGRAM);

const learningProgram = handleAsyncActions(GET_LEARNING_PROGRAM);
export default learningProgram;
