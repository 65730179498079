import React, { useEffect, useRef, useState } from 'react';
global.JEISVGCOMPONENTS = {};
export default function SVGIcon({ src, onReady, ...props }) {
    const [Icon, setIcon] = useState(global.JEISVGCOMPONENTS[src]);
    const newSrc = useRef(src);
    useEffect(() => {
        let live = true;
        newSrc.current = src;
        if (src && !global.JEISVGCOMPONENTS[src])
            import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/images${src}`).then(result => {
                global.JEISVGCOMPONENTS[src] = result.default;
                if (newSrc.current === src && live) setIcon(result.default);
            });
        if (src && global.JEISVGCOMPONENTS[src]) {
            setIcon(global.JEISVGCOMPONENTS[src]);
        }
        return () => (live = false);
    }, [src]);
    useEffect(() => {
        if (Icon && onReady) onReady();
    }, [Icon, onReady]);
    if (!Icon) return null;
    return <Icon {...props} />;
}
