import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
const Card = styled.div`
    & > div {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${({ borderRadius }) => borderRadius / 4}em;
        & > .main {
            width: 100%;
            height: 100%;
            z-index: 10;
            background-color: ${({ backgroundColor }) => backgroundColor};
            border-radius: ${({ borderRadius }) => borderRadius}em;
            overflow: hidden;
        }
        & > .borderTopLeft {
            width: calc(100% - ${({ borderRadius }) => borderRadius / 4}em);
            height: calc(100% - ${({ borderRadius }) => borderRadius / 4}em);
            border-radius: ${({ borderRadius }) => `${borderRadius}em ${borderRadius * 1}em`};
            z-index: 2;
            position: absolute;

            top: 0;
            left: 0;
            background: ${({ borderTopColor }) => borderTopColor};
        }
        & > .borderBottomRight {
            width: 100%;
            height: 100%;
            bottom: 0;
            right: 0;
            border-radius: ${({ borderRadius }) => `${borderRadius}em ${borderRadius * 1.25}em`};
            z-index: 1;
            position: absolute;
            background: ${({ borderBottomColor }) => borderBottomColor};
        }
    }
    &.focus .borderBottomRight {
        background: ${({ activeBorderBottomColor, activeBorderColor }) => activeBorderBottomColor || activeBorderColor};
    }
    &.focus .borderTopLeft {
        background: ${({ activeBorderColor }) => activeBorderColor};
    }
    &.focus .main {
        background: ${({ activeBackgroundColor }) => activeBackgroundColor};
    }
    &.selected .borderBottomRight {
        background: ${({ activeBorderBottomColor, activeBorderColor }) => activeBorderBottomColor || activeBorderColor};
    }
    &.selected .borderTopLeft {
        background: ${({ activeBorderColor }) => activeBorderColor};
    }
    &.selected .main {
        background: ${({ activeBackgroundColor }) => activeBackgroundColor};
    }
`;
export default function RaisedCard({
    borderRadius = 1,
    children,
    onClick,
    onActiveStateChange,
    borderTopColor,
    borderBottomColor,
    backgroundColor,
    activeBackgroundColor,
    activeBorderColor,
    activeBorderBottomColor,
    className,
    selected,
    onMouseDown,
    onTouchStart
}) {
    const ref = useRef();
    const rect = useRef();
    const [isFocus, setIsFocus] = useState(false);

    const handleMouseDown = e => {
        if (onMouseDown) onMouseDown(e);
        setIsFocus(true);
    };
    const handleMouseLeave = () => {
        setIsFocus(false);
    };
    const handleMouseUp = () => {
        setIsFocus(false);
    };
    const handleTouchStart = e => {
        if (onTouchStart) onTouchStart(e);
        setIsFocus(true);
    };
    const handleTouchMove = e => {
        const { clientX, clientY } = e.changedTouches[0];
        const { top, left, bottom, right } = rect.current;
        if (clientX < left || clientX < right || clientY < top || clientX > bottom) setIsFocus(false);
    };
    const handleTouchEnd = () => {
        setIsFocus(false);
    };

    useEffect(() => {
        rect.current = ref.current.getBoundingClientRect();
    }, []);
    useEffect(() => {
        if (onActiveStateChange) onActiveStateChange(isFocus);
    }, [isFocus, onActiveStateChange]);
    return (
        <Card
            ref={ref}
            onClick={onClick}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            className={`${className ? className : ''}${isFocus ? ' focus' : ''}${selected ? ' selected' : ''}`}
            {...{
                borderRadius,
                backgroundColor,
                borderTopColor,
                borderBottomColor,
                activeBackgroundColor,
                activeBorderColor,
                activeBorderBottomColor
            }}
        >
            <div>
                <div className="borderTopLeft"></div>
                <div className="main">{children}</div>
                <div className="borderBottomRight"></div>
            </div>
        </Card>
    );
}
