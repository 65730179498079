import React from 'react';
import { ComposedChart, Line, XAxis } from 'recharts';

export default function LearningTimeGraph({
    focusIndex,
    setFocusIndex,
    learningTimeHistory,
    LearningTimeGrapHeight,
    chartWidth
}) {
    return (
        <ComposedChart
            width={chartWidth}
            height={LearningTimeGrapHeight}
            data={learningTimeHistory}
            margin={{ top: 20, bottom: 0, left: 10, right: 85 }}
        >
            <XAxis
                dataKey="learning_date"
                interval={0}
                tickLine={false}
                axisLine={false}
                tick={props => {
                    const { x, y, index } = props;
                    if (index === focusIndex) {
                        return (
                            <g>
                                <line x1={x} x2={x} y1={0} y2={y + 50} stroke="#22BFBF"></line>
                            </g>
                        );
                    }
                }}
            />
            <Line
                type="monotone"
                dataKey="learning_time"
                stroke="#ff7878"
                dot={props => {
                    const { key, cx, cy, payload, stroke, r, index } = props;
                    let fill = '#FFF';
                    if (payload.learning_habit_yn !== '0') fill = stroke;
                    if (payload.learning_time === null || payload.learning_time === 0) return;

                    return (
                        <g id="circle" key={key}>
                            <circle cx={cx} cy={cy} r={r + 1.5} fill={fill} stroke={stroke}></circle>
                            <circle
                                cx={cx}
                                cy={cy}
                                r={r + 7.5}
                                fill={fill}
                                opacity={0}
                                style={{ cursor: 'pointer' }}
                                onClick={() => setFocusIndex(index)}
                            ></circle>
                        </g>
                    );
                }}
                isAnimationActive={false}
                label={props => {
                    let { x, y, value, offset, index } = props;
                    if (index === focusIndex) {
                        let wh = [65, 30];
                        let stroke = 1;
                        let triangleSize = 15;
                        let radius = 5;
                        if (value === null) {
                            y = 145;
                            value = 0;
                        }
                        return (
                            <defs>
                                <g id="learning_time">
                                    <path
                                        d={`M${x},${y} L${x + triangleSize},${y - wh[1] / 2} H${
                                            x + triangleSize + wh[0] - radius
                                        } q${radius},0 ${radius},${radius} V${
                                            y + wh[1] / 2 - radius
                                        }q0,${radius} -${radius},${radius} H${x + triangleSize}z`}
                                        fill="#ff7878"
                                    ></path>
                                    <path
                                        d={`M${x + stroke},${y} L${x + triangleSize},${y - wh[1] / 2 + stroke} H${
                                            x + triangleSize + wh[0] - radius - stroke
                                        } q${radius},0 ${radius},${radius} V${
                                            y + wh[1] / 2 - radius - stroke
                                        }q0,${radius} -${radius},${radius} H${x + triangleSize}z`}
                                        fill="#fff"
                                    ></path>
                                    <text
                                        x={x + triangleSize + wh[0] / 2 - 2.5}
                                        y={y + offset + 2.5}
                                        fill="#ff7878"
                                        textAnchor="middle"
                                        style={{ fontSize: 18 }}
                                    >
                                        {value}분
                                    </text>
                                </g>
                            </defs>
                        );
                    }
                }}
            />
            <use href="#learning_time" />
        </ComposedChart>
    );
}
