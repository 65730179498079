import theme from '../../../assets/styles/theme';

function getPathData(per, strokeWidth, r) {
    let d = `M${50 + strokeWidth},${strokeWidth / 2}`;
    if (per > 50) {
        d += `A${r},${r},0,0,1,${r + strokeWidth / 2},${2 * r + strokeWidth / 2}`;
    }
    d += `A${r},${r},0,0,1,${Math.sin((2 * Math.PI * per) / 100) * r + 50 + strokeWidth},${
        -Math.cos((2 * Math.PI * per) / 100) * r + 50 + strokeWidth
    }`;

    return d;
}

export default function Donut({ per, strokeWidth = 12, color = 'skyblue', text, className }) {
    const r = 50 + strokeWidth / 2;
    const d = getPathData(per, strokeWidth, r);

    return (
        <svg viewBox={`0 0 ${100 + strokeWidth * 2} ${100 + strokeWidth * 2}`} className={className}>
            <circle
                cx={`${50 + strokeWidth}`}
                cy={`${50 + strokeWidth}`}
                r={r}
                fill="none"
                stroke={theme.palette.gray}
                strokeWidth={strokeWidth}
            ></circle>
            {!!per && (
                <path d={d} r={r} fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round"></path>
            )}
            <text style={{ fontSize: 15 }} x={50 + strokeWidth} y={50 + strokeWidth + 10} textAnchor="middle">
                {text}
            </text>
        </svg>
    );
}
