import React from 'react';
import styled from 'styled-components';
import Rabbit from '../../../assets/images/sign-in/rabbit.svg';
import OriginRobot from '../../../assets/images/character-anim/OS1_character.svg';
import { ReactComponent as Logo } from '../../../assets/images/sign-in/Logo.svg';
import { useRef } from 'react';
const Block = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Img = styled.img`
    width: 8em;
    height: auto;
    margin-top: ${props => ({ [OriginRobot]: 0, [Rabbit]: -3 }[props.src])}em;
`;
const Title = styled(Logo)`
    height: auto;
    width: 8.5em;
    margin-top: 1em;
`;
function SigninRobot() {
    const img = useRef([Rabbit, OriginRobot][Math.floor(Math.random() * 2)]);
    return (
        <Block>
            <Img src={img.current}></Img>
            <Title></Title>
        </Block>
    );
}

export default SigninRobot;
