export default function AD(type, params = {}) {
    return [
        { text: `${params.displayName} 친구, 반가워요!`, robotSrc: 'AD1.svg' },
        { text: `'{${params.curriculumName}}' 학습을 하고 있어요.`, robotSrc: 'AT1.svg' },
        { text: `다시 학습할 내용이 있어요. 학습을 완료해 봐요.`, robotSrc: 'AT6.svg' },
        { text: `${params.gradeCode}등급 학습을 모두 통과했어요.`, robotSrc: `AD4.svg` },
        {
            text: [
                `학습 아이콘들을 알아봐요!`,
                '학습 내용을 잘 알고 있어요.',
                '학습이 필요해요.',
                '모르는 게 있었지만 공부해서 잘 알게 되었어요.',
                '정답을 놓친 문제가 있어서 꼭 다시 학습해야 해요.',
                '현재 학습을 진행하고 있어요.',
                '앞으로 진행할 학습이에요.'
            ],
            robotSrc: `AT6.svg`,
            soundSrc: 'AD5.mp3'
        }
    ][type - 1];
}
