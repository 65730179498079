import React, { useState } from 'react';
import { getRewardHeart, getRewardHeartMonth, postRewardHistory } from '../../api/lms';
import usePromise from '../../hooks/usePromise';
import moment from 'moment';
import useRedux from '../../hooks/useRedux';
import { getLearningGrade } from '../../reducers/learningGrade';
import Loading from '../../components/common/Loading';
import RewardInfo from './components/RewardInfo';
import HeartModal from './components/HeartModal';
import Layout from './components/Layout';
import Panel from './components/Panel';
import ModalRobot from '../../components/common/ModalRobot';
import Planet from './components/Planet';
import CR from '../../assets/texts/CR';
import useSound from '../../hooks/useSound';
import { soundPath } from '../../utils/util';
import SimpleRobotModalBody from '../../components/SimpleRobotModalBody';
import Travel from './components/Travel';
import useRewardPageTransition from '../../hooks/useRewardPageTransition';
import RewardIntro from './components/RewardIntro';

export default function RewardHeartContainer() {
    // const { play: travelPlay } = useSound({ src: `${soundPath}/OS4_ver3_re.mp3`, autoPlay: false });
    const { rewardPageTransition } = useRewardPageTransition();
    const [yearMonth, setYearMonth] = useState(moment().format('YYYYMM'));
    const [modal, setModal] = useState(rewardPageTransition ? 0 : 4); //0:안보임,1:인포,2:선생님하트
    const [travel, setTravel] = useState(0);
    const grades = useRedux({ dataKey: 'learningGrade', thunk: getLearningGrade });
    const rewardHeartMonth = usePromise(getRewardHeartMonth);
    const rewardHeart = usePromise(getRewardHeart, false, { yearMonth });
    const [postRewardHistoryError, setPostRewardHistoryError] = useState();
    const [preloadTravel, setPreloadTravel] = useState(false);
    const [travelLoading, setTravelLoading] = useState(false);
    useSound({ src: rewardPageTransition && `${soundPath}/OS2_ver2_re.mp3`, autoPlay: true, pauseOnUnmount: true });
    const { play: innerRocketSoundPlay } = useSound({
        src: `${soundPath}/OS2_ver2_re.mp3`,
        autoPlay: false,
        pauseOnUnmount: true
    });
    const handleSelect = val => {
        setYearMonth(val.display.replace('.', ''));
    };
    const handleTravel = async () => {
        setModal(0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTravelLoading(true);
        try {
            await postRewardHistory([{ rewardId: 'RB.01', rewardCount: 1 }]);
        } catch (e) {
            setPostRewardHistoryError(e);
            return;
        }
        setTravelLoading(false);
        setTravel(1);
        document.getElementsByTagName('video')[0].play();
        setTimeout(rewardHeart.refresh, 2000);
    };

    const loading = grades.loading || travelLoading;
    const error = rewardHeart.error || rewardHeart.error || grades.error || postRewardHistoryError;
    const {
        currentHeartCount,
        currentRobotRewardProgress,
        newTeacherHeartCount,
        possibleRobotRewardCount,
        totalHeartCount,
        robotRewardTotalCount
    } = rewardHeart.data || {};
    const handleTeacherHeartClick = () => {
        setModal(0);
        innerRocketSoundPlay();
    };
    const handleIntroEnd = () => {
        if (newTeacherHeartCount && moment().format('YYYYMM') === yearMonth && !rewardHeart.loading) setModal(2);
        else setModal(0);
        if (!newTeacherHeartCount) innerRocketSoundPlay();
    };
    const handleHeartRewardClick = () => {
        setPreloadTravel(true);
        setModal(3);
    };
    const handleTravelEnd = () => {
        setPreloadTravel(false);
        setTravel(2);
    };
    const handleTravelCancel = () => {
        setPreloadTravel(false);
        setModal(0);
    };
    if (error) throw error;
    return (
        <Layout travel={travel === 1}>
            {loading && <Loading></Loading>}
            {modal === 1 && <RewardInfo onClick={setModal.bind({}, 0)} />}
            {modal === 4 && <RewardIntro onEnd={handleIntroEnd} />}
            {grades.data && <Planet grade={grades.data.last()} hide={travel === 1}></Planet>}
            {modal === 2 && (
                <HeartModal newTeacherHeartCount={newTeacherHeartCount} onClick={handleTeacherHeartClick}></HeartModal>
            )}
            {modal === 3 && (
                <ModalRobot
                    {...{ ...CR(1) }}
                    title={<div style={{ whiteSpace: 'nowrap' }}>{CR(1).title}</div>}
                    body={<SimpleRobotModalBody text={CR(1).body} />}
                    buttons={[
                        { text: CR(1).buttons[0], onClick: handleTravelCancel },
                        { text: CR(1).buttons[1], onClick: handleTravel }
                    ]}
                ></ModalRobot>
            )}

            {preloadTravel && (
                <Travel
                    travelState={travel}
                    robotRewardTotalCount={robotRewardTotalCount}
                    onTravelEnd={handleTravelEnd}
                />
            )}

            {rewardHeart.data && rewardHeartMonth.data && (
                <Panel
                    hide={travel === 1}
                    yearMonth={yearMonth}
                    onMonthSelect={handleSelect}
                    monthList={rewardHeartMonth.data
                        .map(el => ({
                            display: moment(el).format('YYYY.MM'),
                            item: moment(el).format('YYYY년 MM월')
                        }))
                        .reverse()}
                    heartCount={currentHeartCount}
                    onRobotButtonClick={handleHeartRewardClick}
                    possibleRobotRewardCount={possibleRobotRewardCount}
                    progress={currentRobotRewardProgress}
                    totalHeartCount={totalHeartCount}
                    onInformationButtonClick={setModal.bind({}, 1)}
                    travelEnd={travel > 0}
                    isBeforeMonth={yearMonth < moment().format('YYYYMM')}
                ></Panel>
            )}
        </Layout>
    );
}
