const setCookie = function (cookieName, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    exdate.setHours(0, 0, 0, 0);
    var cookieValue = escape(value) + (exdays == null ? '' : '; expires=' + exdate.toGMTString());
    document.cookie = cookieName + '=' + cookieValue;
};
const deleteCookie = function (cookieName) {
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() - 1);
    document.cookie = `${cookieName}= ; expires=${expireDate.toGMTString()}`;
};
const getCookie = function (cookieName) {
    cookieName = cookieName + '=';
    var cookieData = document.cookie;
    var start = cookieData.indexOf(cookieName);
    var cookieValue = '';
    if (start !== -1) {
        start += cookieName.length;
        var end = cookieData.indexOf(';', start);
        if (end === -1) end = cookieData.length;
        cookieValue = cookieData.substring(start, end);
    }
    return unescape(cookieValue);
};
export const addRememberId = id => {
    const ids = (getCookie('LID') || '').split(',');
    ids.unshift(id);
    setCookie('LID', ids.filter((el, i, arr) => arr.indexOf(el) === i && el !== '').slice(0, 3), 30); //중복제거하고 앞에서 3개
};
export const getRememberIds = () => {
    return (getCookie('LID') || '').split(',').filter(el => el !== '');
};
export const clearRememberIds = () => {
    deleteCookie('LID');
};
export const setDiagnosisIntroCount = id => {
    const count = Number(getCookie(id));
    setCookie(id, count + 1, 1);
};
export const isDiagnosisIntroSkip = id => {
    return Number(getCookie(id)) > 1;
};

export const setNotice = (id, payload) => setCookie(id, payload, 30);
export const getNoticeShow = id => getCookie(id) !== '0';

export const setAchieveLearningTargetTime = () => {
    setCookie('achieveLearningTargetTime', '1', 1);
};
export const getAchieveLearningTargetTime = () => getCookie('achieveLearningTargetTime') === '1';
