export default function CF(index, params = {}) {
    switch (index) {
        case 1:
            return {
                title: '아쉬워요!',
                body: '학습을 정말 그만할 거예요?',
                buttonTexts: ['계속하기', '그만하기'],
                src: 'CF1_re.mp3',
                robotSrc: 'CN6_character.svg'
            };
        case 2:
            return {
                title: '아쉬워요!',
                body: '학습 시간이 초과되었어요!\n집중해서 다시 해 볼까요?',
                buttonTexts: ['다시 도전하기'],
                src: 'CF2_re.mp3',
                robotSrc: 'CF2_character.svg'
            };
        case 3:
            return {
                title: '똑똑!',
                body: '계속해 볼까요?',
                buttonTexts: ['다시 시작하기'],
                src: 'CF3_re_ver1.mp3',
                robotSrc: 'CF3_character.svg'
            };
        case 4:
            return {
                title: '도전!',
                body: `${params.questionCount}문제 연속으로 맞춰 봐요.`,
                buttonTexts: ['도전하기'],
                src: 'CF4_re.mp3',
                robotSrc: 'CF4_character.svg'
            };
        case 5:
            return {
                title: '도전!',
                body: `제한 시간 내에 ${params.questionCount}문제를\n연속으로 맞춰 봐요.`,
                buttonTexts: ['도전하기'],
                src: params.questionCount > 5 ? 'CF5-1_re.mp3' : 'CF5_re.mp3',
                robotSrc: 'CF4_character.svg'
            };
        case 6:
            return {
                title: '재도전!',
                body: '다시 한번 문제를 꼼꼼히 풀어 봐요.',
                buttonTexts: ['재도전하기'],
                src: 'CF6_re.mp3',
                robotSrc: 'CF4_character.svg'
            };
        case 7:
            return {
                title: '대단해요!',
                body: `연산의 달인에 한 발짝 다가갔어요.`,
                buttonTexts: ['하트 획득'],
                src: 'CF7_re.mp3',
                robotSrc: 'CN2-1_character.svg'
            };
        case 8:
            return {
                title: '잘했어요!',
                body: `연산 실력이 한 단계 올라갔어요!`,
                buttonTexts: ['하트 획득'],
                src: 'CF8_re.mp3',
                robotSrc: 'CN1-1_character.svg'
            };
        case 9:
            return {
                title: '도와줘요!',
                body: [
                    '선생님에게 문의할 내용을 입력하세요.\n(문의 내용 작성은 선택사항입니다.)',
                    "'도와줘요'는 1주일에 3회까지 가능해요."
                ],
                buttonTexts: ['취소', '보내기'],
                src: 'CF9_re.mp3',
                robotSrc: 'CN5_character.svg'
            };
        case 10:
            return {
                title: '학습 방법을 알아봐요.',
                body: [
                    '내가 풀 문제 수를 알려 줘요.',
                    '별을 획득하면 유성이 채워져요.',
                    '문제를 들어볼 수 있어요.',
                    '학습을 그만할래요.',
                    '선생님께 도움을 요청해요.',
                    '연습장을 사용해요.'
                ],
                src: 'CF10_re.mp3',
                buttonTexts: ['확인'],
                robotSrc: 'CD1_character.svg'
            };
        case 11:
        case 12:
            return {
                title: '아쉬워요!',
                body: '오답이 발생했어요.\n계속 도전해 봐요!',
                buttonTexts: ['도전 계속하기'],
                src: 'CF11_re.mp3',
                robotSrc: 'CF2_character.svg'
            };
        case 13:
            return {
                title: '이런!',
                body: '조금 더 집중해서 풀어 봐요!',
                buttonTexts: ['확인'],
                src: 'CF13_re.mp3',
                robotSrc: 'CF2_character.svg'
            };
        case 14:
            return {
                title: '알림',
                body: "이번 주 '도와줘요'를 모두 사용했어요.\n다음 주에 이용하세요.",
                buttonTexts: ['확인'],
                src: 'CF14_re.mp3',
                robotSrc: 'CN5_character.svg'
            };
        case 15:
            return {
                body: `{${params.starCount}개}의\n별을 획득했어요!`,
                buttonTexts: ['확인'],
                src: 'CF15_re.mp3'
            };
        case 16:
            return {
                body: `{${params.starCount}개}의 별을 획득했어요!\n드디어 {${params.constellationName}}가 완성되었어요!`,
                buttonTexts: ['확인'],
                src: `CF16-${params.id}_re.mp3`
            };
        case 18:
            return {
                title: '좋아요!',
                body: `'{${params.programName}}'에 대해\n잘 알고 있네요!\n다른 실력도 알아보러 가 봐요.`,
                buttonTexts: ['확인'],
                src: 'CF18_re.mp3',
                robotSrc: 'CF18_character.svg'
            };
        case 19:
            return {
                title: '잘했어요!',
                body: `'{${params.programName}}'에 대해\n정말 잘 알고 있네요!\n다른 실력도 알아보러 가 봐요.`,
                buttonTexts: ['확인'],
                src: 'CF19_re.mp3',
                robotSrc: 'CF18_character.svg'
            };
        case 20:
            return {
                title: '정말 잘했어요!',
                body: `'{${params.programName}}'에 대해\n아주 잘 알고 있네요!\n다른 실력도 알아보러 가 봐요.`,
                buttonTexts: ['확인'],
                src: 'CF20_re.mp3',
                robotSrc: 'CF20_character.svg'
            };
        case 21:
            return {
                title: '멋져요!',
                body: `'{${params.programName}}'에 대해\n정말 잘 알고 있네요!\n다른 실력도 알아보러 가 봐요.`,
                buttonTexts: ['확인'],
                src: 'CF21_re.mp3',
                robotSrc: 'CF20_character.svg'
            };
        case 22:
            return {
                title: '최고예요!',
                body: `'{${params.programName}}'에 대해\n아주 잘 알고 있네요!\n다른 실력도 알아보러 가 봐요.`,
                buttonTexts: ['확인'],
                src: 'CF22_re.mp3',
                robotSrc: 'CF20_character.svg'
            };
        case 23:
            return {
                title: '수고했어요!',
                body: `'{${params.programName}}'에 대해\n대체로 잘 알고 있네요!\n다른 실력도 알아보러 가 봐요.`,
                buttonTexts: ['확인'],
                src: 'CF24_re.mp3',
                robotSrc: 'CD1_character.svg'
            };
        case 24:
            return {
                title: '수고했어요!',
                body: `'{${params.programName}}'에 대해\n조금 더 공부하면 더 잘 할 수 있어요.\n차분하게 풀어 봐요.`,
                buttonTexts: ['확인'],
                src: 'CF24_re.mp3',
                robotSrc: 'CD1_character.svg'
            };
        case 25:
            return {
                title: '수고했어요!',
                body: `'{${params.programName}}'에 대해\n조금 더 공부하면 충분히 잘 할 수 있어요.\n포기하지 말고 풀어 봐요!`,
                buttonTexts: ['확인'],
                src: 'CF24_re.mp3',
                robotSrc: 'CD1_character.svg'
            };
        case 26:
            return {
                title: '아쉬워요!',
                body: `'{${params.programName}}'에 대한\n숙달이 필요해요.\n조금 더 연습해서 빠르게 풀어 봐요!`,
                buttonTexts: ['확인'],
                src: 'CF26_re.mp3',
                robotSrc: 'CD1_character.svg'
            };
        case 27:
            return {
                title: '참 잘했어요!',
                body: `'{${params.programName}}'에 대한\n공부를 마쳤어요.\n그럼 다음 도전을 시작해 봐요!`,
                buttonTexts: ['확인'],
                src: 'CF27_re.mp3',
                robotSrc: 'CF18_character.svg'
            };
        case 28:
            return {
                title: '잘했어요!',
                body: `'{${params.programName}}'에 대한\n공부를 마쳤어요.\n조금 부족한 부분을\n다시 한번 도전해 봐요!`,
                buttonTexts: ['확인'],
                src: 'CF28_re.mp3',
                robotSrc: 'CD1_character.svg'
            };
        case 29:
            return {
                title: '놀라워요!',
                body: `'{${params.curriculumName}}'에 대해\n아주 잘 알게 되었어요.\n정말 대단해요!`,
                buttonTexts: ['확인'],
                src: 'CF29_re.mp3',
                robotSrc: 'CF20_character.svg'
            };
        case 30:
            return {
                title: '축하합니다!',
                body: `{${params.gradeCode}등급 통과! ${params.displayName}!}\n${params.planetName}행성을 여행했어요.`,
                buttonTexts: ['확인'],
                src: 'CF30_ver1.mp3',
                robotSrc: 'CF30_character.svg'
            };
        case 31:
            return {
                title: '축하합니다!',
                body: `{최강 수학 천재 수료증 ${params.displayName}}\n드디어 모든 행성을 여행했어요.`,
                buttonTexts: ['확인'],
                src: 'CF30_ver1.mp3',
                robotSrc: 'CF31_character.svg'
            };
        default:
            return {};
    }
}
