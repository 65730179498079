import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import Dialog from '../../../components/common/Dialog';
import { ReactComponent as Teacher } from '../../../assets/images/learning/teacher.svg';
import CF from '../../../assets/texts/CF';
import media from '../../../utils/media';
const Body = styled.div`
    width: 480px;
    .input-container {
        position: relative;
        border: 1px solid ${theme.palette.gray};
        padding: 0.5em;
        margin: 34px 34px 14px 34px;
        div.length {
            position: absolute;
            bottom: 0;
            right: 0.5em;
        }
        div.placeholder {
            pointer-events: none;
            position: absolute;
            left: 0.5em;
            top: 0.5em;
            font-size: 20px;
            white-space: nowrap;
            & > div:nth-child(2) {
                font-size: 17px;
                ${media.medium} {
                    font-size: 13px;
                }
            }
            ${media.medium} {
                font-size: 15px;
            }
        }
        ${media.medium} {
            margin: 20px 20px 8px 20px;
        }
    }
    textarea {
        resize: none;
        outline: none;
        width: 100%;
        border: none;
        font-size: 20px;
        &::placeholder {
            color: ${theme.palette.textGray};
        }
    }
    .explain {
        color: ${theme.palette.textGray};
        .blue {
            color: ${theme.palette.text};
        }
    }
    .bottom {
        display: block !important;
        flex-direction: column;
        margin-left: 34px;
        padding-bottom: 20px;
        &.pointer-none {
            pointer-events: none;
        }
        ${media.medium} {
            margin-left: 20px;
            padding-bottom: 14px;
        }
    }
    .text-gray {
        color: ${theme.palette.textGray};
    }
    & > .ft-popup-caption {
        font-size: 16px;
        letter-spacing: -0.3px;
        ${media.medium} {
            letter-spacing: unset;
            font-size: 11px;
        }
    }
    ${media.medium} {
        width: 320px;
        textarea {
            font-size: 15px;
        }
    }
`;
function HelpBody({ invalid, content, handleChange, body }) {
    const [focus, setFocus] = useState(false);
    return (
        <Body>
            <div className={`input-container`}>
                <textarea
                    onFocus={setFocus.bind({}, true)}
                    onBlur={setFocus.bind({}, false)}
                    value={content}
                    onChange={handleChange}
                    rows="6"
                ></textarea>
                {content === '' && !focus && (
                    <div className="placeholder text-gray">
                        {body[0].split('\n').map(el => (
                            <div key={el}>{el}</div>
                        ))}
                    </div>
                )}
                <div className="h6 text-gray length">{content.length}/100</div>
            </div>
            <div className="ft-popup-caption bottom">
                <span className="bold">{body[1]}</span>
            </div>
        </Body>
    );
}
const TitleBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 25px;
    .right {
        display: flex;
        align-items: center;
        line-height: 1;
        svg {
            height: 1.2em;
            width: auto;
        }
        span {
            /* padding-top: 0.5em; */
            margin-top: -3px;
            margin-left: 0.5em;
            white-space: nowrap;
        }
    }
    ${media.medium} {
        /* height: 58px; */

        padding-right: 20px;
    }
`;
const Title = ({ title, helpRemainCount }) => {
    return (
        <TitleBlock>
            <div>{title}</div>
            <div className="right">
                <Teacher></Teacher>
                <span>{helpRemainCount}회</span>
            </div>
        </TitleBlock>
    );
};

export default function TeacherCallModal({ onClick, helpRemainCount }) {
    const [content, setContent] = useState('');
    const handleChange = e => {
        if (e.target.value.length <= 100) setContent(e.target.value);
    };
    const handleClick = e => {
        e.target.style.pointerEvents = 'none';
        onClick(1, content);
    };
    const handleCancel = e => {
        onClick(0, content);
    };
    const { title, body, buttonTexts, src } = CF(9);
    return (
        <Dialog
            onClose={onClick.bind({}, 0)}
            src={src}
            title={<Title title={title} helpRemainCount={helpRemainCount}></Title>}
            body={<HelpBody {...{ handleChange, content, body }}></HelpBody>}
            buttons={[
                { text: buttonTexts[0], onClick: handleCancel },
                { text: buttonTexts[1], onClick: handleClick }
            ]}
        ></Dialog>
    );
}
