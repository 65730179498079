import React from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import { ReactComponent as Exit } from '../../../components/FrameLearning/images/exit_button.svg';
import { ReactComponent as Star } from '../../../components/FrameLearning/images/star_null.svg';
import { ReactComponent as Edit } from '../../../components/FrameLearning/images/edit.svg';
import { ReactComponent as Teacher } from '../../../components/FrameLearning/images/teacher.svg';
import { ReactComponent as Speaker } from '../../../assets/images/learning/speaker.svg';
import { ReactComponent as ShootingStar } from '../../../assets/images/reward-items/shooting-star.svg';
import media from '../../../utils/media';
const Block = styled.div`
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 25px;
    .row {
        display: inline-flex;
        align-items: center;
        margin-bottom: 36px;
        /* padding: 0.5em; */
        .pic {
            /* width: 6.5em; */
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }
        .icon {
            width: auto;
            height: 1.5em;
            .exit_button1 {
                fill: ${theme.palette.primary};
            }
        }
        .txt {
            margin-left: 17px;
        }
    }
    ${media.medium} {
        padding-top: 25px;
        padding-left: 20px;
        .row {
            margin-bottom: 20px;
            .pic {
                width: 40px;
            }
            .txt {
                margin-left: 12px;
            }
        }
    }
`;

export default function InformationBody({ texts }) {
    return (
        <Block>
            {texts.map((el, i) => (
                <div key={el} className="row">
                    <div className="pic">
                        {i === 0 && <Star className="icon"></Star>}
                        {i === 1 && <ShootingStar className="icon"></ShootingStar>}
                        {i === 2 && <Speaker className="icon"></Speaker>}
                        {i === 3 && <Exit className="icon"></Exit>}
                        {i === 4 && <Teacher className="icon"></Teacher>}
                        {i === 5 && <Edit className="icon"></Edit>}
                    </div>
                    <div className="txt">{el}</div>
                </div>
            ))}
        </Block>
    );
}
