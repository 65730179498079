import React, { useEffect } from 'react';

const rangeBoxSize = [200, 125, 125, 125, 150];
const titleSize = 30;
const viewBox = [800, rangeBoxSize.reduce((a, b) => a + b) + titleSize];

export default function LearningMapComponent({ mapLoading, learningMapInfo }) {
    useEffect(() => {
        if (!mapLoading) {
            window.learningMap({ data: learningMapInfo, viewBox, rangeBoxSize, titleSize });
        }
    }, [learningMapInfo, mapLoading]);

    return <>{!mapLoading && <svg id="learning-map" viewBox={`0 0 ${viewBox}`} />}</>;
}
