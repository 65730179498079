import React from 'react';
import styled from 'styled-components';
import { ResponsiveContainer, PieChart, Pie, Sector } from 'recharts';
import { useHistory } from 'react-router-dom';

import theme from '../../../assets/styles/theme';
import media from '../../../utils/media';
import { ReactComponent as Button2On1 } from '../../../assets/images/report/button2_on_1.svg';
import { ReactComponent as Button2On2 } from '../../../assets/images/report/button2_on_2.svg';
import { ReactComponent as Button2On3 } from '../../../assets/images/report/button2_on_3.svg';
import { ReactComponent as Button2On4 } from '../../../assets/images/report/button2_on_4.svg';
import { ReactComponent as Button2On5 } from '../../../assets/images/report/button2_on_5.svg';
import { ReactComponent as Button2Off1 } from '../../../assets/images/report/button2_off_1.svg';
import { ReactComponent as Button2Off2 } from '../../../assets/images/report/button2_off_2.svg';
import { ReactComponent as Button2Off3 } from '../../../assets/images/report/button2_off_3.svg';
import { ReactComponent as Button2Off4 } from '../../../assets/images/report/button2_off_4.svg';
import { ReactComponent as Button2Off5 } from '../../../assets/images/report/button2_off_5.svg';
import { ReactComponent as Next } from '../../../assets/images/report/button_achievement.svg';

const color = {
    domain_1: ['#BEE867', '#7DBF4B'],
    domain_2: ['#FFAB70', '#F77823'],
    domain_3: ['#FFB8B8', '#F48787'],
    domain_4: ['#FFD357', '#E8A600'],
    domain_5: ['#D7BDFC', '#8C5BD8']
};

const Conatainer = styled.div`
    position: relative;
    width: calc(50% - 0.5em);
    margin-left: 0.5em;
    height: 10em;

    ${media.small} {
        width: calc(100% - 0.5em);
        margin-left: 0;
    }

    background: ${theme.palette.white};
    border-radius: 20px;
    padding: 0.5em 0.5em;
    margin-top: 40px;
    .arrowIcon {
        position: absolute;
        top: 1em;
        right: 1.5em;
        z-index: 10;
    }

    .triangle {
        position: relative;
        display: flex;
        justify-content: space-between;

        height: 25px;
        width: 100%;
        z-index: 10;

        & > div {
            width: 65px;
        }
        &.range-triangle-1 {
            & > div > div {
                position: relative;
                top: 1px;
                left: 17.5px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid ${color.domain_1[1]};
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid white;
                    transform: scale(0.95) translateY(1.5px);
                }
            }
        }
        &.range-triangle-2 {
            & > div > div {
                position: relative;
                top: 1px;
                left: 17.5px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid ${color.domain_2[1]};
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid white;
                    transform: scale(0.95) translateY(1.5px);
                }
            }
        }
        &.range-triangle-3 {
            & > div > div {
                position: relative;
                top: 1px;
                left: 17.5px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid ${color.domain_3[1]};
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid white;
                    transform: scale(0.95) translateY(1.5px);
                }
            }
        }
        &.range-triangle-4 {
            & > div > div {
                position: relative;
                top: 1px;
                left: 17.5px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid ${color.domain_4[1]};
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid white;
                    transform: scale(0.95) translateY(1.5px);
                }
            }
        }
        &.range-triangle-5 {
            & > div > div {
                position: relative;
                top: 1px;
                left: 17.5px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid ${color.domain_5[1]};
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 25px solid white;
                    transform: scale(0.95) translateY(1.5px);
                }
            }
        }
    }
`;

const PieChartContainer = styled.div`
    width: 100%;
    height: 40%;
    .recharts-wrapper {
        margin: 0px auto;
    }
`;

const PieExpContainer = styled.div`
    .rangeImg {
        width: 4.25em;
        height: 3.25em;
    }

    .range-count {
        margin-bottom: 0.4em;
    }

    .unit {
        margin-left: 5px;
    }

    .back1 {
        background: lightblue;
    }
`;

const ExpBlock = styled.div`
    /* border: 1px solid green; */
    border-radius: 10px;
    padding: 10px 0px;
    position: relative;
    width: 100%;

    &.range-box-1 {
        border: 1px solid ${color.domain_1[1]};
    }
    &.range-box-2 {
        border: 1px solid ${color.domain_2[1]};
    }
    &.range-box-3 {
        border: 1px solid ${color.domain_3[1]};
    }
    &.range-box-4 {
        border: 1px solid ${color.domain_4[1]};
    }
    &.range-box-5 {
        border: 1px solid ${color.domain_5[1]};
    }
`;

const LeftBlock = styled.div`
    width: 35%;
    text-align: right;
    & > div {
        padding-right: 0em;
    }

    .range-name-1 {
        color: ${color.domain_1[1]};
        margin-bottom: 0.4em;
    }
    .range-name-2 {
        color: ${color.domain_2[1]};
        margin-bottom: 0.4em;
    }
    .range-name-3 {
        color: ${color.domain_3[1]};
        margin-bottom: 0.4em;
    }
    .range-name-4 {
        color: ${color.domain_4[1]};
        margin-bottom: 0.4em;
    }
    .range-name-5 {
        color: ${color.domain_5[1]};
        margin-bottom: 0.4em;
    }

    .school-info {
        color: #b5b5b6;
    }

    .school {
        border: 1px solid #b5b5b6;
        border-radius: 5px;
        padding: 2px 6px;
    }
    .tilde {
        margin: 0px 0.2em;
    }
`;
const RightBlock = styled.div`
    position: relative;
    padding-left: 0.5em;
    width: 65%;

    .status-box {
        border: none;
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
    }
    .color1-1 {
        background: ${color.domain_1[0]};
    }
    .color2-1 {
        background: ${color.domain_1[1]};
    }
    .color1-2 {
        background: ${color.domain_2[0]};
    }
    .color2-2 {
        background: ${color.domain_2[1]};
    }
    .color1-3 {
        background: ${color.domain_3[0]};
    }
    .color2-3 {
        background: ${color.domain_3[1]};
    }
    .color1-4 {
        background: ${color.domain_4[0]};
    }
    .color2-4 {
        background: ${color.domain_4[1]};
    }
    .color1-5 {
        background: ${color.domain_5[0]};
    }
    .color2-5 {
        background: ${color.domain_5[1]};
    }

    & > div:nth-of-type(2) {
        & > div:nth-of-type(1) {
            flex: 4;
        }
        & > div:nth-of-type(2) {
            flex: 1;
        }
    }

    .exp {
        & > div:nth-of-type(1) {
            width: 70%;
        }
        & > div:nth-of-type(2) {
            width: 30%;
            text-align: center;
        }
    }
    .unit {
        margin-left: 0.25em;
    }
    .count {
        width: 20px;
        text-align: right;
        position: relative;
        top: -1.5px;
        margin-right: 2px;
    }
`;

const parseSchool = code => code && Number(code.substring(0, 2)) + '-' + Number(code.substring(2, 3));

export default function LearningHistoryComponent({ url, learningAchievement, domain, setDomain }) {
    const history = useHistory();
    let { programs, learningDomains } = learningAchievement;
    const lastIndex = programs['domain_' + domain].schoolProgressCodes.length - 1;
    const start =
        programs['domain_' + domain].schoolProgressCodes[0] && programs['domain_' + domain].schoolProgressCodes[0];
    const end =
        programs['domain_' + domain].schoolProgressCodes[lastIndex] &&
        programs['domain_' + domain].schoolProgressCodes[lastIndex];

    const isSameSchool = parseSchool(start) === parseSchool(end);
    let data = [];
    // programs = {
    //     domain_1: { count: 0, status_1: 0, status_2: 0 },
    //     domain_2: { count: 0, status_1: 0, status_2: 0 },
    //     domain_3: { count: 0, status_1: 0, status_2: 0 },
    //     domain_4: { count: 0, status_1: 0, status_2: 0 },
    //     domain_5: { count: 0, status_1: 0, status_2: 0 }
    // };
    let activeIndex = [];
    for (const key in programs) {
        if (programs[key].count !== 0) {
            if (key === 'domain_' + domain) {
                data.push({ count: programs[key].status_1, domain: key + '-1' });
                data.push({ count: programs[key].status_2, domain: key + '-2' });
            } else {
                data.push({ count: programs[key].count, domain: key });
            }
        }
    }
    data.forEach((el, i) => el.domain.substring(7, 8) === domain && activeIndex.push(i));
    const noData = data.every(el => el.count === 0);
    if (noData) {
        data = [{ count: 1 }];
        activeIndex = [];
    }

    return (
        <Conatainer className="h1">
            <div className="h6 cursor-pointer arrowIcon" onClick={() => history.push(`/report/${url[5]}`)}>
                <Next width="30px" height="30px" />
            </div>
            <PieChartContainer>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={180} height={180}>
                        <Pie
                            className="cursor-pointer"
                            data={data}
                            dataKey="count"
                            fill="#BDBEBF"
                            isAnimationActive={false}
                            innerRadius="55%"
                            outerRadius="80%"
                            startAngle={90}
                            endAngle={-270}
                            strokeWidth={0.5}
                            paddingAngle={0}
                            onClick={props => {
                                const { domain } = props.payload.payload;
                                if (domain) setDomain(domain.substring(7, 8));
                            }}
                            activeIndex={activeIndex}
                            activeShape={props => {
                                const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, name } = props;
                                let fill =
                                    name === activeIndex[0]
                                        ? color['domain_' + domain][0]
                                        : color['domain_' + domain][1];
                                if (noData) fill = '#BDBEBF';
                                return (
                                    <g>
                                        <Sector
                                            cx={cx}
                                            cy={cy}
                                            innerRadius={innerRadius - 2.5}
                                            outerRadius={outerRadius + 2.5}
                                            startAngle={startAngle}
                                            endAngle={endAngle}
                                            fill={fill}
                                            strokeWidth={0.5}
                                            stroke="white"
                                        />
                                    </g>
                                );
                            }}
                        />
                        <text x="50%" y="50%" className="h6" textAnchor="middle">
                            학습한
                        </text>
                        <text x="50%" y="62.5%" className="h6" textAnchor="middle">
                            내용
                        </text>
                    </PieChart>
                </ResponsiveContainer>
            </PieChartContainer>
            <PieExpContainer>
                <div className="flex justify-between h6">
                    <div className={`cursor-pointer`} onClick={() => setDomain('1')}>
                        {domain === '1' ? <Button2On1 className="rangeImg" /> : <Button2Off1 className="rangeImg" />}
                    </div>
                    <div className={`cursor-pointer`} onClick={() => setDomain('2')}>
                        {domain === '2' ? <Button2On2 className="rangeImg" /> : <Button2Off2 className="rangeImg" />}
                    </div>
                    <div className={`cursor-pointer`} onClick={() => setDomain('3')}>
                        {domain === '3' ? <Button2On3 className="rangeImg" /> : <Button2Off3 className="rangeImg" />}
                    </div>
                    <div className={`cursor-pointer`} onClick={() => setDomain('4')}>
                        {domain === '4' ? <Button2On4 className="rangeImg" /> : <Button2Off4 className="rangeImg" />}
                    </div>
                    <div className={`cursor-pointer`} onClick={() => setDomain('5')}>
                        {domain === '5' ? <Button2On5 className="rangeImg" /> : <Button2Off5 className="rangeImg" />}
                    </div>
                </div>
                <div className={`triangle range-triangle-${domain}`}>
                    <div>{domain === '1' && <div></div>}</div>
                    <div>{domain === '2' && <div></div>}</div>
                    <div>{domain === '3' && <div></div>}</div>
                    <div>{domain === '4' && <div></div>}</div>
                    <div>{domain === '5' && <div></div>}</div>
                </div>
                <ExpBlock className={`flex justify-around range-box-${domain}`}>
                    <LeftBlock>
                        <div>
                            <div className={`range-name-${domain} h5`}>{learningDomains[domain]}</div>
                            <div className="flex align-center justify-end school-info h6">
                                {start && <div className="school">{parseSchool(start)}</div>}
                                {end && !isSameSchool && (
                                    <>
                                        <div className="tilde">~</div>
                                        <div className="school">{parseSchool(end)}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </LeftBlock>
                    <RightBlock>
                        <div className="range-count h5">
                            <span>{programs['domain_' + domain].count}</span>
                            <span className="unit">개</span>
                        </div>
                        <div className="flex justify-between align-center">
                            <div className="flex" style={{ flexDirection: 'column' }}>
                                <div className="flex align-center exp">
                                    <div className="flex align-center">
                                        <div className={`status-box color1-${domain} h6`}></div>
                                        <div className="h6">잘 알게 되었어요</div>
                                    </div>
                                    <div className="h6">
                                        <span>{programs['domain_' + domain].status_1}</span>
                                        <span className="unit">개</span>
                                    </div>
                                </div>
                                <div className="flex align-center exp">
                                    <div className="flex align-center">
                                        <div className={`status-box color2-${domain} h6`}></div>
                                        <div className="h6">학습이 더 필요해요</div>
                                    </div>
                                    <div className="h6">
                                        <span>{programs['domain_' + domain].status_2}</span>
                                        <span className="unit">개</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RightBlock>
                </ExpBlock>
            </PieExpContainer>
        </Conatainer>
    );
}
