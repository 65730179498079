import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentFocusIndex,
    setFocusArea as _setFocusArea,
    setNeedProgramFocus as _setNeedProgramFocus
} from '../reducers/programPage';

export default function useProgramPage() {
    const dispatch = useDispatch();
    const { currentFocusIndex, needProgramFocus, focusArea } = useSelector(state => state.programPage);
    const setFocusIndex = useCallback(i => dispatch(setCurrentFocusIndex(i)), [dispatch]);
    const setFocusArea = useCallback(p => dispatch(_setFocusArea(p)), [dispatch]);
    const setNeedProgramFocus = useCallback(bool => dispatch(_setNeedProgramFocus(Boolean(bool))), [dispatch]);
    return {
        focusIndex: currentFocusIndex,
        needProgramFocus,
        focusArea,
        setFocusIndex,
        setFocusArea,
        setNeedProgramFocus
    };
}
