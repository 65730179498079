import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ReactComponent as HeartAttend } from '../assets/images/icon-anim/CN1-1_icon.svg';
import { ReactComponent as HeartHabit } from '../assets/images/icon-anim/CN7_icon.svg';
import CN from '../assets/texts/CN';
import { setMainReward } from '../reducers/learningInfo';
import SimpleRobotModalBody from '../components/SimpleRobotModalBody';
import useLearnerInfo from './useLearnerInfo';
import { setNotice } from '../utils/cookies';

function NoticeModalBody({ type, body, rewardCount }) {
    let img = <HeartAttend />,
        text = body,
        imgCount;
    if (type === 1 || type === 2) {
        imgCount = type;
    }
    if (type === 3 || type === 4 || type === 12) {
        imgCount = 1;
    }
    if (type === 5 || type === 6 || type === 9) {
        text = body;
    }
    if (type === 7) {
        img = <HeartHabit />;
        imgCount = rewardCount;
    }
    return <SimpleRobotModalBody {...{ img, imgCount, text }}></SimpleRobotModalBody>;
}

export default function useGetHeartReward() {
    const { mainReward } = useSelector(({ learningInfo }) => learningInfo);
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();
    const { data: learnerInfo } = useLearnerInfo();
    const modalInfo = useMemo(() => {
        if (mainReward.length === 0 || mainReward.length === index || !learnerInfo?.learner_id) return null;
        let onModalClick = () => {
            if (index < mainReward.length) setIndex(i => i + 1);
        };
        if (mainReward[index] === 8) return { type: 'learningTarget', onModalClick };
        let color = 'primary';
        let txtObj = CN(mainReward[index].txtType || mainReward[index], { ...mainReward[index] });
        if (mainReward[index] < 5) color = 'attend';
        if (mainReward[index]?.txtType === 13) {
            txtObj = mainReward[index];
            onModalClick = type => {
                if (index < mainReward.length) setIndex(i => i + 1);
                //type 따라 다시 안보이기
                if (type === 0) setNotice(txtObj.post_id, type);
            };
        }
        return {
            type: 'robot',
            ...txtObj,
            title: (
                <div style={{ wordBreak: 'keep-all' }}>
                    {txtObj.title.split('\n').map(el => (
                        <div key={el}>{el}</div>
                    ))}
                </div>
            ),
            body: (
                <NoticeModalBody
                    body={txtObj.body}
                    type={mainReward[index].txtType || mainReward[index]}
                    rewardCount={mainReward[index].rewardCount}
                ></NoticeModalBody>
            ),
            // buttons: [{ text: txtObj.btn, onClick: onModalClick }],
            buttons: [].concat(txtObj.btn).map((el, i) => ({ text: el, onClick: onModalClick.bind({}, i) })),
            color,
            src: txtObj.src
        };
    }, [mainReward, index, learnerInfo?.learner_id]);
    useEffect(() => {
        if (mainReward.length === 0) return;
        if (index === mainReward.length) dispatch(setMainReward([]));
    }, [dispatch, mainReward, index]);
    return { modalInfo };
}
