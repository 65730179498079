import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getNotice, postRewardAttend, postRewardHabit } from '../api/lms';
import { setFirstLogin, setHabitState, setMainReward } from '../reducers/learningInfo';
import usePromise from './usePromise';
import moment from 'moment';
import useRedux from './useRedux';
import { getLearnerInfo } from '../reducers/learnerInfo';
import { useHistory } from 'react-router-dom';
import { getNoticeShow } from '../utils/cookies';
export default function useSetHeartReward() {
    const dispatch = useDispatch();
    const rewardAttend = usePromise(postRewardAttend, true);
    const habitState = usePromise(postRewardHabit, true);
    const learnerInfo = useRedux({ thunk: getLearnerInfo, dataKey: 'learnerInfo', wait: true });
    const loading = rewardAttend.loading || habitState.loading || learnerInfo.loading;
    const history = useHistory();
    const error = rewardAttend.error || habitState.error || learnerInfo.error;
    useEffect(() => {
        if (loading || !rewardAttend.data || !habitState.data || !learnerInfo.data) return;
        const { subject_status_code, learning_end_date } = learnerInfo.data;
        const result = [];
        const expireCheck = () => {
            //퇴회,체험학습만료 예정 회원
            if (
                (subject_status_code === '9' || subject_status_code === '0') &&
                moment(learning_end_date).subtract(2, 'days').format('YYYYMMDD') < moment().format('YYYYMMDD')
            )
                result.push({
                    txtType: subject_status_code === '9' ? 5 : 9,
                    expireDate: moment(learning_end_date).add(1, 'days').format('MM월 DD일')
                });
        };
        //이미 출석 완료
        if (rewardAttend.data.result === 'already') {
            //10분 이상 학습했는데 보상 못받았을때
            if (habitState.data.result === 'success')
                result.push({ txtType: 7, rewardCount: habitState.data.rewardCount });
            //10분 이상
            if (habitState.data.result !== 'belowStandards') {
                //체험회원
                if (subject_status_code === '0') {
                    if (moment(learning_end_date).format('YYYYMMDD') === moment().format('YYYYMMDD')) result.push(11);
                    else result.push(6);
                }
                //서비스회원
                if (subject_status_code === '1') result.push(10);
            }
        }
        //생애 최초 로그인
        else if (rewardAttend.data.result === 'first') {
            result.push({ txtType: subject_status_code === '0' ? 12 : 4, display_name: learnerInfo.data.display_name });
            //체험회원은 학습목표시간 설정하지 않음
            if (subject_status_code !== '0' && subject_status_code !== '1') result.push(8);
            expireCheck();
            dispatch(setFirstLogin(true));
        }
        //그외 로그인
        else {
            // 3일 연속
            if (rewardAttend.data.result === 'threeDays') result.push(2);
            //3일 전
            if (rewardAttend.data.result === 'longTimeAgo') result.push(3);
            //하루 로그인
            if (rewardAttend.data.result === 'oneDay') result.push(1);
            expireCheck();
        }
        getNotice()
            .then(r => {
                //
                if (r.post_id && getNoticeShow(r.post_id))
                    result.push({
                        ...r,
                        txtType: 13,
                        body: r.contents,
                        btn: ['다시보지않기', '확인'],
                        robotSrc: 'CN5_character.svg',
                        src: 'OS7_ver4.mp3'
                    });
            })
            .finally(() => {
                //전역으로 관리하고 있는 습관데이터 업데이트
                dispatch(setMainReward(result));
                if (!loading) dispatch(setHabitState(habitState.data));
                history.push('/main');
            });
    }, [rewardAttend.data, learnerInfo.data, habitState.data, loading, dispatch, history]);
    const start = () => {
        rewardAttend.refresh();
        habitState.refresh();
        learnerInfo.update();
    };
    if (error) throw error;
    return { start };
}
