import React from 'react';
import styled from 'styled-components';
import theme from '../../assets/styles/theme';

const ButtonBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2em 1.25em 0.2em 1.25em;
    border: 0.13em solid ${theme.palette.primary};
    border-radius: 1.2em;
    cursor: pointer;
    &.type-0 {
        background: ${theme.palette.white};
        color: ${theme.palette.primary};
    }
    &.type-1 {
        background: ${theme.palette.primary};
        color: ${theme.palette.white};
    }
`;

function Button({ type, text, onClick, className = 'h4 bold' }) {
    return (
        <ButtonBlock className={`${className} type-${type}`} onClick={onClick}>
            {text}
        </ButtonBlock>
    );
}

Button.defaultProps = {
    // fontSize: 18,
    type: 0,
    text: ''
};

export default Button;
