import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import Badge from '../../../components/Badge';
import SoundButton from '../../../components/SoundButton';
import useAchieveLearningTargetTimeBadge from '../../../hooks/useAchieveLearningTargetTimeBadge';
import media from '../../../utils/media';

const Container = styled.div`
    color: ${theme.palette.text};
    background: ${theme.palette.white};
    background: #f5fafa;
    color: #b5b5b6;

    hr {
        width: 100%;
        margin-top: -1.35px;
        height: 1px;
        border: none;
        background: #b5b5b6;
    }

    .h7 {
        font-size: 24px;
        ${media.large} {
            font-size: 23px;
        }
        ${media.medium} {
            font-size: 21px;
        }
        ${media.small} {
            font-size: 19px;
        }
        ${media.xsmall} {
            font-size: 17px;
        }
    }
`;
const NavigatorContainer = styled.div`
    width: 80%;
    max-width: 1024px;
    margin: 0px auto;
    .focus {
        border-bottom: 4px solid ${theme.palette.primary};
        color: ${theme.palette.primary};
    }
    ${media.xsmall} {
        width: 90%;
    }
`;
const NavigaterBlock = styled.div`
    display: flex;
    justify-content: space-between;
`;

const NavigatorItem = styled.div`
    cursor: pointer;
    min-width: 5em;
    text-align: center;
    height: 2.5em;
    display: flex;
    align-items: center;
    & > div {
        margin: 0px auto;
    }

    .item1 {
        display: block;
    }
    .item2 {
        display: none;
    }

    ${media.xsmall} {
        height: 3.5em;
        .item1 {
            display: none;
        }
        .item2 {
            display: block;
        }
    }
    .badge {
        right: -8px;
    }
`;

export default function NavigatorComponent({ url, viewType }) {
    const naviList = ['학습 성취도', '학습 현황', '학습 습관', '나의 위치'];
    const history = useHistory();
    const { isAchieveLearningTargetTime, checkAchieveLearningTargetTime } = useAchieveLearningTargetTimeBadge();
    return (
        <Container>
            <NavigatorContainer className="h7">
                <NavigaterBlock>
                    {naviList.map((el, i) => (
                        <SoundButton key={i}>
                            <NavigatorItem
                                className={viewType === url[i] ? 'focus' : ''}
                                onClick={() => {
                                    history.push(`/report/${url[i]}`);
                                    console.log('navi', i, isAchieveLearningTargetTime);
                                    if (isAchieveLearningTargetTime && i === 2) checkAchieveLearningTargetTime();
                                }}
                            >
                                <Badge show={i === 2 && isAchieveLearningTargetTime}>
                                    <div className="item1">{el}</div>
                                    <div className="item2">
                                        {el.split(' ').map((el, j) => (
                                            <div key={j}>{el}</div>
                                        ))}
                                    </div>
                                </Badge>
                            </NavigatorItem>
                        </SoundButton>
                    ))}
                </NavigaterBlock>
            </NavigatorContainer>
            <hr />
        </Container>
    );
}
