import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useSound from '../../../hooks/useSound';
import { soundPath } from '../../../utils/util';

const Block = styled.div`
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 3em 0;
    max-height: 100%;
    height: 100%;
    .el {
        flex-shrink: 0;

        position: absolute;
        &.anim {
            transition: transform 0.3s;
        }
    }
    .el.center {
        position: initial;
    }
`;

export default function ResponsiveCarousel({ children, onChange, onClose, initialIndex }) {
    const [index, setIndex] = useState(Number.isInteger(initialIndex) ? initialIndex : children.length - 1);
    const newChildren = useMemo(() => children.filter(el => el).map((el, i) => ({ ...el, index: i })), [children]);
    const ref = useRef();
    const isMouseUp = useRef(false);
    const containerWidth = useRef(0);
    const originX = useRef(0);
    const [transform, setTransform] = useState(0);
    const { play } = useSound({ src: `${soundPath}/OS5.mp3`, autoPlay: false });
    const drawing = useRef(false);
    const [anim, setAnim] = useState(false);
    const handleStart = e => {
        let event = e.changedTouches ? e.changedTouches[0] : e;
        originX.current = event.clientX;
        drawing.current = true;
    };
    const handleMove = e => {
        if (drawing.current) {
            isMouseUp.current = true;
            let event = e.changedTouches ? e.changedTouches[0] : e;
            setTransform(((event.clientX - originX.current) / containerWidth.current) * 100);
        }
    };
    const handleEnd = e => {
        if (drawing.current) {
            let newIndex = index;
            if (transform < 0) newIndex = index + 1;
            if (transform > 0) newIndex = index - 1;
            if (newIndex !== index) play();
            setIndex(Math.min(Math.max(0, newIndex), newChildren.length - 1));
            setTransform(0);
            drawing.current = false;
            setAnim(true);
        }
    };
    const handleClick = el => {
        if (!isMouseUp.current && el.index !== index) {
            setIndex(el.index);
            play();
        }
    };
    const handleTransitionEnd = () => {
        if (anim) setAnim(false);
    };
    const handleContainerClick = () => {
        if (onClose && !isMouseUp.current) onClose();
        isMouseUp.current = false;
    };
    useEffect(() => {
        containerWidth.current = ref.current.getBoundingClientRect().width;
    }, []);
    useEffect(() => {
        if (onChange) onChange(index);
    }, [index, onChange]);

    return (
        <Block
            ref={ref}
            onTouchStart={handleStart}
            onTouchMove={handleMove}
            onTouchEnd={handleEnd}
            onMouseDown={handleStart}
            onMouseMove={handleMove}
            onMouseUp={handleEnd}
            onClick={handleContainerClick}
        >
            {newChildren.map((el, i) => {
                let scale = 0.7;
                if (transform > 0 && el.index - index === -1) scale = 0.7 + (0.3 * transform) / 100;
                if (transform < 0 && el.index - index === 1) scale = 0.7 - (0.3 * transform) / 100;
                if (el.index === index) scale = 1 - (0.3 * Math.abs(transform)) / 100;
                el.props = { ...el.props };
                return (
                    <div
                        key={el.index}
                        className={`el${el.index === index ? ' center' : ''}${anim ? ' anim' : ''}`}
                        style={{
                            transform: `translateX(${-100 + 100 * (i - index + 1) + transform}%) scale(${scale})`
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            handleClick(el);
                        }}
                        onTransitionEnd={handleTransitionEnd}
                    >
                        {el}
                    </div>
                );
            })}
        </Block>
    );
}
