import React from 'react';
import styled from 'styled-components';
import { getAccessToken } from '../../../utils/util';
import { ReactComponent as Clock } from '../../../assets/images/clock_icon.svg';
const Block = styled.div`
    background: #fff;
    padding: 0 1.6rem 0 1.6rem;
    flex-grow: 1;
`;

const TitleBolck = styled.div`
    color: #545454;
    padding-top: 20px;
    .description {
        display: flex;
        align-items: center;
        span {
            margin: 0 0.5em;
        }
        .school {
            border: 1px solid;
            border-radius: 0.25rem;
            padding: 0 0.5em;
            margin-right: 0.5em;
        }
        .timeover {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            svg {
                height: 1.25em;
                width: auto;
            }
        }
        .text-gray {
            color: #888888;
        }
    }
`;

const ListBlock = styled.div`
    width: 100%;
`;

const Viewer = styled.div`
    border: 0.3em solid rgb(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin: 0.5rem 0 0.5rem 0;
    &:last-child {
        margin-bottom: 1rem;
    }
    #text {
        padding: 0.8em;
        display: flex;
        align-items: center;
        color: ${props => (props.collected ? '#20a1e2' : '#ed6276')};
    }
    #iframe-wrapper {
        position: relative;
        padding-top: 62.5%;
        overflow: hidden;
    }
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 0;
        pointer-events: none;
    }
`;

const getSchoolText = progressCode => {
    if (!progressCode) return '';
    const schoolGrade = Number(progressCode.substring(0, 2));
    let schoolGradeText = '';
    if (schoolGrade < 7) {
        schoolGradeText = schoolGrade;
    } else if (schoolGrade < 10) {
        schoolGradeText = '중 ' + (schoolGrade - 6);
    } else {
        schoolGradeText = '고 ' + (schoolGrade - 9);
    }
    return schoolGradeText + '-' + progressCode.substring(2, 3);
};

const DiagnosisQuestionViewer = ({ viewInfo }) => {
    const {
        school_progress_code: progressCode,
        school_progress_name: progressNmae,
        learning_domain_name: learningDomainName,
        system5_prescription_yn: timerOver
    } = viewInfo[0];
    const accessToken = getAccessToken();
    return (
        <Block>
            <TitleBolck className="bold">
                <div id="title" className="h4">
                    문항보기
                </div>
                <div className="description text-gray">
                    <div>{learningDomainName}</div>
                    {progressCode && (
                        <>
                            <span>|</span>
                            <div className="school">{getSchoolText(progressCode)}</div>
                            <div>{progressNmae}</div>
                        </>
                    )}
                    {timerOver === '1' && (
                        <div className="timeover">
                            <Clock></Clock>
                            <span>학습시간 초과</span>
                        </div>
                    )}
                </div>
            </TitleBolck>
            <ListBlock className="h5">
                {viewInfo.map((el, i) => {
                    let text = '정답입니다.';
                    if (el.pass_yn === '0') text = el.wrong_answer_example_description;
                    if (el.abort_case === '9') text = '풀지 않고 종료했습니다.';
                    return (
                        <Viewer
                            id={`diagnosis-question-viewer-${i}`}
                            key={i}
                            collected={el.pass_yn === '1'}
                            className="h5"
                        >
                            <div id="text" className="h5">
                                {text}
                            </div>
                            <div id="iframe-wrapper">
                                <iframe
                                    title="LCMS"
                                    src={`https://${process.env.REACT_APP_LCMS_HOST}/frame/diagnosis-question/ko_KR/${
                                        el.frame_id
                                    }/${Number(el.right_answer)}/${
                                        el.selection_answer_number
                                    }?accessToken=${accessToken}`}
                                />
                            </div>
                        </Viewer>
                    );
                })}
            </ListBlock>
        </Block>
    );
};

export default DiagnosisQuestionViewer;
