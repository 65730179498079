import React from 'react';
import { ComposedChart, Bar, XAxis } from 'recharts';

export default function QuestionGraph({
    focusIndex,
    setFocusIndex,
    todayIndex,
    questionCountHistory,
    QuestionGraphHeight,
    chartWidth
}) {
    let preMonth = '';

    return (
        <ComposedChart
            width={chartWidth}
            height={QuestionGraphHeight}
            data={questionCountHistory}
            margin={{ top: 45, bottom: 50, left: 10, right: 85 }}
        >
            <XAxis
                dataKey="learning_date"
                interval={0}
                tickLine={false}
                axisLine={false}
                tick={props => {
                    const { x, y, payload, textAnchor, index } = props;
                    let today = index === todayIndex;
                    let visible = questionCountHistory[index].total_question_count !== 0;
                    let curMonth = payload.value.substring(4, 6);
                    let showMonth = preMonth !== curMonth;
                    let wh = [40, 30];
                    if (visible) preMonth = curMonth;
                    return (
                        <g>
                            {index === focusIndex && <line x1={x} x2={x} y1={0} y2={y - 10} stroke="#22BFBF"></line>}
                            {visible && (
                                <text x={x} y={y + 10} textAnchor={textAnchor} style={{ fontSize: 14 }}>
                                    {Number(payload.value.substring(6, 8))}
                                </text>
                            )}
                            {(today || (showMonth && visible)) && (
                                <g>
                                    {today && (
                                        <g>
                                            <rect
                                                x={x - wh[0] / 2}
                                                y={y + 40 - wh[1] / 2 - 6.5}
                                                width={wh[0]}
                                                height={wh[1]}
                                                rx={5}
                                                fill="#FF7878"
                                            ></rect>
                                            <text
                                                x={x}
                                                y={y + 40}
                                                fill="#fff"
                                                textAnchor={textAnchor}
                                                style={{ fontSize: 16 }}
                                            >
                                                오늘
                                            </text>
                                        </g>
                                    )}
                                    {!today && (
                                        <g>
                                            <rect
                                                x={x - wh[0] / 2}
                                                y={y + 40 - wh[1] / 2 - 6.5}
                                                width={wh[0]}
                                                height={wh[1]}
                                                rx={5}
                                                fill="none"
                                                stroke="#22BFBF"
                                            ></rect>
                                            <text
                                                x={x}
                                                y={y + 40}
                                                fill="#22BFBF"
                                                textAnchor={textAnchor}
                                                style={{ fontSize: 16 }}
                                            >
                                                {Number(preMonth) + '월'}
                                            </text>
                                        </g>
                                    )}
                                </g>
                            )}
                        </g>
                    );
                }}
            />
            <Bar
                dataKey="right_question_count"
                stackId="question-count"
                fill="#A5AFFE"
                type="monotone"
                stroke="none"
                isAnimationActive={false}
                label={props => {
                    let { x, y, width, value, offset, index } = props;
                    if (index === focusIndex) {
                        let wh = [65, 30];
                        x = x + width / 2;
                        let stroke = 1;
                        let triangleSize = 15;
                        let radius = 5;
                        return (
                            <defs>
                                <g id="right_question">
                                    <path
                                        d={`M${x},${y} L${x + triangleSize},${y - wh[1] / 2} H${
                                            x + triangleSize + wh[0] - radius
                                        } q${radius},0 ${radius},${radius} V${
                                            y + wh[1] / 2 - radius
                                        }q0,${radius} -${radius},${radius} H${x + triangleSize}z`}
                                        fill="#A5AFFE"
                                    ></path>
                                    <path
                                        d={`M${x + stroke},${y} L${x + triangleSize},${y - wh[1] / 2 + stroke} H${
                                            x + triangleSize + wh[0] - radius - stroke
                                        } q${radius},0 ${radius},${radius} V${
                                            y + wh[1] / 2 - radius - stroke
                                        }q0,${radius} -${radius},${radius} H${x + triangleSize}z`}
                                        fill="#fff"
                                    ></path>
                                    <text
                                        x={x + triangleSize + wh[0] / 2 + 3}
                                        y={y + offset + 2.5}
                                        fill="#A5AFFE"
                                        textAnchor="end"
                                        style={{ fontSize: 18 }}
                                    >
                                        {value}/
                                    </text>
                                    <text
                                        x={x + triangleSize + wh[0] / 2 + 3}
                                        y={y + offset + 2.5}
                                        fill="#A5AFFE"
                                        textAnchor="start"
                                        style={{ fontSize: 15 }}
                                    >
                                        {questionCountHistory[index].total_question_count}
                                    </text>
                                </g>
                            </defs>
                        );
                    }
                }}
                style={{ cursor: 'pointer' }}
                onClick={props => {
                    const { focusIndex } = props.payload;
                    setFocusIndex(focusIndex);
                }}
            />
            <Bar
                dataKey="wrong_question_count"
                stackId="question-count"
                fill="#dedffd"
                style={{ cursor: 'pointer' }}
                type="monotone"
                stroke="none"
                isAnimationActive={false}
                onClick={props => {
                    const { focusIndex } = props.payload;
                    setFocusIndex(focusIndex);
                }}
            />
            <use href="#right_question"></use>
            <use href="#wrong_question"></use>
        </ComposedChart>
    );
}
