import React, { Component } from 'react';
import ErrorPage from '../pages/ErrorPage';

export default class ErrorBoundary extends Component {
    state = {
        hasError: false,
        errorCode: 404,
        message: ''
    };
    static getDerivedStateFromError(error) {
        return { hasError: true, message: error?.response?.data, errorCode: error?.response?.status };
    }
    handleClick = () => {
        this.setState({ hasError: false });
    };
    render() {
        if (this.state.hasError) {
            // 폴백 UI를 커스텀하여 렌더링할 수 있습니다.
            return (
                <ErrorPage
                    message={this.state.message}
                    code={this.state.errorCode}
                    onClick={this.handleClick}
                ></ErrorPage>
            );
        }

        return this.props.children;
    }
}
