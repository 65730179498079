import React from 'react';
import ModalRobot from '../../../components/common/ModalRobot';
import { ReactComponent as shootingStar } from '../../../assets/images/reward-items/Shooting-star_g.svg';
import { ReactComponent as ufo } from '../../../assets/images/reward-items/ufo.svg';
import { ReactComponent as rocket } from '../../../assets/images/reward-items/spaceship.svg';
import { ReactComponent as planet } from '../../../assets/images/reward-items/planet.svg';
import { ReactComponent as star } from '../../../assets/images/reward-items/star.svg';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import CR from '../../../assets/texts/CR';
import media from '../../../components/FrameLearning/util/media';

const Star = styled(star)`
    transform: scale(0.6);
`;
const ShootingStar = styled(shootingStar)`
    /* width: 2.5em; */
`;
const Rocket = styled(rocket)`
    transform: rotate(30deg);
    /* width: 1.5em; */
`;
const Ufo = styled(ufo)`
    /* width: 2.5em; */
`;
const Planet = styled(planet)`
    /* width: 2.5em; */
`;
const images = [<Star />, <ShootingStar />, <Rocket />, <Ufo />, <Planet />];
const Body = styled.div`
    color: ${theme.palette.text};
    width: 100%;
    & > .row {
        display: flex;
        align-items: center;
        margin: 34px 0;
        svg {
            width: 75px;
            height: 75px;
            margin: 0 25px;
        }
        & > .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        & > .texts {
        }
    }
    ${media.medium} {
        & > .row {
            margin: 20px 0;
            svg {
                width: 35px;
                height: 35px;
            }
            & > .texts {
                .desc {
                    letter-spacing: -0.3px;
                }
            }
        }
    }
`;
const Title = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
export default function RewardItemInfo({ onClick }) {
    const txtObj = CR(4);
    return (
        <ModalRobot
            onClose={onClick}
            {...{ ...txtObj }}
            title={
                <Title>
                    {txtObj.title.split('\n').map(el => (
                        <div key={el}>{el}</div>
                    ))}
                </Title>
            }
            body={
                <Body>
                    {txtObj.titles.map((el, i) => (
                        <div className="row" key={el}>
                            <div className="img-container">{images[i]}</div>
                            <div className="texts">
                                <div className="bold">{el}</div>
                                {txtObj.body[i].split('\n').map(el => (
                                    <div className="desc" key={el}>
                                        {el}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Body>
            }
            buttons={[{ text: txtObj.buttons[0], onClick }]}
        />
    );
}
