import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import * as util from '../util';

const useFlow = ({
    frames,
    frameCodes,
    programId,
    onBeforeLoad,
    onRepeatGenerate,
    onQuestionEnd,
    onFrameEnd,
    onNextFrame,
    onProgramEnd,
    onTrackingEnd,
    onTimerEnd,
    iframe,
    onBasicReTry,
    onBeforeTimer,
    onG2Clear,
    onLoad,
    onNoAction,
    programAchievementDegree,
    programFrameCount,
    onError,
    onHandwriteRecognitionError,
    diagnosisStartProgress
}) => {
    const [iframeKey, setIframeKey] = useState(1);
    const [scratchKey, setScratchKey] = useState(0);
    const [prevResult, setPrevResult] = useState(null);

    const [timer, setTimer] = useState(null);
    const [feedback, setFeedback] = useState(false);
    const [programInfo, setProgramInfo] = useState(null);
    const [learningIndex, setLearningIndex] = useState(diagnosisStartProgress?.indexOf?.(null) || 0);
    const [progress, setProgress] = useState(diagnosisStartProgress?.slice(0, learningIndex > 1 ? 3 : 2) || []);
    const [frameId, setFrameId] = useState(frames[learningIndex]);
    const [totalCount, setTotalCount] = useState(0);
    const [reTryCount, setReTryCount] = useState(0);
    const [repeatGenerate, setRepeatGenerate] = useState(null);
    const [starCount, setStarCount] = useState(0);
    const [paths, setPaths] = useState([]);
    const [achievementDegree, setAchievementDegree] = useState(programAchievementDegree);
    const repeatG2Ref = useRef(false);
    const timerRef = useRef();

    const frameCode = useMemo(() => frameCodes && frameCodes[learningIndex], [frameCodes, learningIndex]);

    const init = () => {
        setTimer(null);
        setFeedback(false);
        setProgramInfo(null);
    };
    const focusIframe = useCallback(() => {
        setTimeout(() => {
            if (!document.getElementById('modal-container') || document.getElementById('loader'))
                iframe?.current?.focus();
        }, 100);
    }, [iframe]);
    const _onLoad = async () => {
        const handWriteRecognitionLevel = util.getHandWriteRecognitionLevel(programId);
        if (onLoad) await onLoad();

        iframe.current.contentWindow.postMessage({ code: '12', handWriteRecognitionLevel, feedbackTime: 2000 }, '*');
        focusIframe();
    };

    const _onRepeatGenerate = useCallback(
        async code => {
            if (!(await onNextFrame(starCount))) return;
            setRepeatGenerate(true);
            await onRepeatGenerate(code);
            init();
            setIframeKey(prev => prev + 1);
        },
        [onRepeatGenerate, onNextFrame, starCount]
    );
    const _onBasicReTry = useCallback(
        async prevResult => {
            if (!(await onNextFrame(starCount))) return;
            if (onBasicReTry) await onBasicReTry(reTryCount);
            setReTryCount(reTryCount => reTryCount + 1);
            init();
            setIframeKey(iframeKey => iframeKey + 1);
            setPrevResult(prevResult);
        },
        [reTryCount, onBasicReTry, onNextFrame, starCount]
    );

    const _onBeforeTimer = useCallback(
        async params => {
            if (onBeforeTimer) await onBeforeTimer(params);
            setTimer(params.time);
        },
        [onBeforeTimer]
    );
    const _onBeforeLoad = useCallback(
        async ({ data }) => {
            if (onBeforeLoad) await onBeforeLoad({ programInfo: data, frameId, repeatGenerate });
            if (programId.indexOf('DG') !== -1) {
                if (learningIndex % 3 === 0 && learningIndex > 0) setProgress([null, null]);
            } else {
                const { repeatCount, preLearningResult, standardTime } = data[frameId];
                const progressCount = preLearningResult ? preLearningResult.filter(el => el === 0).length : repeatCount;
                setProgress(util.makeNullArray(progressCount));
                if (data.learningLogicType === 'G2')
                    _onBeforeTimer({
                        time: (standardTime / 1000) * progressCount,
                        questionCount: repeatCount,
                        repeatGenerate
                    });
            }
            setProgramInfo(data);
        },
        [frameId, learningIndex, onBeforeLoad, programId, _onBeforeTimer, repeatGenerate]
    );

    const _onQuestionEnd = useCallback(
        ({ data }) => {
            const { frameCurrentIndex, frameCurrentResult } = data;
            if (programId.indexOf('DG') !== -1) {
                setProgress(util.getDiagnosisProgress({ progress, frameCurrentResult }));
            } else {
                const newProgress = [...progress];
                newProgress[frameCurrentIndex] = frameCurrentResult;
                setProgress(newProgress);
            }
            if (frameCurrentResult) setStarCount(prev => prev + 1);
            setTotalCount(totalCount => totalCount + 1);
            setFeedback(true);
            setScratchKey(scratchKey => scratchKey + 1);
            const { learningLogicType } = programInfo;
            repeatG2Ref.current = (learningLogicType === 'G1' || learningLogicType === 'G2') && !frameCurrentResult;
            if (onQuestionEnd)
                onQuestionEnd({
                    ...data,
                    paths,
                    trackingId: programInfo.trackingId,
                    repeatG2: repeatG2Ref.current,
                    progress
                });
            timerRef.current = setTimeout(() => {
                setFeedback(false);
            }, 2000);
        },
        [progress, programInfo, programId, onQuestionEnd, paths]
    );

    const _onProgramEnd = useCallback(async () => {
        if (onProgramEnd) await onProgramEnd(starCount);
    }, [onProgramEnd, starCount]);

    const _onNextFrame = useCallback(async () => {
        // 다음 learningIndex 세팅
        const isDiagnosis = programId.indexOf('DG') !== -1;
        let newIndex = learningIndex + 1;
        if (isDiagnosis && progress[0] === progress[1]) newIndex = newIndex + 1; // 진단이고 (O,O) 또는 (X,X)일 때 세번째 문항 넘어감

        if (newIndex === frames.length) {
            // 프로그램 종료
            _onProgramEnd();
        } else {
            // 다음 프레임 실행
            if (!(await onNextFrame(starCount))) return;
            init();
            setLearningIndex(newIndex);
            setFrameId(frames[newIndex]);
        }
    }, [frames, learningIndex, _onProgramEnd, progress, programId, onNextFrame, starCount]);

    const _onG2Clear = useCallback(async () => {
        setTimer(null);
        if (onG2Clear) await onG2Clear(repeatGenerate);
        _onNextFrame();
    }, [repeatGenerate, onG2Clear, _onNextFrame]);

    const _onFrameEnd = useCallback(
        async ({ data }) => {
            const { learningLogicType } = programInfo;
            if (repeatG2Ref.current && data.code === '02') return; //문항 정상종료이고 제너레이트 틀렸을 때 (제너레이트를 마지막까지 풀었을때 오류 방지)
            if (data.passYn === '1' && programAchievementDegree !== null && programAchievementDegree !== undefined)
                setAchievementDegree(prev => prev + 100 / programFrameCount);
            const isForceStop = data.code === '99';
            const isRepeatGenerate =
                ['98', '97'].includes(data.code) &&
                ((learningLogicType === 'G1' && totalCount < 50) || (learningLogicType === 'G2' && totalCount < 100));
            const isRepeatBasic =
                ((['B1', 'B2'].includes(learningLogicType) && reTryCount < 3) ||
                    (learningLogicType === 'A1' && reTryCount < 2)) &&
                data.code === '02' &&
                data.passYn === '0';
            const isG2Clear = data.code === '02' && learningLogicType === 'G2' && data.passYn === '1';
            let abortCase = '0';
            if (isForceStop) abortCase = '9';
            if (isRepeatBasic || isRepeatGenerate) abortCase = '1';
            if (onFrameEnd) await onFrameEnd({ ...data, frameId, starCount, abortCase });
            if (isRepeatGenerate) {
                _onRepeatGenerate(data.code);
            } else if (isRepeatBasic) {
                _onBasicReTry(data.learningResult);
            } else if (isG2Clear) {
                _onG2Clear();
            } else if (!isForceStop) {
                _onNextFrame();
            }
        },
        [
            programInfo,
            totalCount,
            reTryCount,
            _onNextFrame,
            _onRepeatGenerate,
            _onBasicReTry,
            onFrameEnd,
            frameId,
            starCount,
            _onG2Clear,
            programFrameCount,
            programAchievementDegree
        ]
    );
    const _onTrackingEnd = useCallback(
        ({ data }) => {
            if (onTrackingEnd) onTrackingEnd({ ...data, frameId, progress, achievementDegree, frameCode });
        },
        [frameId, progress, onTrackingEnd, achievementDegree, frameCode]
    );
    const _onNoAction = useCallback(
        async ({ data }) => {
            if (onNoAction) await onNoAction({ ...data, abortCase: '1', frameId });
            init();
        },
        [onNoAction, frameId]
    );
    const _onTimerEnd = useCallback(() => {
        setRepeatGenerate(true);
        setScratchKey(prev => prev + 1);
        onTimerEnd();
    }, [onTimerEnd]);

    const _onError = useCallback(
        status => {
            if (onError) onError(status);
        },
        [onError]
    );
    const messageHandler = useCallback(
        async ({ data }) => {
            if (!data.code) return;
            // data.code => 0X: 정상 반응 | 8X: 에러 | 9X: 사용자 (비정상)반응
            // '00': first.js 로드(onBeforeLoad), ...programInfo
            // '01': 문항 정상 종료, ...frameInfo
            // '02': 프레임 정상 종료, ...learningHistory
            // '03': 반응 요소에 정오 표시, ...trackingInfo
            // '81': handWriteRecognition 에러
            // '91': 5동안 반응 없음
            // '96': 도와줘요 강제 종료
            // '97': G2 시간초과 강제 종료, ...learningHistory
            // '98': G2 Repeat 강제 종료, ...learningHistory
            // '99': 학습자 강제 종료, ...learningHistory
            // 'error': 에러 발생
            if (data.code === '00') _onBeforeLoad({ data });
            if (data.code === '01') _onQuestionEnd({ data });
            if (['02', '96', '97', '98', '99'].includes(data.code)) _onFrameEnd({ data });
            if (data.code === '91') _onNoAction({ data });
            if (data.code === '03') _onTrackingEnd({ data });
            if (data.code === '81') onHandwriteRecognitionError();
            if (data.code === 'error') _onError(data.status);
        },
        [_onBeforeLoad, _onQuestionEnd, _onFrameEnd, _onTrackingEnd, _onNoAction, _onError, onHandwriteRecognitionError]
    );
    useEffect(() => {
        const learningIndex = diagnosisStartProgress?.indexOf?.(null) || 0;
        setLearningIndex(learningIndex);
        setProgress(diagnosisStartProgress?.slice(0, learningIndex > 1 ? 3 : 2) || []);
        setFrameId(frames[learningIndex]);
        init();
    }, [frames, diagnosisStartProgress]);

    useEffect(() => {
        setPrevResult(null);
        setReTryCount(0);
        setTotalCount(0);
        setRepeatGenerate(false);
    }, [frameId]);
    useEffect(() => {
        window.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, [messageHandler]);
    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('mouseup', focusIframe);
        return () => window.removeEventListener('mouseup', focusIframe);
    }, [focusIframe]);
    return [
        {
            scratchKey,
            iframeKey,
            frameId,
            prevResult,
            progress,
            timer,
            feedback,
            setPaths,
            frameCode,
            achievementDegree
        },
        _onLoad,
        _onTimerEnd
    ];
};

export default useFlow;
