import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../assets/styles/theme';
import useClickEvent from '../../../hooks/useClickEvent';
import media from '../../../utils/media';
import { ReactComponent as xBtn } from '../../../assets/images/sign-in/Login_button.svg';
const Form = styled.form`
    box-sizing: border-box;
    margin-left: 4em;
    ${media.xsmall} {
        margin-left: 0;
        margin-top: 2em;
    }
    display: flex;
    flex-direction: column;
    min-width: 300px;
`;

const Input = styled.input`
    box-sizing: border-box;
    border: 0px;
    border-radius: 0rem;
    border-bottom: 1.5px solid white;
    padding: 5px 5px;
    color: white;

    font-size: 18px;
    caret-color: white;
    background: transparent;
    width: 100%;
    height: 50px;
    outline: none;
    ::placeholder {
        color: ${theme.palette.white};
    }
`;
const active = css`
    &:active {
        background-color: ${theme.palette.primary};
        color: ${theme.palette.white};
    }
`;
const Button = styled.button`
    margin: auto;
    border: 0px;
    border-radius: 1.5rem;
    margin-top: 3rem;
    max-width: 330px;
    transition: none;
    font-size: 20px;
    height: 55px;
    width: 100%;
    outline: 0;
    cursor: pointer;
    ${({ isMobile }) => (isMobile ? active : '')};
    background-color: ${({ showActive }) => (showActive ? theme.palette.primary : theme.palette.white)};
    color: ${({ showActive }) => (showActive ? theme.palette.white : theme.palette.primary)};
`;

const Remember = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    .id {
        background: rgba(255, 255, 255, 0.5);
        /* color: rgb(14, 183, 187); */
        padding: 0.25em 1em;
        margin: 0 4px 0 4px;
        border-radius: 2em;
        cursor: pointer;
    }
    .x-btn {
        background: white;
        color: rgb(14, 183, 187);
        border-radius: 50%;
        cursor: pointer;
        margin: 0 4px 0 4px;
        width: 1.6em;
        height: 1.6em;
        text-align: center;
    }
`;
const XBtn = styled(xBtn)`
    width: 100%;
    height: auto;
`;

const SigninForm = ({ onSubmit, rememberIds, clearRememberIds, signinForm }) => {
    const [idFocus, setIdFocus] = useState(false);
    const onFocus = () => setIdFocus(true);
    const onRememberIdSelect = id => {
        setIdFocus(false);
        if (id === 'x') return clearRememberIds();
        document.getElementById('username').value = id;
        document.getElementById('password').focus();
    };
    const { active, isMobile, onMouseDown, onMouseOut, onMouseUp, onTouchEnd } = useClickEvent();
    return (
        <Form ref={signinForm} onSubmit={onSubmit}>
            <Input
                className="bold"
                type="text"
                id="username"
                name="username"
                placeholder="아이디"
                autoComplete="off"
                onFocus={onFocus}
            />
            {idFocus && rememberIds[0] && (
                <Remember className="h6">
                    {rememberIds.map(el => (
                        <div className="id" key={el} onClick={onRememberIdSelect.bind(null, el)}>
                            {el}
                        </div>
                    ))}
                    <div className="x-btn" onClick={onRememberIdSelect.bind(null, 'x')}>
                        <XBtn></XBtn>
                    </div>
                </Remember>
            )}
            <Input
                className="bold"
                type="password"
                id="password"
                name="password"
                placeholder="비밀번호"
                autocomplete="on"
            />
            <Button
                className="bold"
                type="submit"
                showActive={active}
                isMobile={isMobile}
                onMouseDown={onMouseDown}
                onTouchEnd={onTouchEnd}
                onMouseOut={onMouseOut}
                onMouseUp={onMouseUp}
            >
                로그인
            </Button>
        </Form>
    );
};

export default SigninForm;
