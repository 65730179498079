import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
    width: 100%;
    height: 100%;
    background: white;
`;

function Layout({ children }) {
    return <Block>{children}</Block>;
}

export default Layout;
