export default function ER(code) {
    if (code === 401)
        return {
            message: '로그아웃 되었어요.\n다시 로그인해 주세요.',
            src: 'GN2_re.mp3'
        };
    if (code === 404)
        return {
            message: '잘못된 요청입니다.\n잠시 후 다시 로그인해 주세요.',
            src: 'GN3_re.mp3'
        };

    return {
        message: '오류가 발생했어요.\n잠시 후 다시 로그인해 주세요.',
        src: 'GN1_re.mp3'
    };
}
