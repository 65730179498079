import React from 'react';
import styled from 'styled-components';
import media from './FrameLearning/util/media';
import ModalBodyText from './ModalBodyText';
const Body = styled.div`
    /* padding-top: 100px; */
    display: flex;
    align-items: center;
    height: 143px;
    &.multi-line {
        height: 269px;
    }
    ${media.medium} {
        /* padding-top: 56px; */
        height: 92px;
        &.multi-line {
            height: 155px;
        }
    }
`;
export default function SimpleDialogBody({ children }) {
    return (
        <Body className={children.split('\n').length > 1 ? 'multi-line' : ''}>
            <ModalBodyText text={children}></ModalBodyText>
        </Body>
    );
}
