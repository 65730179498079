import React from 'react';
import styled from 'styled-components';
import { ReactComponent as UndoIcon } from '../../../components/FrameLearning/images/undo.svg';
import { ReactComponent as DeleteIcon } from '../../../components/FrameLearning/images/delete.svg';
import WithGlitter from '../../../components/WithGlitter';
const Block = styled.div`
    position: fixed;
    width: 100%;
    bottom: 1em;
    left: 0;
    padding: 0 1.6rem;
    z-index: 100;
    pointer-events: none;
    .container {
        margin: 0 auto;
        max-width: 1200px;
        display: flex;
        justify-content: flex-end;
        & > div {
            pointer-events: auto;
            background: #97ddd8;
            padding: 0.5em;
            height: 6em;
            width: 2.5em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 1.5em;
            .icon {
                width: 100%;
                height: auto;
                cursor: pointer;
            }
            hr {
                width: 100%;
                margin: 0.5em;
                border: 1px solid #0c9da1;
            }
        }
    }
`;
export default function PathControlGroup({ onUndoButtonClick, onRemoveButtonClick }) {
    return (
        <Block>
            <div className="container">
                {/* <div> */}
                <WithGlitter>
                    <UndoIcon className="icon" onClick={onUndoButtonClick}></UndoIcon>
                    <hr></hr>
                    <DeleteIcon className="icon" onClick={onRemoveButtonClick}></DeleteIcon>
                </WithGlitter>
                {/* </div> */}
            </div>
        </Block>
    );
}
