import axios from 'axios';
import { getAccessToken } from '../utils/util';

const getConfigure = () => {
    const accessToken = getAccessToken();
    return accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {};
};

export const createGetRequest = url => axios.get(url, getConfigure()).then(result => result.data);
export const createPostRequest = (url, body) => axios.post(url, body, getConfigure()).then(result => result.data);
