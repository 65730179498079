import React from 'react';
import CD from '../assets/texts/CD';
import ModalRobot from './common/ModalRobot';

import { ReactComponent as Planet } from '../assets/images/reward-items/planet.svg';
import { ReactComponent as Rocket } from '../assets/images/reward-items/rocket.svg';
import { ReactComponent as ShootingStar } from '../assets/images/reward-items/shooting-star.svg';
import { ReactComponent as Star } from '../assets/images/reward-items/star.svg';
import { ReactComponent as Ufo } from '../assets/images/reward-items/ufo.svg';
import styled from 'styled-components';
import theme from '../assets/styles/theme';
import { Fragment } from 'react';
import media from '../utils/media';

const Body = styled.div`
    width: 100%;
    .row {
        line-height: 1;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        & > div {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: auto;
                height: 1em;
                max-width: 2em;
                flex-shrink: 0;
                margin-right: 0.25em;
            }
        }
    }
    & > hr {
        border-top: 1px solid #b5b5b6;
        opacity: 0.5;
        margin-top: 44px;
        /* margin: 0.5em 0; */
    }
    .texts {
        flex-direction: column;
        display: flex;
        align-items: center;
        line-height: 1.5;
        padding: 40px 0;
    }
    .programName {
        word-break: keep-all;
        color: ${theme.palette.primary};
        width: 480px;
        text-align: center;
        padding: 0 8px;
        ${media.medium} {
            width: 300px;
        }
    }
    .no-reward {
        margin-top: 45px;
        display: flex;
        justify-content: center;
    }
    ${media.medium} {
        .row {
            padding-top: 30px;
        }
        & > hr {
            margin-top: 28px;
        }
        .texts {
            padding: 24px 0;
        }
        .no-reward {
            margin-top: 30px;
        }
    }
`;
const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const RWImgs = [<Star />, <ShootingStar />, <Rocket />, <Ufo />, <Planet />];
export default function LogoutModal({ onModalClick, data }) {
    const { todayRewards, currentLearningProgramName } = data || {};
    const isItemExits = !!Object.keys(todayRewards || {}).filter(el => el.includes('RW')).length;
    const { title, body, buttons, src, robotSrc } = CD(isItemExits ? 1 : 4, {});
    return (
        <ModalRobot
            onClose={onModalClick.bind({}, 0)}
            robotSrc={robotSrc}
            src={src}
            title={
                <Title>
                    {title.split('\n').map(el => (
                        <div key={el}>{el}</div>
                    ))}
                </Title>
            }
            body={
                <Body>
                    {isItemExits && (
                        <div>
                            <div className="row">
                                {RWImgs.map((el, i) => (
                                    <Fragment key={i}>
                                        {todayRewards[`RW.0${i + 1}`] && (
                                            <div key={i}>
                                                {el}
                                                <div>{todayRewards[`RW.0${i + 1}`]}</div>
                                            </div>
                                        )}
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    )}
                    {!isItemExits && <div className="no-reward">{body[0]}</div>}
                    <hr className="divider"></hr>
                    <div className="texts">
                        <div className="text">{body[isItemExits ? 0 : 1]}</div>
                        <div className="text programName">{currentLearningProgramName}</div>
                    </div>
                </Body>
            }
            buttons={buttons.map((el, i) => ({ text: el, onClick: onModalClick.bind(null, i) }))}
        ></ModalRobot>
    );
}
