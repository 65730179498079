import React from 'react';
import styled from 'styled-components';

import theme from '../../../assets/styles/theme';
import media from '../../../utils/media';
import { ReactComponent as Heart1 } from '../../../assets/images/reward-heart/heart_1.svg';
import { ReactComponent as Heart3 } from '../../../assets/images/reward-heart/heart_3.svg';
import { ReactComponent as Heart2 } from '../../../assets/images/reward-items/star.svg';
import { ReactComponent as Heart4 } from '../../../assets/images/reward-items/shooting-star.svg';
import { ReactComponent as Gear } from '../../../assets/images/report/Gear.svg';

const Container = styled.div`
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;

    .select {
        position: relative;
        width: calc(50% - 40px);
        margin: 0px 20px;
        max-width: 520px;
        ${media.small} {
            width: 100%;
            margin: 0px;
        }
        & > div {
            position: relative;
            left: -7.5px;
            display: inline-block;
            .select-name-container {
                display: flex;
                align-items: center;
                .display_name {
                    margin-left: -0.5rem;
                    z-index: 101;
                }
            }
        }
    }
    .goal {
        width: calc(50% - 40px);
        margin: 0px 20px;
        max-width: 520px;
        text-align: right;

        span {
            color: ${theme.palette.primary};
        }

        svg {
            margin-right: 0.3em;
        }

        ${media.small} {
            margin: 0px;
            width: 100%;
            position: absolute;
            top: 5px;
        }
    }
    .gear {
        position: relative;
        top: 0.025em;
        margin-right: 0.5em;
    }
    .Gear0 {
        fill: ${theme.palette.primary};
    }

    .primary {
        background: ${theme.palette.primary};
        color: white;
    }
    hr {
        margin: 10px 0px 10px 10px;
    }

    .color1 {
        color: #808080;
    }
    .color2 {
        color: #b5b5b6;
    }
    .count {
        display: inline-block;
        margin-top: 5px;
        color: #444444;
    }
    .pointer-none {
        pointer-events: none;
    }
`;

const Card = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(50% - 40px);
    max-width: 520px;
    height: 12em;
    border-radius: 10px;
    margin: 20px;
    padding: 1.5em;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);
    background: ${theme.palette.white};

    ${media.small} {
        margin-bottom: 0px;
        width: 100%;
        margin: 20px 0px;
        margin-bottom: 0px;
    }
    .image {
        width: 4em;
        height: 4em;
        margin-bottom: 5px;
    }
    .svgIcon {
        width: auto;
        height: 3em;
    }
    .rotate {
        transform: rotate(-20deg);
    }
`;

const LeftContentBlock = styled.div`
    width: calc(50% + 5px);
`;

const RightContentBlock = styled.div`
    width: calc(50% - 5px);
    .avg {
        color: #444444;
        margin-right: 1.5em;
    }
    .up {
        background: ${theme.palette.primary};
    }
    .down {
        background: #ff7084;
    }
    .same {
        background: lightgray;
    }

    .avg.up {
        background: none;
        color: ${theme.palette.primary};
    }
    .avg.down {
        background: none;
        color: #ff7084;
    }
    .avg.same {
        background: none;
        color: lightgray;
    }
    .diff-container {
        height: 4em;
        display: flex;
        justify-content: flex-end;
    }

    .diff {
        width: 3em;
        height: 1.75em;
        padding: 0px 10px;
        border-radius: 12.5px;
        color: ${theme.palette.white};
        text-align: center;
    }

    .ctg {
        margin-left: 10px;
    }
`;
const getDiffAndCls = (standard, avg) => {
    const cls = standard > avg ? 'up' : standard === avg ? 'same' : 'down';
    const diff = standard - avg;
    return { cls, diff };
};

const getMinuteType = sec => Math.round(sec / 60);
const getHourType = min => {
    const h = Math.floor(min / 60);
    const m = min % 60;
    return { hour: h, minute: m };
};
const getDateFormat = date => {
    if (!date) return null;
    return date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8);
};
const getDiffDate = (startDate, endDate) => {
    if (!startDate || !endDate)
        return {
            diffYear: 0,
            diffMonth: 0,
            diffDay: 0,
            noDate: true
        };
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = new Date(end - start);
    const diffYear =
        end.getFullYear() -
        start.getFullYear() +
        (startDate.substring(5, 7) === endDate.substring(5, 7) &&
        Number(startDate.substring(8)) > Number(endDate.substring(8))
            ? -1
            : 0);
    const diffMonth = diff.getMonth();
    const diffDay = diff.getDate();
    const noDate = diffYear + diffMonth + diffDay === 0;
    return {
        diffYear,
        diffMonth,
        diffDay,
        noDate
    };
};

export default function StatusComponent({ learnerInfo, learningState, onClick, learningTime: tragetTime, children }) {
    const learningDay = getDiffAndCls(learningState.week_learning_day_count, learningState.avg_learning_days);
    const learningTime = getDiffAndCls(
        getMinuteType(learningState.today_learning_time),
        getMinuteType(learningState.avg_learning_time)
    );
    const questionCount = getDiffAndCls(learningState.today_question_count, learningState.avg_question_count);
    const programCount = getDiffAndCls(learningState.today_program_count, learningState.avg_program_count);
    const totalLearningTime = getHourType(getMinuteType(learningState.total_learning_time));
    const firstDate = getDateFormat(learningState.first_learning_date);
    const lastDate = getDateFormat(learningState.last_learning_date);
    const diffDate = getDiffDate(firstDate, lastDate);

    return (
        <Container className="h6">
            <div className="h5 select">
                <div>
                    <div className="select-name-container">
                        {children}
                        <div className="display_name">{learnerInfo.display_name}친구</div>
                    </div>
                </div>
            </div>
            <div className="h5 color1 goal flex align-center justify-end">
                {learnerInfo.subject_status_code !== '0' && learnerInfo.subject_status_code !== '1' && (
                    <span
                        className={`cursor-pointer flex align-center`}
                        onClick={onClick.bind({}, tragetTime.updateable)}
                    >
                        <Gear />
                        <span>목표 학습시간 설정</span>
                    </span>
                )}
            </div>
            <Card>
                <LeftContentBlock>
                    <div className="image">
                        <Heart1 className="svgIcon" />
                    </div>
                    <div className="color2 h5">총 학습 기간</div>
                    <div>
                        <span className="count h4">
                            {diffDate.noDate && '0일'}
                            {`${diffDate.diffYear !== 0 ? diffDate.diffYear + '년 ' : ''}
                            ${diffDate.diffMonth !== 0 ? diffDate.diffMonth + '개월 ' : ''}
                             ${diffDate.diffDay !== 0 ? diffDate.diffDay + '일' : ''}`}
                        </span>
                    </div>
                </LeftContentBlock>
                <RightContentBlock>
                    <div className="diff-container">
                        <div className={`diff h6 ${learningDay.cls}`}>
                            {learningDay.diff > 0 && '+' + learningDay.diff}
                            {learningDay.diff === 0 && 'ㅡ'}
                            {learningDay.diff < 0 && learningDay.diff}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="ctg color2 h5">금주</div>
                        <div className={`avg h5 ${learningDay.cls}`}>{learningState.week_learning_day_count}일</div>
                    </div>
                    <hr />
                    <div className="flex justify-between">
                        <div className="ctg color2 h5">주평균</div>
                        <div className="avg h5">{learningState.avg_learning_days}일</div>
                    </div>
                </RightContentBlock>
            </Card>
            <Card>
                <LeftContentBlock>
                    <div className="image">
                        <Heart3 className="svgIcon" />
                    </div>
                    <div className="color2 h5">학습 시간</div>
                    <div>
                        <span className="count h4">
                            {`${totalLearningTime.hour !== 0 ? totalLearningTime.hour + '시간 ' : ''}
                            ${totalLearningTime.minute !== 0 ? totalLearningTime.minute + '분' : ''}`}
                            {totalLearningTime.hour === 0 && totalLearningTime.minute === 0 && '0분'}
                        </span>
                    </div>
                </LeftContentBlock>
                <RightContentBlock>
                    <div className="diff-container">
                        <div className={`diff h6 ${learningTime.cls}`}>
                            {learningTime.diff > 0 && '+' + learningTime.diff}
                            {learningTime.diff === 0 && 'ㅡ'}
                            {learningTime.diff < 0 && learningTime.diff}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="ctg color2 h5">오늘</div>
                        <div className={`avg h5 ${learningTime.cls}`}>
                            {getMinuteType(learningState.today_learning_time)}분
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between">
                        <div className="ctg color2 h5">일평균</div>
                        <div className="avg h5">{getMinuteType(learningState.avg_learning_time)}분</div>
                    </div>
                </RightContentBlock>
            </Card>
            <Card>
                <LeftContentBlock>
                    <div className="image">
                        <Heart2 className="svgIcon rotate" />
                    </div>
                    <div className="color2 h5">푼 문항 수</div>
                    <div>
                        <span className="count h4">{learningState.total_question_count}개</span>
                    </div>
                </LeftContentBlock>
                <RightContentBlock>
                    <div className="diff-container">
                        <div className={`diff h6 ${questionCount.cls}`}>
                            {questionCount.diff > 0 && '+' + questionCount.diff}
                            {questionCount.diff === 0 && 'ㅡ'}
                            {questionCount.diff < 0 && questionCount.diff}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="ctg color2 h5">오늘</div>
                        <div className={`avg h5 ${questionCount.cls}`}>{learningState.today_question_count}개</div>
                    </div>
                    <hr />
                    <div className="flex justify-between">
                        <div className="ctg color2 h5">일평균</div>
                        <div className="avg h5">{learningState.avg_question_count}개</div>
                    </div>
                </RightContentBlock>
            </Card>
            <Card>
                <LeftContentBlock>
                    <div className="image">
                        <Heart4 className="svgIcon" />
                    </div>
                    <div className="color2 h5">학습한 프로그램</div>
                    <div>
                        <span className="count h4">{learningState.total_program_count}개</span>
                    </div>
                </LeftContentBlock>
                <RightContentBlock>
                    <div className="diff-container">
                        <div className={`diff h6 ${programCount.cls}`}>
                            {programCount.diff > 0 && '+' + programCount.diff}
                            {programCount.diff === 0 && 'ㅡ'}
                            {programCount.diff < 0 && programCount.diff}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="ctg color2 h5">오늘</div>
                        <div className={`avg h5 ${programCount.cls}`}>{learningState.today_program_count}개</div>
                    </div>
                    <hr />
                    <div className="flex justify-between">
                        <div className="ctg color2 h5">일평균</div>
                        <div className="avg h5">{learningState.avg_program_count}개</div>
                    </div>
                </RightContentBlock>
            </Card>
        </Container>
    );
}
