import React from 'react';
import styled, { keyframes } from 'styled-components';
import usePreventScroll from '../../hooks/usePreventScroll';
import media from '../../utils/media';

const fadein = keyframes`
    from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const Block = styled.div`
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: auto;
    background: ${({ background }) => background};
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${fadein} 0.4s ease-in-out;
    padding: 6rem 1.6rem;

    ${media.medium} {
        padding: 3.5rem 1.6rem;
    }
`;

export default function ModalContainer({ onClick, children, background = 'rgb(0,0,0,0.5)', overflow = 'auto' }) {
    usePreventScroll();
    return (
        <Block
            id="modal-container"
            style={{
                overflow
            }}
            background={background}
            onClick={e => {
                if (onClick && e.currentTarget === e.target) onClick();
            }}
        >
            {children}
        </Block>
    );
}
