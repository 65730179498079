import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from '../../../../assets/styles/theme';
import { ReactComponent as ArrowDown } from '../../../../assets/images/arrow_down.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/images/arrow_up.svg';
import { cardGraphStyle, getResultDetails, makeDetails, buttonBlock } from '../ProgramCard';
import Button from '../../../../components/common/Button';
import { breakPoints } from '../../../../utils/media';
import SoundButton from '../../../../components/SoundButton';
const CardBlock = styled.div`
    position: relative;
    .wrapper {
        max-width: ${breakPoints.large}px;
        padding: 0 1.6rem;
        margin: 0 auto;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        width: 100%;
        cursor: pointer;
        .title {
            display: flex;
            align-items: center;
            .icon-container {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 10px;
                .icon {
                    z-index: 10;
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    border: 2px solid ${theme.palette.repeat};
                    border-radius: 50%;
                    background-color: white;
                    &.fill {
                        background-color: ${theme.palette.repeat};
                    }
                }
            }
        }
        .arrow_down1,
        .arrow_up1 {
            fill: ${theme.palette.textGray};
        }
    }
    .detail {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        ${cardGraphStyle}
    }
    ${buttonBlock}
`;
export default function RepeatProgramCard({ info, isFocus, onClick, index, onButtonClick }) {
    const { programId, programName } = info;
    const details = useMemo(() => getResultDetails(info.programResult, false), [info]);
    const isDetailExist = info.status === '4';
    const backgroundColor = isFocus ? theme.palette.primaryLight : theme.palette.white;
    const handleClick = e => {
        e.stopPropagation();
        onButtonClick({ programId, programName, index, isRepeat: true });
    };
    return (
        <CardBlock id={`repeat-card-${index}`} style={{ backgroundColor, zIndex: isFocus && 5 }}>
            <div className="wrapper">
                <SoundButton>
                    <div className="container h5" onClick={onClick.bind({}, index)}>
                        <div className="title">
                            <div className="icon-container">
                                <span className={`icon ${isDetailExist ? 'fill' : ''}`}></span>
                            </div>
                            <div className={isFocus ? 'bold' : ''}>{programName}</div>
                        </div>
                        {isFocus && <ArrowUp></ArrowUp>}
                        {!isFocus && <ArrowDown></ArrowDown>}
                    </div>
                </SoundButton>
                {isFocus && isDetailExist && <div className="detail">{makeDetails(details, true)}</div>}
                {isFocus && (
                    <div className={`button-block ${isDetailExist ? '' : 'no-detail'}`}>
                        <Button className="h5 bold btn" text={'학습하기'} onClick={handleClick} />
                    </div>
                )}
            </div>
        </CardBlock>
    );
}
