import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import media from '../../../utils/media';

import LearningTimeGraph from './LearningTimeGraph';
import QuestionGraph from './QuestionGraph';

const LearningTimeGrapHeight = 175;
const QuestionGraphHeight = 300;

const Container = styled.div`
    display: flex;
    overflow-x: scroll;
    padding-bottom: 10px;

    .top {
        height: ${LearningTimeGrapHeight + 'px'};
    }
    .bottom {
        height: ${QuestionGraphHeight + 'px'};
    }
    .vertical {
        width: 1.5px;
        background: #b5b5b6;
        height: 396px;
        float: left;
    }

    .legend {
        margin-top: 10px;
    }
    .circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-style: solid;
        border-width: 1px;
        border-radius: 50%;
    }
    .rect {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
    }

    .complete {
        background: #ff7878;
        border-color: #ff7878;
        position: relative;
        top: -0.1em;
    }
    .incomplete {
        border-color: #ff7878;
        background: white;
        position: relative;
        top: -0.1em;
    }
    .correct {
        background: #dedffd;
        position: relative;
        top: -0.1em;
    }
    .incorrect {
        background: #a8a8ff;
        position: relative;
        top: -0.1em;
    }
    .gray {
        color: #919191;
    }
`;
const TitleBlock = styled.div`
    width: 10%;
    min-width: 6em;
    .title {
        padding-left: 1.6em;
    }
    .title.top {
        padding-top: 2em;
    }

    .title.bottom {
        padding-top: 2.5em;
    }
    ${media.large} {
        .title {
            padding-left: 1.2em;
        }
    }
    ${media.medium} {
        .title {
            padding-left: 0.9em;
        }
    }

    ${media.xsmall} {
        min-width: 5em;
        .title {
            padding-left: 0.5em;
        }
    }
`;
const GraphBlock = styled.div`
    width: calc(90% - 6em);
    ${media.xsmall} {
        width: calc(90% - 5em);
    }
    &.noLearning {
        margin: 0 auto; 
    }
    .noLearning {
        height: 395px;
        padding: 160px 0;
        color: gray;
        text-align: center;        
    }
`;

export default function GraphComponent({ todayIndex, learningTimeHistory, questionCountHistory, chartWidth }) {
    const [focusIndex, setFocusIndex] = useState(todayIndex);
    const scroll = useRef();
    // learningTimeHistory = [];
    useEffect(() => {
        scroll.current.scrollLeft = scroll.current.scrollWidth;
    }, []);

    return (
        <Container ref={scroll}>
            <TitleBlock>
                <div className="title top">
                    <div className="h5">학습시간</div>
                    <div className="legend h6">
                        <div className="flex align-center">
                            <span className="circle complete"></span>
                            <span className="gray">달성</span>
                        </div>
                        <div className="flex align-center">
                            <span className="circle incomplete"></span>
                            <span className="gray">미달성</span>
                        </div>
                    </div>
                </div>
                <div className="title bottom">
                    <div className="h5">푼 문항</div>
                    <div className="legend h6">
                        <div className="flex align-center">
                            <span className="rect correct"></span>
                            <span className="gray">전체</span>
                        </div>
                        <div className="flex align-center">
                            <span className="rect incorrect"></span>
                            <span className="gray">정답</span>
                        </div>
                    </div>
                </div>
            </TitleBlock>
            <div className="vertical"></div>
            <GraphBlock className={`h6 ${learningTimeHistory.length === 0 ? 'noLearning' : ''}`}>
                {learningTimeHistory.length === 0 && (
                    <div className="noLearning h5">해당 기간 동안 진행된 학습이 없습니다.</div>
                )}
                {learningTimeHistory.length !== 0 && (
                    <>
                        <div className="graph top learning-time flex">
                            <LearningTimeGraph
                                focusIndex={focusIndex}
                                setFocusIndex={setFocusIndex}
                                learningTimeHistory={learningTimeHistory}
                                LearningTimeGrapHeight={LearningTimeGrapHeight}
                                chartWidth={chartWidth}
                            />
                        </div>
                        <div className="graph bottom question-count">
                            <QuestionGraph
                                focusIndex={focusIndex}
                                setFocusIndex={setFocusIndex}
                                todayIndex={todayIndex}
                                questionCountHistory={questionCountHistory}
                                QuestionGraphHeight={QuestionGraphHeight}
                                chartWidth={chartWidth}
                            />
                        </div>
                    </>
                )}
            </GraphBlock>
        </Container>
    );
}
