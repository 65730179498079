import * as lms from '../api/lms';
import { createPromiseThunk, createInitThunk, handleAsyncActions } from './asyncUtils';

const GET_LEARNING_GRADE = 'learningGrade/GET_LEARNING_GRADE';

export const getLearningGrade = createPromiseThunk(GET_LEARNING_GRADE, lms.getLearningGrade);
export const initLearningGrade = createInitThunk(GET_LEARNING_GRADE);

const learningGrade = handleAsyncActions(GET_LEARNING_GRADE);
export default learningGrade;
