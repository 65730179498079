import { useEffect } from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import CT from '../../../assets/texts/CT';
import ModalRobot from '../../../components/common/ModalRobot';
import { ReactComponent as Exit } from '../../../components/FrameLearning/images/exit_button.svg';
import SimpleRobotModalBody from '../../../components/SimpleRobotModalBody';
import media, { breakPoints } from '../../../utils/media';
import PathControlGroup from './PathControlGroup';
import ScratchPad from './ScratchPad';
import { ReactComponent as Edit } from '../../../components/FrameLearning/images/edit.svg';
import usePreventScroll from '../../../hooks/usePreventScroll';
const Block = styled.div`
    &.d-none {
        display: none;
    }
    .d-none {
        display: none;
    }
    background: ${theme.palette.primaryLight};
    padding: 1em 1.6rem;

    & > .container {
        display: flex;
        max-width: ${breakPoints.large}px;
        margin: 0 auto;
        flex-wrap: wrap;
        .focus-container {
            background: ${theme.palette.white};
            width: 50%;
            position: relative;
            padding-top: 31.25%;
            display: flex;
            flex-direction: column;
            ${media.xsmall} {
                width: 100%;
                padding-top: 62.5%;
            }
            .contents {
                cursor: pointer;
                svg {
                    position: absolute;
                    top: 0;
                    max-width: 1200px;
                    width: 100%;
                    height: 100%;
                }
                svg.edit {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 30px;
                    height: 30px;
                    transform: translate(-50%, -50%);
                }
            }
            &.full-mode {
                position: fixed;
                touch-action: none;
                width: 100%;
                height: 100%;
                z-index: 50;
                padding: 0;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                overflow: auto;
                .contents {
                    cursor: default;
                    width: 100%;
                    max-width: 1200px;
                    flex-grow: 1;
                    position: relative;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    .contents-back {
                        position: absolute;
                        top: 0;

                        rect {
                            fill: ${theme.palette.primaryLight};
                        }
                    }
                }
            }
            .header {
                width: 100%;
                top: 0;
                background: ${theme.palette.primary};
                color: ${theme.palette.white};
                padding: 1em 1.6rem;

                & > div {
                    position: relative;
                    max-width: 1200px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    svg {
                        position: absolute;
                        height: 2em;
                        width: auto;
                        right: 0;
                        cursor: pointer;
                    }
                }
                .memo {
                    font-size: 18px;
                }
                ${media.xsmall} {
                    .memo {
                        margin-top: 50px;
                    }
                    & > div {
                        align-items: flex-start;
                    }
                }
            }
        }
        .message {
            width: 50%;
            padding: 1em;
            ${media.xsmall} {
                width: 100%;
            }
        }
    }
`;
export default function HelpCardDetail({
    learningHelpDetail,
    getHelpDetail,
    updateMemoPath,
    open,
    fullMode,
    handleFrameClick,
    isLandScape,
    setFullMode,
    dateTime
}) {
    usePreventScroll(fullMode);
    const [path, setPath] = useState('');
    // const getLearningHelpDetail = useCallback(async () => await getHelpDetail(dateTime), [dateTime]);
    // const { data: learningHelpDetail, refresh: updateData, loading: detailLoading, error } = usePromise(
    //     getLearningHelpDetail,
    //     true
    // );
    const [paths, setPaths] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [edited, setEdited] = useState(false);
    const handleExitClick = () => {
        if (edited) setShowModal(true);
        else setFullMode(false);
    };
    const handleModalButtonClick = async i => {
        setLoading(true);
        if (i === 1) {
            await updateMemoPath({
                helpHistoryDateTime: dateTime,
                helpMemoPath: JSON.stringify(paths)
            });
        }
        await getHelpDetail(dateTime);
        setLoading(false);
        setFullMode(false);
        setShowModal(null);
    };

    const onUndoButtonClick = e => {
        setEdited(true);
        setPaths(paths.slice(0, paths.length - 1));
    };
    const onRemoveButtonClick = e => {
        setEdited(true);
        setPaths([]);
    };
    const ready = open && learningHelpDetail && !loading;
    useEffect(() => {
        if (learningHelpDetail)
            setPaths(learningHelpDetail.help_memo_path ? JSON.parse(learningHelpDetail.help_memo_path) : []);
    }, [learningHelpDetail]);
    const callback = useCallback(async () => {
        if (open) {
            await getHelpDetail(dateTime);
            setLoading(false);
        }
        if (!open) setLoading(true);
    }, [open, getHelpDetail, dateTime]);
    useEffect(() => {
        callback();
    }, [callback]);
    useEffect(() => {
        if (path !== '') setEdited(true);
    }, [path]);
    useEffect(() => {
        if (fullMode === true) setEdited(false);
    }, [fullMode]);
    return (
        <Block className={`${ready ? '' : 'd-none'}`}>
            <div className="container">
                <div className={`focus-container ${fullMode ? 'full-mode' : ''}`} onClick={handleFrameClick}>
                    {fullMode && (
                        <div className="header">
                            <div>
                                <div className="memo bold ">메모하기</div>
                                <Exit className="exit-btn" onClick={handleExitClick}></Exit>
                            </div>
                        </div>
                    )}
                    <div className="contents">
                        {fullMode && (
                            <svg preserveAspectRatio="xMidYMin" viewBox="0 0 800 500" className="contents-back">
                                <rect x="0" y="0" width="800" height="500"></rect>
                            </svg>
                        )}
                        {learningHelpDetail && (
                            <svg
                                preserveAspectRatio="xMidYMin"
                                className={`contents-svg`}
                                dangerouslySetInnerHTML={{
                                    __html: learningHelpDetail.help_frame_svg.replace(
                                        /(q-mark)|(blink)|(blink-once)/g,
                                        ''
                                    )
                                }}
                                viewBox="0 0 800 500"
                            ></svg>
                        )}
                        <ScratchPad
                            path={path}
                            setPath={setPath}
                            paths={paths}
                            setPaths={setPaths}
                            fullMode={fullMode}
                            isLandScape={isLandScape}
                            onBackButtonClick={setFullMode.bind(null, false)}
                            dateTime={dateTime}
                        ></ScratchPad>
                        {!fullMode && <Edit className="edit" />}
                    </div>
                </div>
                {learningHelpDetail && <div className="message">{learningHelpDetail.help_message}</div>}

                <div className={`${fullMode ? '' : 'd-none'}`}>
                    <PathControlGroup
                        onUndoButtonClick={onUndoButtonClick}
                        onRemoveButtonClick={onRemoveButtonClick}
                    ></PathControlGroup>
                </div>
            </div>
            {showModal && (
                <ModalRobot
                    {...{ ...CT(1) }}
                    body={<SimpleRobotModalBody text={CT(1).body} />}
                    buttons={CT(1).buttons.map((el, i) => ({
                        text: el,
                        onClick: handleModalButtonClick.bind({}, i)
                    }))}
                ></ModalRobot>
            )}
        </Block>
    );
}
