import styled from 'styled-components';
import media from '../../../utils/media';

export default styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    width: 465px;
    ${media.medium} {
        width: 300px;
    }
`;
