import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import media from '../../../utils/media';
import { ReactComponent as Lock } from '../../../assets/images/reward-items/card_lock.svg';
import useSound from '../../../hooks/useSound';
import { soundPath } from '../../../utils/util';
let cards = Promise.all(
    [...Array(12).keys()].map(async el => {
        const result = {};
        result.Front = (
            await import(
                `!!@svgr/webpack?-svgo,+titleProp,+ref!../../../assets/images/reward-items/cscard${el + 1}_1.svg`
            )
        ).default;
        result.Back = (
            await import(
                `!!@svgr/webpack?-svgo,+titleProp,+ref!../../../assets/images/reward-items/cscard${el + 1}_2.svg`
            )
        ).default;
        return result;
    })
);
const zoomIn = keyframes`
from{
    transform:scale(0)
}
to{
    transform:scale(1)
}
`;
const Block = styled.div`
    cursor: pointer;
    background-color: transparent;
    perspective: 1000px;
    .flip-inner {
        transition: transform 0.6s;
        transform-style: preserve-3d;
        animation: ${zoomIn} 0.3s;
        .front,
        .back {
            position: relative;
            top: 0;
            left: 0;
            backface-visibility: hidden;
            & > svg {
                width: 350px;
                height: auto;
                ${media.large} {
                    width: 300px;
                }
                ${media.medium} {
                    width: 250px;
                }
                ${media.small} {
                    width: 230px;
                }
                ${media.xsmall} {
                    width: 220px;
                }
            }
        }
        .back {
            left: 0;
            position: absolute;
            transform: rotateY(180deg);
        }
    }
    .flip-inner.rotate {
        transform: rotateY(180deg);
    }
`;
export default function ConstellationCard({ type, isFocus }) {
    let [Card, setCard] = useState();
    const [flip, setFlip] = useState(false);
    const drawing = useRef(false);
    const isMove = useRef(false);
    const { play } = useSound({ src: `${soundPath}/OS5.mp3`, autoPlay: false });
    const start = () => {
        drawing.current = true;
    };
    const move = () => {
        if (drawing.current) isMove.current = true;
    };
    const handleClick = () => {
        if (type > -1 && !isMove.current && isFocus) {
            setFlip(!flip);
            play();
        }
        isMove.current = false;
        drawing.current = false;
    };
    useEffect(() => {
        cards.then(result => setCard(result[type - 1]));
    }, [type]);
    return (
        <Block onClick={handleClick} onMouseDown={start} onMouseMove={move}>
            <div className={`flip-inner${flip ? ' rotate' : ''}`}>
                {Card && type >= 0 && (
                    <>
                        <div className="front">
                            <Card.Front />
                        </div>
                        <div className="back">
                            <Card.Back />
                        </div>
                    </>
                )}
                {type < 0 && (
                    <div className="front">
                        <Lock></Lock>
                    </div>
                )}
            </div>
        </Block>
    );
}
