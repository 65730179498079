export default function CT(type) {
    return [
        {
            title: '나가기',
            body: '메모를 저장할까요?',
            buttons: ['취소', '저장'],
            src: 'CT1_re.mp3',
            robotSrc: 'CN5_character.svg'
        }
    ][type - 1];
}
