import React from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import Carousel from '../../../components/Carousel';
import ModalContainer from '../../../components/common/ModalContainer';
import WrongAnswerViewer from './WrongAnswerViewer';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
const Block = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    .title {
        max-width: 800px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${theme.palette.white};
        margin: 0 auto;
        position: relative;
        padding: 0.5em 0;
        .x-btn {
            cursor: pointer;
            position: absolute;
            right: calc(2em + 14px);
            height: 0.9em;
            width: auto;
        }
    }
    .body {
        margin: 0 auto;
        max-width: 800px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .carousel-inner-container {
            width: 100%;
        }
        & > div {
            flex: 1;
            /* background: white; */
        }
    }
`;
export default function WrongAnswerAllModal({ onChangeIndexButtonClick, title, onClose, data }) {
    return (
        <ModalContainer background="rgb(0,0,0,0.7)">
            <Block>
                <div className="title h4">
                    {title}
                    <Close className="x-btn" onClick={onClose}></Close>
                </div>
                <div className="body">
                    <Carousel
                        classes={{ carouselInnerContainer: 'carousel-inner-container' }}
                        arrowDown={true}
                        onIndexChange={onChangeIndexButtonClick}
                        showIndex={false}
                    >
                        {data.map(contentsData => (
                            <WrongAnswerViewer
                                key={contentsData.dateTime}
                                achievementDegree={contentsData.achievementDegree}
                                progress={contentsData.progress}
                                timerWidth={contentsData.timerWidth}
                                learningContents={contentsData.learningContents}
                            ></WrongAnswerViewer>
                        ))}
                    </Carousel>
                </div>
            </Block>
        </ModalContainer>
    );
}
