import React from 'react';
import CN from '../../../assets/texts/CN';
import ModalRobot from '../../../components/common/ModalRobot';
import SimpleRobotModalBody from '../../../components/SimpleRobotModalBody';

export default function TimeoverModal({ onClick, txtType }) {
    const txtObj = CN(txtType);
    return (
        <ModalRobot
            {...{ ...txtObj }}
            body={<SimpleRobotModalBody text={txtObj.body} />}
            buttons={[{ text: txtObj.btn, onClick }]}
        ></ModalRobot>
    );
}
