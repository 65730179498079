import React from 'react';
import styled from 'styled-components';
import media from '../FrameLearning/util/media';
function makeFontSizes(fix = 0) {
    const basicSizes = [2.25, 2, 1.75, 1.5, 1.25, 1];
    return basicSizes.map((el, i) => `.h${i + 1},h${i + 1},&.h${i + 1}{font-size:${el - fix}rem;}`).join('');
}
function makeMediaFontSizes() {
    const mediaList = ['xlarge', 'large', 'medium', 'small', 'xsmall'];
    return mediaList.map((el, i) => {
        if (i === 0) return makeFontSizes();
        return `${media[el]}{
            ${makeFontSizes(i * 0.1)}
        }`;
    });
}
const Block = styled.div`
    ${makeMediaFontSizes()}
`;
export default function Typography({ children, className }) {
    return <Block className={className}>{children}</Block>;
}
