import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from '../../assets/styles/theme';
import moment from 'moment';
import ModalContainer from './ModalContainer';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { ReactComponent as Left } from '../../assets/images/keyboard_arrow_left.svg';
import { ReactComponent as Right } from '../../assets/images/keyboard_arrow_right.svg';
import media from '../../utils/media';
function makeCalendar(date) {
    const firstDay = moment(date).startOf('month').day();
    const lastDay = moment(date).endOf('month').day();
    const lastDate = moment(date).endOf('month').date();
    const arr = [...Array(firstDay), ...[...Array(lastDate).keys()].map(i => i + 1), ...Array(6 - lastDay)];
    const weeks = arr.length / 7;
    const result = [];
    for (let i = 0; i < weeks; i++) {
        result[i] = arr.splice(0, 7);
    }
    return result;
}
const Block = styled.div`
    margin: 0 auto;
    background: ${theme.palette.primary};
    border-radius: ${theme.modalRadius};
    overflow: hidden;
    .title {
        padding: 0.5em 1em;
        color: white;
        display: flex;
        align-items: center;
        span {
            margin-top: -2px;
        }
        .day-controller-container {
            display: flex;
            align-items: center;
            line-height: 1;
            & > svg {
                height: 2em;
                width: auto;
                cursor: pointer;
            }
        }
        .close-container {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            & > svg {
                cursor: pointer;
                height: 0.75em;
                width: auto;
            }
        }
    }
    .body {
        border-radius: ${theme.modalRadius} ${theme.modalRadius} 0 0;
        background: white;
        ${media.medium} {
            font-size: 13px;
        }
        .row {
            display: flex;

            & > div:nth-child(1):not(.today) {
                color: red;
            }
            & > div:nth-child(7):not(.today) {
                color: ${theme.palette.primary};
            }
            & > div {
                margin: 0.75em 1em;
                width: 1.5em;
                height: 1.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;
            }
            & > .today {
                color: white;
                background: ${theme.palette.primary};
                padding-bottom: 2px;
            }

            & > .gray {
                opacity: 0.4;
                pointer-events: none;
            }

            .day-name {
                height: 1.5em;
                cursor: inherit;
            }
        }
        .divider {
            border-top: 1px solid lightgray;
            margin: 0 10px;
        }
    }
`;
const isValidDay = (startDate, endDate, currentDate) => {
    if (moment().diff(moment(currentDate), 'months') > 5) return 'gray';
    if (startDate && moment(currentDate).diff(moment(startDate), 'days') < 0) return 'gray';
    if (endDate && moment(currentDate).diff(moment(endDate), 'days') > 0) return 'gray';
    return '';
};
export default function Calendar({ selectedDate, onClose, onSelect, startDate, endDate }) {
    const [currentTime, setCurrentTime] = useState(moment(selectedDate).format('YYYYMMDD'));
    const calendar = useMemo(() => makeCalendar(currentTime), [currentTime]);
    const handleArrowClick = (amount, unit) => {
        setCurrentTime(moment(currentTime).add(amount, unit).format('YYYYMMDD'));
    };
    return (
        <ModalContainer onClick={onClose}>
            <Block>
                <div className="title h4">
                    <div className="day-controller-container">
                        <Left onClick={handleArrowClick.bind({}, -1, 'years')} />
                        <span>{currentTime.slice(0, 4)}</span>
                        <Right onClick={handleArrowClick.bind({}, 1, 'years')} />
                    </div>
                    <div className="day-controller-container">
                        <Left onClick={handleArrowClick.bind({}, -1, 'months')} />
                        <span>{currentTime.slice(4, 6)}</span>
                        <Right onClick={handleArrowClick.bind({}, 1, 'months')} />
                    </div>
                    <div className="close-container">
                        <Close onClick={onClose}></Close>
                    </div>
                </div>
                <div className="body">
                    <div className="row">
                        {['일', '월', '화', '수', '목', '금', '토'].map((el, i) => (
                            <div key={el} className={`day-name bold`}>
                                {el}
                            </div>
                        ))}
                    </div>
                    <hr className="divider"></hr>
                    {calendar.map((row, i) => (
                        <div className="row" key={i + 'row'}>
                            {row.map((day, i) => (
                                <div
                                    key={(day || '') + i}
                                    className={`${
                                        currentTime.slice(0, 6) + ((day && day.toString()) || '').padStart(2, '0') ===
                                        selectedDate
                                            ? 'today'
                                            : ''
                                    } ${isValidDay(
                                        startDate,
                                        endDate,
                                        currentTime.slice(0, 6) + ((day && day.toString()) || '').padStart(2, '0')
                                    )}`}
                                    onClick={() => {
                                        if (day) onSelect(currentTime.slice(0, 6) + day.toString().padStart(2, '0'));
                                    }}
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </Block>
        </ModalContainer>
    );
}
