import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import useLearnerInfo from '../../hooks/useLearnerInfo';
import usePromise from '../../hooks/usePromise';
import * as lms from '../../api/lms';
import { getLearningGrade } from '../../reducers/learningGrade';
import media from '../../utils/media';

import Loading from '../../components/common/Loading';
import SelectComponent from '../../components/common/SelectComponent';
import StatusComponent from '../reportPage/learningStatusComponent/StatusComponent';
import LearningTargetModal from '../../components/common/LearningTargetModal';
import Toast from '../../components/Toast';
import ModalContainer from '../../components/common/ModalContainer';
import { ReactComponent as ToastIcon } from '../../assets/images/report/Toast_icon.svg';
import TT from '../../assets/texts/TT';
import useLearningTime from '../../hooks/useLearningTime';

const Container = styled.div`
    max-width: 1200px;
    margin: 0px auto;

    ${media.xsmall} {
        max-width: 600px;
    }
`;

export default function LearningStatusComponent() {
    const { data: learningGrade, loading: gradeLoading, error: error1 } = useSelector(state => state.learningGrade);
    const { data: learnerInfo, loading: learnerInfoLoading, error: error2 } = useLearnerInfo();
    const { subject_status_code } = learnerInfo || {};
    const regularMember = subject_status_code !== '0' && subject_status_code !== '1';
    const [grade, setGrade] = useState();
    const [toast, setToast] = useState(false);
    const [openLearningTarget, setOpenLearningTarget] = useState(false);
    const {
        data: learningState,
        loading: statusLoading,
        error: error3
    } = usePromise(lms.getLearningStatus, !grade, {
        grade
    });
    const { data: learningTime, error: error4, refresh: learningTimeRefresh } = useLearningTime();
    const dispatch = useDispatch();
    const error = error1 || error2 || error3 || error4;
    const list = useMemo(() => {
        if (learningGrade) {
            return learningGrade
                .map(el => el + '등급')
                .concat()
                .reverse();
        }
    }, [learningGrade]);
    useEffect(() => {
        if (!learningGrade) dispatch(getLearningGrade());
        if (learningGrade) setGrade(learningGrade[learningGrade.length - 1]);
    }, [learningGrade, dispatch]);
    if (error) throw error;
    if (!grade || gradeLoading || statusLoading || learnerInfoLoading) return <Loading />;
    return (
        <Container>
            {learningGrade && learningState && learnerInfo && (!regularMember || learningTime) && (
                <StatusComponent
                    learnerInfo={learnerInfo}
                    learningState={learningState}
                    learningTime={learningTime}
                    onClick={updatable => {
                        if (updatable) setOpenLearningTarget(true);
                        else setToast(true);
                    }}
                >
                    <SelectComponent
                        list={list}
                        onClick={grade => setGrade(grade.substring(0, 1))}
                        selectIndex={list.indexOf(grade + '등급')}
                        style={{
                            selectBox: {
                                border: 'none',
                                background: '#f2f5f5'
                            }
                        }}
                    />
                </StatusComponent>
            )}
            {openLearningTarget && (
                <LearningTargetModal
                    onSaveEnd={learningTimeRefresh}
                    initialTargetTime={learningTime.targetTime}
                    onClick={setOpenLearningTarget.bind({}, false)}
                />
            )}
            {toast && (
                <ModalContainer>
                    <Toast
                        message={TT(2)}
                        icon={<ToastIcon />}
                        style={{ background: 'lightgray' }}
                        onToastHide={setToast.bind({}, false)}
                    />
                </ModalContainer>
            )}
        </Container>
    );
}
