import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import contentCSS from '../../../assets/styles/learningContentCSS';
import { ReactComponent as ArrowDown } from '../../../assets/images/arrow_down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/images/arrow_up.svg';
import { ReactComponent as Teacher } from '../../../components/FrameLearning/images/teacher.svg';
import { ReactComponent as TeacherOff } from '../../../assets/images/teacher_off.svg';
import { ReactComponent as Message } from '../../../assets/images/message.svg';
import HelpCardDetail from './HelpCardDetail';
import Badge from '../../../components/Badge';
import moment from 'moment';
import { breakPoints } from '../../../utils/media';
const Block = styled.div`
    ${contentCSS}
    /* padding: 0 1em; */
    .card-container {
        padding: 1em 1.6rem;

        & > div {
            display: flex;
            align-items: center;
            cursor: pointer;
            max-width: ${breakPoints.large}px;
            margin: 0 auto;
            .date {
                padding: 0 0.5em 0 0;
            }
            .program-name {
                flex-grow: 1;
                padding: 0 1em;
                font-size: 16px;
            }
            .right {
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .arrow {
                    fill: #b5b5b6;
                }
            }
            .check {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 3em;
                svg {
                    width: auto;
                    height: 1.5em;
                }
            }
            .checked > div {
                color: ${theme.palette.primary};
            }
            .not-checked > div {
                color: ${theme.palette.gray};
            }
        }
    }
`;
export default function HelpCard({ data, open, onClick, learningHelpDetail, getHelpDetail, updateMemoPath }) {
    const [fullMode, setFullMode] = useState(false);
    const { help_check_status_code, help_history_date_time, program_name } = data;
    const date = useMemo(
        () => `${help_history_date_time.slice(4, 6)}.${help_history_date_time.slice(6, 8)}.`,
        [help_history_date_time]
    );
    const handleFrameClick = () => {
        if (!fullMode) setFullMode(true);
    };
    return (
        <Block>
            <div className="card-container" onClick={onClick}>
                <div>
                    <Badge
                        show={
                            help_check_status_code === '2' &&
                            help_history_date_time.slice(0, 8) >= moment().subtract(30, 'day').format('YYYYMMDD')
                        }
                    >
                        <div className="date">{date}</div>
                    </Badge>
                    <div className="program-name">{program_name}</div>
                    <div className="right">
                        <div
                            className={`check ${
                                ['not-checked', 'checked', 'checked', 'checked'][help_check_status_code]
                            }`}
                        >
                            {[<TeacherOff />, <Teacher />, <Message />, <Message />][help_check_status_code]}
                            <div>{['미확인', '확인', '메모', '메모'][help_check_status_code]}</div>
                        </div>
                        {!open && <ArrowDown className="arrow"></ArrowDown>}
                        {open && <ArrowUp className="arrow"></ArrowUp>}
                    </div>
                </div>
            </div>
            {
                <HelpCardDetail
                    learningHelpDetail={learningHelpDetail}
                    getHelpDetail={getHelpDetail}
                    updateMemoPath={updateMemoPath}
                    open={open}
                    dateTime={help_history_date_time}
                    fullMode={fullMode}
                    handleFrameClick={handleFrameClick}
                    setFullMode={setFullMode}
                ></HelpCardDetail>
            }
        </Block>
    );
}
