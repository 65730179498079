import * as lms from '../api/lms';
import { createPromiseThunk, createInitThunk, handleAsyncActions } from './asyncUtils';

const GET_LEARNING_FRAME = 'learningProgram/GET_LEARNING_FRAME';

export const getLearningFrame = createPromiseThunk(GET_LEARNING_FRAME, lms.getLearningFrame);
export const initLearningFrame = createInitThunk(GET_LEARNING_FRAME);

const learningFrame = handleAsyncActions(GET_LEARNING_FRAME);
export default learningFrame;
