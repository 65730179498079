export default function AT(type, params = {}) {
    return [
        '학습한 내용이에요.',
        '이만큼 학습했어요.',
        '이렇게 학습했어요.',
        '학습 위치를 확인해요.',
        '풀이 과정을 확인해 봐요.',
        `'{${params.programName}}' 오답을 확인해 봐요.`,
        '선생님께 문의한 내역을 확인해 봐요!'
    ][type - 1];
}
