import { createGlobalStyle } from 'styled-components';
import media from '../../utils/media';

const makeFontSize = (ratio = 1) =>
    [2.5, 2, 1.75, 1.5, 1.25, 1].map((el, i) => `.h${i + 1}{font-size:${el * ratio}rem;}`).join('');
const makeResponsiveFontSize = () => {
    let result = makeFontSize();
    const mediaKey = ['large', 'medium', 'small', 'xsmall'];
    mediaKey.forEach((el, i) => {
        result += `${media[el]}{${makeFontSize(1 - (i + 1) * 0.05)}}`;
    });
    return result;
};

const GlobalStyle = createGlobalStyle`
    
    * {
        margin: 0;
        box-sizing: border-box;
        font-size:inherit;
        user-select: none;
	    -khtml-user-select: none;
	    -o-user-select: none;
	    -moz-user-select: -moz-none;
	    -webkit-user-select: auto;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
    }
   ::selection {
        background: transparent;
        color: inherit;
    }

    ::-moz-selection {
        background: transparent;
        color: inherit;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    #root {
        width: 100%;
        height: 100%;
    }
    .bold,.ft-bold {
        font-family: 'NotoSansKR-Bold', sans-serif !important;
    }
    .medium,.ft-medium {
        font-family: 'NotoSansKR-Medium', sans-serif !important;
    }
    .regular,.ft-regular {
        font-family: 'NotoSansKR-Regular', sans-serif !important;
    }

    .ft-title {
        font-family: 'NotoSansKR-Bold', sans-serif;
        font-size:40px;
    }
    .ft-subtitle{
        font-family:'NotoSansKR-Bold', sans-serif;
        font-size:28px;
    }
    .ft-body{
        font-family:'NotoSansKR-Regular', sans-serif;
        font-size:24px;
    }

    .ft-popup-title {
        font-family: 'NotoSansKR-Bold', sans-serif;
        font-size:31px;
        line-height:1.2;
    }
    .ft-popup-body{
        font-family:'NotoSansKR-Medium', sans-serif;
        font-size:25px;
    }
    .ft-popup-caption{
        font-family:'NotoSansKR-Regular', sans-serif;
        font-size:20px;
    }
    .ft-popup-button{
        font-family:'NotoSansKR-Bold', sans-serif;
        font-size:24px;
    }

    ${media.large}{
        .ft-title {
        font-size:34px;
        }
        .ft-subtitle{
            font-size:24px;
        }
        .ft-body{
            font-size:20px;
        }
      
    }
    ${media.medium}{
        .ft-popup-title {
        font-family: 'NotoSansKR-Bold', sans-serif;
        font-size:19px;
        }
        .ft-popup-body{
            font-family:'NotoSansKR-Medium', sans-serif;
            font-size:15px;
        }
        .ft-popup-caption{
            font-family:'NotoSansKR-Regular', sans-serif;
            font-size:14px;
        }
        .ft-popup-button{
            font-family:'NotoSansKR-Bold', sans-serif;
            font-size:16px;
        }
    }
    ${media.small}{
        .ft-title {
        font-size:22px;
        }
        .ft-subtitle{
            font-size:20px;
        }
        .ft-body{
            font-size:18px;
        }
        
    }
    body {
        width: 100%;
        height: 100%;
        font-family: 'NotoSansKR-Medium', sans-serif;
        color: #545454;
        -webkit-text-size-adjust:none;
    }
   

  ${makeResponsiveFontSize()}
`;
export default GlobalStyle;
