const SET_CURRENT_FOCUS_INDEX = 'programPage/SET_CURRENT_FOCUS_INDEX';
const SET_NEED_PROGRAM_FOCUS = 'programPage/SET_NEED_PROGRAM_FOCUS';
const SET_FOCUS_AREA = 'programPage/SET_FOCUS_AREA';
const keyInfo = {
    [SET_CURRENT_FOCUS_INDEX]: 'currentFocusIndex',
    [SET_NEED_PROGRAM_FOCUS]: 'needProgramFocus',
    [SET_FOCUS_AREA]: 'focusArea'
};
const initialState = {
    [keyInfo[SET_CURRENT_FOCUS_INDEX]]: null,
    [keyInfo[SET_NEED_PROGRAM_FOCUS]]: false,
    [keyInfo[SET_FOCUS_AREA]]: 0
};
const makeNewState = (state, action, key) => ({ ...state, [key]: action[key] });
const actionCreator = type => data => ({ type: type, [keyInfo[type]]: data });

export const setCurrentFocusIndex = actionCreator(SET_CURRENT_FOCUS_INDEX);
export const setNeedProgramFocus = actionCreator(SET_NEED_PROGRAM_FOCUS);
export const setFocusArea = actionCreator(SET_FOCUS_AREA);

export default function programPage(state = initialState, action) {
    if (!keyInfo[action.type]) return state;
    return makeNewState(state, action, keyInfo[action.type]);
}
