import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as PanelBack } from '../../../assets/images/reward-heart/panel_back.svg';
import { ReactComponent as PanelImage } from '../../../assets/images/reward-heart/panel.svg';
import { ReactComponent as Left } from '../../../assets/images/reward-heart/image_1.svg';
import { ReactComponent as Right } from '../../../assets/images/reward-heart/image_2.svg';
import { ReactComponent as Board } from '../../../assets/images/reward-heart/Text_tablet.svg';
import { ReactComponent as ButtonOn } from '../../../assets/images/reward-heart/button_on.svg';
import { ReactComponent as HeartBlack } from '../../../assets/images/reward-heart/heart_Gray.svg';
import { ReactComponent as Information } from '../../../assets/images/reward-heart/icon.svg';
import Gauge from './Gauge';
import moment from 'moment';
import media, { breakPoints } from '../../../utils/media';
import SelectComponent from '../../../components/common/SelectComponent';
import SoundButton from '../../../components/SoundButton';
import theme from '../../../assets/styles/theme';
import AR from '../../../assets/texts/AR';
const Root = styled.div`
    width: 100%;
    max-width: ${breakPoints.large}px;
    display: flex;
    align-items: flex-end;
    transition: opacity 1s;
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
    ${media.xsmall} {
        min-height: unset;
    }
    @media (min-aspect-ratio: 12/7) {
        margin-top: 120px;
    }
`;
const Block = styled.div`
    position: relative;
    /* bottom: 0; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${breakPoints.large}px;
    /* min-width: 700px; */
    z-index: 110;
    ${media.xsmall} {
        min-width: unset;
    }
    & > svg {
        height: auto;
    }
    .absolute {
        position: absolute;
    }
    & > .panel-back {
        width: 100%;
        ${media.xsmall} {
            display: none;
        }
    }
    & > .panel-back-small {
        display: none;
        height: 300px;
        background: #dadce0;
        border-top: 15px solid #e8eaf2;
        ${media.xsmall} {
            display: block;
            width: 100%;
        }
    }
    & > .panel {
        width: 50%;
        bottom: 9%;
        ${media.xsmall} {
            width: 320px;
        }
    }
    .left,
    .right {
        width: 15%;
        margin-top: 6.5%;
        ${media.xsmall} {
            display: none;
        }
    }
    .left {
        left: 6%;
    }
    .right {
        right: 6%;
    }
    .board-container {
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -28.5%;
        ${media.xsmall} {
            width: 330px;
            top: -65px;
        }
    }
    .board {
        width: 100%;
        height: auto;
    }
    .heart {
        top: 40%;
        width: 2.5em;
    }
    .gauge {
        width: 30%;
        height: 20%;
        bottom: 15%;
        ${media.xsmall} {
            width: 200px;
            height: 40px;
        }
    }
    .button-on {
        width: 20%;
        bottom: 17%;
        cursor: pointer;
        ${media.xsmall} {
            width: 200px;
            height: 40px;
        }
    }
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: white;
        /* top: -26%; */
        flex-direction: column;
        ${media.xsmall} {
        }
    }
    .month-total {
        top: 27.6%;
        display: flex;
        width: 50%;
        justify-content: space-between;
        align-items: center;
        transform: translateY(-50%);
        .primary {
            color: ${theme.palette.primary};
        }
        .month-total-right {
            display: flex;
            align-items: center;
            & > div {
                margin-top: -2px;
            }
            .info {
                width: 1.5em;
                height: auto;
                cursor: pointer;
            }
            .black-heart {
                margin: 0 2px 0 10px;
            }
            .total {
                margin-right: 16px;
            }
        }
        ${media.xsmall} {
            width: 300px;
            top: 95px;
        }
    }
`;
export default function Panel({
    hide,
    yearMonth,
    onMonthSelect,
    onInformationButtonClick,
    totalHeartCount,
    monthList,
    heartCount,
    onRobotButtonClick,
    possibleRobotRewardCount,
    progress,
    travelEnd
}) {
    const [seeUniverse, setSeeUniverse] = useState();
    useEffect(() => {
        Array.from(document.getElementsByClassName('heart-texts')).forEach((el, i) => {
            el.textContent = heartCount[`ST.0${i + 1}`];
        });
    }, [heartCount]);
    const message = useMemo(() => {
        //하트 다모음
        if (progress === 100) return AR(4);
        //우주여행후
        if (travelEnd && seeUniverse) return AR(5);
        //90프로이상모음
        if (progress >= 90) return AR(3);
        return AR(13);
    }, [progress, travelEnd, seeUniverse]);
    useEffect(() => {
        const el = document.getElementById('button_on_text');
        if (!el) return;
        if (possibleRobotRewardCount > 1) {
            el.textContent = possibleRobotRewardCount;
        } else {
            el.textContent = null;
        }
    }, [possibleRobotRewardCount]);
    useEffect(() => {
        if (hide) setTimeout(setSeeUniverse, 2000, true);
    }, [hide]);
    return (
        <Root className={hide ? 'hide' : ''}>
            <Block>
                <PanelBack className="panel-back"></PanelBack>
                <div className="panel-back-small"></div>
                <PanelImage className="panel absolute" />
                <Left className="left absolute"></Left>
                <Right className="right absolute"></Right>
                <div className="absolute board-container">
                    <Board className="board"></Board>
                    <div className="title absolute h4">
                        {message.split('\n').map(el => (
                            <div key={el}>{el}</div>
                        ))}
                    </div>
                </div>

                {!!possibleRobotRewardCount && (
                    <SoundButton>
                        <ButtonOn onClick={onRobotButtonClick} className="button-on absolute"></ButtonOn>
                    </SoundButton>
                )}
                {!possibleRobotRewardCount && <Gauge progress={progress} className="gauge"></Gauge>}

                <div className="month-total h5 absolute">
                    <SelectComponent
                        onClick={onMonthSelect}
                        list={monthList}
                        selectIndex={monthList.findIndex(el => el.display === moment(yearMonth).format('YYYY.MM'))}
                        classes={{ select: 'primary' }}
                    ></SelectComponent>
                    <div className="month-total-right">
                        <div>{Number(yearMonth.slice(4, 6))}월 </div>
                        <HeartBlack className="black-heart"></HeartBlack>
                        <div className="total">{totalHeartCount}</div>
                        <Information className="info" onClick={onInformationButtonClick} />
                    </div>
                </div>
            </Block>
        </Root>
    );
}
