export default function CD(type, params = {}) {
    if (!type) return { title: '음?', body: '모르겠', buttons: ['확인'] };
    return [
        {
            title: '오늘은\n이만큼 얻었어요.',
            body: ['다음에 또 만나요!'],
            buttons: ['계속하기', '나가기'],
            src: 'CD1_re.mp3',
            robotSrc: 'CD1_character.svg'
        },
        { title: '아쉬워요!', body: '오늘의 체험학습 시간이 모두 끝났어요.\n다음에 또 만나요.', buttons: ['확인'] },
        { title: '축하해요!', body: '오늘 목표한 학습 시간을 달성했어요!', buttons: ['하트 획득'] },
        {
            title: '아쉬워요!',
            body: ['학습을 정말 그만할 거예요?', '다음에 또 만나요!'],
            buttons: ['계속하기', '그만하기'],
            src: 'CF1_re.mp3',
            robotSrc: 'CN6_character.svg'
        }
    ][type - 1];
}
