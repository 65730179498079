import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import media from '../../../utils/media';
import RaisedCard from '../../../components/RaisedCard';
import SoundButton from '../../../components/SoundButton';
import WithGlitter from '../../../components/WithGlitter';
import SVGIcon from '../../../components/SVGIcon';

const CardBlock = styled.div`
    width: 25%;
    padding-top: 25%;
    position: relative;
    cursor: pointer;
    pointer-events: ${props => props.pointerEvents};
    ${media.small} {
        width: 33.3333%;
        padding-top: 33.3333%;
    }
    ${media.xsmall} {
        width: 50%;
        padding-top: 50%;
    }
    & > .card {
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        position: absolute;
        border-radius: 1em;
        border-width: 0.3em;
        &.focus {
            border-width: 0.3em;
        }
        .main-container {
            padding: 1em 0 0 0.5em;
        }
    }
`;

const Circle = styled.div`
    width: 40%;
    height: 40%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 90%;
        height: auto;
    }
    .icon-off {
        display: none;
    }
    &.focus {
        .icon-off {
            display: inline;
        }
        .icon-active {
            display: none;
        }
    }
`;

const Title = styled.div`
    padding: 20% 5%;
    line-height: 1.2;
    &.white {
        color: white;
    }
`;

const MainCard = ({ info, onClick }) => {
    const [isFocus, setIsFocus] = useState(false);
    const backgroundColor = theme.palette.mainCardBg[info.status - 1];
    const borderTopColor = theme.palette.mainCardTopBorder[info.status - 1];
    const borderBottomColor = theme.palette.mainCardBotttomBorder[info.status - 1];
    const activeBackgroundColor = theme.palette.mainCardBgActive[info.status - 1];
    const activeBorderColor = theme.palette.mainCardBorderActive[info.status - 1];
    const pointerEvents = info.status === 4 ? 'none' : 'auto';
    return (
        <CardBlock className="h5" pointerEvents={pointerEvents}>
            <SoundButton>
                <RaisedCard
                    onClick={onClick.bind({}, info.code)}
                    backgroundColor={backgroundColor}
                    borderTopColor={borderTopColor}
                    borderBottomColor={borderBottomColor}
                    activeBackgroundColor={activeBackgroundColor}
                    activeBorderColor={activeBorderColor}
                    borderRadius={1}
                    onActiveStateChange={setIsFocus}
                    className="card"
                >
                    <WithGlitter glitterWidth={'1em'}>
                        <div className="main-container">
                            <Circle className={isFocus ? 'focus' : ''}>
                                <SVGIcon className="icon-active" src={`/status_${info.status}.svg`} />
                                <SVGIcon className="icon-off" src={`/status_${info.status}_off.svg`} />
                            </Circle>
                            <Title className={`bold ${isFocus ? 'white' : ''}`}>
                                <div>{info.name}</div>
                            </Title>
                        </div>
                    </WithGlitter>
                </RaisedCard>
            </SoundButton>
        </CardBlock>
    );
};

export default MainCard;
