import { soundPath } from '../../utils/util';

export default function AP(type, params = {}) {
    return [
        { text: `'{${params.name}}' 실력을 알아봐요.`, robotSrc: 'AT6.svg' },
        {
            text: [
                '다음 사항을 지켜 주세요.',
                '집중해서\n문제를 풀어요.',
                '스스로\n문제를 풀어요.',
                '여러 번 나누어\n풀어도 돼요.',
                '정답을 모를 경우\n물음표를 눌러요.'
            ].map((text, i) => ({ text, soundSrc: `${soundPath}/AP2-${i + 1}.mp3` })),
            robotSrc: 'AT6.svg'
        },
        { text: `'{${params.name}}' 실력을 알아보는 중이에요.`, robotSrc: 'AT1.svg' },
        { text: `'{${params.name}}' 학습을 해 봐요.`, robotSrc: 'AT6.svg' },
        { text: `'{${params.name}}' 학습을 하고 있어요.`, robotSrc: 'AT1.svg' },
        { text: `'{${params.name}}' 학습을 완료했어요.`, robotSrc: 'AD4.svg' },
        { text: `'{${params.name}}' 조금 더 학습이 필요해요. 학습을 완료해 봐요.`, robotSrc: 'AP7.svg' },
        { text: `'{${params.name}}' 학습을 다시 하면 잘 할 수 있어요. 지금 시작해 봐요.`, robotSrc: 'AP7.svg' },
        { text: `'{${params.name}}' 잘 알게 된 학습이에요. 다시 풀기를 할 수 있어요!`, robotSrc: 'AD4.svg' },
        { text: `'{${params.name}}' 실력 알아보기 문항을 확인해 봐요.`, robotSrc: 'AT6.svg' },
        { text: `'{${params.name}}' 실력을 알아보았어요.`, robotSrc: 'AD4.svg' }
    ][type - 1];
}
