import React from 'react';
import AD from '../../assets/texts/AD';
import MainLayout from '../../components/MainLayout';
import useSound from '../../hooks/useSound';
import { soundPath } from '../../utils/util';
import MainInfo from './components/MainInfo';
const txtObj = AD(5);
export default function MainInformationContainer() {
    useSound({ src: `${soundPath}/${txtObj.soundSrc}` });
    return (
        <MainLayout {...txtObj} message={txtObj.text[0]} back>
            <MainInfo></MainInfo>
        </MainLayout>
    );
}
