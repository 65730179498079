import React from 'react';
import styled from 'styled-components';

import MainHeader from './MainHeader';
const Block = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`;
const ContentBlock = styled.div`
    margin-top: -2rem;
    background: white;
    border-radius: 1.5em 1.5em 0 0;
    flex: 1;
    position: relative;
    z-index: 10;
    & > div {
        border-radius: 1.5em 1.5em 0 0;
        overflow-x: hidden;
        height: 100%;
    }
`;
export default function MainLayout({
    children,
    message,
    back,
    reward,
    logout,
    close,
    badge,
    robotType,
    report,
    wrongAnswerView,
    helpRequest,
    soundSrc,
    robotSrc
}) {
    return (
        <Block>
            <MainHeader
                {...{
                    robotSrc,
                    message,
                    back,
                    reward,
                    logout,
                    close,
                    badge,
                    robotType,
                    report,
                    wrongAnswerView,
                    helpRequest,
                    soundSrc
                }}
            ></MainHeader>
            <ContentBlock>
                <div>{children}</div>
            </ContentBlock>
        </Block>
    );
}
