import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ReactComponent as WrongAnswer } from '../assets/images/report/button_report.svg';
import { ReactComponent as Help } from '../assets/images/report/button_help.svg';
import { ReactComponent as Report } from '../assets/images/main/Main_button_1.svg';
import { ReactComponent as Reward } from '../assets/images/main/Main_button_2.svg';
import { ReactComponent as Logout } from '../assets/images/main/Main_button_3.svg';
import { ReactComponent as BackButton } from '../assets/images/back_white.svg';

import LogoutModal from './LogoutModal';
import * as coms from '../api/coms';
import { getRewardToday } from '../api/lms';
import theme from '../assets/styles/theme';
import media, { breakPoints } from './FrameLearning/util/media';
import usePromise from '../hooks/usePromise';
import { removeAccessToken } from '../utils/util';
import { setPreviousPage } from '../reducers/learningInfo';
import SoundButton from './SoundButton';
import SVGIcon from './SVGIcon';
import ModalBodyText from './ModalBodyText';
import useAchieveLearningTargetTimeBadge from '../hooks/useAchieveLearningTargetTimeBadge';
import Badge from './Badge';
import useLearningHelpBadge from '../hooks/useLearningHelpBadge';

const HeaderBlock = styled.div`
    flex-shrink: 0;
    background: ${theme.palette.primary};
    display: flex;
    justify-content: center;
    padding: 1rem 1.6rem 2rem 1.6rem;
    min-height: 168px;
    ${media.medium} {
        min-height: 158px;
    }
    ${media.small} {
        min-height: 148px;
    }
    ${media.xsmall} {
        min-height: 190px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: ${breakPoints.large}px;

        .back {
            align-self: flex-start;
            flex-shrink: 0;
            width: 2em;
            height: auto;
            cursor: pointer;
            ${media.xsmall} {
                align-self: center;
                height: 59.19px;
            }
        }
        .left {
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
    ${media.xsmall} {
        .container {
            flex-wrap: wrap;
            .left {
                width: 100%;
                order: 1;
                align-items: center;
            }
        }
    }
`;

const ImgBlock = styled.div`
    order: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    & > div {
        display: flex;
        align-items: center;
        position: relative;
    }
    svg {
        margin: 0 0.4em;
        cursor: pointer;
        width: 60px;
        height: auto;
        ${media.large} {
            width: 55px;
        }
        ${media.medium} {
            width: 50px;
        }
        ${media.small} {
            width: 45px;
        }
        ${media.xsmall} {
            width: 40px;
        }
    }
    ${media.xsmall} {
        flex: 1;
        padding: 0;
        align-self: flex-end;
        justify-content: flex-end;
    }
`;

const MessageBlock = styled.div`
    width: 100%;
    color: #fff;
    padding: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    font-size: 30px;
    word-break: keep-all;
    ${media.large} {
        font-size: 27px;
    }
    ${media.medium} {
        font-size: 24px;
    }
    ${media.small} {
        font-size: 22px;
    }
    ${media.xsmall} {
        font-size: 20px;
    }
    & > .img-container {
        flex-shrink: 0;
        align-self: flex-end;
        position: relative;
        height: 120px;
        width: 120px;
        margin-left: 2.5em;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        ${media.large} {
            margin-left: 2em;
        }
        ${media.medium} {
            height: 110px;
            width: 110px;
            margin-left: 1.5em;
        }
        ${media.small} {
            height: 100px;
            width: 100px;
            margin-left: 1em;
        }
        ${media.xsmall} {
            height: 90px;
            width: 90px;
            margin-left: 0.5em;
        }
        & > svg {
            position: absolute;
            height: 100%;
            width: auto;
            z-index: -1;
            &.AD1 {
                height: 130px;
                transform: translate(0, 10%);
                ${media.medium} {
                    height: 110px;
                }
                ${media.small} {
                    height: 100px;
                }
                ${media.xsmall} {
                    height: 90px;
                    transform: translate(-10%, 10%);
                }
            }
            &.AD4 {
                height: 145px;
                transform: translate(0, 5%);
                ${media.medium} {
                    height: 125px;
                }
                ${media.small} {
                    height: 115px;
                }
                ${media.xsmall} {
                    height: 105px;
                }
            }
            &.AP7 {
                height: 130px;
                transform: translate(0, 10%);
                ${media.medium} {
                    height: 110px;
                }
                ${media.small} {
                    height: 100px;
                }
                ${media.xsmall} {
                    height: 90px;
                    transform: translate(-10%, 10%);
                }
            }
            &.AT1 {
                height: 125px;
                transform: translate(-18%, 10%);
                ${media.medium} {
                    height: 105px;
                }
                ${media.small} {
                    height: 95px;
                    transform: translate(-10%, 10%);
                }
                ${media.xsmall} {
                    height: 85px;
                    transform: translate(-15%, 10%);
                }
            }
            &.AT6 {
                height: 140px;
                transform: translate(0, 15%);
                ${media.medium} {
                    height: 120px;
                }
                ${media.small} {
                    height: 110px;
                }
                ${media.xsmall} {
                    height: 100px;
                }
            }
        }
    }

    & > .message {
        margin-left: 20px;
        margin-top: 0.5rem;
        line-height: 1.3;
        flex: 1;
        & > div {
            align-items: flex-start;
            & > div {
                width: 100%;
            }
        }
        ${media.large} {
            margin-left: 18px;
        }
        ${media.medium} {
            margin-left: 16px;
        }
        ${media.small} {
            margin-left: 14px;
        }
        ${media.xsmall} {
            margin-left: 0px;
        }
    }
`;

function MainHeader({ message, back, reward, logout, badge = 0, report, wrongAnswerView, helpRequest, robotSrc }) {
    const history = useHistory();
    const [modalInfo, setModalInfo] = useState({ show: false, data: null });
    const [error, setError] = useState(null);
    const { isAchieveLearningTargetTime } = useAchieveLearningTargetTimeBadge(!!report);
    const { isLearningHelpBadge } = useLearningHelpBadge(report || helpRequest);
    const onBackButtonClick = () => {
        if (back instanceof Function) {
            back();
        } else history.replace('/main');
    };
    const rewardToday = usePromise(getRewardToday, !logout);
    const onRewardClick = () => history.replace('/reward-heart');
    const onLogoutClick = async () => {
        setModalInfo(state => ({ ...state, show: true }));
    };

    const onReportClick = () => history.replace('/report');
    const onModalClick = async type => {
        setModalInfo(state => ({ ...state, show: false }));
        if (type) {
            try {
                await coms.postSignOut();
                removeAccessToken();
                history.replace('/sign-in');
            } catch (e) {
                setError(e);
            }
        }
    };
    const onHelpRequestClick = () => {
        setPreviousPage(window.location.pathname);
        history.push('/help-request');
    };
    const onWrongAnswerClick = () => {
        setPreviousPage(window.location.pathname);
        history.push('/wrong-answer');
    };
    const rightIcons = report || reward || logout || wrongAnswerView || helpRequest;
    useEffect(() => {
        setModalInfo(state => ({ ...state, data: rewardToday.data }));
    }, [rewardToday.data]);
    if (error) throw error;

    return (
        <HeaderBlock>
            <div className="container">
                {back && <BackButton onClick={onBackButtonClick} className="back h4"></BackButton>}
                <div className="left">
                    <MessageBlock>
                        <div className="img-container">
                            <SVGIcon
                                className={robotSrc && robotSrc.replace('.svg', '')}
                                src={robotSrc && `/character/${robotSrc}`}
                            ></SVGIcon>
                        </div>
                        <div className="message">
                            <ModalBodyText text={message || ''} align="left"></ModalBodyText>
                        </div>
                    </MessageBlock>
                </div>
                {rightIcons && (
                    <ImgBlock className="h4">
                        {report && (
                            <Badge show={isAchieveLearningTargetTime || isLearningHelpBadge}>
                                <SoundButton>
                                    <Report onClick={onReportClick}></Report>
                                </SoundButton>
                            </Badge>
                        )}
                        {reward && (
                            <Badge show={!!badge}>
                                <SoundButton>
                                    <Reward onClick={onRewardClick}></Reward>
                                </SoundButton>
                            </Badge>
                        )}
                        {logout && (
                            <SoundButton>
                                <Logout onClick={onLogoutClick}></Logout>
                            </SoundButton>
                        )}
                        {wrongAnswerView && (
                            <SoundButton>
                                <WrongAnswer onClick={onWrongAnswerClick}></WrongAnswer>
                            </SoundButton>
                        )}
                        {helpRequest && (
                            <Badge show={isLearningHelpBadge}>
                                <SoundButton>
                                    <Help onClick={onHelpRequestClick}></Help>
                                </SoundButton>
                            </Badge>
                        )}
                    </ImgBlock>
                )}
            </div>
            {modalInfo.show && <LogoutModal onModalClick={onModalClick} data={modalInfo.data}></LogoutModal>}
        </HeaderBlock>
    );
}

export default MainHeader;
