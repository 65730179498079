import { Fragment } from 'react';
import styled from 'styled-components';
import media from '../utils/media';

const Block = styled.div`
    svg {
        /* margin: 84px 0.25em; */
        width: 85px;
        height: auto;
        margin: 50px 0px 35px;
    }
    .heart-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
            margin: 50px 0 10px;
        }
        .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0 30px;
        }
    }
    .only-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 0 50px;
    }
    ${media.medium} {
        svg {
            margin: 45px 0 30px;
            width: 55px;
            height: auto;
        }
        .heart-container {
            svg {
                margin: 45px 0 10px 0;
            }
            .text {
                margin: 0 0 20px;
            }
        }
        .only-text {
            padding: 40px 0 30px;
        }
    }
`;
export default function SimpleRobotModalBody({ img, text, imgCount }) {
    return (
        <Block>
            {imgCount && text && (
                <div className="heart-container">
                    <div>
                        {[...Array(imgCount).keys()].map(el => (
                            <Fragment key={el}>{img}</Fragment>
                        ))}
                    </div>
                    <div className="ft-popup-body text">
                        {text.split('\n').map(el => (
                            <div key={el}>{el}</div>
                        ))}
                    </div>
                </div>
            )}
            {imgCount && !text && [...Array(imgCount).keys()].map(el => <Fragment key={el}>{img}</Fragment>)}
            {!imgCount && text && (
                <div className="only-text ft-popup-body">
                    {text.split('\n').map(el => (
                        <div key={el}>{el}</div>
                    ))}
                </div>
            )}
        </Block>
    );
}
