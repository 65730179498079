import React, { useMemo, useState } from 'react';
import * as lms from '../../api/lms';
import Loading from '../../components/common/Loading';
import HelpList from './components/HelpList';
import usePromise from '../../hooks/usePromise';
import moment from 'moment';
import TitleCalendarHeader from '../../components/TitleCalendarHeader';
import { useHistory } from 'react-router-dom';
import MainLayout from '../../components/MainLayout';
import AT from '../../assets/texts/AT';
export default function HelpRequestContainer() {
    const history = useHistory();
    const [openIndex, setOpenIndex] = useState(null);
    const [startDate, setStartDate] = useState(moment().subtract(30, 'day').format('YYYYMMDD'));
    const [endDate, setEndDate] = useState(moment().format('YYYYMMDD'));
    const [opened, setOpened] = useState({});
    const { loading: loading1, data, error: error1 } = usePromise(lms.getLearningHelp, false, { startDate, endDate });
    const mappingListData = useMemo(() => {
        return data?.map((el, i) => {
            if (el.help_check_status_code === '2' && opened[i]) {
                return { ...el, help_check_status_code: '3' };
            }
            return el;
        });
    }, [data, opened]);
    const {
        data: learningHelpDetail,
        refresh: getHelpDetail,
        loading: loading2,
        error: error2
    } = usePromise(lms.getHelpDetail, true);
    const { refresh: updateMemoPath, loading: loading3, error: error3 } = usePromise(lms.postHelpMemoPath, true);
    const onBack = () => history.replace('/report');
    const message = useMemo(() => {
        if (!data) return '';
        if (data.length < 1) return '문의한 내용이 없어요.';
        return AT(7);
    }, [data]);
    const error = error1 || error2 || error3;
    const loading = loading1 || loading2 || loading3;
    const handleOpenIndexChange = async i => {
        setOpenIndex(i);

        if (mappingListData[i]?.help_check_status_code === '2') {
            console.log(mappingListData[i].help_history_date_time);
            await lms.postHelpChecked({ helpHistoryDateTime: mappingListData[i].help_history_date_time });
            setOpened(p => ({ ...p, [i]: true }));
        }
    };
    if (error) throw error;
    return (
        <MainLayout back={onBack} message={message} robotSrc="AT6.svg">
            <TitleCalendarHeader
                title="내가 문의한 내역"
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            ></TitleCalendarHeader>
            {data && (
                <HelpList
                    learningHelpDetail={learningHelpDetail}
                    getHelpDetail={getHelpDetail}
                    updateMemoPath={updateMemoPath}
                    data={mappingListData}
                    openIndex={openIndex}
                    setOpenIndex={handleOpenIndexChange}
                ></HelpList>
            )}
            {loading && <Loading></Loading>}
        </MainLayout>
    );
}
