import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../assets/styles/theme';
import useSound from '../../hooks/useSound';
import media from '../../utils/media';
import Button from './Button';
import ModalContainer from './ModalContainer';

const fadein = keyframes`
    from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;
const Block = styled.div`
    animation: ${fadein} ease-in-out 0.3s;
    word-break: keep-all;
    /* min-width: 634px; */
    &.primary {
        background: ${theme.palette.primary};
    }
    &.attend {
        background: ${theme.palette.attendModalTitle};
    }
    &.teacher {
        background: ${theme.palette.teacherHeart};
        & > .bottom {
            & > .btn {
                border-color: ${theme.palette.teacherHeart};
                color: ${theme.palette.teacherHeart};
            }
        }
    }
    border-radius: ${theme.modalRadius};
    margin: auto;
    display: flex;
    flex-direction: column;
    .title {
        display: flex;
        min-height: 100px;
        color: ${theme.palette.white};
        padding-left: 25px;
    }
    .body {
        flex: 1;
        position: relative;
        z-index: 100;
        background: ${theme.palette.white};
        border-radius: ${theme.modalRadius} ${theme.modalRadius} 0 0;
        display: flex;
        justify-content: center;
    }
    .bottom {
        padding-bottom: 36px;
        background: ${theme.palette.white};
        border-radius: 0 0 ${theme.modalRadius} ${theme.modalRadius};
        display: flex;
        justify-content: center;
    }
    ${media.medium} {
        /* min-width: 364px; */
        .title {
            min-height: 58px;
            padding-left: 20px;
        }
        .bottom {
            padding-bottom: 20px;
        }
    }
`;

const CustomButton = styled(Button)`
    padding: 0.25em 1em;
    & + & {
        margin-left: 26px;
    }

    height: 50px;
    border-radius: 28px;
    &.button-count-1 {
        width: 210px;
    }
    &.button-count-2 {
        width: 200px;
    }
    ${media.medium} {
        & + & {
            margin-left: 15px;
        }
        height: 32px;
        border-radius: 16px;
        &.button-count-1 {
            width: 132px;
        }
        &.button-count-2 {
            width: 130px;
        }
    }
`;

export default function Dialog({ title, body, buttons, color = 'primary', src, onClose, overflow }) {
    useSound({ src: `/sounds/${src}` });
    return (
        <ModalContainer onClick={onClose} overflow={overflow}>
            <Block className={color} onClick={e => e.stopPropagation()}>
                {title && <div className="title ft-popup-title">{title}</div>}
                {body && <div className="body ft-popup-body">{body}</div>}
                {buttons && (
                    <div className="bottom ft-popup-button">
                        {buttons.map((el, i) => (
                            <CustomButton
                                className={`bold btn button-count-${buttons.length}`}
                                key={i}
                                type={0}
                                text={el.text}
                                onClick={el.onClick}
                            />
                        ))}
                    </div>
                )}
            </Block>
        </ModalContainer>
    );
}
