import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BackImg } from '../../../assets/images/sign-in/BG_Day.svg';
import { version } from '../../../utils/dataMap';
import media from '../../../utils/media';
const Block = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100%;
    max-height: 100%;
    position: fixed;
    padding: 1.6rem;
    ${media.xsmall} {
        flex-direction: column;
    }
`;
const Back = styled(BackImg)`
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
`;
const Version = styled.div`
    position: fixed;
    right: 1rem;
    bottom: 0.5rem;
    color: white;
    opacity: 0.7;
    ${media.xsmall} {
        font-size: 14px;
    }
`;
function Layout({ children }) {
    return (
        <Block>
            <Back preserveAspectRatio="xMidYMid slice" />
            {children}
            <Version>{version}</Version>
        </Block>
    );
}

export default Layout;
