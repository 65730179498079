import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import bg from '../../../assets/images/reward-items/bg.svg';
import { ReactComponent as Close } from '../../../assets/images/reward-heart/button_Exit.svg';
import { ReactComponent as Cockpit } from '../../../assets/images/reward-items/button_cockpit.svg';
import { ReactComponent as Spaceship } from '../../../assets/images/reward-items/icon_spaceship.svg';
import { ReactComponent as Star } from '../../../assets/images/reward-items/icon_star.svg';
import { ReactComponent as Ufo } from '../../../assets/images/reward-items/icon_ufo.svg';
import { ReactComponent as Information } from '../../../assets/images/reward-items/icon2.svg';
import { breakPoints } from '../../../utils/media';
import theme from '../../../assets/styles/theme';
import SoundButton from '../../../components/SoundButton';
import useRewardPageTransition from '../../../hooks/useRewardPageTransition';
import { soundPath } from '../../../utils/util';
import useSound from '../../../hooks/useSound';

const sideIcons = [<Star />, <Spaceship />, <Ufo />];
const fadeIn = keyframes`
    from{
        opacity:0.3
    }
    to{
        opacity:1
    }
`;
const Root = styled.div`
    overflow: hidden;
    &.anim {
        animation: ${fadeIn} 1s linear;
    }
`;
const Block = styled.div`
    & > .back {
        z-index: -1;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        position: absolute;
        background-image: url('${bg}');
        background-size: cover;
    }
    background: black;
    background-size: cover;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 1.6rem;

    color: ${theme.palette.textSpace};
`;
const HeaderBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: ${breakPoints.large}px;
    width: 100%;
    padding: 1.6rem;
    margin: 0 auto;
    & > svg {
        z-index: 1;
        cursor: pointer;
        width: auto;
        height: 3em;
    }
    &.hide {
        opacity: 0;
    }
`;
const MainBlock = styled.div`
    width: 100%;
    max-width: ${breakPoints.large}px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    color: #6ecbfa;
    margin: 0 auto;

    & > .side {
        position: absolute;
        top: 0;
        right: 1.6rem;
        max-width: ${breakPoints.large}px;
        & > div {
            display: flex;
            align-items: center;
            margin: 0.5em 0;
            height: 2em;
        }
        .text {
            margin-bottom: -0.3em;
            display: inline-block;
            width: 2.5em;
            text-align: center;
        }
        .info-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 1.5em;
            svg {
                cursor: pointer;
                height: 2em;
                width: auto;
            }
        }
    }
`;
const PreventBlock = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 101;
`;
export default function Layout({ preventEvent, isRewardGet, children, rewardSum, onInfoClick }) {
    useSound({ src: !isRewardGet && `${soundPath}/OS4_ver1_re.mp3`, autoPlay: true });
    const { rewardPageTransition, setTransitionState } = useRewardPageTransition();
    const history = useHistory();
    const onClose = () => {
        history.push('/main');
        setTransitionState(false);
    };
    const onCockpit = () => {
        history.push('/reward-heart');
        setTransitionState(true);
    };
    return (
        <Root className={rewardPageTransition ? 'anim' : ''}>
            {preventEvent && <PreventBlock></PreventBlock>}
            <Block>
                <div className="back"></div>
            </Block>
            <HeaderBlock className={`h6 ${preventEvent ? 'hide' : ''}`}>
                <SoundButton>
                    <Close onClick={onClose}></Close>
                </SoundButton>
                <SoundButton>
                    <Cockpit onClick={onCockpit}></Cockpit>
                </SoundButton>
            </HeaderBlock>
            <MainBlock className="main">
                {children}
                {!!rewardSum && (
                    <div className="side h5">
                        {sideIcons.map((el, i) => (
                            <div key={i}>
                                <span className="text">{rewardSum[`RW.0${i + 2}`] || 0}</span>
                                {el}
                            </div>
                        ))}
                        <div className="info-container">
                            <Information onClick={onInfoClick}></Information>
                        </div>
                    </div>
                )}
            </MainBlock>
        </Root>
    );
}
