import React from 'react';
import ModalBodyText from '../../../components/ModalBodyText';
import { ReactComponent as Star } from '../../../assets/images/icon-anim/CF15_icon.svg';
import { ReactComponent as ShootingStar } from '../../../assets/images/icon-anim/CF27_icon.svg';
import { ReactComponent as Ufo } from '../../../assets/images/icon-anim/CF29_icon.svg';
import { ReactComponent as Rocket } from '../../../assets/images/icon-anim/CF18_icon.svg';
import { ReactComponent as Planet1 } from '../../../assets/images/icon-anim/CF30_1_icon.svg';
import { ReactComponent as Planet2 } from '../../../assets/images/icon-anim/CF30_2_icon.svg';
import { ReactComponent as Planet3 } from '../../../assets/images/icon-anim/CF30_3_icon.svg';
import { ReactComponent as Planet4 } from '../../../assets/images/icon-anim/CF30_4_icon.svg';
import { ReactComponent as Planet5 } from '../../../assets/images/icon-anim/CF30_5_icon.svg';
import { ReactComponent as Planet6 } from '../../../assets/images/icon-anim/CF30_6_icon.svg';
import { ReactComponent as Planet7 } from '../../../assets/images/icon-anim/CF30_7_icon.svg';
import { ReactComponent as Planet8 } from '../../../assets/images/icon-anim/CF30_8_icon.svg';
import { ReactComponent as Planet9 } from '../../../assets/images/icon-anim/CF30_9_icon.svg';
import { ReactComponent as Planet10 } from '../../../assets/images/icon-anim/CF30_10_icon.svg';
import { ReactComponent as CS01 } from '../../../assets/images/icon-anim/CF16-1_icon.svg';
import { ReactComponent as CS02 } from '../../../assets/images/icon-anim/CF16-2_icon.svg';
import { ReactComponent as CS03 } from '../../../assets/images/icon-anim/CF16-3_icon.svg';
import { ReactComponent as CS04 } from '../../../assets/images/icon-anim/CF16-4_icon.svg';
import { ReactComponent as CS05 } from '../../../assets/images/icon-anim/CF16-5_icon.svg';
import { ReactComponent as CS06 } from '../../../assets/images/icon-anim/CF16-6_icon.svg';
import { ReactComponent as CS07 } from '../../../assets/images/icon-anim/CF16-7_icon.svg';
import { ReactComponent as CS08 } from '../../../assets/images/icon-anim/CF16-8_icon.svg';
import { ReactComponent as CS09 } from '../../../assets/images/icon-anim/CF16-9_icon.svg';
import { ReactComponent as CS10 } from '../../../assets/images/icon-anim/CF16-10_icon.svg';
import { ReactComponent as CS11 } from '../../../assets/images/icon-anim/CF16-11_icon.svg';
import { ReactComponent as CS12 } from '../../../assets/images/icon-anim/CF16-12_icon.svg';
import { ReactComponent as Complete } from '../../../assets/images/reward-items/Graduationcap.svg';
import CF from '../../../assets/texts/CF';
import ModalRobot from '../../../components/common/ModalRobot';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import media from '../../../utils/media';
const rewardImgs = {
    'RW.01': <Star className="star" />,
    'RW.02': <ShootingStar className="items" />,
    'RW.03': <Rocket className="items" />,
    'RW.04': <Ufo className="ufo items" />,
    'RW.10': <Planet1 className="planet" />,
    'RW.11': <Planet2 className="planet" />,
    'RW.12': <Planet3 className="planet" />,
    'RW.13': <Planet4 className="planet" />,
    'RW.14': <Planet5 className="planet" />,
    'RW.15': <Planet6 className="planet" />,
    'RW.16': <Planet7 className="planet" />,
    'RW.17': <Planet8 className="planet" />,
    'RW.18': <Planet9 className="planet" />,
    'RW.19': <Planet10 className="planet" />,
    'CS.01': <CS01 className="cs" />,
    'CS.02': <CS02 className="cs" />,
    'CS.03': <CS03 className="cs" />,
    'CS.04': <CS04 className="cs" />,
    'CS.05': <CS05 className="cs" />,
    'CS.06': <CS06 className="cs" />,
    'CS.07': <CS07 className="cs" />,
    'CS.08': <CS08 className="cs" />,
    'CS.09': <CS09 className="cs" />,
    'CS.10': <CS10 className="cs" />,
    'CS.11': <CS11 className="cs" />,
    'CS.12': <CS12 className="cs" />,
    complete: <Complete className="complete" />
};
const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.palette.text};
    padding: 0 8px;
    width: 480px;
    &.with-title {
        /* height: 450px; */
    }
    &.no-image {
        justify-content: center;
    }
    ${media.medium} {
        width: 300px;
        &.with-title {
            /* height: 260px; */
        }
    }
    svg {
        margin-bottom: 1em;
        height: 10em;
        width: auto;
        max-width: 400px;
        &.shooting-star {
            height: 5em;
        }
        &.star {
            height: 243.089px;
            margin: 50px 0 0 0;
            ${media.medium} {
                margin: 30px 0 0 0;
                height: 140px;
            }
        }

        &.cs {
            width: 360px;
            height: auto;
            margin: 0;
            margin: 0 0 -30px;
            ${media.medium} {
                margin: 0 0 -20px;
                height: 210px;
            }
        }
        &.items {
            width: 254px;
            height: 146.942px;
            margin: 60px 0 0 0;
            ${media.medium} {
                margin: 34px 0 0 0;
                width: 146px;
                height: 84.463px;
            }
        }
        &.ufo {
            width: 210px;
            height: auto;
            ${media.medium} {
                width: 130px;
            }
        }
        &.planet {
            width: 210px;
            height: auto;
            margin: 25px 0 -30px;

            ${media.medium} {
                margin: 15px 0 -20px;
                width: 130px;
            }
        }
        &.complete {
            width: 210px;
            height: auto;
            margin: 60px 0 0 0;

            ${media.medium} {
                margin: 40px 0 0 0;
                width: 130px;
            }
        }
    }
    .body-text-container {
        margin: 50px 0;
        ${media.medium} {
            margin: 30px 0;
        }
    }
`;
export default function RewardModal({ txtType, type, params, onClick }) {
    const txtObj = CF(txtType, params);
    return (
        <ModalRobot
            {...{ ...txtObj }}
            title={txtObj.title}
            src={txtObj.src}
            body={
                <Body className={`${txtObj.title ? 'with-title' : ''}${rewardImgs[type] ? '' : ' no-image'}`}>
                    {rewardImgs[type]}
                    <div className="body-text-container">
                        <ModalBodyText text={txtObj.body}></ModalBodyText>
                    </div>
                </Body>
            }
            buttons={txtObj.buttonTexts.map(el => ({ text: el, onClick }))}
        ></ModalRobot>
    );
}
