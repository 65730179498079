import * as lms from '../api/lms';
import { createPromiseThunk, createInitThunk, handleAsyncActions } from './asyncUtils';

const GET_LEARNER_INFO = 'learnerInfo/GET_LEARNER_INFO';

export const getLearnerInfo = createPromiseThunk(GET_LEARNER_INFO, lms.getLearnerInfo);
export const initLearnerInfo = createInitThunk(GET_LEARNER_INFO);

const learnerInfo = handleAsyncActions(GET_LEARNER_INFO);
export default learnerInfo;
