export default function CR(type) {
    return [
        {
            title: '하트를 모두 모았네요.',
            body: '신비한 우주의 모습을 볼까요?',
            buttons: ['다음에 보기', '지금 보기'],
            src: 'CR1.mp3',
            robotSrc: 'CN2-1_character.svg'
        },
        {
            title: '선생님이 보내 주신\n하트가 도착했어요.',
            buttons: ['하트 획득'],
            src: 'CR2_re.mp3',
            robotSrc: 'CN1-1_character.svg'
        },
        {
            title: '하트 받는 방법을\n알아볼까요?',
            titles: ['출석', '도전', '습관', '칭찬'],
            body: [
                ['초록 하트 1개: 1일 출석했을 때', '초록 하트 2개: 3일 연속 출석했을 때'],
                ['노란 하트 1개: 연산 실력이 한 단계', '올라갔을 때', '노란 하트 2개: 연산 실력의 달인이', '되었을 때'],
                ['파란 하트 1-3개: 1일 학습 목표 시간을', '달성했을 때'],
                ['빨간 하트 1-5개: 선생님께서 칭찬 하트를', '보내 주실 때']
            ],
            buttons: ['확인'],
            src: 'CR3_re.mp3',
            robotSrc: 'CD1_character.svg'
        },
        {
            title: '우주여행 방법을\n알아봐요!',
            titles: ['별', '유성', '로켓', '비행접시', '행성'],
            body: [
                '정답 수만큼 받은 별을 모아\n별자리를 완성해요!',
                '학습하여 잘 알게 되면\n유성이 떨어져요!',
                '실력 알아보기를 통과하면\n로켓으로 여행할 수 있어요!',
                '학습 내용을 모두 잘 알게 되면\n비행접시로 여행할 수 있어요!',
                '등급을 통과하면\n새로운 행성을 여행할 수 있어요!',
                '비행접시와 로켓을 타고 행성으로 우주여행을 떠나 볼까요?'
            ],
            src: 'CR4_re.mp3',
            buttons: ['확인'],
            robotSrc: 'CD1_character.svg'
        }
    ][type - 1];
}
