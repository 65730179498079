import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
    setCurriculumCode,
    setCurriculumName,
    setPreviousPage,
    setProgram,
    setGradeCode,
    setReportLearningBack
} from '../../../reducers/learningInfo';
import media from '../../../utils/media';
import Loading from '../../../components/common/Loading';

import { ReactComponent as Next } from '../../../assets/images/report/next.svg';
import useHabitState from '../../../hooks/useHabitState';
import useLearnerInfo from '../../../hooks/useLearnerInfo';

const ListBlock = styled.div`
    max-width: 1200px;
    margin: 0px auto;
    padding: 0em 1.6em;
`;
const ListItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0.8em 1.6em;
    .date {
        width: 10%;
        min-width: 3em;
        color: #888888;
        margin-bottom: 0.2em;
    }
    .name {
        color: #444444;
    }
    .info {
        width: 90%;
        ${media.xsmall} {
            width: 100%;
        }
        & > div:nth-of-type(2) {
            min-width: 5em;
        }
    }
    .school {
        display: flex;
        color: #b5b5b6;
        flex-wrap: wrap;
        margin-top: 0.2em;
        font-size: 0.9em;
        ${media.small} {
            display: block;
        }
        div > div {
            margin-top: 0.2em;
            padding: 0 0.5em;
            ${media.small} {
                margin-top: 0.2em;
            }
        }
    }
    .code {
        border: 1px solid #b5b5b6;
        border-radius: 0.2em;
        min-width: 2.7em;
        text-align: center;
    }
    .code_name {
        border: 1px solid transparent;
    }
    .view {
        min-width: 65px;
        color: #22bfbf;
    }
    .next {
        margin-left: 0.5em;
        position: relative;
        top: 0.05em;
        width: 0.4em;
        height: auto;
    }
    .next0 {
        fill: #22bfbf;
    }
    .timeout {
        pointer-events: none;
    }
    .timeout > .view {
        color: lightgray;
    }
    .timeout .next0 {
        fill: lightgray;
    }
`;

const NoHistoryBlock = styled.div`
    width: 100%;
    text-align: center;
    padding: 1em 0em;
    color: #444444;
`;

const getSchool = data => {
    let school = data.school_progress_codes.map((el, i) => {
        return { code: el, name: data.school_progress_names[i] };
    });
    const result = school.filter((el, i) => {
        const index = school.findIndex(el2 => el2.code === el.code);
        return index === i;
    });
    return result;
};

const NoHistory = ({ message }) => (
    <NoHistoryBlock className="h5">
        <div>{message}</div>
    </NoHistoryBlock>
);

const DiagnosisList = ({ url, grade, domain, status, diagnosisHistory, dispatch, history }) => {
    if (diagnosisHistory.length === 0) return <NoHistory message="실력 알아보기 결과가 없어요." />;
    const list = status !== '0' ? diagnosisHistory.concat().reverse() : diagnosisHistory.concat();    

    return list.map((el, i) => (
        <div key={i}>
            <ListItem className="h5">
                {el.last_learning_date && status !== '0' && (
                    <div className="date">
                        {el.last_learning_date.substring(4, 6) + '.' + el.last_learning_date.substring(6, 8)}
                    </div>
                )}
                <div className="info flex align-center justify-between">
                    <div>
                        <div className="name">{el.curriculum_name}</div>
                        {el.school_progress_codes && (
                            <div className="school">
                                {getSchool(el).map((el, i) => (
                                    <div className="flex align-center" key={i}>
                                        <div className="code">
                                            {`${Number(el.code.substring(0, 2))}-${Number(el.code.substring(2, 3))}`}
                                        </div>
                                        <div className="code_name">
                                            {`${Number(el.code.substring(3, 5))}. ${el.name}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {status !== '0' && (
                        <div
                            className="flex align-center cursor-pointer"
                            onClick={() => {
                                dispatch(setGradeCode(grade));
                                dispatch(setProgram({ programName: el.curriculum_name }));
                                dispatch(setCurriculumCode(el.curriculum_code));
                                dispatch(setPreviousPage(`/report/${url[4]}/${domain}/${status}/${grade}`));
                                history.replace('/diagnosis-question');
                            }}
                        >
                            <div className="view">문항 보기</div>
                            <Next className="next" />
                        </div>
                    )}
                </div>
            </ListItem>
            <hr />
        </div>
    ));
};

const ProgramList = ({
    url,
    grade,
    programHistory,
    domain,
    status,
    dispatch,
    history,
    curriculumInfos,
    habitState,
    timeout
}) => {
    if (programHistory.length === 0) return <NoHistory message="다시 학습해야 하는 내용이 없어요." />;
    if (!habitState) return <Loading />;
    return programHistory
        .concat()
        .reverse()
        .map((el, i) => (
            <div key={i}>
                <ListItem className="h5">
                    <div className="date">
                        {el.last_learning_date.substring(4, 6) + '.' + el.last_learning_date.substring(6, 8)}
                    </div>
                    <div className="info flex align-center justify-between">
                        <div>
                            <div className="name">{el.program_name}</div>
                            {el.school_progress_code && (
                                <div className="school">
                                    <div className="flex align-center">
                                        <div className="code">{`${Number(
                                            el.school_progress_code.substring(0, 2)
                                        )}-${Number(el.school_progress_code.substring(2, 3))}`}</div>
                                        <div className="code_name">{`${Number(
                                            el.school_progress_code.substring(3, 5)
                                        )}. ${el.school_progress_name}`}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`flex align-center cursor-pointer ${timeout ? 'timeout' : ''}`}
                            onClick={() => {
                                const curriculumName = curriculumInfos.find(el2 => el2.code === el.curriculum_code);
                                dispatch(setGradeCode(grade));
                                dispatch(setProgram({ programId: el.program_id, programName: el.program_name }));
                                dispatch(setCurriculumCode(el.curriculum_code));
                                dispatch(setPreviousPage(`/report/${url[5]}/${domain}/${status}/${grade}`));
                                dispatch(setCurriculumName(curriculumName.name));
                                dispatch(setReportLearningBack(true));
                                history.replace('/learning');
                            }}
                        >
                            <div className="view">학습하기</div>
                            <Next className="next" />
                        </div>
                    </div>
                </ListItem>
                <hr />
            </div>
        ));
};

export default function DetailListComponent({
    url,
    viewType,
    detail,
    grade,
    status,
    domain,
    diagnosisHistory,
    programHistory,
    listLoading,
    curriculumInfos
}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [timeout, setTimeout] = useState(false);
    const learningInfo = useSelector(state => state.learningInfo);
    const { habitState, getHabitState } = useHabitState();
    const { data: learnerInfo } = useLearnerInfo();
    const { subject_status_code } = learnerInfo;

    useEffect(() => {
        if (!habitState) getHabitState();
        else {
            if (
                habitState.result !== 'belowStandards' &&
                (subject_status_code === '0' || subject_status_code === '1')
            ) {
                setTimeout(true);
                if (learningInfo.reportLearningBack) {
                    dispatch(setReportLearningBack(false));
                    history.push('/main');
                }
            }
        }
    }, [habitState, getHabitState, learningInfo, subject_status_code, history, dispatch]);

    if (listLoading) return <Loading />;
    return (
        <ListBlock>
            {viewType + '/' + detail === url[4] && (
                <DiagnosisList
                    url={url}
                    grade={grade}
                    diagnosisHistory={diagnosisHistory}
                    domain={domain}
                    status={status}
                    dispatch={dispatch}
                    history={history}
                />
            )}
            {viewType + '/' + detail === url[5] && (
                <ProgramList
                    url={url}
                    grade={grade}
                    programHistory={programHistory}
                    domain={domain}
                    status={status}
                    dispatch={dispatch}
                    history={history}
                    curriculumInfos={curriculumInfos}
                    learnerInfo={learnerInfo}
                    habitState={habitState}
                    timeout={timeout}
                />
            )}
        </ListBlock>
    );
}
