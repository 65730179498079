import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import SVGIcon from '../../../components/SVGIcon';

const Block = styled.div`
    position: absolute;
    width: 100%;
    padding-bottom: 63.22%;
    height: 0;
    .cs-svg {
        height: auto;
        position: absolute;
        width: 100%;
        height: 100%;
        .el {
            fill: ${theme.palette.white};
        }
        .el.line {
            opacity: 0.7;
        }
        .el.fill {
            fill: #ffd215;
        }
        .el.line.fill {
            fill: #f9e48c;
            opacity: 1;
        }
        &.fire {
            transform: translate(0, 30%) scale(3);
            &.special {
                transform: translate(30%, 30%) scale(3);
            }
        }
        &.anim-cs {
            transform: scale(1.3);
        }
        &.star-1 {
            transform: scale(2.2);
        }
    }
`;
export default function RewardConstellationStar({ starCount, index, check, constellationAnim, starAnim }) {
    const [fireTime, setFireTime] = useState(false);
    const timer = useRef();
    const handleReady = () => {
        Array.from(document.querySelectorAll('.el')).forEach(el => {
            if (el.dataset.count <= starCount || !check) el.classList.add('fill');
        });
    };
    useEffect(() => {
        if (constellationAnim) timer.current = setTimeout(setFireTime, 1500, true);
        return () => clearTimeout(timer.current);
    }, [constellationAnim]);
    return (
        <Block>
            {!constellationAnim && (
                <SVGIcon onReady={handleReady} className="cs-svg" src={`/constellation/c_${index}.svg`}></SVGIcon>
            )}
            {constellationAnim && (
                <>
                    <SVGIcon className="cs-svg anim-cs" src={`/icon-anim/CF16-${index}_icon.svg`} />
                    {fireTime && (
                        <SVGIcon
                            className={`cs-svg fire${((index - 1) % 4) + 1 > 2 ? ' special' : ''}`}
                            src={`/icon-anim/OA5-${((index - 1) % 4) + 1}_icon.svg`}
                        />
                    )}
                </>
            )}
            {starAnim === 1 && <SVGIcon className="cs-svg star-1" src={`/icon-anim/OA14_icon.svg`}></SVGIcon>}
            {starAnim === 2 && <SVGIcon className="cs-svg star-1" src={`/icon-anim/OA2-2_icon.svg`}></SVGIcon>}
            {starAnim === 3 && (
                <>
                    <SVGIcon className="cs-svg star-1" src={`/icon-anim/OA2-2_icon.svg`}></SVGIcon>
                    <SVGIcon className="cs-svg fire" src={`/icon-anim/OA5-1_icon.svg`} />
                </>
            )}
        </Block>
    );
}
