import React from 'react';
import styled from 'styled-components';
import theme from '../../assets/styles/theme';
import media from '../../utils/media';
import SVGIcon from '../SVGIcon';
import Dialog from './Dialog';

const Block = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    width: 455px;
    .img {
        position: relative;
        height: 100%;
        width: 260px;
    }
    svg {
        position: absolute;
        transform: translateY(25%);
        height: auto;
        width: 100%;
        bottom: 0;
        &.CD1 {
            width: 230px;
            ${media.medium} {
                transform: translate(1%, 25%);
                width: 140px;
            }
        }
        &.CF18 {
            width: 230px;
            transform: translate(5%, 15%);
            ${media.medium} {
                width: 140px;
            }
        }
        &.CF20 {
            width: 230px;
            transform: translate(5%, 15%);
            ${media.medium} {
                width: 140px;
            }
        }
        &.CF2 {
            width: 220px;
            transform: translate(-5%, 15%);

            ${media.medium} {
                transform: translate(0%, 15%);
                width: 135px;
            }
        }
        &.CF30 {
            width: 280px;
            transform: translate(-15%, 20%);
            ${media.medium} {
                transform: translate(-15%, 20%);
                width: 170px;
            }
        }
        &.CF31 {
            width: 280px;
            transform: translate(-15%, 20%);
            ${media.medium} {
                transform: translate(-15%, 20%);
                width: 170px;
            }
        }
        &.CF3 {
            width: 250px;
            transform: translate(-13%, 25%);
            ${media.medium} {
                transform: translate(-8%, 25%);
                width: 150px;
            }
        }
        &.CF4 {
            width: 220px;
            transform: translate(5%, 18%);
            ${media.medium} {
                transform: translate(13%, 18%);
                width: 130px;
            }
        }
        &.CN1-1 {
            width: 250px;
            transform: translateY(15%);
            ${media.medium} {
                width: 155px;
            }
        }
        &.CN2-1 {
            width: 250px;
            transform: translateY(18%);
            ${media.medium} {
                width: 155px;
            }
        }
        &.CN5 {
            width: 180px;
            margin-left: 35px;
            transform: translateY(20%);
            ${media.medium} {
                margin-left: 30px;
                width: 105px;
            }
        }
        &.CN6 {
            width: 220px;
            transform: translate(5%, 20%);
            ${media.medium} {
                width: 130px;
                transform: translate(10%, 20%);
            }
        }
    }
    ${media.medium} {
        width: 280px;
        .img {
            width: 150px;
        }
    }
`;

const Title = styled.div`
    width: 260px;
    display: flex;
    align-items: center;
    color: ${theme.palette.white};
    flex-shrink: 0;
    &.big-robot {
        width: 260px;
    }
    ${media.medium} {
        width: 160px;
        &.big-robot {
            width: 155px;
        }
    }
`;

export default function ModalRobot({ title, body, buttons, color = 'primary', src, robotSrc, onClose }) {
    const robot = robotSrc && (
        <SVGIcon className={robotSrc.replace('_character.svg', '')} src={`/character-anim/${robotSrc}`} />
    );
    const specialCase = ['CF3', 'CN6', 'CF2', 'CF4', 'CF20', 'CF30', 'CN1-1']
        .map(el => el + '_character.svg')
        .includes(robotSrc);
    return (
        <Dialog
            onClose={onClose}
            color={color}
            title={
                title && (
                    <Block>
                        <Title className={`bold ${specialCase ? 'big-robot' : ''}`}>{title}</Title>
                        <div className="img">{robot}</div>
                    </Block>
                )
            }
            body={body}
            buttons={buttons}
            src={src}
        ></Dialog>
    );
}
