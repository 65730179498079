import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Smile } from '../../../assets/images/diagnosis-question/diagnostic_question_smile.svg';
import { ReactComponent as Frowned } from '../../../assets/images/diagnosis-question/diagnostic_question_frowned.svg';
import RaisedCard from '../../../components/RaisedCard';
import CardContent from './CardContent';
import SoundButton from '../../../components/SoundButton';

const Block = styled.div`
    padding: 1em;
    width: 30%;
    min-width: 200px;
`;
const TitleBolck = styled.div`
    margin: 1em 0;
    cursor: pointer;
    svg {
        width: 2em;
        height: auto;
    }
    .white {
        color: white;
    }
`;

function DiagnosisQuestionObjective({ objectiveResults, focusIndex, onClick }) {
    return (
        <Block>
            <div className="h3">학습목표</div>
            {objectiveResults.map((el, i) => (
                <TitleBolck className="bold" key={i}>
                    <SoundButton>
                        <RaisedCard
                            borderRadius={0.8}
                            backgroundColor="#EEFCFC"
                            borderBottomColor="#ACDDDC"
                            borderTopColor="#D7F4F3"
                            activeBorderColor="#2F9999"
                            activeBackgroundColor="#5BD8CF"
                            activeBorderBottomColor="#4ABDB7"
                            key={i}
                            selected={focusIndex === i}
                            onClick={() => onClick(el.objective, i)}
                        >
                            <CardContent className={focusIndex === i ? 'selected' : ''}>
                                {el.result && <Smile></Smile>}
                                {!el.result && <Frowned></Frowned>}
                                <div className={`h5`}>{el.description}</div>
                            </CardContent>
                        </RaisedCard>
                    </SoundButton>
                </TitleBolck>
            ))}
        </Block>
    );
}

export default DiagnosisQuestionObjective;
