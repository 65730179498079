export const makeNullArray = count => {
    const arr = [];
    for (var i = 0; i < count; i++) arr.push(null);
    return arr;
};

export const getDiagnosisProgress = ({ progress, frameCurrentResult }) => {
    const newProgress = [...progress];
    if (progress[0] === null) newProgress[0] = frameCurrentResult;
    if (progress[0] !== null && progress[1] === null) {
        newProgress[1] = frameCurrentResult;
        if (progress[0] !== frameCurrentResult) newProgress[2] = null;
    }
    if (progress[0] !== null && progress[1] !== null && progress[2] === null) newProgress[2] = frameCurrentResult;
    return newProgress;
};

export const filterRewards = rewards => {
    const rightRewards = ['star', 'badge', 'medal', 'crown', 'trophy'];
    const rewardIds = ['RW.01', 'RW.02', 'RW.03', 'RW.04', 'RW.05'];
    return rewards
        .filter(el => rightRewards.includes(el.type))
        .map(el2 => ({
            reward_id: rewardIds[rightRewards.indexOf(el2.type)],
            reward_count: el2.value,
            curriculum_code: el2.info.curriculumCode,
            program_id: el2.info.programId,
            frame_id: el2.info.frameId
        }));
};

export const getHandWriteRecognitionLevel = programId => {
    let level = '4';
    const programIdSplit = programId.split('.');
    const grade = programId.indexOf('DG') !== -1 ? programIdSplit[2] : programIdSplit[1].charAt(0);
    if (['A', 'B', 'C'].includes(grade)) level = '1';
    if (['D', 'E', 'F'].includes(grade)) level = '2';
    if (['G', 'H', 'I'].includes(grade)) level = '3';
    return level;
};
