import { useEffect, useState } from 'react';
import { getAchieveLearningTargetTime, setAchieveLearningTargetTime } from '../utils/cookies';
import useHabitState from './useHabitState';

const useAchieveLearningTargetTimeBadge = (need = true) => {
    const { habitState, getHabitState } = useHabitState();
    const [isCheck, setIsCheck] = useState(getAchieveLearningTargetTime());

    const checkAchieveLearningTargetTime = () => {
        setIsCheck(true);
        setAchieveLearningTargetTime();
    };
    useEffect(() => {
        if (!habitState && need) getHabitState();
    }, [habitState, getHabitState, need]);
    return {
        isAchieveLearningTargetTime: !!(habitState && habitState.result !== 'belowStandards' && !isCheck),
        checkAchieveLearningTargetTime
    };
};

export default useAchieveLearningTargetTimeBadge;
