import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../assets/styles/theme';
import media from '../../utils/media';
import { ReactComponent as BackOff } from './images/backarrow_off.svg';
import { ReactComponent as BackOn } from './images/backarrow_on.svg';
import { ReactComponent as FrontOff } from './images/frontarrow_off.svg';
import { ReactComponent as FrontOn } from './images/frontarrow_on.svg';
const arrowDown = css`
    ${media.xsmall} {
        & > .carousel-main {
            flex-wrap: wrap;
            justify-content: center;
            & > .btn {
                order: 2;
                margin-top: 2em;
            }
            & > .container {
                width: 100%;
                order: 1;
            }
        }
    }
`;
const Block = styled.div`
    width: 100%;
    & > .carousel-main {
        overflow: hidden;
        display: flex;
        align-items: center;
        & > .btn {
            flex-shrink: 0;
            cursor: pointer;
            height: 1.5em;
            &.right {
                margin-left: 2em;
            }
            &.left {
                margin-right: 2em;
            }
            & > svg {
                height: 100%;
                width: auto;
            }
        }
        & > .btn.disable {
            pointer-events: none;
        }

        & > .container {
            flex-grow: 1;
            overflow: hidden;
            flex-shrink: 1;
            .inner {
                display: flex;
                &.transition {
                    transition: transform 0.3s;
                }
                & > div {
                    flex-shrink: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & > div {
                        touch-action: none;
                    }
                }
                .d-none {
                    display: none;
                }
            }
        }
    }
    & > .carousel-index {
        margin-top: 1em;
        display: flex;
        justify-content: center;
        .circle {
            cursor: pointer;
            border-radius: 50%;
            margin: 0 0.25em;
            width: 12px;
            height: 12px;
            border: 2px solid ${theme.palette.primary};
            &.selected {
                background: ${theme.palette.primary};
            }
        }
    }
    ${props => (props.arrowDown ? arrowDown : '')};
`;
export default function Carousel({
    children,
    onIndexChange,
    showIndex = true,
    hideArrow = false,
    autoPlay = false,
    arrowDown = false,
    classes = { carouselInnerContainer: undefined }
}) {
    const [transform, setTransform] = useState(0);
    const [drawing, setDrawing] = useState(false);
    const [index, setIndex] = useState(0);
    const containerWidth = useRef(0);
    const originX = useRef(0);
    const timer = useRef();
    const handleArrowClick = i => {
        setIndex(i + index);
    };
    const handleIndexClick = i => setIndex(i);
    const handleStart = e => {
        let event = e.changedTouches ? e.changedTouches[0] : e;
        setDrawing(true);
        originX.current = event.clientX;
    };
    const handleMove = e => {
        let event = e.changedTouches ? e.changedTouches[0] : e;
        if (drawing) {
            if (
                (index === 0 && originX.current - event.clientX < 0) ||
                (index === children.length - 1 && originX.current - event.clientX > 0)
            )
                return;
            setTransform(((originX.current - event.clientX) / containerWidth.current + index) * 100);
        }
    };
    const handleEnd = e => {
        let event = e.changedTouches ? e.changedTouches[0] : e;
        setDrawing(false);
        if (
            (index === 0 && originX.current - event.clientX < 0) ||
            (index === children.length - 1 && originX.current - event.clientX > 0)
        )
            return;
        if (originX.current - event.clientX > 0) setIndex(index + 1);
        else if (originX.current - event.clientX < 0) setIndex(index - 1);
    };
    useEffect(() => {
        containerWidth.current = document.getElementsByClassName('inner')[0].getBoundingClientRect().width;
    }, []);
    useEffect(() => {
        setTransform(index * 100);
    }, [index]);
    useEffect(() => {
        if (onIndexChange) onIndexChange(index);
    }, [index, onIndexChange]);
    useEffect(() => {
        if (autoPlay) {
            clearTimeout(timer.current);
            let newIndex = index + 1;
            if (autoPlay.cycle && newIndex === children.length) newIndex = 0;
            if (newIndex === children.length && !autoPlay.cycle) return;
            timer.current = setTimeout(setIndex, autoPlay.duration, newIndex);
        }
    }, [children.length, autoPlay, index]);
    useEffect(() => () => clearTimeout(timer.current));
    return (
        <Block className="h5" arrowDown={arrowDown}>
            <div className="carousel-main">
                {!hideArrow && (
                    <div className={`btn left ${index === 0 ? 'disable' : ''}`} onClick={handleArrowClick.bind({}, -1)}>
                        {index > 0 && <BackOn>앞</BackOn>}
                        {index === 0 && <BackOff></BackOff>}
                    </div>
                )}

                <div className="container">
                    <div
                        style={{ transform: `translate(${-transform}%,0)` }}
                        className={`inner ${drawing ? '' : 'transition'}`}
                    >
                        {children.map((el, i) => (
                            <div key={i}>
                                <div
                                    className={classes.carouselInnerContainer}
                                    onTouchStart={handleStart}
                                    onTouchMove={handleMove}
                                    onTouchEnd={handleEnd}
                                >
                                    {el}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {!hideArrow && (
                    <div
                        className={`btn right ${index === children.length - 1 ? 'disable' : ''}`}
                        onClick={handleArrowClick.bind({}, 1)}
                    >
                        {index < children.length - 1 && <FrontOn></FrontOn>}
                        {index === children.length - 1 && <FrontOff></FrontOff>}
                    </div>
                )}
            </div>
            {showIndex && (
                <div className="carousel-index">
                    {children.map((el, i) => (
                        <div
                            key={i}
                            onClick={handleIndexClick.bind({}, i)}
                            className={`circle ${i === index ? 'selected' : ''}`}
                        ></div>
                    ))}
                </div>
            )}
        </Block>
    );
}
