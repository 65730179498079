import React, { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import { postLearningTargetTime } from '../../api/lms';
import theme from '../../assets/styles/theme';
import CN from '../../assets/texts/CN';
import TN from '../../assets/texts/TN';
import TT from '../../assets/texts/TT';
import media from '../../utils/media';
import Toast from '../Toast';
import Dialog from './Dialog';
import Loading from './Loading';
import ModalContainer from './ModalContainer';
import usePromise from '../../hooks/usePromise';
const Block = styled.div`
    background: ${theme.palette.primary};
    width: 100%;
    /* max-width: 400px; */
    border-radius: ${theme.modalRadius};
    overflow: hidden;
    .title-modal {
        color: ${theme.palette.white};
        display: flex;
        align-items: center;
    }
    .body {
        height: 285px;
        padding: 0 50px;
        padding-bottom: 0;
    }
    .body-container {
        background: ${theme.palette.white};
        border-radius: ${theme.modalRadius} ${theme.modalRadius} 0 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        .setting-title {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 48px;
        }
        #slider-container {
            position: relative;
            width: 100%;
            padding: 5px 0;
            margin-top: 26px;
            margin-bottom: 36px;
            .bar {
                width: 100%;
                background: ${theme.palette.gray};
                height: 10px;
                border-radius: 5px;
            }
            .thumb {
                position: absolute;
                cursor: pointer;
                top: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: ${theme.palette.primary};
                z-index: 2;
            }
            .filled {
                background-color: ${theme.palette.primaryLight};
                height: 10px;
                position: absolute;
                top: 5px;
                border-radius: 5px;
                z-index: 0;
                pointer-events: none;
            }
            .vl {
                border-left: 1px solid ${theme.palette.white};
                position: absolute;
                height: 10px;
                top: 5px;
            }
            .minute {
                width: 5em;
                position: absolute;
                text-align: center;
                top: -2em;
                &.selected {
                    color: ${theme.palette.primary};
                }
            }
        }
    }
    .bottom {
        &.no-pointer {
            pointer-events: none;
        }
        background: ${theme.palette.white};
        display: flex;
        justify-content: center;
        padding: 1em;
        div + div {
            margin-left: 1em;
        }
    }
    ${media.medium} {
        .body {
            height: 190px;
            padding: 0 35px;
        }
        .body-container {
            .setting-title {
                margin-top: 25px;
                margin-bottom: 26px;
            }
            #slider-container {
                margin-top: 24px;
                margin-bottom: 18px;
            }
        }
    }
`;
export default function LearningTargetModal({ firstLogin, onClick, initialTargetTime = 10, onSaveEnd }) {
    const sliderRect = useRef();
    const dragging = useRef(false);
    const [currentX, setCurrentX] = useState((initialTargetTime - 10) * 5);
    const [toastShow, setToastShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const selectedIndex = useMemo(() => {
        const q = Math.floor(currentX / 25);
        const r = currentX % 25;
        return q + (r > 10 ? 1 : 0);
    }, [currentX]);
    const { error, refresh: updateLearningTime } = usePromise(postLearningTargetTime, true, {
        targetTime: (selectedIndex + 2) * 5
    });
    const txtObj = CN(8, { heartCount: Math.floor(selectedIndex / 2) + 1 });
    const start = e => {
        dragging.current = true;
    };
    const move = useCallback(e => {
        if (dragging.current) {
            const rect = sliderRect.current;
            let event = e.touches ? e.touches[0] : e;
            const { clientX: x } = event;
            setCurrentX(Math.min(Math.max(0, x - rect.x) / rect.width, 1) * 100);
        }
    }, []);
    const end = useCallback(() => {
        dragging.current = false;
        setCurrentX(selectedIndex * 25);
    }, [selectedIndex]);

    const handleCancel = () => {
        onClick();
    };
    const handleSave = async () => {
        setLoading(true);
        await updateLearningTime();
        if (onSaveEnd) onSaveEnd();
        setLoading(false);
        setToastShow(true);
        setTimeout(onClick, 1800);
    };
    const handleBarClick = e => {
        const rect = sliderRect.current;
        let event = e.touches ? e.touches[0] : e;
        const { clientX } = event;
        const x = Math.min(Math.max(0, clientX - rect.x) / rect.width, 1) * 100;
        const q = Math.floor(x / 25);
        const r = x % 25;
        const index = q + (r > 10 ? 1 : 0);
        setCurrentX(index * 25);
    };
    useEffect(() => {
        window.addEventListener('mouseup', end);
        window.addEventListener('mousemove', move);
        window.addEventListener('touchmove', move);
        window.addEventListener('touchend', end);
        sliderRect.current = document.getElementById('slider-container').getBoundingClientRect();
        return () => {
            window.removeEventListener('mouseup', end);
            window.removeEventListener('mousemove', move);
            window.removeEventListener('touchmove', move);
            window.removeEventListener('touchend', end);
        };
    }, [end, move]);
    if (loading) return <Loading></Loading>;
    if (error) throw error;
    return (
        <ModalContainer>
            {!toastShow && (
                <Block>
                    <Dialog
                        title={<div className="title-modal">{txtObj.title}</div>}
                        body={
                            <div className="body-container">
                                <div className="setting-title">
                                    <div className="bold">
                                        <div>{txtObj.body[0]}</div>
                                        <div>{txtObj.body[1]}</div>
                                    </div>
                                </div>
                                <div id="slider-container">
                                    <div className="bar" onClick={handleBarClick}></div>
                                    <div
                                        className="thumb"
                                        style={{ left: `calc(${currentX}% - 10px)` }}
                                        onMouseDown={start}
                                        onTouchStart={start}
                                    ></div>
                                    <div className="filled" style={{ width: `${currentX}%` }}></div>
                                    {[1, 2, 3].map(el => (
                                        <div key={el} className="vl" style={{ left: `${el * 25}%` }}></div>
                                    ))}
                                    {[0, 1, 2, 3, 4].map(el => (
                                        <div
                                            key={el}
                                            className={`ft-popup-caption minute ${
                                                selectedIndex === el ? 'selected' : ''
                                            }`}
                                            style={{ left: `calc(${el * 25}% - 2.5em)` }}
                                        >
                                            {(el + 2) * 5}분
                                        </div>
                                    ))}
                                </div>
                                <div className="ft-popup-caption">{txtObj.body[2]}</div>
                            </div>
                        }
                        src={txtObj.src}
                        buttons={[
                            { text: txtObj.btn[0], onClick: handleCancel },
                            { text: txtObj.btn[1], onClick: handleSave }
                        ].slice(firstLogin ? 1 : 0, 2)}
                    ></Dialog>
                </Block>
            )}
            {toastShow && <Toast message={firstLogin ? TN(1) : TT(1)}></Toast>}
        </ModalContainer>
    );
}
