import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import theme from '../../../assets/styles/theme';
import media from '../../../utils/media';
import SelectComponent from '../../../components/common/SelectComponent';
import { ReactComponent as CharacterOn1 } from '../../../assets/images/report/Character_on_1.svg';
import { ReactComponent as CharacterOn2 } from '../../../assets/images/report/Character_on_2.svg';
import { ReactComponent as CharacterOn3 } from '../../../assets/images/report/Character_on_3.svg';
import { ReactComponent as Next } from '../../../assets/images/report/button_achievement.svg';

const Container = styled.div`
    position: relative;
    width: calc(50% - 0.5em);
    margin-right: 0.5em;
    height: 10em;

    ${media.small} {
        width: calc(100% - 0.5em);
        margin-right: 0;
    }

    background: ${theme.palette.white};
    border-radius: 20px;
    padding: 0.5em 0.75em;
    margin-top: 40px;
    .arrowIcon {
        position: absolute;
        top: 1em;
        right: 1.5em;
    }
`;
const SelectContainer = styled.div`
    position: absolute;
    top: -45px;
    left: 15px;
    display: flex;
    align-items: center;
    .display_name {
        z-index: 100;
        position: relative;
        margin-left: -0.5rem;
    }
`;
const ProgressContainer = styled.div`
    .title {
    }
    .unit {
        margin-left: 5px;
    }
    .rate {
        position: relative;
        width: 102px;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b5b5b6;
        text-align: center;
        div {
            height: 30px;
            line-height: 30px;
        }
        .exp {
            min-width: 40px;
            margin-right: 0.2em;
        }
        .count {
        }
        .unit {
        }
    }

    .progress-bar {
        position: relative;
        display: flex;
        margin: 0.5em 0.5em 0 0;

        div {
            height: 15px;
            border-radius: 7.5px;
        }
    }

    .bar1-background {
        position: absolute;
        top: 0px;
        left: 0px;
        background: ${theme.palette.primary};
        border: 1px solid ${theme.palette.primary};
    }
    .bar2-background {
        position: absolute;
        top: 0px;
        left: 0px;
        background: #feadad;
        border: 1px solid #feadad;
    }
    .bar3-background {
        position: absolute;
        top: 0px;
        left: 0px;
        background: #ffd357;
        border: 1px solid #ffd357;
    }
`;

const LegendBlock = styled.div`
    margin-right: 1em;
    & > div > div {
        margin: 0.5em 0;
    }
    & > div > div:nth-of-type(1) {
        width: 75%;
    }
    & > div > div:nth-of-type(2) {
        width: 25%;
    }
    .exp {
        margin-left: 20px;
    }
    .status-count {
        width: 40px;
        text-align: center;
        position: relative;
        top: -2px;
    }

    .image {
        width: 4.25em;
        height: 4.25em;
        border-radius: 50%;
    }
    .svgIcon {
        width: 4em;
        height: 4em;
        margin: 0 auto;
    }
`;

export default function LearningProgressComponent({
    url,
    learningGrade,
    setGrade,
    grade,
    learningAchievement,
    displayName
}) {
    const history = useHistory();
    const { status_0, status_1, status_2 } = learningAchievement.diagnosis;
    const total = status_0 + status_1 + status_2;
    const progress = ((status_1 + status_2) / total) * 100;
    let offset = '-70px';
    if (progress > 95) offset = '-85px';
    if (progress < 80) offset = '-40px';
    if (progress < 10) offset = '-30px';

    const list = learningGrade
        .map(el => el + '등급')
        .concat()
        .reverse();
    return (
        <Container className="h1">
            <div className="h6 cursor-pointer arrowIcon" onClick={() => history.push(`/report/${url[4]}`)}>
                <Next width="30px" height="30px" />
            </div>
            <SelectContainer>
                <SelectComponent
                    list={list}
                    onClick={grade => setGrade(grade.substring(0, 1))}
                    selectIndex={list.indexOf(grade + '등급')}
                    style={{
                        selectBox: {
                            border: 'none',
                            background: '#f2f5f5'
                        }
                    }}
                />
                <div className="h5 display_name">{displayName}친구</div>
            </SelectContainer>
            <ProgressContainer>
                <div className="title h5">
                    <span>실력 알아보기</span>
                </div>
                <div className="progress-bar">
                    <div className="bar3-background" style={{ width: `100%` }}></div>
                    <div
                        className="bar2-background"
                        style={{ width: `calc(100% * ((${status_2} + ${status_1}) / ${total}))` }}
                    ></div>
                    <div className="bar1-background" style={{ width: `calc(100% * (${status_1} / ${total}))` }}></div>
                </div>
                <div
                    className="rate h6"
                    style={{ marginLeft: `calc(100% * ((${status_2} + ${status_1}) / ${total}) + ${offset})` }}
                >
                    <div className="exp">진척률</div>
                    <div className="count">{total !== 0 && Math.round(progress, 0)}</div>
                    <div className="unit">%</div>
                </div>
                <LegendBlock className="h6">
                    <div className="flex align-center justify-between">
                        <div className="flex align-center">
                            <div className="flex align-center justify-center image">
                                <CharacterOn1 className="svgIcon" />
                            </div>
                            <div className="exp h5">잘했어요</div>
                        </div>
                        <div className="bold status-count h5">{status_1}</div>
                    </div>
                    <div className="flex align-center justify-between">
                        <div className="flex align-center">
                            <div className="flex align-center justify-center image">
                                <CharacterOn2 className="svgIcon" />
                            </div>
                            <div className="exp h5">학습해요</div>
                        </div>
                        <div className="bold status-count h5">{status_2}</div>
                    </div>
                    <div className="flex align-center justify-between">
                        <div className="flex align-center">
                            <div className="flex align-center justify-center image">
                                <CharacterOn3 className="svgIcon" />
                            </div>
                            <div className="exp h5">진행할 거예요</div>
                        </div>
                        <div className="bold status-count h5">{status_0}</div>
                    </div>
                </LegendBlock>
            </ProgressContainer>
        </Container>
    );
}
