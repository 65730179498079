import { combineReducers } from 'redux';
import learningGrade from './learningGrade';
import learningCurriculum from './learningCurriculum';
import learningProgram from './learningProgram';
import learningFrame from './learningFrame';
import learningInfo from './learningInfo';
import learnerInfo from './learnerInfo';
import programPage from './programPage';
const appReducer = combineReducers({
    learningGrade,
    learningCurriculum,
    learningProgram,
    learningFrame,
    learningInfo,
    learnerInfo,
    programPage
});
const rootReducer = (state, action) => {
    if (action.type === 'INIT_STORE') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
