const constellationText = [
    { ko_KR: '염소자리', en_US: 'Capricorn', term: '12월25일 ~ 1월19일' },
    { ko_KR: '물병자리', en_US: 'Aquarius', term: '1월20일 ~ 2월18일' },
    { ko_KR: '물고기자리', en_US: 'Pisces', term: '2월19일 ~ 3월20일' },
    { ko_KR: '양자리', en_US: 'Aries', term: '3월21일 ~ 4월19일' },
    { ko_KR: '황소자리', en_US: 'Taurus', term: '4월20일 ~ 5월20일' },
    { ko_KR: '쌍둥이자리', en_US: 'Gemini', term: '5월21일 ~ 6월21일' },
    { ko_KR: '게자리', en_US: 'Cancer', term: '6월22일 ~ 7월22일' },
    { ko_KR: '사자자리', en_US: 'Leo', term: '7월23일 ~ 8월22일' },
    { ko_KR: '처녀자리', en_US: 'Virgo', term: '8월23일 ~ 9월23일' },
    { ko_KR: '천칭자리', en_US: 'Libra', term: '9월24일 ~ 10월22일' },
    { ko_KR: '전갈자리', en_US: 'Scorpio', term: '10월23일 ~ 11월22일' },
    { ko_KR: '사수자리', en_US: 'Sagittarius', term: '11월23일 ~ 12월24일' }
];

export default constellationText;
