import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRewardHistory, postRewardConstellationCheck } from '../../api/lms';
import Loading from '../../components/common/Loading';
import usePromise from '../../hooks/usePromise';
import useRedux from '../../hooks/useRedux';

import { getLearningGrade } from '../../reducers/learningGrade';
import { setReward } from '../../reducers/learningInfo';
import { grades as allGrades } from '../../utils/dataMap';
import Constellation from './components/Constellation';
import ConstellationCards from './components/ConstellationCards';

import Layout from './components/Layout';
import Planets from './components/Planets';
import RewardItemInfo from './components/RewardItemInfo';
import RewardItems from './components/RewardItems';
import RewardModal from './components/RewardModal';

export default function RewardItemContainer() {
    const animList = useRef([]);
    const [animStart, setAnimStart] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [modal, setModal] = useState(0);
    const [rewardIndex, setRewardIndex] = useState(0);
    const [rewardModalInfo, setRewardModalInfo] = useState();
    const { data, error: error1, loading: loading1, refresh: rewardHistoryFetch } = usePromise(getRewardHistory);
    const { data: grades, error: error2, loading: loading2 } = useRedux({
        thunk: getLearningGrade,
        dataKey: 'learningGrade'
    });
    const starAnimType = useRef(0);
    const timerRef = useRef();
    const rewards = useSelector(state => state.learningInfo.reward);
    const loading = loading1 || loading2;
    const lastConstellation = data && data.collectedConstellations.last();
    const preventEvent = useRef(rewards.length > 0);
    const [error3, setError3] = useState();
    const handleConstellationClick = async () => {
        setModal(2);
        try {
            if (lastConstellation && !lastConstellation.reward_check_yn) {
                await postRewardConstellationCheck();
                await rewardHistoryFetch();
            }
        } catch (error) {
            setError3(error);
        }
    };
    const error = error1 || error2 || error3;
    useEffect(() => {
        const reward = rewards[rewardIndex];
        if (!reward) return;
        if (reward.type === 'stop') {
            setRewardModalInfo(null);

            setAnimStart(true);
            timerRef.current = setTimeout(
                () => {
                    dispatch(setReward([]));
                    history.push(reward.params.to);
                },
                animList.current.find(el => el.indexOf('CS.') !== -1) ? 5000 : 4000
            );
            return;
        }
        if (reward.type === 'RW.01') {
            if (1 <= reward.value && reward.value <= 14) starAnimType.current = 1;
            else if (15 <= reward.value && reward.value <= 19) starAnimType.current = 2;
            else if (20 <= reward.value) starAnimType.current = 3;
        }
        animList.current.push(reward.type);
        if (reward) setRewardModalInfo({ ...reward, onClick: setRewardIndex.bind({}, rewardIndex + 1) });
    }, [rewards, rewardIndex, history, dispatch]);
    useEffect(() => () => clearTimeout(timerRef.current), []);
    if (error) throw error;
    return (
        <>
            <Layout
                preventEvent={preventEvent.current > 0}
                isRewardGet={!!rewardModalInfo}
                rewardSum={(data || {}).rewardSum}
                onInfoClick={setModal.bind({}, 1)}
            >
                {loading && <Loading></Loading>}
                {!loading && data && (
                    <Constellation
                        starAnim={animStart && starAnimType.current}
                        constellationAnim={animStart && animList.current.find(el => el.indexOf('CS.') !== -1)}
                        totalStarCount={data.nextConstellation.reward_condition_value}
                        starCount={data.rewardSum['RW.01']}
                        csIndex={Number(
                            (lastConstellation && !lastConstellation.reward_check_yn
                                ? lastConstellation
                                : data.nextConstellation
                            ).reward_id.slice(3, 5)
                        )}
                        check={!lastConstellation || lastConstellation.reward_check_yn}
                        onClick={handleConstellationClick}
                    ></Constellation>
                )}
                {!loading && grades && (
                    <RewardItems
                        grades={grades}
                        rewardSum={(data || {}).rewardSum}
                        onPlanetClick={setModal.bind({}, 3)}
                        ufoAnim={animStart && animList.current.includes('RW.04')}
                        rocketAnim={animStart && animList.current.includes('RW.03')}
                        shootingStarAnim={animStart && animList.current.includes('RW.02')}
                        planetAnim={animStart && animList.current.find(el => Number(el.replace('RW.', '')) >= 10)}
                        planetIndex={
                            rewards?.map(el => Number(el.type?.replace('RW.', ''))).find(el => el >= 10) - 9 ||
                            allGrades.indexOf(grades?.last()) + 1
                        }
                    ></RewardItems>
                )}
                {modal === 1 && <RewardItemInfo onClick={setModal.bind({}, 0)}></RewardItemInfo>}
                {rewardModalInfo && <RewardModal {...{ ...rewardModalInfo }}></RewardModal>}
                {data && modal === 2 && (
                    <ConstellationCards
                        checkLast={lastConstellation && !lastConstellation.reward_check_yn}
                        onCardClose={setModal.bind({}, 0)}
                        collectedConstellations={data.collectedConstellations}
                    ></ConstellationCards>
                )}
                {modal === 3 && <Planets grades={grades} onClose={setModal.bind({}, 0)}></Planets>}
            </Layout>
        </>
    );
}
