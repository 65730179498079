import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Smile } from '../../../assets/images/diagnosis-question/diagnostic_question_smile.svg';
import { ReactComponent as Frowned } from '../../../assets/images/diagnosis-question/diagnostic_question_frowned.svg';
import DiagnosisQuestionViewer from './DiagnosisQuestionViewer';
import theme from '../../../assets/styles/theme';
import RaisedCard from '../../../components/RaisedCard';
import CardContent from './CardContent';
import SoundButton from '../../../components/SoundButton';
const Block = styled.div`
    width: 100%;
    .objectives {
        background: ${theme.palette.primary};
        & > div {
            padding: 1em 1.6rem;
            border-radius: 20px 20px 0 0;
            background: white;
        }
    }
    .container {
        display: flex;
        margin: 1em 0 0 0;
        overflow-x: auto;
        padding-bottom: 10px;
    }
`;
const TitleContainer = styled.div`
    min-width: 204px;
    & + & {
        margin-left: 1em;
    }
    svg {
        width: 2em;
        height: auto;
    }
`;

export default function DiagnosisQuestionSmall({ objectiveResults, focusIndex, onClick, viewInfo }) {
    return (
        <Block>
            <div className="objectives">
                <div>
                    <div className="h3">학습목표</div>
                    <div className="container">
                        {objectiveResults.map((el, i) => (
                            <TitleContainer key={i}>
                                <SoundButton>
                                    <RaisedCard
                                        borderRadius={0.8}
                                        backgroundColor="#EEFCFC"
                                        borderBottomColor="#ACDDDC"
                                        borderTopColor="#D7F4F3"
                                        activeBorderColor="#2F9999"
                                        activeBackgroundColor="#5BD8CF"
                                        activeBorderBottomColor="#4ABDB7"
                                        selected={focusIndex === i}
                                        onClick={() => onClick(el.objective, focusIndex === i ? null : i)}
                                    >
                                        <CardContent className={focusIndex === i ? 'selected' : ''}>
                                            {el.result && <Smile></Smile>}
                                            {!el.result && <Frowned></Frowned>}
                                            <div className="h5">{el.description}</div>
                                        </CardContent>
                                    </RaisedCard>
                                </SoundButton>
                            </TitleContainer>
                        ))}
                    </div>
                </div>
            </div>
            <DiagnosisQuestionViewer viewInfo={viewInfo}></DiagnosisQuestionViewer>
        </Block>
    );
}
