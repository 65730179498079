import React, {  useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import useProgramPage from '../../../hooks/useProgramPage';
import { breakPoints } from '../../../utils/media';
import ProgramCard from './ProgramCard';
export const progressContainer = css`
    & > .progress-container {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        & > div {
            width: 100%;
            max-width: ${breakPoints.large}px;
            position: relative;
        }
        .progress {
            position: absolute;
            border-left: 2px dotted gray;
            height: calc(100% - 30px);
            /* border-right: 2px dashed gray; */
            /* border-style: dashed; */
            left: calc(1.6rem + 29px);
        }
    }
`;
const ListBlock = styled.div`
    width: 100%;
    margin: 0 auto 40px 0;
    background: white;
    position: relative;
    ${progressContainer}
`;

function ProgramList({ curriculumStatus, programInfos, onButtonClick, focusIndex, setFocusIndex }) {
    const { setNeedProgramFocus, needProgramFocus, focusArea, setFocusArea } = useProgramPage();
    const infos = useMemo(
        () =>
            programInfos.map((el, i) => {
                return {
                    ...el,
                    isFocused: i === focusIndex,
                    index: i
                };
            }),
        [programInfos, focusIndex]
    );
    const onChangeFocus = index => {
        if (focusArea === 0 && focusIndex === index) setFocusIndex(-1);
        else setFocusIndex(index);
        setFocusArea(0);
    };
    // const [progressHeight, setProgressHeight] = useState(0);
    const executeInitScroll = useRef(false);
    useEffect(() => {
        if (needProgramFocus && !executeInitScroll.current && Number.isInteger(focusIndex) && focusArea === 0) {
            document.getElementById('program-card-' + focusIndex)?.scrollIntoView({ behavior: 'smooth' });
            setNeedProgramFocus(false);
            executeInitScroll.current = true;
        }
    }, [focusIndex, needProgramFocus, setNeedProgramFocus, focusArea]);

    return (
        <ListBlock>
            <div className="progress-container">
                <div>
                    <div className="progress"></div>
                </div>
            </div>
            {infos.map(el => (
                <ProgramCard
                    key={el.programId}
                    info={el}
                    onChangeFocus={onChangeFocus}
                    onButtonClick={onButtonClick}
                    curriculumStatus={curriculumStatus}
                    programInfos={programInfos}
                />
            ))}
        </ListBlock>
    );
}

export default ProgramList;
