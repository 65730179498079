import React, { useRef } from 'react';
import ProgressStar from './ProgressStar';
import ScratchPad from './ScratchPad';
import styled from 'styled-components';
import useFlow from './hooks/useFlow';
import Timer from './Timer';
import Help from './Help';
import { getAccessToken } from '../../utils/util';
import { breakPoints } from '../../utils/media';
const Block = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    * {
        -webkit-user-select: none;
    }
`;
const Frame = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    max-width: ${breakPoints.large}px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1.6rem 0 1.6rem;
`;
const Progress = styled.div`
    position: relative;
`;
const Bottom = styled.div`
    width: 100%;
    margin-bottom: 1em;
    & > div {
        max-width: 1200px;
        width: 100%;
        height: 2em;
        padding: 0 1.6rem;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;
function FrameLearning({
    iframe,
    frames,
    frameCodes,
    programId,
    onTeacherCallButtonClick,
    onHomeButtonClick,
    onInfoTextClick,
    onProgramDetailClick,
    onInformationButtonClick,
    onQuestionEnd,
    onFrameEnd,
    onNextFrame,
    onProgramEnd,
    onBeforeLoad,
    onRepeatGenerate,
    onTrackingEnd,
    onTimerEnd,
    onBasicReTry,
    onBeforeTimer,
    onG2Clear,
    onLoad,
    onNoAction,
    programAchievementDegree,
    programFrameCount,
    timerPause,
    helpRemainCount,
    onError,
    onHandwriteRecognitionError,
    diagnosisStartProgress
}) {
    const _iframe = useRef();
    if (!iframe) iframe = _iframe;
    const [flow, handleOnLoad, handleTimerEnd] = useFlow({
        frames,
        frameCodes,
        iframe,
        onFrameEnd,
        onNextFrame,
        onQuestionEnd,
        onProgramEnd,
        onBeforeLoad,
        onRepeatGenerate,
        onTrackingEnd,
        onTimerEnd,
        onBasicReTry,
        onBeforeTimer,
        onG2Clear,
        onLoad,
        onNoAction,
        programId,
        programAchievementDegree,
        programFrameCount,
        onError,
        onHandwriteRecognitionError,
        diagnosisStartProgress
    });
    const {
        scratchKey,
        iframeKey,
        frameId,
        prevResult,
        progress,
        timer,
        feedback,
        setPaths,
        frameCode,
        achievementDegree
    } = flow;
    const accessToken = getAccessToken();
    return (
        <Block>
            <Progress>
                <ProgressStar
                    result={progress}
                    infoText={frameCode}
                    onHomeButtonClick={onHomeButtonClick}
                    onInfoTextClick={onInfoTextClick}
                    onInformationButtonClick={onInformationButtonClick}
                    achievementDegree={achievementDegree}
                    onProgramDetailClick={onProgramDetailClick}
                    feedback={feedback}
                    programId={programId}
                ></ProgressStar>
                {timer && <Timer timer={timer} onTimerEnd={handleTimerEnd} paused={timerPause}></Timer>}
            </Progress>

            {frameId && (
                <Frame>
                    <iframe
                        ref={iframe}
                        key={iframeKey}
                        style={{ width: '100%', height: '100%' }}
                        title="lcms"
                        src={`https://${
                            process.env.REACT_APP_LCMS_HOST
                        }/frame/learning/M2/ko_KR/${frameId}?accessToken=${accessToken}${
                            prevResult ? '&prevResult=' + prevResult : ''
                        }`}
                        frameBorder="0"
                        onLoad={handleOnLoad}
                    ></iframe>
                </Frame>
            )}
            <Bottom className="h5" style={{ visibility: feedback ? 'hidden' : 'visible' }}>
                <div>
                    {onTeacherCallButtonClick && (
                        <Help
                            helpRemainCount={helpRemainCount}
                            onTeacherCallButtonClick={onTeacherCallButtonClick}
                        ></Help>
                    )}
                    <ScratchPad scratchKey={scratchKey} onPathsChange={setPaths}></ScratchPad>
                </div>
            </Bottom>
        </Block>
    );
}

export default FrameLearning;
