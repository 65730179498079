import React from 'react';
import styled from 'styled-components';
import ModalRobot from '../../../components/common/ModalRobot';
import { ReactComponent as Heart1 } from '../../../assets/images/reward-heart/heart_1.svg';
import { ReactComponent as Heart2 } from '../../../assets/images/reward-heart/heart_2.svg';
import { ReactComponent as Heart3 } from '../../../assets/images/reward-heart/heart_3.svg';
import { ReactComponent as Heart4 } from '../../../assets/images/reward-heart/heart_4.svg';
import CR from '../../../assets/texts/CR';
import media from '../../../utils/media';

const hearts = [<Heart1 />, <Heart2 />, <Heart3 />, <Heart4 />];
const Row = styled.div`
    display: flex;
    align-items: center;
    margin: 34px 0;
    svg {
        width: 40px;
        height: auto;
        margin: 0 20px 0 20px;
    }
    .ft-popup-caption {
        font-size: 22px;
        letter-spacing: -0.3px;
    }
    ${media.medium} {
        margin: 20px 0;
        svg {
            width: 30px;
            margin: 0 15px;
        }
        .ft-popup-caption {
            font-size: 14px;
            letter-spacing: -0.3px;
        }
    }
`;
const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Body = styled.div`
    width: 100%;
`;
export default function RewardInfo({ onClick }) {
    const txtObj = CR(3);
    return (
        <ModalRobot
            onClose={onClick}
            {...{ ...txtObj }}
            title={
                <Title>
                    {txtObj.title.split('\n').map(el => (
                        <div key={el}>{el}</div>
                    ))}
                </Title>
            }
            body={
                <Body>
                    {txtObj.titles.map((el, i) => (
                        <Row key={el}>
                            {hearts[i]}
                            <div>
                                <div className="bold">{el}</div>
                                {txtObj.body[i].map(el => (
                                    <div className="ft-popup-caption medium" key={el}>
                                        {el}
                                    </div>
                                ))}
                            </div>
                        </Row>
                    ))}
                </Body>
            }
            buttons={[{ text: txtObj.buttons[0], onClick }]}
        ></ModalRobot>
    );
}
