export default function CN(type, params) {
    let title, body, btn, src, random, robotSrc;
    switch (type) {
        case 1:
            random = Math.floor(Math.random() * 5);
            if (random === 0) title = '반가워요!';
            if (random === 1) title = '오늘도 즐거운 하루예요!';
            if (random === 2) title = '어서 와요!';
            if (random === 3) title = '오늘도 별 모으러 가 봐요!';
            if (random === 4) title = '실력을 쌓으러 가 봐요!';
            src = `CN1-${random + 1}_re.mp3`;
            btn = '출석 완료';
            robotSrc = 'CN1-1_character.svg';
            break;
        case 2:
            random = Math.floor(Math.random() * 3);
            if (random === 0) title = '3일 연속 출석 중!';
            if (random === 1) title = '파이팅! 오늘도 출석!';
            if (random === 2) title = '꾸준히 출석 중! ';
            src = `CN2-${random + 1}_re.mp3`;
            btn = '출석 완료';
            robotSrc = 'CN2-1_character.svg';
            break;
        case 3:
            random = Math.floor(Math.random() * 3);
            if (random === 0) {
                title = '오래간만이에요!';
                body = '다시 별을 모으러 가 볼까요?';
            }
            if (random === 1) {
                title = '오래간만이에요!';
                body = '조금씩 꾸준히 해 봐요.';
            }
            if (random === 2) {
                title = '다시 만나 반가워요!';
                body = '매일 조금씩 해 봐요.';
            }
            src = `CN3-${random + 1}_re.mp3`;
            btn = '출석 완료';
            robotSrc = 'CN2-1_character.svg';
            break;
        case 4:
            title = `환영해요!`;
            body = `${params.display_name}친구,\n재능AI수학을 시작해 볼까요?`;
            btn = '출석 완료';
            src = 'CN4_re.mp3';
            robotSrc = 'CN1-1_character.svg';
            break;
        case 5:
            title = '알림';
            body = `${params.expireDate}부터 학습 접속이\n종료될 예정이에요.`;
            btn = '확인';
            src = 'CN5_re.mp3';
            robotSrc = 'CN5_character.svg';
            break;
        case 6:
            title = '아쉬워요!';
            body = `오늘의 체험 학습 시간이 모두 끝났어요.\n다음에 또 만나요.`;
            btn = '확인';
            src = 'CN6_re.mp3';
            robotSrc = 'CN6_character.svg';
            break;
        case 7:
            title = '축하해요!';
            body = '오늘 목표한 학습 시간을 달성했어요!';
            btn = '하트 획득';
            src = 'CN7_re.mp3';
            robotSrc = 'CN1-1_character.svg';
            break;
        case 8:
            title = '1일 학습 목표 시간 설정';
            body = [
                `하트를 ${params.heartCount}개 받을 수 있어요.`,
                `하루에 공부할 시간을 스스로 정해 봐요.`,
                `매일 학습 시작 전에 다시 정할 수 있어요.`
            ];
            btn = [`취소`, `저장`];
            src = 'CT2_re.mp3';
            break;
        case 9:
            title = '알림';
            body = `${params.expireDate}부터 학습 접속이\n종료될 예정이에요.`;
            btn = '확인';
            src = 'CN5_re.mp3';
            robotSrc = 'CN5_character.svg';
            break;
        case 10:
            title = '아쉬워요!';
            body = `오늘의 학습 시간이 모두 끝났어요.\n다음에 또 만나요.`;
            btn = '확인';
            src = 'CN10.mp3';
            robotSrc = 'CN6_character.svg';
            break;
        case 11:
            title = '아쉬워요!';
            body = `체험 학습이 모두 끝났어요.\n다음 학습은 선생님께 문의해요.`;
            btn = '확인';
            src = 'CN10.mp3';
            robotSrc = 'CN6_character.svg';
            break;
        case 12:
            title = `환영해요!`;
            body = `${params.display_name}친구,\n체험 학습은 하루 10분씩 할 수 있어요.\n실수로 문제를 틀리지 않도록 학습 전\n주의 사항을 꼭 지켜 주세요!\n재능AI수학을 시작해 볼까요?`;
            btn = '출석 완료';
            src = 'CN4_re.mp3';
            robotSrc = 'CN1-1_character.svg';
            break;
        default:
    }
    return { title, body, btn, src, robotSrc };
}
