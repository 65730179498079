import React from 'react';
import styled from 'styled-components';

const parseText = (text, emphasisClassName) => {
    return text.split('\n').map(row => {
        const texts = row.split(/{|}/).filter(el => el !== '');
        return texts.map(text => ({ text, className: row[row.indexOf(text) - 1] === '{' ? emphasisClassName : '' }));
    });
};
const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export default function ModalBodyText({ text, emphasisClassName = 'ft-bold', align = 'center' }) {
    const parsedTexts = parseText(text, emphasisClassName);
    return (
        <Block>
            {parsedTexts.map((row, i) => (
                <div style={{ textAlign: align }} key={i}>
                    {row.map((el, j) => (
                        <span key={j} className={el.className}>
                            {el.text}
                        </span>
                    ))}
                </div>
            ))}
        </Block>
    );
}
