import React from 'react';
import Loading from '../../components/common/Loading';
import FrameLearning from '../../components/FrameLearning';
import useLearningWindow from '../../hooks/useLearningWindow';
import styled from 'styled-components';
import ModalRobot from '../../components/common/ModalRobot';
import Feedback from './components/Feedback';
import ProgramDetailModal from './components/ProgramDetailModal';
import TeacherCallModal from './components/TeacherCallModal';
import Layout from '../../components/Layout';
import Toast from '../../components/Toast';
const Container = styled.div`
    visibility: ${({ isShow }) => (isShow ? 'hidden' : 'visible')};
`;
function LearningContainer() {
    const {
        frameLearning,
        programId,
        iframe,
        modalInfo,
        loading,
        timerPause,
        feedback,
        programDetailModal,
        onProgramDetailModalClick,
        onLoad,
        onHomeButtonClick,
        onProgramDetailClick,
        onTeacherCallButtonClick,
        onInformationButtonClick,
        onFrameEnd,
        onQuestionEnd,
        onBeforeLoad,
        onRepeatGenerate,
        onTrackingEnd,
        onProgramEnd,
        onTimerEnd,
        onBasicReTry,
        onBeforeTimer,
        onG2Clear,
        onInfoTextClick,
        onNoAction,
        isDiagnosis,
        helpRemainCount,
        toastData,
        error,
        onError,
        onHandwriteRecognitionError,
        onNextFrame
    } = useLearningWindow();
    if (error) throw error;
    return (
        <Layout>
            {frameLearning && (
                <Container isShow={loading}>
                    <FrameLearning
                        programId={programId}
                        iframe={iframe}
                        frames={frameLearning.frames}
                        frameCodes={frameLearning.frameCodes}
                        diagnosisStartProgress={frameLearning.diagnosisStartProgress}
                        timerPause={timerPause}
                        onHomeButtonClick={onHomeButtonClick}
                        onProgramDetailClick={onProgramDetailClick}
                        onTeacherCallButtonClick={isDiagnosis ? null : onTeacherCallButtonClick}
                        onInformationButtonClick={isDiagnosis ? null : onInformationButtonClick}
                        onFrameEnd={onFrameEnd}
                        onBeforeLoad={onBeforeLoad}
                        onRepeatGenerate={onRepeatGenerate}
                        onTrackingEnd={onTrackingEnd}
                        onProgramEnd={onProgramEnd}
                        onTimerEnd={onTimerEnd}
                        onBasicReTry={onBasicReTry}
                        onBeforeTimer={onBeforeTimer}
                        onG2Clear={onG2Clear}
                        onInfoTextClick={onInfoTextClick}
                        onLoad={onLoad}
                        onQuestionEnd={onQuestionEnd}
                        onNoAction={onNoAction}
                        programAchievementDegree={
                            frameLearning.programResult && frameLearning.programResult.achievementDegree
                        }
                        programFrameCount={frameLearning.programResult && frameLearning.programResult.frameCount}
                        helpRemainCount={helpRemainCount}
                        onError={onError}
                        onHandwriteRecognitionError={onHandwriteRecognitionError}
                        onNextFrame={onNextFrame}
                    ></FrameLearning>
                </Container>
            )}
            {loading && <Loading></Loading>}
            {modalInfo && !modalInfo.type && <ModalRobot {...{ ...modalInfo }}></ModalRobot>}
            {modalInfo && !!modalInfo.type && (
                <TeacherCallModal {...{ ...modalInfo }} preventClick={toastData.show}></TeacherCallModal>
            )}
            {feedback.type && (
                <Feedback key={feedback.count} type={feedback.type} isDiagnosis={feedback.isDiagnosis}></Feedback>
            )}
            {programDetailModal.show && (
                <ProgramDetailModal
                    data={programDetailModal.data}
                    onClick={onProgramDetailModalClick}
                ></ProgramDetailModal>
            )}
            {toastData.show && <Toast message={toastData.message}></Toast>}
        </Layout>
    );
}

export default LearningContainer;
