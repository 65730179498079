import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Loading from '../../../components/common/Loading';
const Block = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    opacity: ${({ view }) => (view ? 1 : 0)};
    transition: opacity 1s;
    .videoWrapper {
        width: 100%;
        max-width: 1200px;
        height: 100%;
        border: none;
        background: #000;
        video {
            /* object-fit: cover; */
        }
    }
    .playButton {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: red;
        font-size: 30px;
    }
`;
export default function Travel({ robotRewardTotalCount, onTravelEnd, travelState }) {
    const ref = useRef();
    const scene = useRef((robotRewardTotalCount % 10) + 1);
    const [view, setView] = useState(false);
    const [buffer, setBuffer] = useState(false);
    const handleEnd = () => {
        setView(false);
        onTravelEnd();
    };
    const handleBuffer = () => {
        setBuffer(true);
    };
    const handleBufferEnd = () => {
        setBuffer(false);
    };
    useEffect(() => {
        if (travelState === 1) {
            setView(true);
        }
    }, [travelState]);
    return (
        <Block view={view}>
            {buffer && <Loading />}
            <ReactPlayer
                ref={ref}
                width="100%"
                height="100%"
                className="videoWrapper"
                url={`https://jei-mp4.jei.com/jeiai/MATH/reward_${scene.current
                    .toString()
                    .padStart(2, '0')}_HD.mp4`}
                playsinline={true}
                onEnded={handleEnd}
                onBuffer={handleBuffer}
                onBufferEnd={handleBufferEnd}
            ></ReactPlayer>
        </Block>
    );
}
