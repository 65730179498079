import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import media from '../../../utils/media';

import { ReactComponent as Back } from '../../../assets/images/back_white.svg';
import DetailHeaderComponent from './DetailHeaderComponent';
import DetailListComponent from './DetailListComponent';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #f2f5f5;
    position: relative;
    /* padding: 0 1.6rem; */
    .title {
        margin: 20px;
    }
    .back1 {
        fill: #444444;
    }
    .back {
        align-self: flex-start;
        flex-shrink: 0;
        width: 2em;
        height: auto;
        cursor: pointer;
        ${media.xsmall} {
            align-self: center;
            height: 59.19px;
        }
    }
`;
const HeaderContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
    height: auto;
    background: #f2f5f5;
`;
const BackContainer = styled.div`
    width: 100%;
    padding: 0 1.6rem;
`;
const BackBlock = styled.div`
    position: relative;
    top: 15px;
    padding: 0;
    cursor: pointer;
    max-width: 1200px;
    margin: 0 auto;
`;
const ListContainer = styled.div`
    overflow: auto;
    background: #fff;
    position: relative;
`;

export default function DetailViewComponent({
    url,
    viewType,
    detail,
    grade,
    domain,
    setDomain,
    status,
    setStatus,
    learningAchievement,
    diagnosisHistory,
    programHistory,
    listLoading,
    curriculumInfos
}) {
    const history = useHistory();
    const title = viewType + '/' + detail === url[4] ? '실력 알아보기 결과' : '다시 학습해야 하는 내용';

    return (
        <Container>
            <BackContainer>
                <BackBlock onClick={() => history.replace('/report')}>
                    <Back className="back h4" />
                </BackBlock>
            </BackContainer>
            <HeaderContainer>
                <div className="title text-align-center bold h4">{title}</div>
                <DetailHeaderComponent
                    url={url}
                    viewType={viewType}
                    detail={detail}
                    status={status}
                    setStatus={setStatus}
                    domain={domain}
                    setDomain={setDomain}
                    learningAchievement={learningAchievement}
                />
            </HeaderContainer>
            <ListContainer>
                <DetailListComponent
                    url={url}
                    viewType={viewType}
                    detail={detail}
                    grade={grade}
                    status={status}
                    domain={domain}
                    diagnosisHistory={diagnosisHistory}
                    programHistory={programHistory}
                    listLoading={listLoading}
                    curriculumInfos={curriculumInfos}
                />
            </ListContainer>
        </Container>
    );
}
