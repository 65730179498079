import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DiagnosisImg1 } from '../../../assets/images/icon-anim/AP2-1.svg';
import { ReactComponent as DiagnosisImg2 } from '../../../assets/images/icon-anim/AP2-2.svg';
import { ReactComponent as DiagnosisImg3 } from '../../../assets/images/icon-anim/AP2-3.svg';
import { ReactComponent as DiagnosisImg4 } from '../../../assets/images/icon-anim/AP2-4.svg';
import Button from '../../../components/common/Button';
import AP from '../../../assets/texts/AP';
import { breakPoints } from '../../../components/FrameLearning/util/media';
import Carousel from '../../../components/Carousel';
import { isMobile } from '../../../utils/util';
import useSound from '../../../hooks/useSound';
import { useSelector } from 'react-redux';
const Block = styled.div`
    padding: 1.6rem;
    width: 100%;
    height: 100%;
    margin: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f2f5f5;
    & > .card-container {
        width: 100%;
        /* flex-grow: 1; */
        flex-shrink: 1;
        max-width: ${breakPoints.large}px;

        & > div {
            width: 100%;
            /* height: 100%; */
            display: flex;
            align-items: center;
        }
        .el {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0 3em 0;
        }
        svg {
            height: 6em;
            width: auto;
            margin-bottom: 1em;
        }
    }
    & > .button-container {
        height: 5em;
        padding-top: 2em;
        padding-bottom: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
            width: 8em;
        }
    }
`;
const txtObj = AP(2).text;
const imgs = [<DiagnosisImg1 />, <DiagnosisImg2 />, <DiagnosisImg3 />, <DiagnosisImg4 />];
export default function DiagnosisIntro({ onClick }) {
    const { gradeCode } = useSelector(({ learningInfo }) => learningInfo);
    const [index, setIndex] = useState(0);
    useSound({ src: txtObj[index].soundSrc });
    const introLength = ['A', 'B', 'C'].includes(gradeCode) ? 3 : 4;
    return (
        <Block className="h4">
            <div className="card-container">
                <div>
                    <Carousel
                        autoPlay={{ cycle: false, duration: 3000 }}
                        onIndexChange={setIndex}
                        showIndex={true}
                        hideArrow={isMobile()}
                    >
                        {imgs.slice(0, introLength).map((el, i) => (
                            <div className="el" key={i}>
                                {i === index && el}
                                {txtObj[i + 1].text.split('\n').map((text, j) => (
                                    <div key={j}>{text}</div>
                                ))}
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
            <div className="button-container">
                {index === introLength - 1 && <Button className="btn" text="확인" onClick={onClick}></Button>}
            </div>
        </Block>
    );
}
