import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import Dialog from '../../../components/common/Dialog';
import { ReactComponent as Pass } from '../../../assets/images/learning/star_success.svg';
import { ReactComponent as NotPass } from '../../../assets/images/learning/star_fail.svg';
import { ReactComponent as NotStart } from '../../../assets/images/learning/no-progress.svg';
import { ReactComponent as ShootingStar } from '../../../assets/images/reward-items/shooting-star.svg';
import media from '../../../utils/media';
const Block = styled.div`
    background: white;
    .program-detail-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 465px;
        padding-right: 25px;
        word-break: keep-all;
        ${media.medium} {
            width: 300px;
            padding-right: 20px;
        }
        svg {
            height: 1.5em;
            width: auto;
        }
        & > div {
            width: 100%;
        }
    }
    .btn {
        padding: 0.5em;
    }
    .frames-container {
        width: 480px;
        padding: 0 18px;
        font-size: 24px;
        ${media.medium} {
            width: 320px;
            padding: 0 10px;
            font-size: 15px;
        }
    }
    .frames {
        overflow: auto;
        height: 300px;
        ${media.medium} {
            height: 200px;
        }
        .first {
            background: ${theme.palette.primaryLight};
        }
        & > div {
            display: flex;
            align-items: center;

            border-radius: 1.5em;
            padding: 14px;
            ${media.medium} {
                padding: 10px;
            }
            .state {
                position: relative;
                width: 1.5em;
                height: 1.5em;
                /* color: white; */
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1em;
                & > span {
                    position: absolute;
                    margin-top: -0.1em;
                }
            }
        }
    }
    .btn {
        width: 8em;
        margin: 0 auto;
    }
    hr {
        border-top: 1px solid #f5f5f5;
        margin: 18px 0 33px 0;
        ${media.medium} {
            margin: 10px 0 20px 0;
        }
    }
    .frameName {
        word-break: keep-all;
    }
`;
export default function ProgramDetailModal({ data, onClick }) {
    useEffect(() => {
        document.getElementsByClassName('first')[0]?.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <Block>
            <Dialog
                onClose={onClick}
                title={
                    <div className="program-detail-title">
                        <div>{data.programName}</div>
                        <ShootingStar />
                    </div>
                }
                body={
                    <div className="frames-container">
                        <div className="frames">
                            {data.frames &&
                                data.frames.map((el, i) => {
                                    return (
                                        <div
                                            id={el.frameId}
                                            key={el.frameId}
                                            className={el.frameId === data.frameId ? 'first' : ''}
                                        >
                                            <div className="state">
                                                {el.passYn === '0' && <NotPass />}
                                                {el.passYn === '1' && <Pass />}
                                                {el.passYn === null && <NotStart />}
                                                <span> {el.questionCount}</span>
                                            </div>
                                            <div className="frameName">{el.frameName}</div>
                                        </div>
                                    );
                                })}
                        </div>
                        <hr></hr>
                    </div>
                }
                buttons={[{ text: '확인', onClick }]}
            />
        </Block>
    );
}
