export default function CL(type) {
    return [
        '아이디와 비밀번호를 입력하세요.',
        '올바른 아이디가 아닙니다.\n확인 후 다시 입력하세요!',
        '비밀번호가 일치하지 않습니다.\n확인 후 다시 입력하세요!',
        '사용할 수 없는 ID입니다.',
        '사용할 수 없는 ID입니다.',
        '현재 로그인 할 수 없습니다.\n관리자에게 문의하세요.',
        '서비스 점검 중',
        '네트워크에 접속할 수 없습니다.\n네트워크 연결 상태를 확인하세요.'
    ][type - 1];
}
