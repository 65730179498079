import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as Close } from '../../../assets/images/reward-heart/button_Exit.svg';
import { ReactComponent as Telescope } from '../../../assets/images/reward-heart/button_telescope.svg';
import { breakPoints } from '../../../utils/media';
import { ReactComponent as BG } from '../../../assets/images/reward-heart/bg_full.svg';
import { ReactComponent as Window } from '../../../assets/images/reward-heart/bg_light.svg';
import SoundButton from '../../../components/SoundButton';
import useRewardPageTransition from '../../../hooks/useRewardPageTransition';

const fadeIn = keyframes`
    from{
        opacity:0.3
    }
    to{
        opacity:1
    }
`;
const Block = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.anim {
        animation: ${fadeIn} 1s linear;
    }
    & > .header {
        display: flex;
        justify-content: space-between;
        max-width: ${breakPoints.large}px;
        width: 100%;
        padding: 1.6rem;
        z-index: 2;
        & > svg {
            cursor: pointer;
            width: auto;
            height: 3em;
            transition: opacity 1s;
            &.hide {
                opacity: 0;
            }
        }
    }
    & > .main {
        max-width: ${breakPoints.large}px;
        width: 100%;
    }
    & > .black-back {
        width: 100%;
        height: 100%;
        position: fixed;
        background: black;
    }
    & > .back {
        z-index: 0;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        position: fixed;
    }
    .window {
        position: fixed;
        pointer-events: none;
        z-index: 100;
        margin-top: 20px;
        width: calc(100% - 3.2rem - 6em);
        max-width: calc(1200px - 3.2rem - 6em);
        height: auto;
    }
`;
export default function Layout({ children, travel }) {
    const { rewardPageTransition, setTransitionState } = useRewardPageTransition();

    const history = useHistory();
    const onClose = () => {
        history.push('/main');
        setTransitionState(false);
    };
    const onTelescope = () => {
        history.push('/reward-item');
        setTransitionState(true);
    };
    return (
        <Block className={rewardPageTransition ? 'anim' : ''}>
            <div className="black-back"></div>
            <BG className="back" preserveAspectRatio="xMidYMin slice" />
            <Window className="window" preserveAspectRatio="xMinYMin"></Window>

            <div className="header h6">
                <SoundButton>
                    <Close onClick={onClose}></Close>
                </SoundButton>
                <SoundButton>
                    <Telescope className={travel ? 'hide' : ''} onClick={onTelescope}></Telescope>
                </SoundButton>
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <div className="main">{children}</div>
        </Block>
    );
}
