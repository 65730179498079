import React from 'react';
import styled from 'styled-components';
import SVGIcon from '../../../components/SVGIcon';
const Block = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    position: relative;
    z-index: 10;
    .circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }
    .type-0 {
        background: #828282;
    }
    .type-1 {
        background: #ffb300;
    }
    .type-2 {
        background: #60a4f0;
    }
    .type-3 {
        background: #ff7878;
    }
    svg.program-icon {
        margin: 0;
    }
`;
export default function ProgramLearningStatus({ type }) {
    return (
        <Block>
            {type !== 1 && <div className={`circle type-${type}`}></div>}
            {type === 1 && <SVGIcon className="program-icon" src="/status_3.svg"></SVGIcon>}
        </Block>
    );
}
