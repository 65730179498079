import { createGetRequest, createPostRequest } from './util';
const host = `https://${process.env.REACT_APP_LMS_HOST}`;
export const getLearnerInfo = () => createGetRequest(`${host}/learner/learning/basic-info`);
export const getLearningGrade = () => createGetRequest(`${host}/learner/learning/grade`);
export const getLearningCurriculum = ({ gradeCode }) =>
    createGetRequest(`${host}/learner/learning/curriculum/${gradeCode}`);
export const getLearningProgram = ({ gradeCode, curriculumCode }) =>
    createGetRequest(`${host}/learner/learning/program/${gradeCode}/${curriculumCode}`);
export const getLearningRepeatProgram = ({ gradeCode, curriculumCode }) =>
    createGetRequest(`${host}/learner/learning/repeat-program/${gradeCode}/${curriculumCode}`);
export const getLearningFrame = ({ gradeCode, curriculumCode, programId }) =>
    createGetRequest(`${host}/learner/learning/frame/${gradeCode}/${curriculumCode}/${programId}`);
export const getRepeatLearningFrame = ({ gradeCode, curriculumCode, programId }) =>
    createGetRequest(`${host}/learner/learning/repeat-frame/${gradeCode}/${curriculumCode}/${programId}`);
//frame-learning
export const postLearningHistory = body => createPostRequest(`${host}/learner/learning/history`, body);
export const getLearningResult = ({ gradeCode, curriculumCode, programId, learningHistoryStatusCode }) =>
    createGetRequest(
        `${host}/learner/learning/result/${gradeCode}/${curriculumCode}/${programId}/${learningHistoryStatusCode}`
    );
export const getLearningStart = ({ gradeCode, curriculumCode }) =>
    createGetRequest(`${host}/learner/learning/start/${gradeCode}/${curriculumCode}`);
export const getDiagnosisQuestionInfo = ({ curriculumCode }) =>
    createGetRequest(`${host}/learner/learning/diagnosis-question/${curriculumCode}`);

export const postLearningTracking = body => createPostRequest(`${host}/learner/learning/tracking`, body);
export const getProgramDetail = ({ gradeCode, curriculumCode, programId }) =>
    createGetRequest(`${host}/learner/learning/program-detail/${gradeCode}/${curriculumCode}/${programId}`);
export const getRepeatProgramDetail = ({ gradeCode, curriculumCode, programId }) =>
    createGetRequest(`${host}/learner/learning/repeat-program-detail/${gradeCode}/${curriculumCode}/${programId}`);
export const postLearningTargetTime = body => createPostRequest(`${host}/learner/learning/target-time`, body);
export const getLearningTargetTime = body => createGetRequest(`${host}/learner/learning/target-time`, body);

//reward
export const getRewardHistory = () => createGetRequest(`${host}/learner/reward/history`);
export const getRewardHeart = ({ yearMonth }) => createGetRequest(`${host}/learner/reward/heart/${yearMonth}`);
export const getRewardHeartMonth = () => createGetRequest(`${host}/learner/reward/heart/month`);
export const getRewardBadge = () => createGetRequest(`${host}/learner/reward/badge`);
export const getRewardToday = () => createGetRequest(`${host}/learner/reward/today`);

export const postRewardAttend = () => createPostRequest(`${host}/learner/reward/attend`);
export const postRewardHabit = () => createPostRequest(`${host}/learner/reward/habit`);
export const postRewardConstellation = () => createPostRequest(`${host}/learner/reward/constellation`);
export const postRewardHistory = body => createPostRequest(`${host}/learner/reward/history`, body);
export const postRewardConstellationCheck = () => createPostRequest(`${host}/learner/reward/constellation/checked`);

//report
export const getLearningStatus = ({ grade }) => createGetRequest(`${host}/learner/report/status/${grade}`);
export const getLearningHabit = () => createGetRequest(`${host}/learner/report/habit`);
export const getLearningAchievement = ({ grade }) => createGetRequest(`${host}/learner/report/achievement/${grade}`);
export const getDiagnosisHistory = ({ grade, status }) =>
    createGetRequest(`${host}/learner/report/achievement/diagnosis/${grade}/${status}`);
export const getProgramHistory = ({ grade, domain }) =>
    createGetRequest(`${host}/learner/report/achievement/programs/${grade}/${domain}`);

//도움 요청
export const getHelpRemainCount = () => createGetRequest(`${host}/learner/learning/help/remain-count`);
export const postLearningHelp = body => createPostRequest(`${host}/learner/learning/help`, body);
export const getHelpDetail = helpHistoryDateTime =>
    createGetRequest(`${host}/learner/learning/help/detail/${helpHistoryDateTime}`);
export const postHelpMemoPath = body => createPostRequest(`${host}/learner/learning/help/memo-path`, body);
export const getLearningHelp = ({ startDate, endDate }) =>
    createGetRequest(`${host}/learner/learning/help/list/${startDate}/${endDate}`);
export const postHelpChecked = ({ helpHistoryDateTime }) =>
    createPostRequest(`${host}/learner/learning/help/checked/${helpHistoryDateTime}`);
//학습체계도
export const getLearningMapData = () => createGetRequest(`${host}/learner/report/map`);

//wrong-answer
export const getWrongAnswers = ({ startDate, endDate }) =>
    createGetRequest(`${host}/learner/learning/tracking/list/${startDate}/${endDate}`);
export const getLearningTracking = ({ learningDate, programId }) =>
    createGetRequest(`${host}/learner/learning/tracking/detail/${learningDate}/${programId}`);

export const postLearningLog = body => createPostRequest(`${host}/learner/learning/log`, body);

export const getNotice = () => createGetRequest(`${host}/common/notice/11`);
