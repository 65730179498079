import React from 'react';
import styled, { keyframes } from 'styled-components';
import SVGIcon from '../../../components/SVGIcon';
import { grades } from '../../../utils/dataMap';
import media, { breakPoints } from '../../../utils/media';
const floating = keyframes`
    0%,100% {
    transform: translateY(0);
	}
    50%{
        transform:translateY(-15%);
    }
`;
const Block = styled.div`
    position: fixed;
    z-index: 10;
    top: calc(3.2rem + 5em);
    max-width: ${breakPoints.large}px;
    width: 100%;
    margin: 0 auto;
    opacity: 0.5;
    animation: ${floating} 6s infinite ease-in-out;
    display: flex;
    justify-content: flex-end;
    transition: opacity 1s;
    &.hide {
        opacity: 0;
    }
    ${media.xsmall} {
        justify-content: center;
    }
    .planet {
        height: 20em;
        width: 20em;
    }
`;
export default function Planet({ grade, hide }) {
    return (
        <Block className={`h6${hide ? ' hide' : ''}`}>
            <SVGIcon className="planet" src={`/reward-items/planet_${grades.indexOf(grade) + 1}.svg`}></SVGIcon>
        </Block>
    );
}
