import React from 'react';
import styled from 'styled-components';
const Block = styled.div`
    position: absolute;
    width: 100%;
    height: 3em;
    display: flex;
    & > .main {
        border: 2px solid #1c8a7f;
        border-radius: 10px;
        width: 93%;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        overflow: hidden;
        & > .bar {
            position: absolute;
            z-index: 0;
            left: 0;
            height: 100%;
            background-color: #71f0e3;
        }
        & > .graduation {
            z-index: 0;
            height: 15%;
            width: 2px;
            background-color: #1c8a7f;
        }
    }
    & > .side {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 7%;
        & > .number {
            position: absolute;
            top: -20%;
            left: 17%;
            color: #1c8a7f;
        }
        & > .edge {
            position: absolute;
            left: 0;
            background-color: #1c8a7f;
            width: 30%;
            height: 30%;
        }
    }
`;
export default function Gauge({ progress, className }) {
    return (
        <Block className={className}>
            <div className="main">
                <div className="bar" style={{ width: progress + '%' }}></div>
                <div className="graduation"></div>
                <div className="graduation"></div>
                <div className="graduation"></div>
                <div className="graduation"></div>
                <div className="graduation"></div>
            </div>
            <div className="side">
                <div className="number">30</div>
                <div className="edge"></div>
            </div>
        </Block>
    );
}
