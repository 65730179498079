import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ProgramList from './components/ProgramList';
import Loading from '../../components/common/Loading';
import AP from '../../assets/texts/AP';
import useLearnerInfo from '../../hooks/useLearnerInfo';
import useHabitState from '../../hooks/useHabitState';
import useLearningStart from '../../hooks/useLearningStart';
import { setPreviousPage, setProgram } from '../../reducers/learningInfo';
import DiagnosisIntro from './components/DiagnosisIntro';
import TimeoverModal from './components/TimeoverModal';
import { getLearningProgram } from '../../reducers/learningProgram';
import MainLayout from '../../components/MainLayout';
import useGetHeartReward from '../../hooks/useGetHeartReward';
import MainRewardModal from '../../components/MainRewardModal';
import { setDiagnosisIntroCount, isDiagnosisIntroSkip } from '../../utils/cookies';

import moment from 'moment';
import RepeatProgramContainer from './RepeatProgramContainer';
import useProgramPage from '../../hooks/useProgramPage';

function ProgramContainer() {
    const { setFocusIndex, focusIndex, focusArea } = useProgramPage();
    const { data: programInfos, loading: programLoading, error } = useSelector(state => state.learningProgram);
    const { currentFocusIndex, gradeCode, curriculumCode } = useSelector(state => state.learningInfo);
    const { data: curriculumInfos } = useSelector(state => state.learningCurriculum);
    const curriculumInfo = useRef(curriculumInfos && curriculumInfos.find(el => el.code === curriculumCode));
    const [diagnosisIntro, setDiagnosisIntro] = useState(false);
    const [selectedProgramId, setSelectedProgramId] = useState('');
    const { data: learnerInfo } = useLearnerInfo();
    const { habitState, getHabitState } = useHabitState();
    const { modalInfo } = useGetHeartReward();
    const [modal, setModal] = useState(false);
    const programName = useRef();
    const [initialLoading, setInitialLoading] = useState(true);
    const [flowError, setFlowError] = useState();
    const message = useMemo(() => {
        if (diagnosisIntro) return { ...AP(2).text[0], robotSrc: AP(2).robotSrc }; // 진단프로그램 시작전 인트로 메세지
        if (focusArea === 1) return AP(6, { name: curriculumInfo.current.name });
        if (programInfos && Number.isInteger(focusIndex)) {
            //프로그램 선택시
            if (focusIndex >= 0) {
                //진단 눌렀을때
                if (focusIndex === 0) {
                    //진단 처음
                    if (programInfos[0].status === 0) return AP(1, { name: programInfos[0].programName });
                    //진단 진행중
                    if (programInfos[0].status === 1) return AP(3, { name: programInfos[0].programName });
                    //진단 완료
                    if (programInfos[0].status > 1) {
                        //처방 있을 때
                        if (programInfos.length > 1) {
                            return AP(11, { name: programInfos[0].programName });
                        }
                        return AP(6, { name: programInfos[0].programName });
                    }
                }
                //프로그램 눌렀을 때
                else {
                    //프로그램 달성
                    if (programInfos[focusIndex].status === 2)
                        return AP(9, { name: programInfos[focusIndex].programName });
                    //프로그램 미달성
                    if (programInfos[focusIndex].status === 3)
                        return AP(8, { name: programInfos[focusIndex].programName });
                    //프로그램 학습중
                    if (programInfos[focusIndex].status === 1) {
                        //프로그램 처음
                        if (
                            programInfos[focusIndex].status === 1 &&
                            programInfos[focusIndex].programResult.learningTime === 0
                        )
                            return AP(4, { name: programInfos[focusIndex].programName });
                        //프로그램 이미 학습시작
                        if (
                            programInfos[focusIndex].status === 1 &&
                            programInfos[focusIndex].programResult.learningTime > 0
                        )
                            return AP(5, { name: programInfos[focusIndex].programName });
                    }
                }
            }
            // 아무 프로그램도 선택하지 않을 시
            if (focusIndex < 0) {
                //처방 존재하지 않을 때
                if (programInfos.length === 1) {
                    // 진단을 아직 시작하지 않음
                    if (programInfos[0].status === 0) return AP(1, { name: programInfos[0].programName });
                    // 진단 중
                    if (programInfos[0].status === 1) return AP(3, { name: programInfos[0].programName });
                }
                //처방 프로그램이 존재
                else {
                    const currentProgram = programInfos.find(el => el.status === 1);
                    //현재 진행중인 프로그램이 있을 때
                    if (currentProgram) {
                        //아직 프로그램을 시작하지 않음
                        if (currentProgram.programResult.learningTime === 0)
                            return AP(4, { name: currentProgram.programName });
                        //프로그램 진행 중
                        if (currentProgram.programResult.learningTime > 0)
                            return AP(5, { name: currentProgram.programName });
                    }
                }
                //프로그램 달성
                if (programInfos.every(el => el.status > 1)) {
                    const curriculumName = curriculumInfo.current.name;
                    if (programInfos.some((el, i) => el.status === 3 && i > 0)) return AP(7, { name: curriculumName });
                    return AP(6, { name: curriculumName });
                }
            }
        }
        return '';
    }, [diagnosisIntro, programInfos, focusIndex, focusArea]);
    const dispatch = useDispatch();
    const history = useHistory();
    const startFlow = useLearningStart();
    const loading = initialLoading || programLoading || !habitState;
    const startFlowWrapper = async ({ programId, programName, isRepeat }) => {
        try {
            await startFlow({ programId, programName, isRepeat });
        } catch (error) {
            setFlowError(error);
        }
    };
    const onButtonClick = ({ programId, status, index, programName: _programName }) => {
        const isDiagnosis = programId.indexOf('DG') !== -1;
        const { learner_id, subject_status_code, learning_end_date } = learnerInfo;
        setFocusIndex(index);
        dispatch(setPreviousPage('/program'));
        programName.current = _programName;
        if (isDiagnosis && (status === 2 || status === 3)) {
            // 문항보기
            dispatch(setProgram({ programId, programName: _programName }));
            history.replace('/diagnosis-question');
        } else if (
            habitState.result !== 'belowStandards' &&
            (subject_status_code === '0' || subject_status_code === '1')
        ) {
            //체험회원
            if (subject_status_code === '0') {
                if (moment(learning_end_date).format('YYYYMMDD') === moment().format('YYYYMMDD')) setModal(11);
                else setModal(6);
            }
            //서비스회원
            if (subject_status_code === '1') setModal(10);
        } else if (isDiagnosis) {
            const skip = isDiagnosisIntroSkip(`${learner_id}-01-${['A', 'B', 'C'].includes(gradeCode) ? '01' : '02'}`);
            //진단 문항 시작
            setSelectedProgramId(programId);
            if (skip) return startFlowWrapper({ programId, programName: programName.current });
            setDiagnosisIntro(true);
        } else {
            // 학습하기
            startFlowWrapper({ programId, programName: programName.current });
        }
    };
    const onDiagnosisIntroClick = async () => {
        setDiagnosisIntroCount(`${learnerInfo.learner_id}-01-${['A', 'B', 'C'].includes(gradeCode) ? '01' : '02'}`);
        startFlowWrapper({ programId: selectedProgramId, programName: programName.current });
    };
    const onRepeatButtonClick = p => {
        const { subject_status_code, learning_end_date } = learnerInfo;
        if (habitState.result !== 'belowStandards' && (subject_status_code === '0' || subject_status_code === '1')) {
            //체험회원
            if (subject_status_code === '0') {
                if (moment(learning_end_date).format('YYYYMMDD') === moment().format('YYYYMMDD')) setModal(11);
                else setModal(6);
            }
            //서비스회원
            if (subject_status_code === '1') setModal(10);
            return;
        }
        dispatch(setPreviousPage('/program'));
        startFlowWrapper(p);
    };
    useEffect(() => {
        if (gradeCode && curriculumCode) dispatch(getLearningProgram({ curriculumCode, gradeCode }));
    }, [dispatch, gradeCode, curriculumCode]);
    useEffect(() => {
        if (!gradeCode || !curriculumCode) history.replace('/main');
    }, [gradeCode, curriculumCode, history, dispatch]);
    useEffect(() => {
        if (programInfos) setInitialLoading(false);
        if (Number.isInteger(focusIndex)) return;
        if (programInfos?.length > 1) {
            // 처방프로그램 있을 때, 포커스 설정
            const statusArr = programInfos.map(el => el.status);
            let focus = statusArr.indexOf(1, 1); //현재 진행 중
            if (focus < 0) {
                focus = statusArr.indexOf(3, 1); //미달성 프로그램 존재(여러 개일 경우 위에 프로그램 기준)
                if (focus < 0) focus = -1; //모든 프로그램 달성(포커스 없음)
            }

            setFocusIndex(focus);
        } else if (programInfos) {
            setFocusIndex(0);
        }
    }, [currentFocusIndex, programInfos, setFocusIndex, focusIndex]);

    useEffect(() => {
        if (!habitState) getHabitState();
    }, [habitState, getHabitState]);

    if (error || flowError) throw error || flowError;
    return (
        <>
            <MainLayout back message={message.text} robotSrc={message.robotSrc}>
                {loading && <Loading></Loading>}
                {programInfos && !diagnosisIntro && (
                    <ProgramList
                        loading={loading}
                        programInfos={programInfos}
                        onButtonClick={onButtonClick}
                        focusIndex={focusIndex}
                        setFocusIndex={setFocusIndex}
                        curriculumStatus={curriculumInfo.current.status}
                    />
                )}
                {programInfos && !diagnosisIntro && (
                    <RepeatProgramContainer
                        programInfos={programInfos}
                        gradeCode={gradeCode}
                        curriculumCode={curriculumCode}
                        onButtonClick={onRepeatButtonClick}
                    ></RepeatProgramContainer>
                )}
                {diagnosisIntro && <DiagnosisIntro onClick={onDiagnosisIntroClick}></DiagnosisIntro>}
                {modal && <TimeoverModal txtType={modal} onClick={setModal.bind(null, false)}></TimeoverModal>}
                {modalInfo && <MainRewardModal {...{ ...modalInfo }}></MainRewardModal>}
            </MainLayout>
        </>
    );
}

export default ProgramContainer;
