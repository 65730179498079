import React from 'react';
import styled from 'styled-components';
import theme from '../../assets/styles/theme';
import { ReactComponent as TeacherIcon } from './images/teacher.svg';

import WithGlitter from '../WithGlitter';
import SoundButton from '../SoundButton';
const Block = styled.div`
    border-radius: 1.5em;
    background: ${theme.palette.primary};
    height: 100%;
    color: ${theme.palette.white};
    display: flex;
    align-items: center;
    margin-right: 1em;
    position: relative;
    cursor: pointer;
    svg.teacher-icon {
        height: 1.5em;
        width: auto;
    }

    .container {
        margin-left: 0.25em;
        height: 100%;
        padding: 0.75em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .help {
        margin: 0 0.5em;
    }
`;
export default function Help({ onTeacherCallButtonClick, helpRemainCount }) {
    return (
        <SoundButton>
            <Block onClick={onTeacherCallButtonClick} className="bold">
                <WithGlitter className="container" glitterColor="#62E6E6" glitterWidth={'0.6em'}>
                    <TeacherIcon className="teacher-icon"></TeacherIcon>
                    <div className="help">도와줘요!</div>
                    <div>{helpRemainCount}회</div>
                </WithGlitter>
            </Block>
        </SoundButton>
    );
}
