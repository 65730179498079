import { preloadAudios } from './util';

global.JEIAUDIOS = {};

global.Array.prototype.last = function () {
    return this[this.length - 1];
};

const audioList = ['/sounds/OS6_ver2.mp3'];
preloadAudios(audioList);
