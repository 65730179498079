import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    overflow-x: auto;
    flex: 1;
    background: #f2f5f5;
    height: 100%;    
    &.padding {
        padding: 1.6rem;
    }
    .flex {
        display: flex;
    }
    .justify-around {
        justify-content: space-around;
    }
    .justify-between {
        justify-content: space-between;
    }
    .justify-center {
        justify-content: center;
    }
    .justify-end {
        justify-content: flex-end;
    }
    .align-center {
        align-items: center;
    }
    .text-align-right {
        text-align: right;
    }
    .text-align-center {
        text-align: center;
    }
    .cursor-pointer {
        cursor: pointer;
    }
`;

export default function ContentsComponent({ children, padding }) {
    return <Container className={`${padding ? 'padding' : ''}`}>{children}</Container>;
}
