import React, { useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import theme from '../../assets/styles/theme';

const move = keyframes`
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
`;

const Block = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: ${theme.palette.primary};
    .paused {
        animation-play-state: paused;
    }
`;

const Bar = styled.div`
    width: ${({ isTracking, timer }) => (isTracking ? timer : 100)}%;
    height: 100%;
    transform-origin: center left;
    background: ${({ timer }) => (timer ? theme.palette.timer : theme.palette.primary)};
    ${({ timer, isTracking }) => {
        if (timer && !isTracking) {
            return css`
                animation: ${move} ${timer}s linear;
            `;
        }
    }};
`;

export default function Timer({ paused, onTimerEnd, isTracking, timer }) {
    useEffect(() => {
        const anim = document.getElementById('anim');
        anim.addEventListener('animationend', onTimerEnd);
        return () => {
            anim.removeEventListener('animationend', onTimerEnd);
        };
    }, [onTimerEnd]);
    return (
        <Block>
            <Bar id="anim" timer={timer} className={paused && 'paused'} isTracking={isTracking} />
        </Block>
    );
}
