import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import Loading from '../../../components/common/Loading';
import Typography from '../../../components/common/Typography';
import { breakPoints } from '../../../components/FrameLearning/util/media';
import DiagnosisQuestionObjective from './DiagnosisQuestionObjective';
import DiagnosisQuestionSmall from './DiagnosisQuestionSmall';
import DiagnosisQuestionViewer from './DiagnosisQuestionViewer';

const Block = styled.div`
    display: flex;
    margin: 0 auto;
    width: 100%;
    background: white;
    max-width: 1200px;
`;

const getConditionDatas = (data, condition) => {
    const conditionKeys = Object.keys(condition);
    return data.filter(el => conditionKeys.every(el2 => el[el2] === condition[el2]));
};

const getObjectives = data => {
    return data.map(el => el.learning_objective_code).filter((el, i, arr) => !i || el !== arr[i - 1]);
};

const getObjectiveResults = data => {
    const objectives = getObjectives(data);
    const objectiveResults = [];

    for (let i = 0; i < objectives.length; i++) {
        const objDatas = getConditionDatas(data, { learning_objective_code: objectives[i] });

        let result = 0;
        for (var j = 0; j < objDatas.length; j++) {
            result += Number(objDatas[j].pass_yn);
        }
        result = result >= 2;
        const description = objDatas[0].learning_objective_description;
        objectiveResults.push({ objective: objectives[i], result, description });
    }
    return objectiveResults;
};

const getViewerInfo = (data, objective) => {
    return getConditionDatas(data, { learning_objective_code: objective });
};

function DiagnosisQuestionList({ data }) {
    const objectiveResults = getObjectiveResults(data);
    const [focusIndex, setFocusIndex] = useState(0);
    const [viewInfo, setViewInfo] = useState(getViewerInfo(data, objectiveResults[0].objective));
    const [size, setSize] = useState();
    const onClick = (objective, index) => {
        if (index !== focusIndex) {
            setFocusIndex(index);
            setViewInfo(getViewerInfo(data, objective));
        }
    };
    const resize = useCallback(() => {
        if (window.innerWidth < breakPoints.xsmall) setSize('small');
        else setSize('normal');
    }, []);
    useEffect(() => {
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [resize]);
    if (!size) return <Loading></Loading>;
    return (
        <Typography className="h6">
            <Block>
                {size === 'normal' && (
                    <>
                        <DiagnosisQuestionObjective objectiveResults={objectiveResults} focusIndex={focusIndex} onClick={onClick} />
                        {viewInfo && <DiagnosisQuestionViewer viewInfo={viewInfo} />}
                    </>
                )}
                {size === 'small' && (
                    <>
                        <DiagnosisQuestionSmall objectiveResults={objectiveResults} focusIndex={focusIndex} onClick={onClick} viewInfo={viewInfo}></DiagnosisQuestionSmall>
                    </>
                )}
            </Block>
        </Typography>
    );
}

export default DiagnosisQuestionList;
