import moment from 'moment';
import { getLearningHelp } from '../api/lms';
import usePromise from './usePromise';

const useLearningHelpBadge = (refetch = true) => {
    const { data } = usePromise(getLearningHelp, !refetch, {
        startDate: moment().subtract(30, 'day').format('YYYYMMDD'),
        endDate: moment().format('YYYYMMDD')
    });
    return {
        isLearningHelpBadge: !!data?.some(el => el.help_check_status_code === '2')
    };
};

export default useLearningHelpBadge;
