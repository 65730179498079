import React, { useState, useEffect, useCallback } from 'react';

import DiagnosisQuestionList from './components/DiagnosisQuestionList';
import Loading from '../../components/common/Loading';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as lms from '../../api/lms';
import AP from '../../assets/texts/AP';
import MainLayout from '../../components/MainLayout';
import usePromise from '../../hooks/usePromise';

function DiagnosisQuestionContainer() {
    const { curriculumCode, program, previousPage } = useSelector(state => state.learningInfo);
    const { data, loading, error: error1 } = usePromise(lms.getDiagnosisQuestionInfo, !curriculumCode, {
        curriculumCode
    });
    const history = useHistory();
    const message = AP(10, { name: program.programName });
    const [iframeError, setIframeError] = useState();
    const handleBack = () => history.replace(previousPage);

    const messageHandler = useCallback(({ data }) => {
        if (data.code === 'error') setIframeError({ response: { status: data.status } });
    }, []);
    useEffect(() => {
        if (!curriculumCode) history.replace('/program');
    }, [curriculumCode, history]);
    useEffect(() => {
        window.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, [messageHandler]);
    const error = error1 || iframeError;
    if (error) throw error;
    return (
        <MainLayout back={handleBack} close message={message.text} robotSrc={message.robotSrc}>
            {data && <DiagnosisQuestionList data={data} />}
            {loading && <Loading />}
        </MainLayout>
    );
}

export default DiagnosisQuestionContainer;
