import { useDispatch, useSelector } from 'react-redux';
import { setRewardPageTransition } from '../reducers/learningInfo';

export default function useRewardPageTransition() {
    const { rewardPageTransition } = useSelector(state => state.learningInfo);
    const dispatch = useDispatch();
    const setTransitionState = bool => {
        dispatch(setRewardPageTransition(bool));
    };
    return { rewardPageTransition, setTransitionState };
}
