import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowUp } from '../../assets/images/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/arrow_down.svg';

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    outline: none;
    z-index: 100;
`;
const SelectBlock = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 0.3em 1em;
    cursor: pointer;
    svg {
        margin-left: 0.5em;
        fill: #22bfbf;
    }
    &.pointer-none {
        pointer-events: none;
        .arrow_down1 {
            fill: #b5b5b6;
            /* fill: transparent; */
        }
    }
`;
const ListBlock = styled.div`
    display: inline-flex;
    flex-direction: column;
    /* padding: 0.5em; */
    position: absolute;
    top: 1.75em;
    background: #fff;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);
    border-radius: 0.1em;
`;
const List = styled.div`
    padding: 0.25em 0.5em;
    cursor: pointer;
    /* margin: 0.1em; */
    text-align: center;
    &:hover {
        background: #ebebeb;
    }
`;

// { list, showIndex, onClick }
// list: [...] || [{display, item}, {display, item}, ...]
// selectIndex: select된 index
// onClick: callback, 리스트 정보를 인자로 전달
// style: {selectBox, select, listBox, list, ...}
// classes: {select, list, ...}

export default function SelectComponent({ list, selectIndex = 0, onClick, style = {}, classes = {} }) {
    const select = list[selectIndex].display ? list[selectIndex].display : list[selectIndex];
    const [open, setOpen] = useState(false);
    const [minWidth, setMinWidth] = useState();
    const selectElement = useRef();

    useEffect(() => {
        const width = selectElement.current.offsetWidth;
        const rate = 0.9;
        setMinWidth(width * rate);
    }, []);

    return (
        <Container className="h5" tabIndex="-1" onBlur={setOpen.bind({}, false)}>
            <SelectBlock
                className={list.length === 1 ? 'pointer-none' : ''}
                onClick={setOpen.bind({}, !open)}
                ref={selectElement}
                style={style.selectBox}
            >
                <div className={classes.select} style={style.select}>
                    {select}
                </div>
                {open ? <ArrowUp /> : <ArrowDown />}
            </SelectBlock>
            {open && (
                <ListBlock style={{ ...style.listBox, minWidth: `${minWidth}px` }}>
                    {list.map((el, i) => (
                        <List
                            key={i}
                            onClick={() => {
                                setOpen(!open);
                                if (onClick) onClick(el);
                            }}
                            style={style.list}
                            className={classes.list}
                        >
                            {el.item ? el.item : el}
                        </List>
                    ))}
                </ListBlock>
            )}
        </Container>
    );
}
