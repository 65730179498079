import React from 'react';
import ModalRobot from '../../../components/common/ModalRobot';
import { ReactComponent as Heart4 } from '../../../assets/images/icon-anim/CR2_icon.svg';
import CR from '../../../assets/texts/CR';
import SimpleRobotModalBody from '../../../components/SimpleRobotModalBody';
import styled from 'styled-components';
import media from '../../../utils/media';
const Title = styled.div`
    /* height: 153px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* white-space: nowrap; */
    letter-spacing: -0.2px;
    ${media.medium} {
        height: 88px;
    }
`;
export default function HeartModal({ onClick, newTeacherHeartCount }) {
    const txtObj = CR(2);
    return (
        <ModalRobot
            color="teacher"
            {...{ ...txtObj }}
            title={
                <Title>
                    {txtObj.title.split('\n').map(el => (
                        <div key={el}>{el}</div>
                    ))}
                </Title>
            }
            body={<SimpleRobotModalBody img={<Heart4 />} imgCount={newTeacherHeartCount} text={txtObj.body} />}
            buttons={[{ text: txtObj.buttons[0], onClick }]}
        ></ModalRobot>
    );
}
