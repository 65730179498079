const mediaQuery = maxWidth => `
    @media (max-width: ${maxWidth}px)
`;
export const breakPoints = {
    large: 1200, // 태블릿 가로 993~1200
    medium: 992, // 태블릿 세로 769~992
    small: 768, // 핸드폰 가로 577~768
    xsmall: 576 // 핸드폰 세로 ~576
};
const media = {
    large: mediaQuery(breakPoints.large),
    medium: mediaQuery(breakPoints.medium),
    small: mediaQuery(breakPoints.small),
    xsmall: mediaQuery(breakPoints.xsmall),
    custom: mediaQuery
};

export default media;
