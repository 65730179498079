import React, { useMemo } from 'react';
import { getLearningRepeatProgram } from '../../api/lms';
import usePromise from '../../hooks/usePromise';
import RepeatProgramList from './components/repeat/RepeatProgramList';
function getNeedRepeatProgram(programInfos) {
    const { status } = programInfos[0];
    if (status === 2) return true; //처방 없음
    return status === 3 && programInfos.slice(1).every(el => /M1\.[A-Z]99\.\d{2}/.test(el.programId));
}

export default function RepeatProgramContainer({ onButtonClick, programInfos, gradeCode, curriculumCode }) {
    const isNeedProgram = useMemo(() => getNeedRepeatProgram(programInfos), [programInfos]);
    const { data: repeatProgramInfos } = usePromise(getLearningRepeatProgram, !isNeedProgram, {
        gradeCode,
        curriculumCode
    });
    if (!repeatProgramInfos || repeatProgramInfos?.length === 0) return null;
    return <RepeatProgramList onButtonClick={onButtonClick} programInfos={repeatProgramInfos}></RepeatProgramList>;
}
