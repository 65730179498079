import React from 'react';
import styled from 'styled-components';
import Button from '../../components/common/Button';
import { ReactComponent as Robot } from '../../assets/images/character-anim/GN1_character.svg';
import ER from '../../assets/texts/ER';
import useSound from '../../hooks/useSound';
import { soundPath } from '../../utils/util';
import media from '../../utils/media';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const Block = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    background: linear-gradient(
        135deg,
        #009ab5,
        #009fb7,
        #00b6bf,
        #00bec2,
        #03bdc4,
        #0ebacc,
        #20b6d8,
        #38b0ea,
        #57a8ff
    );
    & > div {
        margin: 2em;
        display: flex;
        align-items: center;
        flex-direction: column;
        .message {
            color: white;
            ${media.xsmall} {
                font-size: 21px;
            }
        }
        .btn {
            margin-top: 2em;
            width: 180px;
        }
    }
    & > svg {
        width: 250px;
        height: 250px;
        ${media.xsmall} {
            width: 190px;
            height: 190px;
        }
    }
`;

export default function ErrorPage({ code, onClick, message, btn = true, autoPlay = true }) {
    const txtObj = ER(code);
    if (code === 555) txtObj.message = message;
    const history = useHistory();
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch({ type: 'INIT_STORE' }); //리덕스 초기화
        if (onClick) onClick();
        history.push('/sign-in');
    };
    useSound({ src: `${soundPath}/${txtObj.src}`, autoPlay });

    return (
        <Block>
            <Robot></Robot>
            <div>
                {txtObj.message.split('\n').map(el => (
                    <div key={el} className="h3 message">
                        {el}
                    </div>
                ))}
                {btn && <Button className="btn h4" text="확인" onClick={handleClick}></Button>}
            </div>
        </Block>
    );
}
