import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import { Provider } from 'react-redux';
import GlobalStyle from './assets/styles/GlobalStyle';
import './assets/styles/font.css';
import './utils/prototypes';
import store from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { startGA } from './utils/util';

startGA();

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <GlobalStyle />
            <Routes />
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

serviceWorkerRegistration.register({
    onUpdate: registration => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
});
