import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Program1 } from '../../../assets/images/status_5.svg';
import { ReactComponent as Program2 } from '../../../assets/images/status_6.svg';
import { ReactComponent as Program3 } from '../../../assets/images/status_1.svg';
import { ReactComponent as Program4 } from '../../../assets/images/status_2.svg';
import { ReactComponent as Program5 } from '../../../assets/images/status_3.svg';
import { ReactComponent as Program6 } from '../../../assets/images/status_4.svg';
import theme from '../../../assets/styles/theme';
import AD from '../../../assets/texts/AD';

const Block = styled.div`
    padding: 1.6rem;
    height: 100%;
    display: flex;
    align-items: center;
    background: ${theme.palette.primaryLight};
    & > .container {
        max-width: 840px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .card {
            background: ${theme.palette.white};
            margin: 1em 1em;
            min-width: 280px;
            max-width: 380px;
            width: 100%;
            height: 8em;
            border-radius: 1em;
            display: flex;
            align-items: center;
            padding: 1.5em;
            box-shadow: 0 0 2px 2px ${theme.palette.gray};
            svg {
                flex-shrink: 0;
                width: 4em;
                height: auto;
            }
            .texts {
                margin-left: 1em;
            }
            &.selected {
                background: #e5e5e5;
                box-shadow: none;
            }
        }
    }
`;
const txtObj = AD(5);

const ProgramImage = [<Program1 />, <Program2 />, <Program3 />, <Program4 />, <Program5 />, <Program6 />];

export default function MainInfo() {
    return (
        <Block>
            <div className="container">
                {ProgramImage.map((el, i) => (
                    <div className={`card`} key={i}>
                        {el}
                        <div className={`texts h5`}>
                            {txtObj.text[i + 1].split('\n').map(el => (
                                <div key={el}>{el}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Block>
    );
}
