import React from 'react';
import { ReactComponent as TopRightImg } from '../../../assets/images/sign-in/Login_planet1.svg';
import { ReactComponent as BottomLeftImg } from '../../../assets/images/sign-in/Login_planet2.svg';
import { ReactComponent as StarsDownImg } from '../../../assets/images/sign-in/BG_Day_star_down.svg';
import { ReactComponent as StarsUpImg } from '../../../assets/images/sign-in/BG_Day_star_up.svg';
import styled, { css } from 'styled-components';
import media from '../../../utils/media';
const commonCSS = css`
    position: fixed;
    z-index: -1;
`;
const TopRight = styled(TopRightImg)`
    ${commonCSS}
    top: 1.5em;
    right: 1.5em;
    width: 20vw;
    height: calc(50vh - 101.5px - 1.5em);
    ${media.xsmall} {
        height: calc(50vh - 210px - 1.5em);
    }
`;
const BottomLeft = styled(BottomLeftImg)`
    ${commonCSS}
    bottom: 0;
    left: 0;
    width: 90vw;
    height: calc(50vh - 101.5px);
    ${media.xsmall} {
        height: calc(50vh - 210px);
    }
`;
const StarsUp = styled(StarsUpImg)`
    ${commonCSS}
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(50vh - 101.5px - 1.5em);
    ${media.xsmall} {
        height: calc(50vh - 210px - 1.5em);
    }
`;
const StarsDown = styled(StarsDownImg)`
    ${commonCSS}
    bottom: 0;
    right: 0;
    width: 100vw;
    height: calc(50vh - 101.5px);
    ${media.xsmall} {
        height: calc(50vh - 210px);
    }
`;
export default function BackgroundElements() {
    return (
        <>
            <StarsUp preserveAspectRatio="xMinYMin" />
            <StarsDown preserveAspectRatio="xMaxYMax" />
            <TopRight preserveAspectRatio="xMaxYMin"></TopRight>
            <BottomLeft preserveAspectRatio="xMinYMax"></BottomLeft>
        </>
    );
}
