import React from 'react';
import styled from 'styled-components';
import media from '../../../utils/media';
import { ReactComponent as Star0Icon } from '../../../components/FrameLearning/images/star_0.svg';
import { ReactComponent as Star1Icon } from '../../../components/FrameLearning/images/star_1.svg';
import { ReactComponent as Star2Icon } from '../../../components/FrameLearning/images/star_2.svg';
import { ReactComponent as StarnullIcon } from '../../../components/FrameLearning/images/star_null.svg';
import { ReactComponent as ProgramsEmpty } from '../../../components/FrameLearning/images/programs_empty.svg';
import { ReactComponent as ProgramsFill } from '../../../components/FrameLearning/images/programs_fill.svg';
import theme from '../../../assets/styles/theme';

const Block = styled.div`
    width: 100%;
    height: 50px;
    ${media.medium} {
        height: 45px;
    }
    ${media.small} {
        height: 35px;
    }
    ${media.xsmall} {
        height: 25px;
    }
    background: ${theme.palette.primary};
    .container {
        margin: 0 auto;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        padding: 5px 1.6rem 5px 1.6rem;
        justify-content: space-between;
    }

    .stars {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0 1em;
    }
`;

export default function ProgressStar({ result, onProgramDetailClick, achievementDegree, feedback }) {
    const firstNull = result.indexOf(null);
    const width = Math.max(40 * result.length, 40) + (achievementDegree === null ? -10 : 60);
    return (
        <Block>
            <div className="container">
                <div className="stars">
                    <svg viewBox={`0 0 ${width} 45`} width={`${width}px`} height="100%" style={{ maxWidth: '100%' }}>
                        {result.map((el, i) => (
                            <React.Fragment key={i}>
                                {el === 0 && <Star0Icon width="30" x={40 * i}></Star0Icon>}
                                {el === 1 && <Star1Icon width="30" x={40 * i}></Star1Icon>}
                                {el === null && firstNull === i && !feedback && <Star2Icon width="30" x={40 * i} />}
                                {el === null && (firstNull !== i || feedback) && <StarnullIcon width="30" x={40 * i} />}
                            </React.Fragment>
                        ))}
                        {achievementDegree !== null && (
                            <g onClick={onProgramDetailClick}>
                                <mask id="mymask">
                                    <rect
                                        x="0"
                                        y="0"
                                        width="100%"
                                        fill="#fff"
                                        height={((100 - Math.min(100, achievementDegree)) / 100) * 45.7}
                                        style={{
                                            transition: 'height 1s'
                                        }}
                                    ></rect>
                                </mask>
                                <rect width="60" height="100%" x={40 * result.length} fill="transparent"></rect>
                                <ProgramsFill width="60" x={40 * result.length}></ProgramsFill>
                                <ProgramsEmpty width="60" x={40 * result.length} mask="url('#mymask')"></ProgramsEmpty>
                            </g>
                        )}
                    </svg>
                </div>
            </div>
        </Block>
    );
}
