import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useRedux({ thunk, dataKey, params = {}, wait = false }) {
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector(state => state[dataKey]);
    const update = useCallback(
        () => dispatch(thunk(params)),
        // eslint-disable-next-line
        Object.values(params).concat([dispatch, thunk])
    );
    useEffect(() => {
        if (!loading && !data && !wait) update();
    }, [update, loading, data, wait]);

    return { data, loading, error, update };
}
