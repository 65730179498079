import React, { useMemo } from 'react';
import styled from 'styled-components';
import MainCard from './MainCard';
import { useSelector } from 'react-redux';
import { ReactComponent as Help } from '../../../assets/images/main/list_chapter_information.svg';
import { useHistory } from 'react-router-dom';
import { breakPoints } from '../../../components/FrameLearning/util/media';
import SelectComponent from '../../../components/common/SelectComponent';
import SoundButton from '../../../components/SoundButton';
import theme from '../../../assets/styles/theme';
import { ReactComponent as Gear } from '../../../assets/images/report/Gear.svg';
import media from '../../../utils/media';
const ListBlock = styled.div`
    max-width: ${breakPoints.large}px;
    background: white;
    margin: 0 auto 0 auto;
    padding: 0 1.6rem 0 1.6rem;
`;

const SelectGrade = styled.div`
    padding: 5px 1em 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    ${media.xsmall} {
        align-items: flex-start;
    }
    .select-name-container {
        display: flex;
        align-items: center;
        height: 2.5em;
        .display-name {
            margin-left: -0.5rem;
        }
    }

    .right {
        color: ${theme.palette.primary};
        display: flex;
        align-items: center;

        .Gear0 {
            fill: ${theme.palette.primary};
        }
        .learning-target {
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
                margin-right: 0.3em;
            }
            span {
                margin-bottom: 0.03em;
            }
        }
        .help {
            cursor: pointer;
            margin-left: 1em;
            display: flex;
            align-items: center;
        }
        ${media.xsmall} {
            flex-direction: column;
            align-items: flex-end;
            .learning-target {
                /* order: 2; */
                display: none;
            }
            .help {
                /* order: 1; */
                height: 40px;
            }
        }
    }
`;
const LearningTargetXsmall = styled.div`
    padding: 0 1em 0 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    display: none;
    color: ${theme.palette.primary};
    .Gear0 {
        fill: ${theme.palette.primary};
    }
    svg {
        margin-right: 0.3em;
    }
    span {
        margin-bottom: 0.03em;
    }
    ${media.xsmall} {
        display: flex;
    }
`;
const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
    height: calc(100% - 70px);
`;

function MainList({
    gradeInfos,
    curriculumInfos,
    onClick,
    onChangeGrade,
    displayName,
    learnerInfo,
    onLearningTargetClick
}) {
    const { gradeCode } = useSelector(state => state.learningInfo);
    const history = useHistory();
    const grades = useMemo(() => gradeInfos.map(el => el + '등급').reverse(), [gradeInfos]);
    const onChange = (el, i) => {
        onChangeGrade(el.replace('등급', ''));
    };
    const onHelpClick = () => history.push('/main-information');
    return (
        <ListBlock>
            <SelectGrade className="h5">
                <div className="select-name-container">
                    <SelectComponent
                        classes={{ select: 'h4' }}
                        list={grades}
                        onClick={onChange}
                        selectIndex={grades.indexOf(gradeCode + '등급') < 0 ? 0 : grades.indexOf(gradeCode + '등급')}
                    ></SelectComponent>
                    <span className="display-name h5">{displayName}친구</span>
                </div>
                <div className="h5 right">
                    {learnerInfo.subject_status_code !== '0' && learnerInfo.subject_status_code !== '1' && (
                        <div className="learning-target" onClick={onLearningTargetClick}>
                            <Gear />
                            <span>목표 학습시간 설정</span>
                        </div>
                    )}
                    <div className="help">
                        <SoundButton>
                            <Help onClick={onHelpClick} style={{ width: 'auto', height: '1.5em' }}></Help>
                        </SoundButton>
                    </div>
                </div>
            </SelectGrade>
            {learnerInfo.subject_status_code !== '0' && learnerInfo.subject_status_code !== '1' && (
                <LearningTargetXsmall onClick={onLearningTargetClick}>
                    <Gear />
                    <span>목표 학습시간 설정</span>
                </LearningTargetXsmall>
            )}
            <List>
                {curriculumInfos.map((el, i) => (
                    <MainCard key={el.code} info={el} onClick={onClick} />
                ))}
            </List>
        </ListBlock>
    );
}

export default MainList;
