import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { initLearningFrame } from '../reducers/learningFrame';
import { setGradeCode, setProgram, setReward } from '../reducers/learningInfo';
import { initLearningGrade } from '../reducers/learningGrade';
import * as util from '../utils/util';
import * as lms from '../api/lms';
import { initLearningCurriculum } from '../reducers/learningCurriculum';
import useLearnerInfo from './useLearnerInfo';
import { grades, planetNames } from '../utils/dataMap';
import useProgramPage from './useProgramPage';
const useLearningStart = () => {
    const { setFocusIndex } = useProgramPage();
    const { gradeCode, curriculumCode } = useSelector(state => state.learningInfo);
    const { data: curriculums } = useSelector(state => state.learningCurriculum);
    const dispatch = useDispatch();
    const history = useHistory();
    const { data: learnerInfo } = useLearnerInfo();
    return async ({ programId, programName, isRepeat }) => {
        if (programId.indexOf('DG') !== -1) {
            // result.code
            // '60': 진단프로그램 실행
            // '61': 자동이력 반영 후 진단프로그램 실행
            // '51': 진단처방 실시, 처방 있음 repeat
            // '52': 진단처방 실시, 처방 있음 minus
            // '30': 진단처방 실시, 등급 미달성
            // '31': 진단처방 실시, 등급 달성
            // '32': 진단처방 실시, 수료

            const { code, diagnosisProgramId, learningProgramCount, wrongObjectiveCount } = await lms.getLearningStart({
                gradeCode,
                curriculumCode
            });
            if (['60', '61'].includes(code)) {
                dispatch(initLearningFrame());
                dispatch(setProgram({ programId: diagnosisProgramId, programName }));
                setTimeout(() => history.replace('/learning'), 300);
            }
            if (['51', '52', '53'].includes(code)) {
                const rewardArr = [];
                if (code === '51')
                    rewardArr.push({
                        type: 'repeat',
                        txtType: 24,
                        params: { programName, programCount: learningProgramCount }
                    });
                if (code === '52')
                    rewardArr.push({
                        type: 'minus',
                        txtType: 25,
                        params: { programName, programCount: learningProgramCount }
                    });
                if (code === '53')
                    rewardArr.push({
                        type: 'system5',
                        txtType: 26,
                        params: { programName, programCount: learningProgramCount }
                    });
                rewardArr.push({ type: 'stop', params: { to: '/program' } });
                dispatch(setReward(rewardArr));
                history.replace('/reward-item');
            }
            if (['30', '31', '32'].includes(code)) {
                const rewardArr = [];
                rewardArr.push({
                    type: 'RW.03',
                    value: 1,
                    txtType: wrongObjectiveCount ? 23 : util.getContinuousPassDiagnosis(curriculumCode, curriculums),
                    params: { programName }
                });
                if (code === '31' || code === '32') {
                    rewardArr.push({
                        type: `RW.1${grades.indexOf(gradeCode)}`,
                        txtType: 30,
                        value: 1,
                        params: {
                            gradeCode,
                            displayName: learnerInfo.displayName,
                            planetName: planetNames[grades.indexOf(gradeCode)]
                        }
                    }); // 등급 달성
                    if (code === '32')
                        rewardArr.push({
                            type: code === 'complete',
                            txtType: 31,
                            params: { displayName: learnerInfo.displayName }
                        }); // 등급 달성
                    dispatch(setGradeCode(null)); // gradeCode 초기화
                    dispatch(initLearningGrade()); // gradeInfos 초기화
                }
                // TODO curriculumInfos 초기화
                rewardArr.push({ type: 'stop', params: { to: '/main' } });
                dispatch(initLearningCurriculum());
                dispatch(setReward(rewardArr));
                setFocusIndex(null);
                await lms.postRewardHistory(util.filterRewards(rewardArr));
                history.replace('/reward-item');
            }
        } else {
            dispatch(initLearningFrame());
            dispatch(setProgram({ programId, programName }));
            history.replace('/learning');
        }
    };
};

export default useLearningStart;
