import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Timer } from '../../../assets/images/learning/timer.svg';
import theme from '../../../assets/styles/theme';
import media from '../../../utils/media';
import { convertsecToMinuteSec } from '../../../utils/util';
const Block = styled.div`
    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 42px;
        margin-bottom: 36px;
        ${media.medium} {
            margin-top: 30px;
            margin-bottom: 20px;
        }
    }
    .timer {
        height: auto;
        margin-right: 25px;
        width: 80px;
        ${media.medium} {
            width: 50px;
            margin-right: 10px;
        }
    }
    .primary {
        color: ${theme.palette.primary};
    }
    .text {
        margin-bottom: 36px;
        ${media.medium} {
            margin-bottom: 20px;
        }
    }
`;
export default function TimerModalBody({ children, time }) {
    return (
        <Block>
            <div className="icon-container">
                <Timer className="timer" />
                <span className="primary">{convertsecToMinuteSec(time)}</span>
            </div>
            <div className="text">{children}</div>
        </Block>
    );
}
