import * as lms from '../api/lms';
import { createPromiseThunk, createInitThunk, handleAsyncActions } from './asyncUtils';

const GET_LEARNING_CURRICULUM = 'learningCurriculum/GET_LEARNING_CURRICULUM';

export const getLearningCurriculum = createPromiseThunk(GET_LEARNING_CURRICULUM, lms.getLearningCurriculum);
export const initLearningCurriculum = createInitThunk(GET_LEARNING_CURRICULUM);

const learningCurriculum = handleAsyncActions(GET_LEARNING_CURRICULUM);
export default learningCurriculum;
