import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Robot } from '../../assets/images/character-anim/GN1_character.svg';
import media from '../../utils/media';
const Block = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    background: linear-gradient(
        135deg,
        #009ab5,
        #009fb7,
        #00b6bf,
        #00bec2,
        #03bdc4,
        #0ebacc,
        #20b6d8,
        #38b0ea,
        #57a8ff
    );
    ${media.small} {
        flex-direction: column;
    }
    & > div {
        margin: 2em;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: white;
        .title {
            margin-bottom: 1rem;
        }
        .duration {
            margin-bottom: 1rem;
            background: #58daf0;
            padding: 0.125rem 0.5rem;
        }
    }
    & > svg {
        width: 250px;
        height: 250px;
        ${media.xsmall} {
            width: 190px;
            height: 190px;
        }
    }
`;

export default function SystemMaintenancePage({ title, duration, content }) {
    return (
        <Block>
            <Robot></Robot>
            <div>
                {title.split('\n').map(el => (
                    <div key={el} className="h1 title">
                        {el}
                    </div>
                ))}
                {duration.split('\n').map(el => (
                    <div key={el} className="h4 duration">
                        {el}
                    </div>
                ))}
                {content.split('\n').map(el => (
                    <div key={el} className="h6 content">
                        {el}
                    </div>
                ))}
            </div>
        </Block>
    );
}
