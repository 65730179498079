import React, { useState, useMemo, useRef, useEffect } from 'react';
import Loading from '../../components/common/Loading';
import * as coms from '../../api/coms';
import { addRememberId, getRememberIds, clearRememberIds } from '../../utils/cookies';
import { setAccessToken, setGAConfig } from '../../utils/util';
import { useDispatch } from 'react-redux';
import CL from '../../assets/texts/CL';
import Layout from './components/Layout';
import SigninForm from './components/SigninForm';
import SigninRobot from './components/SigninRobot';
import BackgroundElements from './components/BackgroundElements';
import Dialog from '../../components/common/Dialog';
import useSetHeartReward from '../../hooks/useSetHeartReward';
import SimpleDialogBody from '../../components/SimpleDialogBody';
import SignInModalTitle from './components/SignInModalTitle';

function SigninContainer() {
    const signinForm = useRef();
    const errorMessage = useRef();
    const [loginState, setLoginState] = useState(null);
    const [rememberIds, setRememberIds] = useState(getRememberIds());
    const [errorType, setErrorType] = useState();
    const [continuous401, setContinuous401] = useState(0);

    const dispatch = useDispatch();
    const { start } = useSetHeartReward();
    const onSubmit = async e => {
        e.preventDefault();
        const form = signinForm.current;
        const submitData = {
            username: form.username.value,
            password: form.password.value
        };
        if (submitData.username === '' || submitData.password === '') {
            setContinuous401(0);
            setErrorType(1);
            setLoginState('error');
            return;
        }

        if (window.screen && window.screen.width && window.screen.height) {
            submitData.screenSize = `${window.screen.width}*${window.screen.height}`;
        }
        setLoginState('loading');
        try {
            const result = await coms.postSignIn(submitData);
            addRememberId(form.username.value);
            setGAConfig({ user_id: form.username.value?.toUpperCase() });
            setAccessToken(result.accessToken);
            dispatch({ type: 'INIT_STORE' }); //리덕스 초기화
            start();
        } catch (e) {
            setLoginState('error');
            if (!navigator.onLine) {
                setErrorType(8);
                return;
            }
            setContinuous401(prev => (e.response && e.response.status === 401 ? prev + 1 : 0));
            //TODO: 에러타입에 따른 모달창
            switch (e.response && e.response.status) {
                case 401:
                    setErrorType(3); //username 맞지만 password 틀림
                    form.password.value = '';
                    break;
                case 402:
                    setErrorType(4); //체험회원 기간만료
                    break;
                case 403:
                    setErrorType(5); // 퇴회회원
                    break;
                case 404:
                    setErrorType(2); //username 찾을 수 없음
                    form.password.value = '';
                    form.username.value = '';
                    break;
                case 555:
                    errorMessage.current = e.response.data;
                    setErrorType(7); // 서비스 점검 중
                    break;
                default:
                    setErrorType(6);
            }
        }
    };
    const handleClearRememberIds = () => {
        setRememberIds([]);
        clearRememberIds();
    };
    const onSignErrorClick = () => setLoginState(null);
    const modalInfo = useMemo(() => {
        if (!errorType) return null;
        return {
            title: <SignInModalTitle>알림</SignInModalTitle>,
            body: <SimpleDialogBody>{errorType === 7 ? errorMessage.current : CL(errorType)}</SimpleDialogBody>,
            src: `OS7_ver4.mp3`,
            buttons: [{ text: '확인', onClick: onSignErrorClick }]
        };
    }, [errorType]);
    useEffect(() => {
        if (continuous401 > 0 && continuous401 % 5 === 0) {
            coms.postSignInFail({ username: signinForm.current.username.value });
        }
    }, [continuous401]);
    return (
        <Layout>
            <SigninRobot />
            <SigninForm
                signinForm={signinForm}
                onSubmit={onSubmit}
                rememberIds={rememberIds}
                clearRememberIds={handleClearRememberIds}
            />
            {loginState === 'loading' && <Loading />}
            {loginState === 'error' && modalInfo && <Dialog {...{ ...modalInfo }}></Dialog>}
            <BackgroundElements></BackgroundElements>
        </Layout>
    );
}

export default SigninContainer;
